import produce from 'immer'
import {
  ADD_SECTION_RESPONSE,
  GET_SECTION_BY_SECTION_ID_RESPONSE,
  GET_SECTION_LIST_BY_LAW_RESPONSE,
  GET_SECTION_LIST_BY_STATUS_RESPONSE,
  UPDATE_SECTION_RESPONSE,
  UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE,
  GET_ALL_SECTION_LIST_RESPONSE,
  DELETE_SECTION_BY_SECTION_ID_RESPONSE,
  GET_SECTION_LIST_BY_LAW_IDS_RESPONSE,
} from './actionType'
export const initialState = {
  sectionApiResponse: null,
  editSectionApiResponse: null,
  sectionList: [],
  LawsectionList: [],
  sectionListByLawIds: [],
}
const sectionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_SECTION_RESPONSE:
        {
          if (action.payload.message == undefined) {
            draft.sectionApiResponse = null
          } else {
            draft.sectionApiResponse = {
              message: action.payload.message,
              responseType: action.payload.responseType,
            }
          }
        }

        break
      case GET_ALL_SECTION_LIST_RESPONSE:
        draft.sectionList = action.payload.data
        break
      case GET_SECTION_LIST_BY_LAW_IDS_RESPONSE:
        draft.sectionListByLawIds = action.payload.data
        break
      case GET_SECTION_BY_SECTION_ID_RESPONSE:
        draft.editSectionApiResponse = {
          message: action.payload.message,
          data: action.payload.data,
          responseType: action.payload.responseType,
        }
        break
      case UPDATE_SECTION_RESPONSE:
        draft.sectionApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE:
        draft.sectionApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case GET_SECTION_LIST_BY_STATUS_RESPONSE:
        draft.sectionList = action.payload.sectionList
        break
      case DELETE_SECTION_BY_SECTION_ID_RESPONSE:
        draft.sectionApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case GET_SECTION_LIST_BY_LAW_RESPONSE:
        draft.LawsectionList = action.payload.sectionList
        break
      default:
        state = { ...state }
        break
    }
  })
export default sectionReducer
