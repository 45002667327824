export const TOKEN = 'accessToken'
export const ROLE_ID = 'RoleId'
export const USER_ID = 'UserId'
export const USER_NAME = 'UserName'
export const USER_TYPE_ID = 'UserTypeId'
export const COMPANY_ID = 'CompanyId'
export const EMAIL = 'email'
export const USERFULLNAME = 'fullName'
export const SUBSCRIBER_TOKEN = 'subscriberToken'
export const LOGIN_TYPE = 'login_type'
