import {
  CREATE_FAQ_REQUEST,
  CREATE_FAQ_RESPONSE,
  CREATE_FAQ_REVIEW_REQUEST,
  CREATE_FAQ_REVIEW_RESPONSE,
  CREATE_REVIEW_LAW_REQUEST,
  CREATE_REVIEW_LAW_RESPONSE,
  GET_FAQ_DETAILS_REQUEST,
  GET_FAQ_DETAILS_RESPONSE,
  GET_FAQ_HISTORY_REQUEST,
  GET_FAQ_HISTORY_RESPONSE,
  GET_LIST_FAQ_REQUEST,
  GET_LIST_FAQ_RESPONSE,
  GET_LIST_KEYWORDS_REQUEST,
  GET_LIST_KEYWORDS_RESPONSE,
  IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_REQUEST,
  IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_RESPONSE,
} from './actionType'

export const createFaqRequest = (data) => {
  return {
    type: CREATE_FAQ_REQUEST,
    payload: { data },
  }
}

export const createFaqResponse = (message, responseType) => {
  return {
    type: CREATE_FAQ_RESPONSE,
    payload: { message, responseType },
  }
}
export const getListFaqRequest = (data) => {
  return {
    type: GET_LIST_FAQ_REQUEST,
    payload: { data },
  }
}
export const getListFaqResponse = (data) => {
  return {
    type: GET_LIST_FAQ_RESPONSE,
    payload: { data },
  }
}
export const getListKeywordsRequest = () => {
  return {
    type: GET_LIST_KEYWORDS_REQUEST,
    // payload: { },
  }
}
export const getListKeywordsResponse = (data) => {
  return {
    type: GET_LIST_KEYWORDS_RESPONSE,
    payload: { data },
  }
}
export const getFaqDetailsRequest = (data) => {
  return {
    type: GET_FAQ_DETAILS_REQUEST,
    payload: { data },
  }
}
export const getFaqDetailsResponse = (data) => {
  return {
    type: GET_FAQ_DETAILS_RESPONSE,
    payload: { data },
  }
}

export const getFaqHistoryRequest = (id) => {
  return {
    type: GET_FAQ_HISTORY_REQUEST,
    payload: { id },
  }
}
export const getFaqHistoryResponse = (data) => {
  return {
    type: GET_FAQ_HISTORY_RESPONSE,
    payload: { data },
  }
}

export const createFaqReviewRequest = (data) => {
  return {
    type: CREATE_FAQ_REVIEW_REQUEST,
    payload: { data },
  }
}

export const createFaqReviewResponse = (message, responseType) => {
  return {
    type: CREATE_FAQ_REVIEW_RESPONSE,
    payload: { message, responseType },
  }
}

export const createReviewLawRequest = (data) => {
  return {
    type: CREATE_REVIEW_LAW_REQUEST,
    payload: { data },
  }
}

export const createreReviewLawResponse = (message, responseType) => {
  return {
    type: CREATE_REVIEW_LAW_RESPONSE,
    payload: { message, responseType },
  }
}



export const getActiveDeactiveFaqForFrontendRequest = (data) => {
  return {
    type: IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_REQUEST,
    payload: { data },
  }
}

export const getActiveDeactiveFaqForFrontendResponse = (
  message,
  responseType,

) => {
  return {
    type: IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_RESPONSE,
    payload: { message, responseType },
  }
}
