import moment from 'moment'
import "moment-timezone"
export const getDateFromDateTimeString = (value) => {
  return moment(value, 'DD-MM-YYYY').format('DD-MM-YYYY')
}

export const getDateTimeFromDateTimeString = (value) => {
  return moment(value).format('DD-MM-YYYY')
}

export const getDateTimesSlach = (value) => {
  return moment(value).format('DD-MM-YYYY')
}
export const getTimeFromTimeString = (value) => {
  return moment(value, 'HH:mm').format('HH:mm')
}

export const getDateObjectFromMoment = () => {
  return moment().toDate()
}

export const getDateObjectWithValueFromMoment = (value) => {
  return moment(value, 'DD-MM-YYYY').toDate()
}

export const getCurrentDate = () => {
  return moment(new Date()).format('DD-MM-YYYY')
}
var thisFiscalStart
var thisFiscalEnd
export const getCurrentFinalcialYear = () => {
  if (moment().quarter() === 2) {
    thisFiscalStart = moment()
      .month('April')
      .startOf('month')
      .format('YYYY-MM-DD')
    thisFiscalEnd = moment()
      .add(1, 'year')
      .month('March')
      .endOf('month')
      .format('YYYY-MM-DD')
  } else {
    thisFiscalStart = moment()
      .subtract(1, 'year')
      .month('April')
      .startOf('month')
      .format('YYYY-MM-DD')
    thisFiscalEnd = moment().month('March').endOf('month').format('YYYY-MM-DD')
  }

  return (
    moment(thisFiscalStart).format('YY') +
    '-' +
    moment(thisFiscalEnd).format('YY')
  )
}

export const getCurrentFinalcialYearDate = (value) => {
  var today = new Date()
  if (today.getMonth() + 1 <= 3) {
    thisFiscalStart = moment()
      .add(-1, 'year')
      .month('April')
      .startOf('month')
      .format('YYYY-MM-DD')
    thisFiscalEnd = moment().month('March').endOf('month').format('YYYY-MM-DD')
  } else {
    thisFiscalStart = moment()
      .month('April')
      .startOf('month')
      .format('YYYY-MM-DD')
    thisFiscalEnd = moment()
      .add(1, 'year')
      .month('March')
      .endOf('month')
      .format('YYYY-MM-DD')
  }

  if (value === 'thisFiscalStart') {
    return moment(thisFiscalStart).format('YYYY-MM-DD')
  } else {
    return moment(thisFiscalEnd).format('YYYY-MM-DD')
  }
}

export const getAddDaysDate = (values) => {
  return moment(new Date()).add(values, 'days').format('DD-MM-YYYY')
}

export const getFirstDateOfCurrentMonth = () => {
  return moment().startOf('month').format('DD-MM-YYYY')
}

export const getFirstDateOfCurrentWeek = () => {
  return moment().startOf('week').format('DD-MM-YYYY')
}

export const getCurrentTime = () => {
  return moment().format('HH:mm')
}

export const isValidDate = (value) => {
  let result

  if (value !== '__-__-____' && value !== '') {
    // as input date mask has '__-__-____' value by default
    result = moment(value, 'DD-MM-YYYY', true).isValid()
  } else {
    // if it has default value then return true, as it will be blank and will be handled by the required validator if specified
    result = true
  }

  return result
}

export const isValidTime = (value) => {
  let result

  if (value !== '__:__' && value !== '') {
    // as input time mask has '__:__' value by default
    result = moment(value, 'HH:mm', true).isValid()
  } else {
    // if it has default value then return true, as it will be blank and will be handled by the required validator if specified
    result = true
  }

  return result
}

export const customDateValidator = () => {
  return {
    customDate: {
      // name the rule
      message: 'The :attribute must be a valid date.',
      rule: (val) => {
        return isValidDate(val)
      },
      messageReplace: (message, params) =>
        message.replace(':values', this.helpers.toSentence(params)), // optional
      required: true, // optional
    },
  }
}

export const customTimeValidator = () => {
  return {
    customTime: {
      // name the rule
      message: 'The :attribute must be a valid time.',
      rule: (val) => {
        return isValidTime(val)
      },
    },
  }
}

export const getDayName = (date) => {
  let dateTime
  dateTime = moment(date, 'DD-MM-YYYY')
  return dateTime.format('dddd')
}

export const getCurrFinancialYear = () => {
  var fiscalyear = ''
  var today = new Date()

  if (today.getMonth() + 1 <= 3) {
    fiscalyear =
      Number(today.getFullYear().toString().slice(2)) -
      1 +
      '-' +
      Number(today.getFullYear().toString().slice(2))
  } else {
    fiscalyear =
      Number(today.getFullYear().toString().slice(2)) +
      '-' +
      (Number(today.getFullYear().toString().slice(2)) + 1)
  }

  return fiscalyear
}

export const getStartDateOfCurrentFinancialYear = () => {
  //get current month in number formate for comparision
  let currentMonth = moment(new Date()).format('M')

  //get last year
  let lastYear = moment().subtract(1, 'year').format('YYYY')

  // Check the current month and based on that get the start date of current financial year
  // compare currentMonth with the 4(April) as 4(April) is the starting month of the current financial year
  if (currentMonth >= 4) {
    return moment().month(3).startOf('month').format('DD-MM-YYYY')
  } else {
    return moment()
      .year(lastYear)
      .month(3)
      .startOf('month')
      .format('DD-MM-YYYY')
  }
}

export const lessThanDateValidator = () => {
  return {
    lessThanDate: {
      // name the rule
      message: 'The :attribute can not be less than from date.',
      rule: (val, params) => {
        let fromDate = moment(params[0], 'DD-MM-YYYY').unix()
        let toDate = moment(val, 'DD-MM-YYYY').unix()

        return fromDate <= toDate ? true : false
      },
      required: true, // optional
    },
  }
}

export const AuditLockMechanism = (HeaderDate) => {
  const ModuleRecordDate = moment(HeaderDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
  const FreezeDate = moment(
    localStorage.getItem(authConstants.FREEZE_DATE),
    'DD-MM-YYYY',
  ).format('YYYY-MM-DD')
  const ApplyDate = moment(
    localStorage.getItem(authConstants.APPLY_DATE),
    'DD-MM-YYYY',
  ).format('YYYY-MM-DD')
  var CurrDate = moment().format('YYYY-MM-DD')

  let AuditLock = true
  if (
    (FreezeDate === undefined && ApplyDate === undefined) ||
    (FreezeDate === 'Invalid date' && ApplyDate === 'Invalid date')
  ) {
    return AuditLock === true
  }

  AuditLock =
    ModuleRecordDate >= FreezeDate &&
      CurrDate >= ApplyDate &&
      FreezeDate !== ModuleRecordDate
      ? true
      : false
  return AuditLock
}

export const ConfirmAuditDate = (ModuleHeaderDate) => {
  const FreezeDate = moment(
    localStorage.getItem(authConstants.FREEZE_DATE),
    'DD-MM-YYYY',
  ).format('YYYY-MM-DD')
  const ApplyDate = moment(
    localStorage.getItem(authConstants.APPLY_DATE),
    'DD-MM-YYYY',
  ).format('YYYY-MM-DD')
  let ActualDate = moment(ModuleHeaderDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
  var CurrDate = moment().format('YYYY-MM-DD')
  if (CurrDate >= ApplyDate) {
    if (FreezeDate >= ActualDate) {
      return true
      // await SearchModalConfirmBox(
      //     'Please enter valid voucher date'
      // );
      // return false;
    }
  }
}

export function formatDateForApi(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()
  if (month?.length < 2) month = '0' + month
  if (day?.length < 2) day = '0' + day
  return [year, month, day].join('-')
}

export const getDateTimeFromDateTime = (value) => {
  return moment(value).format('DD-MM-YYYY HH:mm:ss')
}

export const getDateTimezone = (value) => {
  let date = moment(value, 'DD-MM-YYYY HH:mm:ss').format('MM-DD-YYYY HH:mm:ss')
  let utcDate = new Date(date).toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    hour12: true,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
  return moment(utcDate, 'MM-DD-YYYY HH:mm:ss A').format(
    'DD-MM-YYYY hh:mm:ss A',
  )
}


export const getFormatDate = (value, format) => {
  return moment(value).format(format)
}

// export const dateFormatChange = (value, currentFormat, newFormat) => {
//   return moment(value, currentFormat).format(newFormat)
// }

export const dateFormatChange = (dateString, currentFormat, newFormat) => {
  //Convert the original date string to Ahmedabad time zone (Asia/Kolkata)
  const ahmedabadDateTime = moment.tz(dateString, currentFormat, "Asia/Kolkata");

  // Extract the hour from the date in the Asia/Kolkata time zone
  const hour = ahmedabadDateTime.format('H');

  // Convert the hour to 12-hour format
  const hourIn12HourFormat = (parseInt(hour, 10) % 12) || 12;

  // Replace the hour portion in the formatted date string with the 12-hour format
  const formattedDateTime = ahmedabadDateTime.format(newFormat).replace(hour, hourIn12HourFormat);
  return formattedDateTime
}

// export const dateFormatChange = (dateString, currentFormat, newFormat) => {
//   // Parse the date string using the original format and set it to UTC
//   let parsedDate = moment.utc(dateString, currentFormat);

//   // Convert the parsed date to Indian time zone (Asia/Kolkata) and format it
//   let formattedDate = parsedDate.tz('Asia/Kolkata').format(newFormat);
//   return formattedDate
// }