import produce from 'immer'
import {
  CREATE_FAQ_RESPONSE,
  CREATE_FAQ_REVIEW_RESPONSE,
  CREATE_REVIEW_LAW_RESPONSE,
  GET_FAQ_DETAILS_RESPONSE,
  GET_FAQ_HISTORY_RESPONSE,
  GET_LIST_FAQ_RESPONSE,
  GET_LIST_KEYWORDS_RESPONSE,
  IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_RESPONSE,
} from './actionType'

export const initialState = {
  addFaq: null,
  listFaq: null,
  listKeywords: null,
  detailFaq: null,
  faqHistoryApiResponse: null,
  faqReviewApiResponse: null,
  reviewLawApiResponse: null,
  activeDeactiveFaqApiResponse: null
}

export const faqReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_FAQ_RESPONSE:
        if (action.payload.message == undefined) {
          draft.addFaq = null
        } else {
          draft.addFaq = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          }
        }
        break
      case GET_LIST_KEYWORDS_RESPONSE:
        draft.listKeywords = action.payload.data
        break
      case GET_LIST_FAQ_RESPONSE:
        draft.listFaq = action.payload.data
        break
      case GET_FAQ_DETAILS_RESPONSE:
        draft.detailFaq = action.payload.data
        break
      case GET_FAQ_HISTORY_RESPONSE:
        draft.faqHistoryApiResponse = action.payload.data
        break

      case CREATE_FAQ_REVIEW_RESPONSE:
        draft.addFaq = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CREATE_REVIEW_LAW_RESPONSE:
        draft.reviewLawApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_RESPONSE:
        draft.activeDeactiveFaqApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      // if (action.payload.message == undefined) {
      //   draft.listFaq = null;
      // }
      // else {
      // draft.listFaq = {
      //   message: action.payload.message,
      //   data: action.payload.data,
      //   responseType: action.payload.responseType
      // }
      // } break
      default:
        state = { ...state }
        break
    }
  })
