import {
  GET_ROLE_LIST_REQUEST,
  GET_ROLE_LIST_RESPONSE,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_RESPONSE,
  ROLE_PERMISSION_REQUEST,
  ROLE_PERMISSION_RESPONSE,
  ROLE_PERMISSION_UPDATE_REQUEST,
  UPDATE_ROLE_REQUEST,
  ROLE_PERMISSION_UPDATE_RESPONSE,
} from './actionType'

export const getRoleListRequest = () => {
  return {
    type: GET_ROLE_LIST_REQUEST,
  }
}

export const getRoleListResponse = (getRoleList) => {
  return {
    type: GET_ROLE_LIST_RESPONSE,
    payload: { getRoleList },
  }
}
export const createRoleRequest = (data) => {
  return {
    type: CREATE_ROLE_REQUEST,
    payload: { data },
  }
}
export const createRoleResponse = (data) => {
  return {
    type: CREATE_ROLE_RESPONSE,
    payload: { data },
  }
}
export const updateRoleRequest = (data) => {
  return {
    type: UPDATE_ROLE_REQUEST,
    payload: { data },
  }
}
export const updateRoleResponse = (updateRole) => {
  return {
    type: UPDATE_ROLE_REQUEST,
    payload: { updateRole },
  }
}

export const rolePermissionRequest = (data) => {
  return {
    type: ROLE_PERMISSION_REQUEST,
    payload: { data },
  }
}
export const rolePermissionResponse = (data) => {
  return {
    type: ROLE_PERMISSION_RESPONSE,
    payload: { data },
  }
}

export const rolePermissionUpdateRequest = (data) => {
  return {
    type: ROLE_PERMISSION_UPDATE_REQUEST,
    payload: { data },
  }
}

export const rolePermissionUpdateResponse = () => {
  return {
    type: ROLE_PERMISSION_UPDATE_RESPONSE,
    payload: {},
  }
}
