import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import aboutImage1 from 'images/about-us_6.png'

import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { getUserDetailsRequest } from 'store/frontEnd/about/action'
import HelmetComponent from '../Helmet/HelmetComponent'
import { getQueryNotificationRequest } from 'store/frontEnd/home/action'

const About = (props) => {
  useEffect(() => {
    if (localStorage.getItem('subscriberToken')) {
      props.getQueryNotificationRequest()
    }
  }, [])

  return (
    <>
      <HelmetComponent title={null} description={null} keywords={null} />
      <div className="inner-main">
        <div className="top_section">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>About Us</h1>
                  <span>About Us</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>About Us</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about_main">
          <div className="container">
            <Row>
              <Col lg={8} className="contact_text">
                <h3>We are helping to grow your Bussiness.</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="about_section1">
          <div className="container">
            <Row className="m-0">
              <Col lg={6} className="about_text">
                <span className="sub-title">WHY CHOOSE TEXFAQ</span>
                <h3>
                  creative team always give you best solution for your business
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas et arcu blandit nisi varius volutpat a a ex. Morbi in
                  tincidunt lacus. Morbi semper nec diam quis euismod.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas et arcu blandit nisi varius volutpat.
                </p>
              </Col>
              <Col lg={6}>
                <div className="why_choose-img">
                  <img src={aboutImage1} alt="about-us" />
                  <svg
                    width="268"
                    height="232"
                    viewBox="0 0 268 232"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8667 0H0V17.8462H17.8667V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 0H35.7333V17.8462H53.6V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 0H89.3333V17.8462H71.4667V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 0H107.2V17.8462H125.067V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 0H160.8V17.8462H142.933V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 0H178.667V17.8462H196.533V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 0H232.267V17.8462H214.4V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 0H250.133V17.8462H268V0Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0 35.6923H17.8667V53.5385H0V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 35.6923H35.7333V53.5385H53.6V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 35.6923H89.3333V53.5385H71.4667V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 35.6923H107.2V53.5385H125.067V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 35.6923H160.8V53.5385H142.933V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 35.6923H178.667V53.5385H196.533V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 35.6923H232.267V53.5385H214.4V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 35.6923H250.133V53.5385H268V35.6923Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0 71.3846H17.8667V89.2308H0V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 71.3846H35.7333V89.2308H53.6V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 71.3846H89.3333V89.2308H71.4667V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 71.3846H107.2V89.2308H125.067V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 71.3846H160.8V89.2308H142.933V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 71.3846H178.667V89.2308H196.533V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 71.3846H232.267V89.2308H214.4V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 71.3846H250.133V89.2308H268V71.3846Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0 107.077H17.8667V124.923H0V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 107.077H35.7333V124.923H53.6V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 107.077H89.3333V124.923H71.4667V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 107.077H107.2V124.923H125.067V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 107.077H160.8V124.923H142.933V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 107.077H178.667V124.923H196.533V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 107.077H232.267V124.923H214.4V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 107.077H250.133V124.923H268V107.077Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0 142.769H17.8667V160.615H0V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 142.769H35.7333V160.615H53.6V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 142.769H89.3333V160.615H71.4667V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 142.769H107.2V160.615H125.067V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 142.769H160.8V160.615H142.933V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 142.769H178.667V160.615H196.533V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 142.769H232.267V160.615H214.4V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 142.769H250.133V160.615H268V142.769Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0 178.462H17.8667V196.308H0V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 178.462H35.7333V196.308H53.6V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 178.462H89.3333V196.308H71.4667V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 178.462H107.2V196.308H125.067V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 178.462H160.8V196.308H142.933V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 178.462H178.667V196.308H196.533V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 178.462H232.267V196.308H214.4V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 178.462H250.133V196.308H268V178.462Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0 214.154H17.8667V232H0V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M53.6 214.154H35.7333V232H53.6V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M71.4667 214.154H89.3333V232H71.4667V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M125.067 214.154H107.2V232H125.067V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M142.933 214.154H160.8V232H142.933V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M196.533 214.154H178.667V232H196.533V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M214.4 214.154H232.267V232H214.4V214.154Z"
                      fill="currentColor"
                    />
                    <path
                      d="M268 214.154H250.133V232H268V214.154Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </Col>
            </Row>
          </div>
          <div className="bg-shape">
            <svg
              width="457"
              height="455"
              viewBox="0 0 457 455"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.2492 224.531L134.416 189.382L116.664 292.492L30.2492 224.531Z"
                fill="currentColor"
              />
              <path
                d="M362.051 205.957L433.718 181.774L421.504 252.714L362.051 205.957Z"
                fill="currentColor"
              />
              <path
                d="M181.217 68.5996L26.1485 1.2662e-06L43.4277 159.355L51.9576 153.737L36.8177 14.1116L172.687 74.2179L181.217 68.5996Z"
                fill="currentColor"
              />
              <path
                d="M162.647 80.8306L49.3754 30.7211L61.9973 147.124L162.647 80.8306Z"
                fill="currentColor"
              />
              <path
                d="M247.967 452.034L272.217 402.388L220.115 406.048L221.839 408.895L267.49 405.688L246.243 449.187L247.967 452.034Z"
                fill="currentColor"
              />
              <path
                d="M244.213 445.837L261.927 409.572L223.869 412.246L244.213 445.837Z"
                fill="currentColor"
              />
              <path
                d="M314.423 78.8747L253.897 118.74L246.307 48.7415L314.423 78.8747Z"
                stroke="currentColor"
                strokeWidth="5"
              />
              <path
                d="M112.639 391.3L52.1141 431.165L44.5239 361.167L112.639 391.3Z"
                stroke="currentColor"
                strokeWidth="5"
              />
              <path
                d="M299.639 253.3L239.114 293.165L231.524 223.167L299.639 253.3Z"
                stroke="currentColor"
                strokeWidth="5"
              />
            </svg>
          </div>
        </div>
        <div className="about_card">
          <div className="container">
            <Row>
              <Col className="">
                <ul>
                  <li>
                    <div>
                      <svg
                        fill="currentColor"
                        height="800px"
                        width="800px"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 286.439 286.439"
                        enableBackground="new 0 0 286.439 286.439"
                      >
                        <path d="m45.422,135.326c-3.313,0-6,2.687-6,6v22.064c0,3.313 2.687,6 6,6s6-2.687 6-6v-22.064c0-3.314-2.687-6-6-6z" />
                        <path d="m69.883,120.619c-3.313,0-6,2.687-6,6v36.771c0,3.313 2.687,6 6,6s6-2.687 6-6v-36.771c0-3.313-2.687-6-6-6z" />
                        <path d="m94.344,98.817c-3.313,0-6,2.687-6,6v58.573c0,3.313 2.687,6 6,6s6-2.687 6-6v-58.573c0-3.314-2.687-6-6-6z" />
                        <path d="m118.805,83.849c-3.313,0-6,2.687-6,6v73.542c0,3.313 2.687,6 6,6s6-2.687 6-6v-73.542c0-3.314-2.687-6-6-6z" />
                        <path d="m281.728,196.948l-43.009-43.009c4.473-10.953 4.097-23.43-1.11-34.131v-47.09c0-8.808-7.166-15.974-15.974-15.974h-205.661c-8.808-0.001-15.974,7.165-15.974,15.973v114.683c0,8.808 7.166,15.974 15.974,15.974h65.068v14.322h-17.278c-3.313,0-6,2.686-6,6s2.687,6 6,6h110.081c3.313,0 6-2.686 6-6s-2.687-6-6-6h-17.278v-14.322h65.068c5.674,0 10.653-2.982 13.487-7.453l23.816,23.816c3.142,3.142 7.268,4.712 11.395,4.712s8.253-1.571 11.395-4.712h0.001c6.281-6.283 6.281-16.507-0.001-22.789zm-8.486,14.304c-1.604,1.604-4.214,1.604-5.819,0l-40.719-40.719 5.819-5.819 40.719,40.719c1.605,1.604 1.605,4.214 0,5.819zm-51.607-19.878h-205.661c-2.191,0-3.974-1.783-3.974-3.974v-114.683c0-2.191 1.782-3.974 3.974-3.974h205.661c2.191,0 3.974,1.783 3.974,3.974v32.357c-16.362-12.656-40.032-11.492-55.035,3.51-16.276,16.278-16.276,42.763 0,59.04 7.886,7.885 18.369,12.228 29.521,12.228 5.526,0 10.888-1.07 15.852-3.106l9.663,9.662v0.993c-0.002,2.19-1.784,3.973-3.975,3.973zm-128.593,26.322v-14.322h51.524v14.322h-51.524zm128.087-58.558c-5.619,5.619-13.09,8.713-21.035,8.713-7.946,0-15.416-3.094-21.035-8.713-11.598-11.598-11.598-30.47 0-42.069 5.8-5.799 13.417-8.699 21.035-8.699 7.617,0 15.235,2.9 21.034,8.699 11.599,11.598 11.599,30.47 0.001,42.069z" />
                        <path d="m199.306,151.167c-7.204-0.435-12.711-6.648-12.276-13.852 0.199-3.308-2.32-6.151-5.628-6.351-3.309-0.197-6.151,2.32-6.351,5.628-0.833,13.809 9.724,25.72 23.532,26.553 0.123,0.007 0.245,0.011 0.367,0.011 3.148,0 5.791-2.454 5.983-5.639 0.2-3.307-2.32-6.15-5.627-6.35z" />
                      </svg>
                    </div>
                    <h5>Business Analysis</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas et arcu blandit nisi varius volutpat.
                    </p>
                  </li>
                  <li>
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 4L13.7071 9.29289C13.3166 9.68342 12.6834 9.68342 12.2929 9.29289L10.7071 7.70711C10.3166 7.31658 9.68342 7.31658 9.29289 7.70711L5 12"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 1V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.07989 19 4.2 19H19"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <h5>Data Analysis</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas et arcu blandit nisi varius volutpat.
                    </p>
                  </li>
                  <li>
                    <div>
                      <svg
                        width="20"
                        height="30"
                        viewBox="0 0 20 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5713 19L7.42847 24.1429L12.5713 29.2857"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M17.7942 10.6429C18.5841 12.011 19 13.563 19 15.1429C19 16.7227 18.5841 18.2747 17.7942 19.6429C17.0043 21.011 15.8682 22.1472 14.5 22.9371C13.1318 23.727 11.5798 24.1429 10 24.1429"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M7.42868 11.2857L12.5715 6.14286L7.42868 0.999999"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M2.20577 19.6429C1.41586 18.2747 1 16.7227 1 15.1429C1 13.563 1.41586 12.011 2.20577 10.6429C2.99569 9.27468 4.13183 8.13854 5.5 7.34862C6.86817 6.55871 8.42017 6.14285 10 6.14285"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <h5>Quick Solution</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas et arcu blandit nisi varius volutpat.
                    </p>
                  </li>
                  <li>
                    <div>
                      <svg
                        width="23"
                        height="26"
                        viewBox="0 0 23 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.29778 4.9987L2.6917 5.91785L2.29778 4.9987C1.47855 5.3498 0.990512 6.20064 1.10106 7.08506L2.07119 14.8461C2.29134 16.6073 3.17232 18.2191 4.53585 19.3554L10.2196 24.0919C10.9613 24.71 12.0387 24.71 12.7804 24.0919L18.4641 19.3554C19.8277 18.2191 20.7087 16.6073 20.9288 14.8461L21.8989 7.08506C22.0095 6.20064 21.5215 5.3498 20.7022 4.9987L12.2878 1.39254C11.7847 1.17692 11.2153 1.17692 10.7122 1.39254L2.29778 4.9987Z"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M7.42847 13L11.0686 16.6402C11.2917 16.8633 11.6632 16.8265 11.8382 16.564L16.9285 8.92857"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <h5>Ensure Security</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas et arcu blandit nisi varius volutpat.
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ userAboutReducer }) => {
  return { userAboutReducer }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetailsRequest: (creds) => {
      dispatch(getUserDetailsRequest(creds))
    },
    getQueryNotificationRequest: () => {
      dispatch(getQueryNotificationRequest())
    },
  }
}
export default connect(mapDispatchToProps, mapDispatchToProps)(About)
