export const LOGIN_API_REQUEST = 'LOGIN_API_REQUEST'
export const LOGIN_API_RESPONSE = 'LOGIN_API_RESPONSE'

export const CREATE_USER_API_REQUEST = 'create_user_api_request'
export const CREATE_USER_API_RESPONSE = 'create_user_api_response'

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST'
export const GET_USER_LIST_RESPONSE = 'GET_USER_LIST_RESPONSE'

export const USER_STATUS_REQUEST = 'USER_STATUS_REQUEST'
export const USER_STATUS_RESPONSE = 'USER_STATUS_RESPONSE'
