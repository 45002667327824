import produce from 'immer'
import {
  ADMIN_OTP_VERIFICATION_RESPONSE,
  CLEAR_ADMIN_OTP_VERIFICATION_RESPONSE,
  CLEAR_LOGOUT_RESPONSE,
  CLEAR_VERIFY_TOKEN_RESPONSE,
  LOGIN_API_RESPONSE,
  LOGOUT_API_RESPONSE,
  VERIFY_TOKEN_RESPONSE,
} from './actionType'
export const initialState = {
  loginApiResponse: {},
  verifyTokenApiResponse: {},
  logoutApiResponse: {},
  adminOtpApiResponse: null
}
export const loginReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_API_RESPONSE:
        draft.loginApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case VERIFY_TOKEN_RESPONSE:
        draft.verifyTokenApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEAR_VERIFY_TOKEN_RESPONSE:
        draft.verifyTokenApiResponse = {}
        break


      case LOGOUT_API_RESPONSE:
        draft.logoutApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CLEAR_LOGOUT_RESPONSE:
        draft.logoutApiResponse = null
        break

      case ADMIN_OTP_VERIFICATION_RESPONSE: {
        draft.adminOtpApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      case CLEAR_ADMIN_OTP_VERIFICATION_RESPONSE:
        draft.adminOtpApiResponse = {}
        draft.loginApiResponse = {}
        break
      default:
        state = { ...state }
        break
    }
  })
