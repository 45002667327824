import React, { useEffect } from 'react'
import { MDBDataTable } from 'mdbreact'
import {
  DEFAULT_PAGE_LENGTH,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from 'constant/commonConstants'

export default function CustomisedDatatable(props) {
  useEffect(() => {
    const inputElement = document.querySelector(
      '[data-test="datatable-input"] input',
    )
    if (inputElement) {
      inputElement.type = 'search'
    }
  }, [props?.tableRecords])

  return (
    <React.Fragment>
      <MDBDataTable
        responsive
        // striped
        bordered
        data={props.tableRecords}
        noBottomColumns
        searching={props.tableRecords?.rows?.length > 0 ? true : false}
        paging={props.tableRecords?.rows?.length > 0 ? true : false}
        entriesOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        displayEntries={
          props.displayEntries === false ? props.displayEntries : true
        }
        entries={props.entries ? props.entries : DEFAULT_PAGE_LENGTH}
        sortRows={props.sortRows ? props.sortRows : []}
      />
    </React.Fragment>
  )
}
