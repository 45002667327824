import { EN, ENGLISH, JAPANESE, JPN } from "./commonConstants"

export const reportType = [
  { label: 'Client Report', value: 'Personal' },
  { label: 'Publish Report', value: 'Publish' },
  { label: 'Internal Report', value: 'Internal' },
]
export const reportFilter = [
  { label: 'My Published Reports', value: 'my-published' },
  { label: 'Other Published Reports', value: 'Other-published' },
]



export const languageList = [
  { label: ENGLISH, value: EN  },
  { label: JAPANESE , value: JPN  },
]