import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  ADD_LAW_REQUEST,
  GET_LAW_LIST_REQUEST,
  EDIT_LAW_REQUEST,
  GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_REQUEST,
  GET_LAW_SECTION_LIST_REQUEST,
  GET_LAW_LIST_BY_CATEGORY_ID_REQUEST,
  ADD_LAW_REVISION_REQUEST,
} from './actionType'

import { handleApiCallException } from '../../shared/action'
import {
  addLawResponse,
  getLawListResponse,
  editLawResponse,
  getLawStatusResponse,
  getLawListRequest,
  getLawSectionListResponse,
  getLawListByCatogeryIdResponse,
  addLawRevisionResponse,
} from './action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { initialPaginationState } from 'pages/masterPages/Law/CommonFunction'

//send request to server
function* addLaw({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/law', data)
    yield put(addLawResponse(response.message, response.status))
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//send request to server for get law list
function* getLawList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/lawList', data)
    if (response.status) {
      yield put(getLawListResponse(response.data))
    } else {
      yield put(getLawListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getLawListByCatogeryId({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'get', `/lawList/${data}`)
    if (response.status) {
      yield put(getLawListResponse(response.data))
      yield put(getLawListByCatogeryIdResponse([]))
    } else {
      yield put(getLawListByCatogeryIdResponse([]))
      yield put(getLawListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getlawSectionList() {
  try {
    const response = yield call(invokeApi, 'get', '/lawSectionList')
    if (response.status) {
      yield put(getLawSectionListResponse(response.data))
    } else {
      yield put(getLawSectionListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//send request to server for update law
function* updateLaw({ payload: { data } }) {
  const lawData = {
    lawName: data.lawName,
    id: data.lawId,
  }

  try {
    const response = yield call(invokeApi, 'post', '/addLaw', lawData)
    if (response.status) {
      yield put(editLawResponse(response.message, SUCCESS))
    } else {
      yield put(editLawResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* lawStatus({ payload: { data } }) {
  const { currentPage, dataPerPage, ...modData } = data

  try {
    const response = yield call(invokeApi, 'post', '/lawActive', modData)
    if (response.status) {
      yield put(getLawStatusResponse(response.message, SUCCESS, response.data))
      yield put(
        getLawListRequest(initialPaginationState(dataPerPage, currentPage)),
      )
    } else {
      yield put(getLawStatusResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* lawRevision({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/lawRevision', data)

    if (response.status) {
      yield put(
        addLawRevisionResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(addLawRevisionResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(ADD_LAW_REQUEST, addLaw)
  yield takeEvery(GET_LAW_LIST_REQUEST, getLawList)
  yield takeEvery(GET_LAW_SECTION_LIST_REQUEST, getlawSectionList)
  yield takeEvery(EDIT_LAW_REQUEST, updateLaw)
  yield takeEvery(GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_REQUEST, lawStatus)
  yield takeEvery(GET_LAW_LIST_BY_CATEGORY_ID_REQUEST, getLawListByCatogeryId)
  yield takeEvery(ADD_LAW_REVISION_REQUEST, lawRevision)
}
function* lawSaga() {
  yield all([fork(watchLaw)])
}
export default lawSaga
