import produce from 'immer'
import {
  CLEAR_USER_FAQ_LIST_RESPONSE,
  GET_USER_FAQ_LIST_RESPONSE,
  UPDATE_POPULAR_FAQ_RESPONSE,
} from './actionType'
let faqListOffset = []
export const initialState = {
  faqList: [],
  updatePopularFaqApiResponse: null,
}
export const UserFaqReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_FAQ_LIST_RESPONSE: {
        if (!faqListOffset.includes(action.payload.offset)) {
          faqListOffset.push(action.payload.offset)
          draft.faqList = draft.faqList.concat(action.payload.response)
        }
        break
      }
      case CLEAR_USER_FAQ_LIST_RESPONSE: {
        faqListOffset = []
        draft.faqList = []
        break
      }

      case UPDATE_POPULAR_FAQ_RESPONSE: {
        draft.updatePopularFaqApiResponse = []
        break
      }

      default:
        state = { ...state }
        break
    }
  })
