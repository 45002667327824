import CustomisedModal from 'components/Modal/CustomisedModal'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getAuditLogListRequest, getAuthenticationLogsRequest } from 'store/auditLog/action'
import 'font-awesome/css/font-awesome.min.css'
import { clearLogoutResponse, logoutRequest } from 'store/auth/login/action'
import { autoLogout } from 'helpers/authUtils'
import { useNavigate } from 'react-router-dom'
import { SUCCESS } from 'constant/commonConstants'
import { Button } from 'reactstrap'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

function LogoutModal(props) {
  const navigate = useNavigate()
  const [unsubscribe, setUnsubscribe] = useState(false)
  const isSubscribed = JSON.parse(localStorage.getItem('verificationData'))

  useEffect(() => {
    if (props.logoutApiResponse?.responseType == SUCCESS) {
      ApiResponseMessage(props.logoutApiResponse?.message, SUCCESS)
      props.clearLogout()
      props.onModalDismiss()
      autoLogout();
      navigate("/home")
    }
  }, [props.logoutApiResponse])


  return (
    <React.Fragment>
      <CustomisedModal
        isModalOpen={props.isModalOpen}
        modalSize={"modal-dialog-scrollable "}
        onModalDismiss={() => props.onModalDismiss()}
        modalName={unsubscribe ? "Unsubscribe" : props.modalName}
      >
        <div className='logout-modal'>
          <h4 className='text-center mt-0 w-100 d-inline-block'>{unsubscribe ? "Are you sure you want to unsubscribe your account?" : "Are you sure you want to logout?"}  </h4>
          <div className='d-flex justify-content-center gap-2 my-2'>
            <Button color='secondary' className='btn-outline-secound-primary' onClick={() => unsubscribe ? setUnsubscribe(false) : props.onModalDismiss()}>Cancel</Button>
            <Button color='primary' className='btn-secound-primary' onClick={() => {
              props.logoutRequest({ authenticationLogId: isSubscribed.authenticationLog, type: unsubscribe ? "unsubscribe" : "Subscriber", subscriberId: isSubscribed?.userId || "" })
            }}>{unsubscribe ? "Unsubscribe" : "Logout"} </Button>
          </div>
          {
            !unsubscribe && <div className='w-100 text-center'>
              <a href='javascript:void(0);' onClick={() => setUnsubscribe(true)}>Unsubscribe</a>
            </div>
          }
        </div>
      </CustomisedModal>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {

    logoutRequest: (creds) => {
      dispatch(logoutRequest(creds))
    },
    clearLogout: () => {
      dispatch(clearLogoutResponse())
    },




  }
}
const mapStateToProps = ({ loginReducer }) => {
  return {
    logoutApiResponse: loginReducer.logoutApiResponse,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal)
