import { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'

export const CodeComponent = (props) => {
  const [digits, setDigits] = useState('')
  useEffect(() => {
    setDigits(props)
  }, [props])

  return (
    <ReactCodeInput
      type="Number"
      fields={props.digits}
      value={digits.value}
      {...props}
    />
  )
}
