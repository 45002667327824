import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  ACTIVE_SECTION_REQUEST,
  ADD_SECTION_REQUEST,
  DELETE_SECTION_BY_SECTION_ID_REQUEST,
  GET_ALL_SECTION_LIST_REQUEST,
  GET_SECTION_BY_SECTION_ID_REQUEST,
  GET_SECTION_LIST_BY_LAW_IDS_REQUEST,
  GET_SECTION_LIST_BY_LAW_REQUEST,
  GET_SECTION_LIST_BY_STATUS_REQUEST,
  UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST,
} from './actionType'

import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import {
  activeSectionResponse,
  addSectionResponse,
  deleteSectionBySectionIdResponse,
  getAllSectionListRequest,
  getAllSectionListResponse,
  getSectionBySectionIdResponse,
  getSectionListByLawIdsResponse,
  getSectionListByLawResponse,
  getSectionListByStatusResponse,
  updateSectionStatusBySectionIdResponse,
} from './action'
import {
  SECTION,
  SECTION_BY_STATUS,
  SECTION_LIST_BY_LAW,
} from '../../../helpers/apiConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

function* addSection({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/section', data)
    if (response.status) {
      // yield put(getAllSectionListRequest())
      yield put(addSectionResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(addSectionResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getAllSectionList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/sectionList', data)

    if (response.status) {
      yield put(getAllSectionListResponse(response.data))
    } else {
      yield put(getAllSectionListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getSectionListByLawIds({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/lawsectionList', data)
    if (response.status) {
      yield put(getSectionListByLawIdsResponse(response.data.list))
    } else {
      yield put(getSectionListByLawIdsResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getSectionBySectionId({ payload: { sectionId } }) {
  try {
    const response = yield call(invokeApi, 'get', SECTION + '/' + sectionId, '')
    if (response.status) {
      yield put(
        getSectionBySectionIdResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getSectionBySectionIdResponse(response.message, DANGER, []))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* activeSection({ payload: { data } }) {
  const { currentPage, dataPerPage, ...modData } = data
  try {
    const response = yield call(invokeApi, 'post', '/sectionActive', modData)
    if (response.status) {
      yield put(activeSectionResponse(response.message, SUCCESS))
      yield put(
        getAllSectionListRequest({
          searchText: '',
          pagination: {
            limit: dataPerPage,
            page: currentPage ? currentPage : 0,
            orderKey: 'createdDate',
            orderBy: 'ASC',
          },
          filter: {},
        }),
      )
    } else {
      yield put(activeSectionResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* updateSectionStatusBySectionId({ payload: { sectionId, status } }) {
  try {
    const response = yield call(
      invokeApi,
      'patch',
      SECTION + '/' + sectionId + '/status/' + status,
    )
    if (response.status) {
      yield put(getAllSectionListRequest())
      yield put(
        updateSectionStatusBySectionIdResponse(response.message, SUCCESS),
      )
    } else {
      yield put(
        updateSectionStatusBySectionIdResponse(response.message, DANGER),
      )
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getSectionListByStatus({ payload: { status } }) {
  try {
    const response = yield call(
      invokeApi,
      'get',
      SECTION_BY_STATUS + '/' + status,
    )
    if (response.status) {
      yield put(getSectionListByStatusResponse(response.data.list))
    } else {
      yield put(getSectionListByStatusResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* deleteSectionBySectionId({ payload: { sectionId } }) {
  try {
    const response = yield call(invokeApi, 'delete', SECTION + '/' + sectionId)
    if (response.status) {
      yield put(getAllSectionListRequest())
      yield put(deleteSectionBySectionIdResponse(response.message, SUCCESS))
    } else {
      yield put(deleteSectionBySectionIdResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getSectionListByLaw({ payload: { lawId } }) {
  try {
    const response = yield call(
      invokeApi,
      'get',
      SECTION_LIST_BY_LAW + '/' + lawId,
    )
    if (response.status) {
      yield put(getSectionListByLawResponse(response.data.list))
    } else {
      yield put(getSectionListByLawResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
export function* watchSection() {
  yield takeEvery(ADD_SECTION_REQUEST, addSection)
  yield takeEvery(GET_ALL_SECTION_LIST_REQUEST, getAllSectionList)
  yield takeEvery(GET_SECTION_LIST_BY_LAW_IDS_REQUEST, getSectionListByLawIds)
  yield takeEvery(GET_SECTION_BY_SECTION_ID_REQUEST, getSectionBySectionId)
  yield takeEvery(ACTIVE_SECTION_REQUEST, activeSection)
  yield takeEvery(
    UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST,
    updateSectionStatusBySectionId,
  )
  yield takeEvery(GET_SECTION_LIST_BY_STATUS_REQUEST, getSectionListByStatus)
  yield takeEvery(
    DELETE_SECTION_BY_SECTION_ID_REQUEST,
    deleteSectionBySectionId,
  )
  yield takeEvery(GET_SECTION_LIST_BY_LAW_REQUEST, getSectionListByLaw)
}
function* sectionSaga() {
  yield all([fork(watchSection)])
}
export default sectionSaga
