import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { ADMIN_OTP_VERIFICATION_REQUEST, LOGIN_API_REQUEST, LOGOUT_API_REQUEST, VERIFY_TOKEN_REQUEST } from './actionType'

import { LoginResponse, adminOtpVerificationResponse, logoutResponse, verifyTokenResponse } from './action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import { setLoginUserData } from 'helpers/authUtils'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import { getIpAddress } from 'pages/Faq/CommonFunction'

//send request to server
function* login({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/login', data)
    if (response.status) {

      //setLoginUserData(response.data)
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(LoginResponse(response.message, SUCCESS, response.data))
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(LoginResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

const IpAddress = async () => {
  let ip = await getIpAddress()
  return ip
}

function* verifyToken({ payload: { data } }) {
  let response
  try {
    console.log("data==", data)
    response = yield call(invokeApi, 'post', '/verify-token', data)

    if (response.status) {
      setLoginUserData(response.data)

      yield put(verifyTokenResponse(response.message, SUCCESS, response.data))
    } else {
      yield put(verifyTokenResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* logout({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/logout', data)
    if (response.status) {
      yield put(logoutResponse(response.message, SUCCESS))
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(logoutResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* adminOtpVerification({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/console-verify-otp', data)
    if (response.status) {
      setLoginUserData(response.data)

      ApiResponseMessage(response.message, SUCCESS)

      yield put(
        adminOtpVerificationResponse(response.message, SUCCESS, response.data),
      )
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(adminOtpVerificationResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(LOGIN_API_REQUEST, login)
  yield takeEvery(VERIFY_TOKEN_REQUEST, verifyToken)
  yield takeEvery(LOGOUT_API_REQUEST, logout)
  yield takeEvery(ADMIN_OTP_VERIFICATION_REQUEST, adminOtpVerification)



}
function* LoginSaga() {
  yield all([fork(watchLaw)])
}
export default LoginSaga
