export const UserInitialState = () => {
  return {
    email: '',
    password: '',
  }
}

export const createUserInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    email: value.email,
    contactNo: value.phoneNumber,
    roleId: value.roleId,
    fullName: value.fullName,
    countryId: value.countryList,
    userId: value.userId,
  }
}

export const countryInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    countryName: value.countryName,
    countryCode: value.countryCode,
  }
}
