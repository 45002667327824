/* eslint-disable no-debugger */

import React from 'react'
import { FormGroup, Input } from 'reactstrap'

const CheckBoxItem = (props) => {
  const { moduleId, roleId, id, handleOnChange, value, permissionId } = props
  return (
    <td className="text-center">
      <FormGroup check inline>
        <Input
          type="checkbox"
          onChange={(e) =>
            handleOnChange({
              moduleId,
              roleId,
              [id]: e.target.checked,
              permissionId,
              // delete: true,
              // modules: [
              //   {
              //     moduleId: moduleId,
              //     // [id]: e.target.checked,
              //   },
              // ],
              // roleId: roleId,
            })
          }
          checked={value}
        />
      </FormGroup>
    </td>
  )
}

export default CheckBoxItem
