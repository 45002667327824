//my-app/src/authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal'

// Msal Configurations

const config = {
  auth: {
    authority:
      `https://login.microsoftonline.com/${process.env.REACT_APP_SSO_TENANT_ID}`,
    tenantId: process.env.REACT_APP_SSO_TENANT_ID,
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin + '/console/logout',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

// User Name
// User.20@expdiginetdev.onmicrosoft.com

// password
// @#RwefasQWE1211212QW

// Authentication Parameters
const authenticationParameters = {
  scopes: ['user.read'],
}

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html',
}

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options,
)
