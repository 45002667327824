export const APPROVED = 'Approved'
export const REJECTED = 'Rejected'
export const EDITOR = 'editor'

export const PENDING = 'Pending'
export const DRAFT = 'Draft'

export const AUTH_MODE_LC = 1
export const AUTH_MODE_SSO = 2

export const PENDING_FOR_APPROVER = 'Pending for approval'
export const REJECTED_BY_APPROVER = 'Rejected by approver'
export const REJECTED_BY_PUBLISHER = 'Rejected by publisher'
export const DRAFTED = 'Drafted'
export const PENDING_FOR_PUBLISHER = 'Pending for publisher'
export const INTERNAL_USERS = [/honeycombsoftwares/]
export const INTERNAL = 'INTERNAL'
export const CLIENT_REPORT = 'Client Report'

export const PUBLISHED = 'Published'
export const DASHBOARD = 1000
export const USER_MODULE_ID = 1
export const ROLE_MODULE_ID = 2
export const COUNTRY_MODULE_ID = 3
export const SERVICELINE_MODULE_ID = 4
export const CATEGORY_MODULE_ID = 5
export const LAW_MODULE_ID = 6
export const SECTION_MODULE_ID = 7
export const FAQ_MODULE_ID = 8
export const REPORT_MODULE_ID = 9
export const ARTICAL_MODULE_ID = 10
export const HELP_AND_SUPPORT_MODULE_ID = 11
export const REPORT_DOWNLOAD_MODULE_ID = 12
export const SUBSCIBER_MODULE_ID = 13
export const TEMPLATE_MODULE_ID = 14
export const CONSULTATION_MODULE_ID = 15
export const TEAM_CREATION_MODULE_ID = 16

export const APPROVEDPERMISSION = 1

export const modules = [
  {
    name: 'home',
    id: 1000,
  },
  {
    name: 'user',
    id: 1,
  },
  {
    name: 'role',
    id: 2,
  },
  {
    name: 'country',
    id: 3,
  },
  {
    name: 'serviceline',
    id: 4,
  },
  {
    name: 'category',
    id: 5,
  },
  {
    name: 'law',
    id: 6,
  },
  {
    name: 'section',
    id: 7,
  },
  {
    name: 'faq',
    id: 8,
  },
  {
    name: 'report',
    id: 9,
  },
  {
    name: 'article',
    id: 10,
  },
  {
    name: 'help',
    id: 11,
  },
  {
    name: 'reportRequest',
    id: 12,
  },
  {
    name: 'subscriber',
    id: 13,
  },
  {
    name: 'template',
    id: 14,
  },
  {
    name: 'consultationRequest',
    id: 15,
  },
  {
    name: 'team-creation',
    id: 16,
  },
]
