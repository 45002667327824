import produce from 'immer'
import {
  CLEARE_RESEND_QUERY_RESPONSE,
  CLEAR_SUBSCRIBER_QUERY_LIST_RESPONSE,
  CREATE_RESEND_QUERY_RESPONSE,
  GET_CONVERSATION_DETAILS_QUERY_RESPONSE,
  GET_SUBSCRIBER_QUERY_RESPONSE,
  SUBSCRIBER_TICKET_INDICATOR_RESPONSE,
} from './actionType'

let queryListOffset = []
export const initialState = {
  subscriberQueryList: [],
  conversationDetails: null,
  resendQueryApiResponse: null,
}

export const UserTicketReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_SUBSCRIBER_QUERY_RESPONSE: {
        if (!queryListOffset.includes(action.payload.offset)) {
          queryListOffset.push(action.payload.offset)
          draft.subscriberQueryList = draft.subscriberQueryList.concat(
            action.payload.response.list,
          )
        }
        break
      }
      case CLEAR_SUBSCRIBER_QUERY_LIST_RESPONSE: {
        queryListOffset = []
        draft.subscriberQueryList = []
        break
      }

      case GET_CONVERSATION_DETAILS_QUERY_RESPONSE:
        draft.conversationDetails = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CREATE_RESEND_QUERY_RESPONSE:
        draft.resendQueryApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case SUBSCRIBER_TICKET_INDICATOR_RESPONSE:
        // draft.resendQueryApiResponse = {
        //   message: action.payload.message,
        //   responseType: action.payload.responseType,
        // }
        break

      case CLEARE_RESEND_QUERY_RESPONSE:
        draft.resendQueryApiResponse = null
        break

      default:
        state = { ...state }
        break
    }
  })
