import {
  GET_SERVICE_LINE_LIST_RESPONSE,
  GET_SERVICE_LINE_LIST_REQUEST,
  CLEAR_SERVICE_LINE_LIST_RESPONSE,
  ADD_SERVICE_LINE_REQUEST,
  ADD_SERVICE_LINE_RESPONSE,
  IS_ACTIVE_DEACTIVE_SERVICE_LINE_REQUEST,
  IS_ACTIVE_DEACTIVE_SERVICE_LINE_RESPONSE,
} from './actionType'

export const getServiceLineListRequest = (Data) => {
  return {
    payload: { Data },
    type: GET_SERVICE_LINE_LIST_REQUEST,
  }
}
export const getServiceLineListResponse = (serviceLineList) => {
  return {
    type: GET_SERVICE_LINE_LIST_RESPONSE,
    payload: { serviceLineList },
  }
}

export const ClearServiceListResponse = () => {
  return {
    type: CLEAR_SERVICE_LINE_LIST_RESPONSE,
    payload: {},
  }
}

export const addServiceLineRequest = (data) => {
  return {
    type: ADD_SERVICE_LINE_REQUEST,
    payload: { data },
  }
}

export const addServiceLineResponse = (message, responseType) => {
  return {
    type: ADD_SERVICE_LINE_RESPONSE,
    payload: { message, responseType },
  }
}

export const getActiveDeactiveServicesRequest = (data) => {
  return {
    type: IS_ACTIVE_DEACTIVE_SERVICE_LINE_REQUEST,
    payload: { data },
  }
}

export const getActiveDeactiveServicesResponse = (
  message,
  responseType,
  data,
) => {
  return {
    type: IS_ACTIVE_DEACTIVE_SERVICE_LINE_RESPONSE,
    payload: { message, responseType, data },
  }
}
