import React from 'react';
class Footer extends React.Component {
  render() {
    return <footer>
        <div className="backend-footer">
            <p className="mb-0">
              &#169; {new Date().getFullYear()} - All Rights Reserved.
            </p>
          </div>
    </footer>;
  }
}
export default Footer;
