import React from 'react'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
let title = "GT Mitra: Tax &amp; Regulatory Tool | Grant Thornton Bharat"
let description = "GT Mitra is a specialised Tax &amp; regulatory tool designed to empower businesses in navigating India&#39;s intricate tax and regulatory landscape.";
let keywords = "GT Mitra, Tax advisory services, Tax tool, tax and regulatory framework, Indian market deals, Solutions for foreign investors, Tax and regulatory tool, cross border transactions"
const urlList = [
  {
    url: '/home',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/about',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/report',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/reportDetails',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/contact-us',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/tickets',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/legal-disclaimer',
    title: title,
    description: description,
    keywords: keywords,
  },
  {
    url: '/terms-conditions',
    title: title,
    description: description,
    keywords: keywords,
  },
]

const schemaData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "GT Mitra",
  "url": "https://gtmitra.gtbharat.in/home",
  "description": " GTMitra is a specialised, one-stop platform designed to empower businesses in navigating India's intricate tax and regulatory landscape.",
}

function HelmetComponent(props) {
  const location = useLocation()
  const [headerDetails, setHeaderDetails] = useState([])

  useEffect(() => {
    let headerData = urlList?.filter(
      (x) => x?.url === '/' + location.pathname.split('/')[1],
    )?.[0]

    if (headerData?.url) {
      let formatValue = {
        title: props.title ? props.title : headerData.title,
        description: props.description
          ? props.description
          : headerData.description,
        keywords: headerData.keywords,
      }
      setHeaderDetails(formatValue)
    } else {
      setHeaderDetails({
        title: '404',
        description: '404',
        keywords: '404',
      })
    }
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        <title>{headerDetails.title}</title>
        <meta name="description" content={headerDetails.description} />
        <meta
          name="keywords"
          content={
            props.keywordsList ? props.keywordsList : headerDetails.keywords
          }
        />
        <link rel="canonical" href={location.pathname} />
      </Helmet>
    </>
  )
}

const mapStateToProps = ({ userHomeReducer }) => {
  return {
    keywordsList: userHomeReducer.keywordsList,
  }
}

export default connect(mapStateToProps)(HelmetComponent)
