import { useEffect, useState, React } from 'react'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button } from 'reactstrap'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import {
  getAllSectionListRequest,
  getSectionListByLawIdsRequest,
} from 'store/master/section/action'
import {
  createFaqRequest,
  createFaqResponse,
  getListKeywordsRequest,
  getFaqDetailsRequest,
} from 'store/faq/action'
import Select from 'react-select'
import { getFilterCategoryListRequest } from 'store/master/category/action'
import {
  getLawListByCategoryIdRequest,
  getLawListRequest,
} from 'store/master/law/action'
import { DATE_FORMAT_DATEPICKER } from 'constant/commonConstants'
import { getDateObjectWithValueFromMoment } from 'helpers/DataTimeHelpers'
import DatePicker from 'react-datepicker'

const initialState = () => {
  return {
    categoryId: '',
    subCategoryId: '',
    laws: '',
    sections: '',
    // status: '',
    date: '',
    faqDate: '',
  }
}

const FaqFilterSelectorFormik = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  let dispatch = useDispatch()

  const { detailFaq } = useSelector((state) => state.faqReducer)
  const [initialFaqValues, setFaqValues] = useState(initialState)
  const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])

  useEffect(() => {
    if (props.faqCreate != null) {
      props.clearFaqRepo(null)
      navigate('/console/faq')
    }
    if (params.id) {
      dispatch(getFaqDetailsRequest(params.id))
    }

    props.getlistKeywords()
  }, [props.faqCreate])

  useEffect(() => {
    if (props.categoryFilterList?.length == 1 && props?.isReportModule) {
      let categoryId = props.categoryFilterList[0]?.value
        ? props.categoryFilterList[0]?.value
        : ''
      if (categoryId != '') {
        filterSubCategory(categoryId)
        setFaqValues({
          ...initialState(),
          categoryId: categoryId,
        })
      }
    }
  }, [props?.categoryFilterList, props.isReportModule])

  const setType = () => {
    let type = ''
    if (props.Type == 'REPORT') {
      type = 'REPORT'
    } else if (props.Type == 'CONTENT') {
      type = 'CONTENT'
    } else if (props.Type == 'CONSULTATION') {
      type = 'CONSULTATION'
    } else {
      type = 'CONTENT'
    }
    return type
  }
  useEffect(() => {
    props.getSubCatList({
      searchText: '',
      type: setType(),
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })

    // props.getServiceLineListRequest({
    //   // searchText: '',
    //   pagination: {
    //     limit: 0,
    //     page: 0,
    //     orderKey: 'createdDate',
    //     orderBy: 'ASC',
    //   },
    // })

    // props.getAllSectionList({
    //   searchText: '',
    //   pagination: {
    //     limit: 0,
    //     page: 0,
    //     orderKey: 'createdDate',
    //     orderBy: 'ASC',
    //   },
    //   filter: {},
    // })
  }, [])

  useEffect(() => {
    if (detailFaq?.details != null && params.id) {
      setFaqValues(createFaqInitialState(detailFaq?.details))
      setTags(detailFaq?.details?.keywords?.map((x) => ({ id: x, text: x })))

      filterSubCategory(
        detailFaq?.details.categoryDetails.parentCategoryId?.toString(),
      )
      getSectionListByLawIds(detailFaq?.details?.lawList?.map((x) => x.id))
    }
  }, [detailFaq, props.categoryAndSubList])

  const filterSubCategory = (id) => {
    if (id) {
      let filterSubCategoryValues = props.categoryAndSubList
        ?.filter((item) => item.id == id)[0]
        ?.subCat.map((opt) => ({
          value: opt.id,
          label: opt.catName,
        }))
      setSubCategoryDropdownList(filterSubCategoryValues)
    } else {
      setSubCategoryDropdownList([])
    }
  }

  const getLawListByCatogeryId = (id) => {
    if (id) {
      dispatch(getLawListByCategoryIdRequest(id))
    }
  }
  const getSectionListByLawIds = (id) => {
    if (id) {
      dispatch(
        getSectionListByLawIdsRequest({
          lawIdList: [id],
        }),
      )
    }
  }

  const setParentFilterActiveClass = (values) => {
    if (props.setIsActiveClass) {
      {
        values?.categoryId ||
          values?.subCategoryId ||
          values?.createdDate ||
          values?.laws ||
          values?.sections
          ? props.setIsActiveClass(true)
          : props.setIsActiveClass(false)
      }
    }

    return ''
  }

  return (
    <Formik initialValues={initialFaqValues} enableReinitialize={true}>
      {({ values, setFieldValue }) => (
        <>
          <Form className="faq-create">
            <Row>
              <Col lg="4" className="no-floating-label">
                {setParentFilterActiveClass(values)}
                <FormGroup className="mb-0">
                  <Field
                    component={Select}
                    menuPlacement="auto"
                    name="categoryId"
                    className="form-control selectbox"
                    options={props?.categoryFilterList}
                    onChange={(opt) => {
                      if (opt === null) {
                        setFieldValue('categoryId', null)
                      }
                      setFieldValue('subCategoryId', '')
                      setFieldValue('laws', '')
                      setFieldValue('sections', '')
                      setFieldValue('categoryId', opt && opt?.value)
                      filterSubCategory(opt ? opt.value : '')
                      props?.getFaqListFilter(
                        opt?.value,
                        '',
                        '',
                        '',
                        '',
                        props.isFaqModule
                          ? values?.faqDate
                          : values?.createdDate,
                      )
                    }}
                    value={props?.categoryFilterList.filter(
                      (option) => option.value === values.categoryId,
                    )}
                    isClearable={true}
                  // closeMenuOnSelect={false}
                  />
                  <Label className="form-label">Service Line</Label>
                </FormGroup>
              </Col>
              <Col lg="4" className="no-floating-label">
                <FormGroup className="mb-0">
                  <Field
                    component={Select}
                    menuPlacement="auto"
                    name="subCategoryId"
                    className="form-control selectbox"
                    options={subCategoryDropdownList}
                    onChange={(opt) => {
                      setFieldValue('subCategoryId', opt && opt?.value)
                      if (opt?.value) {
                        getLawListByCatogeryId(opt?.value)
                      }

                      props?.getFaqListFilter(
                        values?.categoryId,
                        opt?.value,
                        values?.laws,
                        values?.sections,
                        props.isFaqModule
                          ? values?.faqDate
                          : values?.createdDate,
                      )
                    }}
                    value={subCategoryDropdownList?.filter(
                      (option) => option.value === values.subCategoryId,
                    )}
                    isClearable={true}
                  />
                  <Label className="form-label">Category</Label>
                </FormGroup>
              </Col>
              {props.isReportModule && (
                <Col lg="4" className="no-floating-label">
                  <DatePicker
                    placeholderText="Select Date"
                    popperPlacement="top-end"
                    closeOnScroll={true}
                    selected={
                      values?.createdDate
                        ? getDateObjectWithValueFromMoment(values?.createdDate)
                        : null
                    }
                    className="form-control"
                    name="createdDate"
                    maxDate={new Date()}
                    dateFormat={DATE_FORMAT_DATEPICKER}
                    onChange={(opt) => {
                      props?.getFaqListFilter(
                        values?.categoryId,
                        values?.subCategoryId,
                        values?.laws,
                        values?.sections,
                        // values?.status,
                        opt,
                      )
                      setFieldValue('createdDate', opt)
                    }}
                    isClearable
                  />
                </Col>
              )}
              <Col lg="5" className="no-floating-label">
                <FormGroup className="mb-0">
                  <Field
                    component={Select}
                    menuPlacement="auto"
                    name="laws"
                    className="form-control selectbox"
                    options={props.lawDropdownList}
                    onChange={(opt) => {
                      setFieldValue('sections', '')
                      setFieldValue('laws', opt && opt?.value)
                      getSectionListByLawIds(opt?.value)
                      props?.getFaqListFilter(
                        values?.categoryId,
                        values?.subCategoryId,
                        opt?.value ? opt?.value : '',
                        '',
                        '',
                        props.isFaqModule
                          ? values?.faqDate
                          : values?.createdDate,
                      )
                    }}
                    value={props.lawDropdownList?.filter(
                      (option) => option.value === values.laws,
                    )}
                    isClearable={true}
                  />
                  <Label className="form-label">Law</Label>
                </FormGroup>
              </Col>
              {props?.moduleName != 'consultationRequest' && (
                <Col lg="5" className="no-floating-label">
                  <FormGroup className="mb-0">
                    <Field
                      component={Select}
                      menuPlacement="auto"
                      name="sections"
                      className="form-control selectbox"
                      options={props.sectionDropdownList}
                      onChange={(opt) => {
                        setFieldValue('sections', opt && opt?.value)
                        props?.getFaqListFilter(
                          values?.categoryId,
                          values?.subCategoryId,
                          values?.laws,
                          opt?.value,
                          props.isFaqModule
                            ? values?.faqDate
                            : values?.createdDate,
                        )
                      }}
                      value={props.sectionDropdownList?.filter(
                        (option) => option.value === values.sections,
                      )}
                      isClearable={true}
                    />
                    <Label className="form-label">Section</Label>
                  </FormGroup>
                </Col>
              )}

              <Button
                className="my-tooltip cancel_filter"
                data-tooltip-content={'Close filter'}
                color="link"
                onClick={() => {
                  props.cancelFilterSection(true)
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="CurrentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="CurrentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>

              {props.isFaqModule && (
                <Col className="no-floating-label">
                  <DatePicker
                    placeholderText="Select Date"
                    popperPlacement="top-end"
                    closeOnScroll={true}
                    selected={
                      values?.faqDate
                        ? getDateObjectWithValueFromMoment(values?.faqDate)
                        : null
                    }
                    maxDate={new Date()}
                    className="form-control"
                    name="faqDate"
                    dateFormat={DATE_FORMAT_DATEPICKER}
                    onChange={(opt) => {
                      props?.getFaqListFilter(
                        values?.categoryId,
                        values?.subCategoryId,
                        values?.laws,
                        values?.sections,
                        '',
                        opt,
                      )
                      setFieldValue('faqDate', opt)
                    }}
                    isClearable
                  />
                </Col>
              )}
              {props.isReportModule && (
                <Col className="d-flex">
                  <Button
                    color="link"
                    className="clear_btn text-white"
                    style={{ color: 'black' }}
                    onClick={() => {
                      props?.getFaqListFilter()
                      setFieldValue('categoryId', null)
                      setFieldValue('subCategoryId', null)
                      setFieldValue('laws', null)
                      setFieldValue('sections', null)
                      // setFieldValue('status', null)
                      setFieldValue('createdDate', null)
                    }}
                  >
                    Clear filter
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  sectionReducer,
  faqReducer,
  categoryReducer,
  lawReducer,
  createUserReducer,
  serviceLineReducer,
}) => {
  let faqDetails = []
  let categoryAndSubList = []
  let categoryFilterList = []
  let lawDropdownList = []
  let sectionDropdownList = []
  let faqCreate = null
  let userDropdownList = []
  let serviceLineDropdown = []
  let subCategoryDropdownList = []

  if (faqReducer.detailFaq && faqReducer.detailFaq.details) {
    faqDetails = faqReducer.detailFaq.details
  }
  if (
    categoryReducer.categoryFilterList.list &&
    categoryReducer.categoryFilterList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryFilterList.list
    categoryFilterList = categoryReducer.categoryFilterList.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt.catName,
      }),
    )
  }

  if (lawReducer.lawList && lawReducer.lawList.list?.length > 0) {
    lawDropdownList = lawReducer.lawList.list?.map((opt) => ({
      value: opt.id,
      label: opt.lawName,
    }))
  }

  if (
    sectionReducer.sectionListByLawIds &&
    sectionReducer.sectionListByLawIds?.length > 0
  ) {
    sectionDropdownList = sectionReducer?.sectionListByLawIds.map((opt) => ({
      value: opt.id,
      label: opt.sectionName,
    }))
  }

  if (
    createUserReducer.getUserListApiResponse &&
    createUserReducer.getUserListApiResponse?.data?.list?.length > 0
  ) {
    userDropdownList = createUserReducer.getUserListApiResponse?.data.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt.fullName,
      }),
    )
  }

  if (faqReducer?.addFaq) {
    faqCreate = faqReducer?.addFaq
  }

  if (
    serviceLineReducer &&
    serviceLineReducer.serviceLineList?.list?.length > 0
  ) {
    serviceLineDropdown = serviceLineReducer.serviceLineList?.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt?.catName,
      }),
    )
  }

  if (
    categoryReducer.subCatList.list &&
    categoryReducer.subCatList.list.length > 0
  ) {
    subCategoryDropdownList = categoryReducer?.subCatList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }
  return {
    faqDetails,
    categoryAndSubList,
    categoryFilterList,
    lawDropdownList,
    sectionDropdownList,
    faqCreate,
    listKeywords: faqReducer.listKeywords,
    userDropdownList: userDropdownList,
    serviceLineDropdown: serviceLineDropdown,
    subCategoryDropdownList: subCategoryDropdownList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLawListRequest: (data) => {
      dispatch(getLawListRequest(data))
    },
    getAllSectionList: (data) => {
      dispatch(getAllSectionListRequest(data))
    },
    clearFaqRepo: () => {
      dispatch(createFaqResponse(null))
    },
    createFaq: (data) => {
      dispatch(createFaqRequest(data))
    },
    getlistKeywords: () => {
      dispatch(getListKeywordsRequest())
    },
    // getServiceLineListRequest: (creds) => {
    //   dispatch(getServiceLineListRequest(creds))
    // },

    // getSubCatList: (data) => {
    //   dispatch(getSubCatListByCatIdRequest(data))
    // },
    getSubCatList: (data) => {
      dispatch(getFilterCategoryListRequest(data))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FaqFilterSelectorFormik)
