export const GET_ROLE_LIST_REQUEST = 'get_role_list_request'
export const GET_ROLE_LIST_RESPONSE = 'get_role_list_response'

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST'
export const CREATE_ROLE_RESPONSE = 'CREATE_ROLE_RESPONSE'

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST'

export const ROLE_PERMISSION_REQUEST = 'ROLE_PERMISSION_REQUEST'
export const ROLE_PERMISSION_RESPONSE = 'ROLE_PERMISSION_RESPONSE'

export const ROLE_PERMISSION_UPDATE_REQUEST = 'ROLE_PERMISSION_UPDATE_REQUEST'
export const ROLE_PERMISSION_UPDATE_RESPONSE = 'ROLE_PERMISSION_UPDATE_RESPONSE'
