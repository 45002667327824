import produce from 'immer'
import {
  ADD_COUNTRY_RESPONSE,
  GET_COUNTRY_LIST_RESPONSE,
  ADD_COUNTRY_STATUS_RESPONSE,
  USER_COUNTRY_LIST_RESPONSE,
} from './actionType'

//Initial state for Country
export const initialState = {
  countryApiResponse: null,
  editCountryApiResponse: null,
  countryList: [],
  countyStatus: null,
  userCountryList: [],
}

//country function for operation
export const countryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_COUNTRY_RESPONSE:
        if (action.payload.message == undefined) {
          draft.countryApiResponse = null
        } else {
          draft.countryApiResponse = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          }
        }
        break

      //get country List
      case GET_COUNTRY_LIST_RESPONSE:
        draft.countryList = action.payload.countryList
        break

      case USER_COUNTRY_LIST_RESPONSE:
        draft.userCountryList = action.payload.userCountryList
        break

      case ADD_COUNTRY_STATUS_RESPONSE:
        draft.countyStatus = action.payload.countyStatus
        break

      default:
        state = { ...state }
        break
    }
  })
