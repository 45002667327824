import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  ADD_COUNTRY_REQUEST,
  GET_COUNTRY_LIST_REQUEST,
  ADD_COUNTRY_STATUS_REQUEST,
  USER_COUNTRY_LIST_REQUEST,
} from './actionType'
import { handleApiCallException } from '../../shared/action'

import { invokeApi } from '../../../helpers/axiosHelper'

import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import {
  addCountryResponse,
  getCountryListResponse,
  addCountryStatusResponse,
  getCountryListRequest,
  userCountryListResponse,
  // getAllCountryListRequest,
} from './action'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

//Add country
function* addCountry({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/country', data)
    if (response.status) {
      // yield put(getAllCountryListRequest());
      yield put(addCountryResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      ApiResponseMessage(response.message, response.status, DANGER)
      yield put(addCountryResponse(response.message, response.status, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getCountryList({ payload: { Data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/countryList', Data)
    if (response.status) {
      yield put(getCountryListResponse(response.data))
    } else {
      yield put(getCountryListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getUserCountryList({ payload: { Data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/userCountryList', Data)
    if (response.status) {
      yield put(userCountryListResponse(response.data))
    } else {
      yield put(userCountryListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* ChangeCountryStatus({ payload: { data } }) {
  const { currentPage, dataPerPage, ...modData } = data

  try {
    const response = yield call(invokeApi, 'post', '/countryActive', modData)
    if (response.status) {
      yield put(
        addCountryStatusResponse(response.message, SUCCESS, response.data),
      )
      yield put(
        getCountryListRequest({
          searchText: '',
          pagination: {
            limit: dataPerPage,
            page: currentPage,
            orderKey: 'createdDate',
            orderBy: 'ASC',
          },
          filter: {},
        }),
      )
    } else {
      yield put(addCountryStatusResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//watch country
export function* watchCountry() {
  yield takeEvery(ADD_COUNTRY_REQUEST, addCountry)
  yield takeEvery(GET_COUNTRY_LIST_REQUEST, getCountryList)
  yield takeEvery(USER_COUNTRY_LIST_REQUEST, getUserCountryList)
  yield takeEvery(ADD_COUNTRY_STATUS_REQUEST, ChangeCountryStatus)
}

function* countrySaga() {
  yield all([fork(watchCountry)])
}
export default countrySaga
