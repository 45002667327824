  import React from 'react'
  import ReactDOM from 'react-dom/client'
  import './index.css'
  import App from './App'
  import reportWebVitals from './reportWebVitals'
  import { Provider } from 'react-redux'
  import { BrowserRouter } from 'react-router-dom'
  import { HelmetProvider } from 'react-helmet-async'
  import 'i18n/config'
  import { Tooltip } from 'react-tooltip'
  import CachingClear from 'components/CachingClear/CachingClear'
  import { persistor, store } from 'store/store'
  import { PersistGate } from 'redux-persist/integration/react'
  import AppLogout from 'components/AppLogout/AppLogout'

  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <HelmetProvider>
            <Tooltip anchorSelect=".my-tooltip" place="bottom" />
            <CachingClear>
              <AppLogout>
                <App />
              </AppLogout>
            </CachingClear>
          </HelmetProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>,
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
