import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import {
  createtSubscriberQueryResponse,
  getCategoriesResponse,
  getUserReportDetailsResponse,
  getUserReportDownloadResponse,
  getUserReportListResponse,
} from './action'

import {
  GET_USER_REPORT_LIST_REQUEST,
  GET_USER_REPORT_DETAILS_REQUEST,
  CREATE_SUBSCRIBER_QUERY_REQUEST,
  CREATE_USER_REPORT_DOWNLOAD_REQUEST,
} from './actionType'
import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'

function* getCategoriesList() {
  try {
    const response = yield call(invokeApi, 'get', `/categoriesList`)
    if (response.status) {
      yield put(getCategoriesResponse(response.data))
    } else {
      yield put(getCategoriesResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getUserReportList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/userReportList', data)
    if (response.status) {
      yield put(getUserReportListResponse(response.data.list, data.offset))
    } else {
      yield put(getUserReportListResponse([], data.offset))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getUserReportDetails({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/userReportDetails/${id}`)
    if (response.status) {
      response.data?.sectionData?.reportSection?.sort((x, y) => {
        return x.orderNo - y.orderNo
      })
      response.data.sectionData?.reportSection?.forEach((section) => {
        if (section?.subSections?.length > 0) {
          section?.subSections?.sort((x, y) => {
            return x.orderNo - y.orderNo
          })

          section?.subSections?.forEach((subSection) => {
            subSection?.sectionData?.faqs.sort((faq1, faq2) => {
              return faq1.orderNo - faq2.orderNo
            })
          })
        }
        if (section.sectionData?.faqs?.length) {
          section.sectionData?.faqs?.sort((faq1, faq2) => {
            return faq1.orderNo - faq2.orderNo
          })
        }
      })
      yield put(
        getUserReportDetailsResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getUserReportDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* createtSubscriberQuery({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/subscriberQuery', data)

    if (response.status) {
      yield put(createtSubscriberQueryResponse(response.message, SUCCESS))
    } else {
      yield put(createtSubscriberQueryResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createUserReportDownload({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'post',
      '/requestReportDownload',
      data,
    )

    if (response.status) {
      var cacheBuster = Math.random();
      var urlWithCacheBuster = response.data.link + '?report=' + cacheBuster;
      // Open the window with the modified URL
      window.open(urlWithCacheBuster);
      yield put(getUserReportDownloadResponse(response.message, SUCCESS))
    } else {
      yield put(getUserReportDownloadResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
export function* watchReport() {
  //yield takeEvery(ADD_LAW_REQUEST, getCategoriesList)
  yield takeEvery(GET_USER_REPORT_LIST_REQUEST, getUserReportList)
  yield takeEvery(GET_USER_REPORT_DETAILS_REQUEST, getUserReportDetails)
  yield takeEvery(CREATE_SUBSCRIBER_QUERY_REQUEST, createtSubscriberQuery)
  yield takeEvery(CREATE_USER_REPORT_DOWNLOAD_REQUEST, createUserReportDownload)
}
function* userReportSaga() {
  yield all([fork(watchReport)])
}
export default userReportSaga
