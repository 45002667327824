import { useEffect, React, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import {
  addCountryRequest,
  addCountryResponse,
} from 'store/master/country/action'
import { countryInitialState } from './CommonFunction'
import SimpleReactValidator from 'simple-react-validator'
import { __t } from 'i18n/translator'
import { isValidCountryCode } from 'helpers/SimpleReactValidators'

const AddCountry = (props) => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const countryData = countryInitialState(location?.state?.countryDetails)
  const operationType = !params.id ? 'Submit' : 'Update'

  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidCountryCode()),
    }),
  )

  useEffect(() => {
    if (props.countryCreate != null) {
      navigate('/console/country')
      props.clearCountryResponse()
    }
  }, [props.countryCreate])

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let formattedValues = ''
      formattedValues = {
        ...values,
        countryCode: values.countryCode,
        countryId: params.id ? params.id : '',
      }

      props.addCountry(formattedValues)
    } else {
      validator.current.showMessages()
    }
  }
  return (
    <Formik initialValues={countryData} onSubmit={formSubmit}>
      {({ values, errors, touched, handleSubmit }) => (
        <>
          <div className="container">
            <div className="section-head mt-3">
              <div>
                <Button
                  data-tooltip-content={'Back'}
                  className="my-tooltip back_btn"
                  onClick={() => {
                    navigate('/console/country')
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                  </svg>
                </Button>
                <h3> {!params.id ? 'Add Country' : ' Update country'} </h3>
              </div>
            </div>
          </div>

          <Form className="form-horizontal form-login">
            <Card className="container p-0">
              <CardBody className="row">
                <FormGroup className="col mb-0">
                  <Field
                    id="countryName"
                    name="countryName"
                    placeholder="countryName"
                    className="form-control"
                    type="countryName"
                  />
                  <Label className="form-label" htmlFor="countryName">
                    Country
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t('validations.country.country_required'),
                    values.countryName,
                    'required|alpha_space',
                    {
                      messages: {
                        required: __t('validations.country.country_required'),
                        alpha_space: __t(
                          'validations.country.country_allow_only_alpha',
                        ),
                      },
                    },
                  )}
                </FormGroup>

                <FormGroup className="col mb-0">
                  <Field
                    id="countryCode"
                    name="countryCode"
                    placeholder="example@example.com"
                    className="form-control"
                    type="countryCode"
                    disabled={params.id ? true : false}
                  />

                  <Label className="form-label" htmlFor="countryCode">
                    Country code
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t('validations.country.country_code_required'),
                    values.countryCode,
                    'required|isValidCountryCode',
                    {
                      messages: {
                        required: __t(
                          'validations.country.country_code_required',
                        ),
                        isValidCountryCode: __t(
                          'validations.country.country_code_invalid_required',
                        ),
                      },
                    },
                  )}
                </FormGroup>
              </CardBody>
            </Card>
            <Card className="container p-0 mt-3"></Card>
          </Form>

          <Row className="container">
            <Col className="d-flex justify-content-end mb-3 p-0">
              <FormGroup className="d-flex m-0 p-0">
                <Button
                  type="button"
                  color="outline-primary"
                  block
                  className="w-100 waves-effect waves-light ms-4"
                  onClick={() => {
                    navigate('/console/country')
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  block
                  className="w-100 waves-effect waves-light ms-4"
                  onClick={handleSubmit}
                >
                  {operationType}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({ countryReducer }) => {
  return {
    countryCreate: countryReducer.countryApiResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCountry: (creds) => {
      dispatch(addCountryRequest(creds))
    },
    clearCountryResponse: () => {
      dispatch(addCountryResponse())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCountry)
