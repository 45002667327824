import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Button,
} from 'reactstrap'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import {
  cleareResendQueryResponse,
  createResendQueryRequest,
  getConversationDetailsRequest,
} from 'store/frontEnd/tickets/action'
import { getDateTimeFromDateTime } from 'helpers/DataTimeHelpers'
import SimpleReactValidator from 'simple-react-validator'
import { SUCCESS } from 'constant/commonConstants'
import { getQueryNotificationRequest } from 'store/frontEnd/home/action'
import { CloseButton } from 'react-bootstrap'

const TicketsDetails = (props) => {
  const { id } = useParams()

  const validator = useRef(new SimpleReactValidator())
  const [initialValues, setInitialValue] = useState({
    query: '',
  })
  const [isFullScreen, setIsFullScreen] = useState(false)
  const location = useLocation()
  const [conversationDetails, setConversationDetails] = useState([])

  useEffect(() => {
    if (props.conversationDetails) {
      setConversationDetails(props.conversationDetails)
    }
  }, [props.conversationDetails])

  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(scrollToBottom, [conversationDetails])

  useEffect(() => {
    props.getConversationDetails(id)
  }, [id])


  useEffect(() => {
    if (props.resendQueryApiResponse === SUCCESS) {
      setInitialValue({
        query: '',
      })
      props.getQueryNotificationRequest()
      // props.getConversationDetails(id)
      props.cleareResendQuery()
    }
  }, [props.resendQueryApiResponse])

  const formSubmit = async (values, { resetForm }) => {
    if (validator.current.allValid()) {
      let formattedValues = {
        ...values,
        queryId: id,
        createdBy: location.state.createdBy,
      }
      props.createResendQuery(formattedValues)

      setInitialValue({ query: '' })
      resetForm()
      validator.current.hideMessages()
    } else {
      validator.current.showMessages()
    }
  }

  const [isStickyNote, setIsStickyNote] = useState(true)

  return (
    <>
      <div>
        <div className="top_section my_queries">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>My Queries</h1>
                  <span>My Queries</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/home"> Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to="/tickets"> My Tickets</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      Tickets ID : {location.state.ticketId}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
              {/* <Col>
                <div className="ticket-id-date">
                  <div className="ticket_id">
                    <p>
                      Tickets ID : <span>{location.state.ticketId}</span>
                    </p>
                  </div>
                  <div className="ticket-date">
                    <span>{location.state.createdDate}</span>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        { isStickyNote &&
        <div className='ticket_note-box'>
          <p className='mb-0'>Note : For your safety, please avoid sharing sensitive information such as personal identification numbers on this platform, We will store your email address securely for our internal use only.</p>
          <CloseButton title='Close' onClick={() => setIsStickyNote(false)}></CloseButton>
        </div>
        }
        <div className={'ticket_section ticket_details ' + (isFullScreen && 'full-sc-chat')}>
          <div className='ticket ticket_details'>
            <div className="ticket-head">
              {/* <div className="ticket_head">
                <div className="ticket_id">
                  <p>
                    Tickets ID : <span>{location.state.ticketId}</span>
                  </p>
                </div>
                <div className="ticket_head_right">
                  <p className='ticket_status pending'>Pending</p>
                  change class "completed"
                  <span> {location.state.createdDate}</span>
                </div>
              </div> */}
              <div className="ticket_text">
                <h5>{location.state.ReportTitle}</h5>
                <p>{location.state.sectionTitle}</p>
              </div>
              <a
                title="Fullscreen Chat"
                className="fullscreen-chat cursor-pe"
                onClick={() => setIsFullScreen(isFullScreen ? false : true)}
              >
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.031 20.031L2.55 28.5H9V30H0V21H1.5V27.4275L9.969 18.969L11.031 20.031ZM30 0H21V1.5H27.45L18.969 9.969L20.0295 11.031L28.5 2.5725V9H30V0ZM21 30H30V21H28.5V27.4275L20.031 18.969L18.9705 20.031L27.45 28.5H21V30ZM9 0H0V9H1.5V2.5725L9.969 11.031L11.0295 9.969L2.55 1.5H9V0Z" fill="currentColor"/>
                </svg>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.5 21H21V28.5" stroke="currentColor" strokeWidth="1.5"/>
                  <path d="M21 21L31 31" stroke="currentColor" strokeWidth="1.5"/> <path d="M11 28.5V21H3.5" stroke="currentColor" strokeWidth="1.5"/> <path d="M11 21L1 31" stroke="currentColor" strokeWidth="1.5"/> <path d="M3.5 11H11V3.5" stroke="currentColor" strokeWidth="1.5"/> <path d="M11 11L1 1" stroke="currentColor" strokeWidth="1.5"/> <path d="M21 3.5V11H28.5" stroke="currentColor" strokeWidth="1.5"/> <path d="M21 11L31 1" stroke="currentColor" strokeWidth="1.5"/>
                </svg>
              </a>
            </div>

            <div className="ticket_chat">
              <ul className="small-scroll">
                {conversationDetails?.map((x, index) => {
                  return (
                    <React.Fragment key={index}>
                      {x?.query != null && (
                        <li className="sent_massage">
                          <div>
                            <p>{x?.query}
                            <span>{getDateTimeFromDateTime(x.createdDate)}</span>
                            </p>
                            
                          </div>
                        </li>
                      )}
                      {x?.respond != null && (
                        <li className="receive_massage">
                          <div>
                            <p>{x?.respond}
                            <span>{getDateTimeFromDateTime(x.createdDate)}{' '}</span>
                            </p>
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
                <div ref={messagesEndRef}></div>
              </ul>

              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={formSubmit}
              >
                {({ values, handleSubmit }) => (
                  <Form>
                    <FormGroup className="mb-0 chat_input">
                      {validator.current.message(
                        'query',
                        values.query,
                        'required',
                      )}
                      <Field
                        type="text"
                        className="form-control"
                        name="query"
                        placeholder="Write your answer here..."
                        value={values.query}
                      />
                      <Button
                        color="primary"
                        type="submit"
                        className="message_sent_btn"
                      //disabled={values.query.length > 0 ? true : false}
                      >
                        <svg
                          width="38"
                          height="32"
                          viewBox="0 0 38 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.6897 7.63793L2 13.6724L12.2586 19.7069C18.4943 14.6782 31.3276 4.5 32.7759 4.01724C34.2241 3.53448 33.7816 4.62069 33.3793 5.22414L14.069 20.9138L26.1379 30.569L30.9655 19.1034L37 1L17.6897 7.63793Z"
                            fill="currentColor"
                            stroke="currentColor"
                          />
                          <path
                            d="M13.1748 22.4003L12.3424 21.732L12.3619 22.7993L12.4722 28.8328L12.4917 29.9001L13.2992 29.2018L16.8641 26.1189L17.318 25.7264L16.8501 25.3508L13.1748 22.4003Z"
                            stroke="currentColor"
                          />
                        </svg>
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ userTicketReducer }) => {
  let conversationDetails = []
  let resendQueryApiResponse = []
  if (
    userTicketReducer.conversationDetails &&
    userTicketReducer.conversationDetails?.data?.list
  ) {
    conversationDetails = userTicketReducer.conversationDetails.data.list
  }

  if (userTicketReducer.resendQueryApiResponse) {
    resendQueryApiResponse =
      userTicketReducer.resendQueryApiResponse.responseType
  }

  return {
    conversationDetails: conversationDetails,
    resendQueryApiResponse: resendQueryApiResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConversationDetails: (id) => {
      dispatch(getConversationDetailsRequest(id))
    },
    createResendQuery: (data) => {
      dispatch(createResendQueryRequest(data))
    },
    cleareResendQuery: () => {
      dispatch(cleareResendQueryResponse())
    },
    getQueryNotificationRequest: () => {
      dispatch(getQueryNotificationRequest())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketsDetails)
