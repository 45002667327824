import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import {
  GET_COUNT_REQUEST,
  GET_PUBLISHER_LIST_REQUEST,
  GET_USER_CATEGORIES_REQUEST,
  GET_VISITOR_REQUEST,
} from './actionType'
import {
  getCountResponse,
  getPublisherListResponse,
  getUserCategoriesResponse,
  getVisitorResponse,
} from './action'

function* getCategoriesRequestDetails({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'get', '/categories')

    if (response.status) {
      yield put(getUserCategoriesResponse(response.data.list))
    } else {
      yield put(getUserCategoriesResponse([]))
      //yield put(getPopularFaqResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* Visitor({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/visitor', data)
    if (response.status) {
      // ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(getVisitorResponse(response.message, SUCCESS, response.data))
    } else {
      // ApiResponseMessage(response.message, DANGER)
      yield put(getVisitorResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* viewCount({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/viewCount', data)
    if (response.status) {
      // ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(getCountResponse(response.message, SUCCESS, response.data))
    } else {
      // ApiResponseMessage(response.message, DANGER)
      yield put(getCountResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getPublisherList({ payload: { Data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/publisherList', Data)
    if (response.status) {
      yield put(getPublisherListResponse(response.data.list))
    } else {
      yield put(getPublisherListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchFaq() {
  yield takeEvery(GET_VISITOR_REQUEST, Visitor)
  yield takeEvery(GET_COUNT_REQUEST, viewCount)
  yield takeEvery(GET_USER_CATEGORIES_REQUEST, getCategoriesRequestDetails)
  yield takeEvery(GET_PUBLISHER_LIST_REQUEST, getPublisherList)
}
function* userCommonSaga() {
  yield all([fork(watchFaq)])
}
export default userCommonSaga
