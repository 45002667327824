import {
  CLEAR_USER_FAQ_LIST_RESPONSE,
  GET_USER_FAQ_LIST_REQUEST,
  GET_USER_FAQ_LIST_RESPONSE,
  UPDATE_POPULAR_FAQ_REQUEST,
  UPDATE_POPULAR_FAQ_RESPONSE,
} from './actionType'

export const getUserFaqListRequest = (data) => {
  return {
    type: GET_USER_FAQ_LIST_REQUEST,
    payload: { data },
  }
}

export const getUserFaqListResponse = (response, offset) => {
  return {
    type: GET_USER_FAQ_LIST_RESPONSE,
    payload: { response, offset },
  }
}
export const clearUserFaqListResponse = () => {
  return {
    type: CLEAR_USER_FAQ_LIST_RESPONSE,
  }
}
export const updateFaqListRequest = (data) => {
  return {
    type: UPDATE_POPULAR_FAQ_REQUEST,
    payload: { data },
  }
}

export const updateFaqListResponse = (data) => {
  return {
    type: UPDATE_POPULAR_FAQ_RESPONSE,
    payload: { data },
  }
}
