import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  CREATE_FAQ_REQUEST,
  CREATE_FAQ_REVIEW_REQUEST,
  CREATE_REVIEW_LAW_REQUEST,
  GET_FAQ_DETAILS_REQUEST,
  GET_FAQ_HISTORY_REQUEST,
  GET_LIST_FAQ_REQUEST,
  GET_LIST_KEYWORDS_REQUEST,
  IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_REQUEST,
} from './actionType'
import {
  createFaqResponse,
  getListFaqResponse,
  getListKeywordsResponse,
  getFaqDetailsResponse,
  getFaqHistoryResponse,
  createFaqReviewResponse,
  createreReviewLawResponse,
  getFaqDetailsRequest,
  getActiveDeactiveFaqForFrontendResponse,
} from './action'

import { invokeApi } from '../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

function* createFaq({ payload: { data } }) {
  try {
    let url = '/createFaq'
    if (data?.faqId) {
      url = '/updateFaq'
    }
    const response = yield call(invokeApi, 'post', url, data)

    if (response.status) {
      yield put(createFaqResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      yield put(createFaqResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* listFaq({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/faqList', data)

    if (response.status) {
      if (response?.data?.list?.length > 0) {
        response?.data.list?.forEach((faq) => {
          faq?.approvalWorkFlow?.approvalDetails?.sort((user1, user2) => {
            return user1.orderNo - user2.orderNo
          })
        })
      }

      yield put(getListFaqResponse(response.data))
      yield put(getFaqDetailsResponse([]))
    } else {
      yield put(getListFaqResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* faqDetails({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'get', `/faqDetails/${data}`)

    if (response.status) {
      if (response?.data?.details?.length > 0) {
        response?.data?.details?.forEach((faq) => {
          faq?.approvalWorkFlow?.approvalDetails?.sort((user1, user2) => {
            return user1.orderNo - user2.orderNo
          })
        })
      }
      yield put(getFaqDetailsResponse(response.data))
    } else {
      yield put(getFaqDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* listKeywords() {
  try {
    const response = yield call(invokeApi, 'post', '/keywordsList')
    if (response.status) {
      yield put(getListKeywordsResponse(response.data))
    } else {
      yield put(getListKeywordsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getFaqHistory({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/faqHistory/${id}`)

    if (response.status) {
      yield put(getFaqHistoryResponse(response.data))
    } else {
      yield put(getFaqHistoryResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createFaqReview({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/faqReview', data)

    if (response.status) {
      yield put(createFaqReviewResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(createFaqReviewResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createReviewLaw({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reviewLaw', data)
    if (response.status) {
      if (data.faqId) {
        yield put(getFaqDetailsRequest(data.faqId))
      }
      yield put(createreReviewLawResponse(response.message, SUCCESS))
    } else {
      yield put(createreReviewLawResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getActiveDeactiveFaqForFrontend({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/faqFrontStatus', data)
    if (response.status) {

      yield put(getActiveDeactiveFaqForFrontendResponse(response.message, SUCCESS))
    } else {
      yield put(getActiveDeactiveFaqForFrontendResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}



export function* watchLaw() {
  yield takeEvery(CREATE_FAQ_REQUEST, createFaq)
  yield takeEvery(GET_LIST_FAQ_REQUEST, listFaq)
  yield takeEvery(GET_LIST_KEYWORDS_REQUEST, listKeywords)
  yield takeEvery(GET_FAQ_DETAILS_REQUEST, faqDetails)
  yield takeEvery(GET_FAQ_HISTORY_REQUEST, getFaqHistory)
  yield takeEvery(CREATE_FAQ_REVIEW_REQUEST, createFaqReview)
  yield takeEvery(CREATE_REVIEW_LAW_REQUEST, createReviewLaw)
  yield takeEvery(IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_REQUEST, getActiveDeactiveFaqForFrontend)


}
function* faqSaga() {
  yield all([fork(watchLaw)])
}
export default faqSaga
