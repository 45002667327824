import produce from 'immer';
import { CREATE_ROLE_RESPONSE, GET_ROLE_LIST_RESPONSE, ROLE_PERMISSION_RESPONSE } from './actionType';
export const initialState = {
  getRoleList: [],
  rolePermission: [],
  createUpdateRoleRespo: null
};
//country function for operation
export const roleReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_ROLE_LIST_RESPONSE:
    {
      draft.getRoleList = action.payload.getRoleList;
      break;
    }
    case ROLE_PERMISSION_RESPONSE:
    {
      draft.rolePermission = action.payload.data;
      break;
    }
    case CREATE_ROLE_RESPONSE:
    {
      draft.createUpdateRoleRespo = action.payload.data;
      break;
    }
    default:
      state = { ...state };
      break;
    }
  });
