import produce from 'immer'
import {
  ASK_FOR_SUBSCRIBE,
  GET_COUNT_RESPONSE,
  GET_PUBLISHER_LIST_RESPONSE,
  GET_USER_CATEGORIES_RESPONSE,
  GET_VISITOR_RESPONSE,
} from './actionType'
export const initialState = {
  userCategoriesList: [],
  askForSubscribeId: '',
  publisherList: [],
}
export const UserCommonReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_CATEGORIES_RESPONSE: {
        draft.userCategoriesList = action.payload.data
        break
      }
      case ASK_FOR_SUBSCRIBE: {
        draft.askForSubscribeId = action.payload.id
        break
      }

      case GET_VISITOR_RESPONSE: {
        draft.visitorApiReponse = action.payload.data
        break
      }

      case GET_COUNT_RESPONSE: {
        draft.visitorApiReponse = action.payload.data
        break
      }

      case GET_PUBLISHER_LIST_RESPONSE:
        draft.publisherList = action.payload.publisherList
        break

      default:
        state = { ...state }
        break
    }
  })
