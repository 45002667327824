/* eslint-disable no-debugger */

import React, { useEffect } from 'react'
import { Button, NavItem, NavLink } from 'reactstrap'

const RoleNavItem = (props) => {
  const {
    name,
    id,
    setCreateRole,
    isDefault,
    isActive,
    handleOnclick,
    handleOnEdit,
  } = props
  useEffect(() => {
    if (isDefault) {
      setCreateRole(id)
      handleOnclick(id)
    }
  }, [isDefault])
  return (
    <NavItem className={isActive ? 'active' : ''}>
      <NavLink
        onClick={() => {
          setCreateRole(id)
          handleOnclick(id)
        }}
      >
        {name}
      </NavLink>
      {isActive && (
        <Button
          color="link"
          onClick={() => handleOnEdit(id, name)}
          className="m-0 p-0"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.1208 7.42339L15.9168 5.62738C16.462 5.08213 16.7346 4.80951 16.8804 4.51541C17.1577 3.95586 17.1577 3.29891 16.8804 2.73935C16.7346 2.44526 16.462 2.17263 15.9168 1.62738C15.3715 1.08213 15.0989 0.809509 14.8048 0.663773C14.2452 0.38649 13.5883 0.38649 13.0287 0.663773C12.7346 0.809509 12.462 1.08213 11.9168 1.62738L10.0981 3.44604C11.062 5.09664 12.4481 6.4722 14.1208 7.42339ZM8.64365 4.9005L1.77314 11.771C1.34808 12.1961 1.13555 12.4086 0.995818 12.6697C0.856084 12.9308 0.797138 13.2255 0.679248 13.815L0.0638519 16.8919C-0.00267025 17.2246 -0.0359313 17.3909 0.0586767 17.4855C0.153285 17.5801 0.31959 17.5468 0.6522 17.4803L3.72918 16.8649C4.31863 16.747 4.61336 16.6881 4.87446 16.5483C5.13555 16.4086 5.34808 16.1961 5.77315 15.771L12.6625 8.8816C11.0409 7.86595 9.66919 6.50366 8.64365 4.9005Z"
              fill="CurrentColor"
            />
          </svg>
        </Button>
      )}
    </NavItem>
  )
}

export default RoleNavItem
