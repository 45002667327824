import produce from 'immer';
import {
  CREATE_USER_API_RESPONSE,
  GET_USER_LIST_RESPONSE,
  USER_STATUS_RESPONSE,
} from './actionType';
export const initialState = {
  createUserApiResponse: null,
  getUserListApiResponse: null,
  statusApiResponse: null,
};
export const createUserReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case CREATE_USER_API_RESPONSE:
      {
        if (action.payload.message == undefined) {
          draft.createUserApiResponse = null;
        }
        else {
          draft.createUserApiResponse = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          };
        }
      }
      break;

    case GET_USER_LIST_RESPONSE:
      draft.getUserListApiResponse = {
        data: action.payload.data,
        message: action.payload.message,
        responseType: action.payload.responseType,
      };
      break;

    case USER_STATUS_RESPONSE:
      draft.statusApiResponse = {
        data: action.payload.data,
        message: action.payload.message,
        responseType: action.payload.responseType,
      };
      break;

    default:
      state = { ...state };
      break;
    }
  });
