import React, { useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const CustomPagination = ({
  dataPerPage,
  totalData,
  currentPage,
  direction,
  setDataPerPage,
  setPaginationData,
  setCurrentPage,
  ...args
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
    pageNumbers.push(i)
  }

  let start = 1,
    end = pageNumbers?.length
  if (currentPage - 2 > 1) {
    start = currentPage - 2
  }
  if (currentPage + 2 < pageNumbers?.length) {
    end = currentPage + 2
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const handleDataPerPageChange = (event) => {
    setDataPerPage(Number(event))
  }

  return (
    <div className="table-pagination">
      <div className="record-perpage">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
          <DropdownToggle caret>{dataPerPage}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleDataPerPageChange('10')}>
              10
            </DropdownItem>
            <DropdownItem onClick={() => handleDataPerPageChange('25')}>
              25
            </DropdownItem>
            <DropdownItem onClick={() => handleDataPerPageChange('50')}>
              50
            </DropdownItem>
            <DropdownItem onClick={() => handleDataPerPageChange('100')}>
              100
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <span className="totle_records">{totalData} Records</span>
      </div>
      <Pagination>
        <Pagination.First
          onClick={() => paginate(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {start !== 1 && <Pagination.Ellipsis />}
        {pageNumbers.slice(start - 1, end).map((number) => (
          <Pagination.Item
            key={number}
            onClick={() => paginate(number)}
            active={currentPage === number}
          >
            {number}
          </Pagination.Item>
        ))}
        {end !== pageNumbers?.length && <Pagination.Ellipsis />}
        <Pagination.Next
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === pageNumbers?.length}
        />
        <Pagination.Last
          onClick={() => paginate(pageNumbers?.length)}
          disabled={currentPage === pageNumbers?.length}
        />
      </Pagination>
    </div>
  )
}

export default CustomPagination
