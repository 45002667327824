export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST'
export const CREATE_TEAM_RESPONSE = 'CREATE_TEAM_RESPONSE'

export const GET_TEAM_LEAD_REQUEST = 'GET_TEAM_LEAD_REQUEST'
export const GET_TEAM_LEAD_RESPONSE = 'GET_TEAM_LEAD_RESPONSE'

export const GET_TEAM_USER_REQUEST = 'GET_TEAM_USER_REQUEST'
export const GET_TEAM_USER_RESPONSE = 'GET_TEAM_USER_RESPONSE'

export const CLEARE_CREATE_TEAM_RESPONSE = 'CLEARE_CREATE_TEAM_RESPONSE'

export const GET_TEAM_MEMBER_LIST_REQUEST = 'GET_TEAM_MEMBER_LIST_REQUEST'
export const GET_TEAM_MEMBER_LIST_RESPONSE = 'GET_TEAM_MEMBER_LIST_RESPONSE'

export const DELETE_TEAM_MEMBER_REQUEST = 'DELETE_TEAM_MEMBER_REQUEST'
export const DELETE_TEAM_MEMBER_RESPONSE = 'DELETE_TEAM_MEMBER_RESPONSE'
