import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button } from 'reactstrap'
import forgotbg from 'images/forgot_bg.jpg'
import logosm from 'images/logo.webp'
import { connect } from 'react-redux'
import { forGetPassword } from 'store/auth/passwordforget/action'
import SimpleReactValidator from 'simple-react-validator'
import { __t } from 'i18n/translator'
import ReCAPTCHA from 'react-google-recaptcha'
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER } from 'constant/commonConstants'
const state = {
  email: '',
  recaptcha: '',
  user_captcha_input: ""
}

const ForgotPassward = (props) => {
  const validator = useRef(new SimpleReactValidator())
  const recaptchaRef = useRef();

  const FormSubmit = async (values, { setFieldValue }) => {
    if (validator.current.allValid()) {

      let isValidateCaptcha = validateCaptcha(values?.user_captcha_input)
      if (!isValidateCaptcha) {
        ApiResponseMessage('Invalid captcha', DANGER)
        return false
      }
      props.ForgotApiRequest(values)
      recaptchaRef.current.reset();
      setFieldValue('recaptcha', '')
    }
    else {
      validator.current.showMessages()
    }
  }

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, [])

  return (
    <div className="login-page">
      <Row>
        <Col className="login-img">
          <img alt="Forgot Password" src={forgotbg} />
        </Col>
        <Formik
          initialValues={state}
          onSubmit={FormSubmit}

        >
          {({ values, errors, touched, setValues, setFieldValue }) => (
            <Col className="login-form">
              <div className="login-main">
                <div className="loging-head">
                  <img alt="Logo" src={logosm} />
                </div>

                <div className="loging-content">
                  <h2>Forgot Password</h2>
                  <p>
                    No worries,Please enter your registered email address. We
                    will send you a link to reset your password.
                  </p>
                  <Form
                    role={'form'}
                    className="form-horizontal form-login"
                  >
                    <FormGroup className="col-12">
                      <Field
                        id="email"
                        name="email"
                        placeholder="example@example.com"
                        className="form-control"
                        type="email"
                      />
                      <Label className="form-label" htmlFor="email">
                        Email Address
                      </Label>
                      {validator.current.message(
                        __t('validations.auth.email'),
                        values.email,
                        'required|email',
                        {
                          messages: {
                            required: __t('validations.auth.email'),
                            email:
                              __t(
                                'validations.common.email_valid'
                              ),
                          },
                        },
                      )}
                    </FormGroup>
                    <FormGroup className="col-12">
                      {/* <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                        onChange={(value) => {
                          setValues({ ...values, recaptcha: value })
                        }}
                        onExpired={() => {
                          setValues({ ...values, recaptcha: '' })
                        }}
                      ></ReCAPTCHA> */}

                      <div className='captcha_box'>
                        <div className='captach_text'>
                          <LoadCanvasTemplateNoReload />
                          <Button className='btn btn-link' color='link' title='Reload Captcha' onClick={() => {
                            loadCaptchaEnginge(6);
                          }}
                          >
                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.93077 9.2003C2.00244 4.23968 6.07619 0.25 11.0789 0.25C14.3873 0.25 17.287 1.99427 18.8934 4.60721C19.1103 4.96007 19.0001 5.42199 18.6473 5.63892C18.2944 5.85585 17.8325 5.74565 17.6156 5.39279C16.2727 3.20845 13.8484 1.75 11.0789 1.75C6.8945 1.75 3.50372 5.0777 3.431 9.19817L3.83138 8.80092C4.12542 8.50918 4.60029 8.51105 4.89203 8.80509C5.18377 9.09913 5.18191 9.574 4.88787 9.86574L3.20805 11.5324C2.91565 11.8225 2.44398 11.8225 2.15157 11.5324L0.471757 9.86574C0.177716 9.574 0.17585 9.09913 0.467591 8.80509C0.759331 8.51105 1.2342 8.50918 1.52824 8.80092L1.93077 9.2003ZM18.7864 8.46658C19.0786 8.17781 19.5487 8.17781 19.8409 8.46658L21.5271 10.1333C21.8217 10.4244 21.8245 10.8993 21.5333 11.1939C21.2421 11.4885 20.7673 11.4913 20.4727 11.2001L20.0628 10.7949C19.9934 15.7604 15.9017 19.75 10.8825 19.75C7.56379 19.75 4.65381 18.007 3.0412 15.3939C2.82366 15.0414 2.93307 14.5793 3.28557 14.3618C3.63806 14.1442 4.10016 14.2536 4.31769 14.6061C5.6656 16.7903 8.09999 18.25 10.8825 18.25C15.0887 18.25 18.4922 14.9171 18.5625 10.7969L18.1546 11.2001C17.86 11.4913 17.3852 11.4885 17.094 11.1939C16.8028 10.8993 16.8056 10.4244 17.1002 10.1333L18.7864 8.46658Z" fill="currentColor" /></svg>
                          </Button>
                        </div>
                        <div className='captcha_input'>
                          <Field
                            autoComplete="off"
                            name="user_captcha_input"
                            type="text"
                            className="form-control"
                            placeholder="Enter captcha value*"

                          />
                        </div>
                      </div>

                      {validator.current.message(
                        __t('validations.common.recaptcha'),
                        values.user_captcha_input,
                        'required',
                        {
                          messages: {
                            required: __t(
                              'validations.common.recaptcha',
                            ),
                          },
                        },
                      )}
                    </FormGroup>

                    <Col lg="12">
                      <Button
                        type="submit"
                        color="primary"
                        block
                        className="w-100 waves-effect waves-light"
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col lg="12">
                      <Link
                        to="/console/login"
                        className="pt-3 w-100 text-center backto_login"
                      >
                        <svg
                          className="me-1"
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
                            fill="currentColor"
                          />
                        </svg>
                        Back To Login
                      </Link>
                    </Col>
                  </Form>
                </div>
                {/* <div className="text-center copy-right w-100">
                    <p>
                      {' '}
                      &#169; {new Date().getFullYear()} - All rights reserved.
                    </p>
                  </div> */}
              </div>
            </Col>

          )}
        </Formik>
      </Row>
      <div className="login-footer">
        <p className="mb-0">
          &#169; {new Date().getFullYear()} - All Rights Reserved.
        </p>
      </div>
    </div>
  )
}
// forgotPassword

const mapStateToProps = ({ loginReducer }) => {
  return {
    loginReducer: loginReducer.loginApiResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ForgotApiRequest: (creds) => {
      dispatch(forGetPassword(creds))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassward)
