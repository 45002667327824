import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import CreateReport from './CreateReport'
import { connect } from 'react-redux'

import {
  Link,
  useLocation,
  useOutletContext,
} from 'react-router-dom'
import { DEFAULT_PAGE_LENGTH, ENGLISH } from 'constant/commonConstants'
import {
  clearReportResponse,
  getReportDownloadRequest,
  getReportRequest,
} from 'store/report/action'
import {
  Row,
  Label,
  FormGroup
} from 'reactstrap'
import Select from 'react-select'
import { ReportTable } from 'components/Table/ReportTable'
import { formatDateForApi } from 'helpers/DataTimeHelpers'
import { createUserReportDownloadRequest } from 'store/frontEnd/report/action'
import { authUserType } from 'helpers/authUtils'
import ViewReportDetails from './ViewReportDetails'
import { languageList } from 'constant/dropdown'

const Report = (props) => {
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const location = useLocation()
  const [reportList, setReportList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState(DEFAULT_PAGE_LENGTH)
    const [activeLanguage, setActiveLanguage] = useState(ENGLISH);
  const [isOperationType, setIsOperationType] = useState({
    action: 'list',
    id: '',
  })

  const [currentActiveTab, setcurrentActiveTab] = useState()
  const [userType, setUserType] = useState('')
  const [searchText, setSearchText] = useState('')
  const savedLanguage = localStorage.getItem('selectedLanguage');
  const [paginationData, setPaginationData] = useState({
    type: location.state?.isActiveTab ? location.state?.isActiveTab : 'All',
    searchText: '',
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: {},
  })

  const tableAction = (data) => {
    setIsOperationType(data)
  }

  useEffect(() => {
    if (props.reportList) {
      setReportList(props.reportList)
    }
  }, [props.reportList])

  useEffect(() => {
    setCurrentPage(1)
  }, [dataPerPage])

  useEffect(() => {
    if (isOperationType?.action == "list") {
      props.getReportList({
        searchText: searchText,
        pagination: {
          limit: dataPerPage,
          page: currentPage,
          orderKey: 'createdDate',
          orderBy: 'ASC',
        },
        filter: paginationData.filter,
        type: currentActiveTab ? currentActiveTab : 'All',
      })
    }
  }, [currentPage, dataPerPage, currentActiveTab, searchText, isOperationType])

  useEffect(() => {
    if (location?.state?.action === 'save') {
      tableAction(location?.state)
    } else {
      props.getReportList(paginationData)
      setIsOperationType({
        action: 'list',
        id: '',
      })
    }
  }, [location.state, location.key])

  const isAdmin = () => {
    let isUserAdmin =
      permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
        ? true
        : false

    return isUserAdmin
  }
  useEffect(() => {
    let checkUserType = authUserType()
    if (isAdmin() == true) {
      setUserType('INTERNAL')
    } else if (checkUserType) {
      setUserType(checkUserType)
    }
  }, [])

  const resetAction = () => {
    props.getReportList(paginationData)
    setIsOperationType({
      action: 'list',
      id: '',
    })
  }

  const manegeDownloadByPermissions = (row) => {
    let isActiveTab = currentActiveTab ? currentActiveTab : 'All'
    if (
      permissions?.add === 1 &&
      permissions?.approve === 1 &&
      permissions?.delete === 1 &&
      permissions?.update === 1 &&
      permissions?.view === 1
    ) {
      props.downloadRequest(row?.id,row?.language)
    } else if (isActiveTab == 'Other' || isActiveTab == 'Other-published') {
      props.createDownloadRequest({
        language:savedLanguage,
        reportId: row.id,
        createdBy: row?.createdBy,
      })
    } else if (
      isActiveTab == 'All' ||
      isActiveTab == 'Published' ||
      isActiveTab == 'Internal'
    ) {
      props.downloadRequest(row?.id,row?.language)
    }
  }

  const columns = [
    {
      dataField: 'question',
      text: 'Question',
      sort: true,
    },
    {
      dataField: 'Approvel',
      text: 'Approvel',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Stutus',
      sort: true,
    },
    {
      dataField: 'Action',
      text: '',
      headerClasses: 'action_col',

      formatter: (cell, row) => (
        <div className="action_col text-center">
          <Link
            className="btn btn-link edit_btn"
            to={'view/' + row.id}
            state={{ faq: row }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1123_13264)">
                <path
                  d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1123_13264">
                  <rect width="20" height="20" fill="CurrentColor" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className={isOperationType.action != 'list' && 'd-none'}>
      <div className='create-report'>
          <div className="container">
            <Row>
              <FormGroup className="col mb-0 language-selector">
                <Label className="form-label select-label">Language:</Label>

                <Select
                  id="language"
                  className="form-control selectbox"
                  options={languageList}
                  onChange={(opt) => {
                    setActiveLanguage(
                      opt?.value ? opt?.value : ENGLISH
                    )
                  }}
                  value={languageList.find((option) => option.label === activeLanguage)}             
                  menuPlacement="auto"
                />
              </FormGroup>
            </Row>
          </div>
        </div>
        <Formik>
          <ReportTable
            getCurrentActiveFilter={(filterData, Type) => {
              setPaginationData({
                ...paginationData,
                type: Type,
                filter: filterData,
              })
            }}
            getViewDetailPageData={(id, isReviewData) => {
              setIsOperationType({
                action: 'viewDetails',
                id: id,
                isReview: isReviewData,
              })
            }}
            //new change
            currentActiveTab={(e) => {
              setcurrentActiveTab(e)
            }}
            //new change

            getFilterDataByLaw={(
              CatId,
              SubCatId,
              Date,
              Type,
              publishedReportType,
            ) => {
              setCurrentPage(1)
              if (currentPage == 1) {
                props.getReportList({
                  ...paginationData,
                  type: Type,
                  filter: {
                    categoryId: CatId === undefined || null ? '' : CatId,
                    subCategoryId:
                      SubCatId === undefined || null ? '' : SubCatId,
                    date: Date != null ? formatDateForApi(Date) : '',
                    publishedReportType: publishedReportType
                      ? publishedReportType
                      : '',
                  },
                })
              } else {
                setPaginationData({
                  ...paginationData,
                  type: Type,
                  filter: {
                    categoryId: CatId === undefined || null ? '' : CatId,
                    subCategoryId:
                      SubCatId === undefined || null ? '' : SubCatId,
                    date: Date != null ? formatDateForApi(Date) : '',
                  },
                })
              }

              // filterData(CatId, SubCatId, Date, Type)
            }}
            getRefreshList={(isActiveTab) => {
              // setCurrentPage(1)
              setPaginationData({
                ...paginationData,
                filter: {},
                type: isActiveTab,
              })

              props.getReportList({
                type: isActiveTab,
                searchText: '',
                pagination: {
                  limit: dataPerPage,
                  page: currentPage,
                  orderKey: 'createdDate',
                  orderBy: 'ASC',
                },
                filter: {},
              })
            }}
            userType={userType}
            hedingName="Reports"
            buttonName={permissions?.add === 1 ? 'Create Report' : ''}
            component={CreateReport}
            totalRecords={props?.totalRecords}
            action={tableAction}
            clearReportResponse={() => {
              props.clearReportResponse()
            }}
            updateData={(e) => {
              if (e.searchText) {
                setSearchText(e.searchText)
              } else {
                setSearchText('')
              }
              props.getReportList(e)
            }}
            data={reportList ? reportList : []}
            columns={columns}
            download={(row) => {
              let data = {
                ...row,
                language:activeLanguage
              }
              manegeDownloadByPermissions(data)
            }}
            dataPerPage={dataPerPage}
            currentPage={currentPage}
            setDataPerPage={setDataPerPage}
            setCurrentPage={setCurrentPage}
            activeLanguage={activeLanguage}
          ></ReportTable>
        </Formik>
      </div>

      <div className={isOperationType.action != 'viewDetails' && 'd-none'}>
        <ViewReportDetails
          viewReportId={isOperationType.id}
          isReview={isOperationType.isReview ? isOperationType.isReview : null}
          currentActiveTab={currentActiveTab}
          resetViewDetailsData={(value) => {
            if (value == true) {
              props.getReportList({
                type: currentActiveTab ? currentActiveTab : 'All',
                searchText: '',
                pagination: {
                  limit: dataPerPage,
                  page: currentPage,
                  orderKey: 'createdDate',
                  orderBy: 'ASC',
                },
                filter: {},
              })
            }
            setIsOperationType({
              action: 'list',
              id: '',
            })
          }}
          download={(row) => {
            manegeDownloadByPermissions(row)
          }}
        />
      </div>

      {isOperationType.action === 'save' && (
        <CreateReport
          id={isOperationType.id}
          resetAction={() => resetAction()}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ reportReducer }) => {
  let reportList = []
  let totalRecords = 0
  if (reportReducer.reportList) {
    reportList = reportReducer?.reportList?.list
      ? reportReducer?.reportList?.list
      : []
    totalRecords = reportReducer?.reportList?.totalRecords
      ? reportReducer?.reportList?.totalRecords
      : 0
  }

  return {
    reportList: reportList,
    totalRecords,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReportList: (data) => {
      dispatch(clearReportResponse())
      dispatch(getReportRequest(data))
    },
    clearReportResponse: () => {
      dispatch(clearReportResponse())
    },
    downloadRequest: (id, language) => {
      dispatch(getReportDownloadRequest(id,language))
    },
    createDownloadRequest: (data) => {
      dispatch(createUserReportDownloadRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Report)
