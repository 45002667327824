import { useEffect, useState, React } from 'react'
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  Table,
  Label,
  FormGroup
} from 'reactstrap'
import { languageList } from 'constant/dropdown'
import Select from 'react-select'
import { connect, useDispatch } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router-dom'
import {
  clearIsReportRevisionResponse,
  clearReportDetailsResponse,
  createReportReviewRequest,
  getIsReportRevisionRequest,
  getReportDetailsRequest,
} from 'store/report/action'
import { getDateTimesSlach } from 'helpers/DataTimeHelpers'
import { dateFormater, statusLableChanges } from 'helpers/generalUtils'
import {
  DRAFTED,
  PENDING,
  PENDING_FOR_APPROVER,
  APPROVED,
  PUBLISHED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
  PENDING_FOR_PUBLISHER,
  CLIENT_REPORT,
} from 'constant/databaseConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER, EN, ENGLISH, JPN } from 'constant/commonConstants'
import { statusBox, adminTranslateLanguage } from 'pages/Faq/CommonFunction'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { getValueByKey } from 'helpers/authUtils'
import { getFirstLetters } from 'pages/ConsultationRequest/CommonFunction'
import { __t } from 'i18n/translator'


const ViewReportDetails = (props) => {
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const [isOpen, setIsCollapseIds] = useState([])
  const [comment, setComment] = useState('')
  const [isComment, setIsComment] = useState(false)
  const [commentList, setCommentList] = useState([])
  const [userId] = useState(JSON.parse(getValueByKey('userId')))
  const [userList, setUserList] = useState([])
  const [isPublisher, setIsPublisher] = useState(false)
  const [isSideCollapse, setIsSideCollapse] = useState(true)
  const [isApprove, setIsApprove] = useState(false)
  const [isParentApiCall, setIsParentApiCall] = useState(false)
  const [activeLanguage, setActiveLanguage] = useState(EN);
  //const [count, setCount] = useState(1);

  const viewReportId = props.viewReportId ? props.viewReportId : ''
  const navigate = useNavigate()
  let dispatch = useDispatch()
  useEffect(() => {
    if (props.viewReportId) {
      setIsParentApiCall(false)
      dispatch(getReportDetailsRequest(props.viewReportId))
    }
  }, [props.viewReportId])

  useEffect(() => {
    if (props.reportResponseType === 'success') {
      props.clearReportDetails()
      setComment("")
      setIsComment(false)
      props.resetViewDetailsData(true)
    }
  }, [props.reportResponseType])
  useEffect(() => {
    if (props.isReviewApiType) {
      setIsParentApiCall(true)
      dispatch(getReportDetailsRequest(props.viewReportId))
      props.clearIsReportRevision()
    }
  }, [props.isReviewApiType])

  useEffect(() => {
    if (props.reportDetails?.approvalDetails) {
      setCommentList(
        props.reportDetails?.approvalDetails.filter(
          (x) => x?.comment !== null && x?.comment !== '',
        ),
      )
    }
    let users = props.reportDetails?.approvalDetails?.filter(
      (x) =>
        x?.orderNo == 1 &&
        x?.workFlowId == props.reportDetails?.workFlowId &&
        props.reportDetails.status === APPROVED,
    )[0]
    if (users != undefined) {
      setIsPublisher(true)
    }
  }, [props.reportDetails?.approvalDetails])

  useEffect(() => {
    setIsCollapseIds(
      props.reportDetails?.reportSection?.map((x, index) => {
        return {
          id: index,
          open: false,
        }
      }),
    )

    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      const approvalUsers = props.reportDetails?.approvalDetails?.filter(
        (x) =>
          x?.approverId == userId &&
          x?.workFlowId == props.reportDetails?.workFlowId,
      )
      setUserList(approvalUsers)
    } else {
      const approvalUsers = props.reportDetails?.approvalDetails?.filter(
        (x) => x?.workFlowId == props.reportDetails?.workFlowId,
      )
      setUserList(approvalUsers)
    }
  }, [props.reportDetails])

  const handleClick = (id) => {
    let data = isOpen?.map((item) =>
      item.id === id ? { ...item, open: !item.open } : item,
    )
    setIsCollapseIds([...data])
  }

  const filterApproveAndRejectButton = () => {
    const approvalUsers = props.reportDetails?.approvalDetails?.filter(
      (x) =>
        x?.viewToUser == 1 &&
        x?.status == PENDING &&
        x?.workFlowId == props.reportDetails?.workFlowId,
    )?.[0]

    let status = false
    if (
      props.reportDetails?.approvalDetails?.length > 0 &&
      approvalUsers?.status == PENDING &&
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      props.reportDetails?.status != PUBLISHED
    ) {
      status = true
    }
    return status
  }

  const approveAndRejectData = (value) => {
    let approvalUsers = []
    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      approvalUsers = props.reportDetails?.approvalDetails?.filter(
        (x) =>
          x?.approverId == userId &&
          x?.workFlowId == props.reportDetails?.workFlowId,
      )[0]
    } else {
      approvalUsers = props.reportDetails?.approvalDetails?.filter(
        (x) =>
          x?.workFlowId == props.reportDetails?.workFlowId &&
          x?.status == PENDING,
      )[0]
    }
    let formatValue = {
      reportId: viewReportId,
      approverDetailsId: approvalUsers?.id ? approvalUsers.id : '',
      workFlowId: approvalUsers?.workFlowId ? approvalUsers?.workFlowId : '',
      status: value
        ? props.reportDetails.status === PENDING_FOR_APPROVER
          ? APPROVED
          : PUBLISHED
        : props.reportDetails.status === PENDING_FOR_APPROVER
          ? REJECTED_BY_APPROVER
          : REJECTED_BY_PUBLISHER,
      comment: comment,
    }
    dispatch(createReportReviewRequest(formatValue))
  }

  const scrollToSection = (className) => {
    const section = document.querySelector(className)
    let position = section.getBoundingClientRect()
    window.scrollTo(position.left, position.top + window.scrollY - 140)
  }
  const isReportRevision = (values) => {
    props.getIsReportRevisionRequest(values)
  }
  const isAdmin = () => {
    let isUserAdmin =
      permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
        ? true
        : false

    return isUserAdmin
  }

  let count = 0;
  const incrementCount = (value) => {
    if ((!value.isParent && !value.isGroup) || (value.isParent == undefined && value.isGroup == undefined)) {
      count = count + 1;
      count = Math.floor(count);
    }
    if (value.isParent && value.isGroup) {
      count = Math.floor(count);
      count = count + 1;
      count = count + 0.1;
      count = parseFloat(count.toFixed(1));
    }
    if (!value.isParent && value.isGroup) {
      count = count + 0.1
      count = parseFloat(count.toFixed(1));
    }

  };

  function findColor(element) {
    let color = null;

    // Check if the current element has color
    if (element.style.color) {
      color = element.style.color;
    } else {
      // If the current element doesn't have color, check its children
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i];
        const childColor = findColor(child);
        if (childColor) {
          color = childColor; // Assign the child color if found
          break; // Exit loop if color is found
        }
      }
    }

    return color;
  }

  function setColor(element) {
    const color = findColor(element);
    if (color && !element.style.color) {
      element.style.color = color;
    }
  }


  function convertLineHeight(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const elementsWithLineHeight = doc.querySelectorAll('[style*="line-height"]');
    const elementsWithTextIndent = doc.querySelectorAll('[style*="text-indent"]');
    const liElements = doc.querySelectorAll('li');


    elementsWithLineHeight.forEach(element => {
      const style = element.getAttribute('style');
      const modifiedStyle = style.replace(/line-height:\s*(\d+)%/gi, (_, lineHeightPercentage) => {
        if (lineHeightPercentage) {
          return `line-height: 1.5`;
        }
      });
      element.setAttribute('style', modifiedStyle);
    });

    elementsWithTextIndent.forEach(element => {
      const style = element.getAttribute('style');
      const modifiedStyle = style.replace(/text-indent:\s*(-?\d+)px/gi, (_, textIndent) => {
        if (parseInt(textIndent) < 0) {
          return `text-indent: 0`;
        }
      });
      element.setAttribute('style', modifiedStyle);
    });

    liElements.forEach(li => setColor(li));

    // const elementsWithColor = doc.querySelectorAll('li');

    //     applyColorToLis()
    // console.log("elementsWithColor:", elementsWithColor);

    // applyColorToLi(elementsWithColor);

    return doc.body.innerHTML;
  }


  return (
    <>
      <div className="container faq-view-detail report-view-detail">
        <div className='create-report'>
          <div className="container">
            <Row>
              <FormGroup className="col mb-0 language-selector">
                <Label className="form-label select-label">Language:</Label>

                <Select
                  id="language"
                  className="form-control selectbox"
                  options={languageList}
                  onChange={(opt) => {
                    setActiveLanguage(
                      opt?.value ? opt?.value : ENGLISH
                    )
                  }}
                  value={languageList.find((option) => option.value === activeLanguage)}             
                  menuPlacement="auto"
                />
              </FormGroup>
            </Row>
          </div>
        </div>
        <div className="section-head view_report mt-3 mb-1 w-100">
          <div className="view_title_head">
            <div className="d-inline-flex align-items-start">
              <Button
                className="back_btn"
                onClick={() => {
                  setIsApprove(false)
                  setIsComment(false)
                  setComment("")
                  props.clearReportDetails()
                  props.resetViewDetailsData(isParentApiCall)
                  setCommentList([])
                }}
              >
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 7L0.792893 6.29289L0.0857865 7L0.792893 7.70711L1.5 7ZM16.5 8C17.0523 8 17.5 7.55228 17.5 7C17.5 6.44772 17.0523 6 16.5 6V8ZM6.79289 0.292893L0.792893 6.29289L2.20711 7.70711L8.20711 1.70711L6.79289 0.292893ZM0.792893 7.70711L6.79289 13.7071L8.20711 12.2929L2.20711 6.29289L0.792893 7.70711ZM1.5 8H16.5V6H1.5V8Z"
                    fill="CurrentColor"
                  />
                </svg>
              </Button>
              <div className="ms-2">
                <h3>
                  
                  {props.reportDetails?.reportTitle
                    ? adminTranslateLanguage(activeLanguage, props.reportDetails?.translation, props.reportDetails?.reportTitle, 'reportTitle')
                    : ''}
                </h3>

                {props.reportDetails?.reportDescription ? (
                  <div
                    className="w-100 mb-2 JoditEditor-border"
                    dangerouslySetInnerHTML={{
                      __html: adminTranslateLanguage(activeLanguage, props.reportDetails?.translation, props.reportDetails?.reportDescription, 'reportDescription'),
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex gap-2">
              {((permissions?.add == 1 &&
                (props.reportDetails?.status == REJECTED_BY_APPROVER ||
                  props.reportDetails?.status == REJECTED_BY_PUBLISHER ||
                  props.reportDetails?.status == PUBLISHED)) ||
                (props.reportDetails?.reportType == 'Internal' &&
                  props.reportDetails?.status == APPROVED) ||
                props.reportDetails?.status == DRAFTED) && (
                  <Button
                    onClick={() => {
                      navigate('/console/report', {
                        state: { action: 'save', id: viewReportId },
                      })
                    }}
                    className={
                      props.currentActiveTab == 'Published'
                        ? permissions.approve == 1 ||
                          userId == props.reportDetails?.createdBy
                          ? 'creat_btn'
                          : 'd-none'
                        : 'creat_btn'
                    }
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.1208 7.42339L15.9168 5.62738C16.462 5.08213 16.7346 4.80951 16.8804 4.51541C17.1577 3.95586 17.1577 3.29891 16.8804 2.73935C16.7346 2.44526 16.462 2.17263 15.9168 1.62738C15.3715 1.08213 15.0989 0.809509 14.8048 0.663773C14.2452 0.38649 13.5883 0.38649 13.0287 0.663773C12.7346 0.809509 12.462 1.08213 11.9168 1.62738L10.0981 3.44604C11.062 5.09664 12.4481 6.4722 14.1208 7.42339ZM8.64365 4.9005L1.77314 11.771C1.34808 12.1961 1.13555 12.4086 0.995818 12.6697C0.856084 12.9308 0.797138 13.2255 0.679248 13.815L0.0638519 16.8919C-0.00267025 17.2246 -0.0359313 17.3909 0.0586767 17.4855C0.153285 17.5801 0.31959 17.5468 0.6522 17.4803L3.72918 16.8649C4.31863 16.747 4.61336 16.6881 4.87446 16.5483C5.13555 16.4086 5.34808 16.1961 5.77315 15.771L12.6625 8.8816C11.0409 7.86595 9.66919 6.50366 8.64365 4.9005Z"
                        fill="CurrentColor"
                      />
                    </svg>
                    Edit Report
                  </Button>
                )}

              <Button
                onClick={() => {
                  props.download({
                    language:activeLanguage,
                    id: props?.reportDetails?.reportId,
                    createdBy: props?.reportDetails?.reportId,
                  })
                }}
                className="creat_btn"
              >
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 13V1M11 13L7 9M11 13L15 9M1 15L1.621 17.485C1.72915 17.9177 1.97882 18.3018 2.33033 18.5763C2.68184 18.8508 3.11501 18.9999 3.561 19H18.439C18.885 18.9999 19.3182 18.8508 19.6697 18.5763C20.0212 18.3018 20.2708 17.9177 20.379 17.485L21 15"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
              <div className="btn-box">
                {filterApproveAndRejectButton() == true && (
                  <div className="approve_btn">
                    <Button
                      color="danger"
                      onClick={async () => {
                        setIsComment(true)
                        setIsApprove(false)
                        if (comment == '') {
                          setIsComment(true)
                          setIsApprove(false)
                          if (isComment === true) {
                            ApiResponseMessage('Please enter comment', DANGER)
                          }
                        } else {
                          const response = await commonConfirmBox(
                            'Do you want to send back this Report for review?',
                          )
                          if (response) {
                            approveAndRejectData(false)
                          }
                        }
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9"
                          stroke="CurrentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M18 18L6 6"
                          stroke="CurrentColor"
                          strokeWidth="2"
                        />
                      </svg>
                      Send Back for Review
                    </Button>
                    <Button
                      // className="my-tooltip"
                      // data-tooltip-content={'Approve'}
                      color="success"
                      onClick={async () => {
                        setIsComment(true)
                        setIsApprove(true)
                        if (isComment) {
                          const response = await commonConfirmBox(
                            isPublisher === true
                              ? 'Do you want to publish this report?'
                              : 'Do you want to approve this report?',
                          )
                          if (response) {
                            setIsComment(false)
                            approveAndRejectData(true)
                          }
                        }
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5"
                          stroke="CurrentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667"
                          stroke="CurrentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                      {isPublisher === true ? 'Publish' : 'Approve'}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="view_report_details mt-1">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                <div className="status-drop-box">
                  <div className="user-box">
                    <div className="first_name_td">
                      {userList?.map((user) => (
                        <span
                          className="my-tooltip short-name"
                          data-tooltip-content={user?.name}
                        >
                          {getFirstLetters(user?.name)}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="notification-list">
                    {(() => {
                      let status = ''
                      const approvalUsers =
                        props.reportDetails?.approvalDetails?.filter(
                          (x) =>
                            x?.approverId == userId &&
                            x?.workFlowId == props.reportDetails?.workFlowId,
                        )?.[0]

                      if (
                        (permissions?.approve == 1 ||
                          permissions?.publish == 1) &&
                        permissions?.add != 1 &&
                        props.reportDetails?.approvalDetails?.length > 0
                      ) {
                        status = approvalUsers?.status
                      } else {
                        status = props.reportDetails?.status
                      }

                      if (status == APPROVED) {
                        let checkPendingList =
                          props.reportDetails?.approvalDetails?.filter(
                            (x) => x.status == PENDING,
                          )
                        if (checkPendingList?.length > 0) {
                          status = PENDING_FOR_PUBLISHER
                        }
                      }

                      return (
                        <>
                          <span
                            className={
                              'faq-list-status-box ' + statusBox(status)
                            }
                          ></span>
                          <span>
                            {status === 'Drafted'
                              ? 'Saved'
                              : statusLableChanges(status)}
                          </span>
                        </>
                      )
                    })()}
                  </div>
                </div>
              </div>
              <div className="gap-2 d-flex">
                <div className="version-box">
                  {props.reportDetails?.publishedVersion && (
                    <p>
                      Published Version :{' '}
                      {props.reportDetails?.publishedVersion}
                    </p>
                  )}
                </div>
                {props.reportDetails?.currentVersion && (
                  <div className="version-box draft-version">
                    <p>
                      current Version : {props.reportDetails?.currentVersion}{' '}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <Row className="w-100 mt-3 mb-0">
              <Col lg={4}>
                <p>Service Line:</p>
                {''}
                <span>
                  {props.reportDetails?.parentCategoryName && (
                    <>{props.reportDetails?.parentCategoryName}</>
                  )}
                </span>
              </Col>
              <Col lg={4}>
                <p>Category Name:</p>
                <span>
                  {props.reportDetails?.subCategoryName && (
                    <>
                      {' '}
                      {props.reportDetails?.subCategoryName} <br></br>
                    </>
                  )}

                  {props.reportDetails?.otherCategory && (
                    <> {props.reportDetails?.otherCategory}</>
                  )}
                </span>
              </Col>

              <Col lg={2}>
                <p>Report Type:</p>
                <span>
                  {props.reportDetails?.reportType && (
                    <>
                      {props.reportDetails?.reportType == 'Personal'
                        ? CLIENT_REPORT
                        : props.reportDetails?.reportType}
                    </>
                  )}
                </span>
              </Col>
              <Col lg={2}>
                <p>Last updated:</p>
                <span>
                  {props.reportDetails?.updatedDate
                    ? getDateTimesSlach(props.reportDetails.updatedDate)
                    : '-'}
                </span>
              </Col>
              {props.reportDetails?.keywords?.length > 0 && (
                <Col lg={12} className="mt-3 w-100">
                  <div className="report_keywords">
                    <p>SEO Keywords</p>
                    <ul className="p-0 m-0">
                      {props.reportDetails?.keywords?.map((keywords, index) => (
                        <li key={index}> {keywords} </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <Row>
          <Col lg="12">
            <div className="inner-section report_inner-section">
              <Card
                className={
                  isSideCollapse
                    ? 'category-panel'
                    : 'category-panel side-collapse'
                }
              >
                <CardHeader className="panel-head">
                  <a
                    onClick={() => {
                      setIsSideCollapse(isSideCollapse ? false : true)
                    }}
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 8H30"
                        stroke="CurrentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M7 17H25"
                        stroke="CurrentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M7 27H30"
                        stroke="CurrentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </a>
                  <b>Section List</b>
                </CardHeader>
                <CardBody>
                  <ul className="report_highpoint">
                    {props.reportDetails?.reportSection?.map((report, idx) => {
                      return (
                        <>
                          {
                            report?.sectionTitle != "No section" &&
                            <li key={idx}>
                              <a
                                className="cursor-pe"
                                color="link"
                                onClick={() => {
                                  handleClick(idx)
                                  scrollToSection('#parent' + idx)
                                }}
                              >
                                {adminTranslateLanguage(activeLanguage, report?.translation, report?.sectionTitle, 'sectionTitle')}
                              </a>
                              {report.subSections?.map((subSections, index) => {
                                return (
                                  <Collapse
                                    isOpen={
                                      isOpen?.find((item) => item.id === idx)?.open
                                    }
                                    // timeout="auto"
                                    unmountOnExit
                                    key={index}
                                  >
                                    <ul key={index}>
                                      {
                                        subSections?.sectionData?.faqs.length > 0 && <li key={index}>
                                          <a
                                            className="btn btn-link cursor-pe"
                                            onClick={() =>
                                              scrollToSection(
                                                '#sub' + subSections?.subSectionId,
                                              )
                                            }
                                          >
                                            {adminTranslateLanguage(activeLanguage, subSections?.translation, subSections?.sectionTitle, 'sectionTitle')}
                                          </a>
                                        </li>
                                      }

                                    </ul>
                                  </Collapse>
                                )
                              })}
                            </li>
                          }


                        </>

                      )
                    })}
                  </ul>
                </CardBody>
              </Card>
              <Card className="main-report-list main-report-list">

                <CardBody>
                  {/* abc */}
                  {props.reportDetails?.reportSection?.map((report, idx) => {
                    return (
                      <div
                        className="report_section"
                        id={'parent' + idx}
                        key={idx}
                      >
                        {
                          report?.sectionTitle != "No section" && <h3 className="report_title">
                            {adminTranslateLanguage(activeLanguage, report?.translation, report?.sectionTitle, 'sectionTitle')}
                          </h3>
                        }

                        {/* {report?.sectionData?.description &&
                          <div
                            className="JoditEditor-border"
                            dangerouslySetInnerHTML={{
                              __html: report?.sectionData?.description,
                            }}
                          />
                        } */}
                        <div className="topic_section">
                          {
                            report.sectionData?.faqs.length > 0 && <Table border={1}>
                              <thead>
                                <tr>
                                  <th style={{ width: '50px' }}>{report?.sectionTitle != "No section" ? __t('frontend.report_details.serial_number') : __t('frontend.report_details.faq_no')} </th>
                                  <th className='text-center'>{report?.sectionTitle != "No section" ? __t('frontend.report_details.particulars') : __t('frontend.report_details.faq_and_responses')} </th>
                                </tr>
                              </thead>
                              <tbody>
                                {report.sectionData?.faqs?.map((faq, index) => {

                                  return (
                                    <tr key={index}>
                                      {
                                        incrementCount(faq)
                                      }
                                      <td className='align-top text-center' style={{ width: '50px' }}>{count} </td>
                                      <td>
                                        <b>
                                          {!faq?.newQuestion &&
                                            faq?.isReview == 1 &&
                                            (isAdmin() == true ||
                                              props.reportDetails?.createdBy ==
                                              userId) && (
                                              <div
                                                className="circle_animation my-tooltip "
                                                data-tooltip-content="Updated FAQ"
                                                onClick={async () => {
                                                  const response =
                                                    await commonConfirmBox(
                                                      'Do you want to show updated FAQ?',
                                                    )
                                                  if (response) {
                                                    isReportRevision({
                                                      sectionFaqId: faq.sectionFaqId,
                                                      reportId: viewReportId,
                                                      faqPublishId: faq?.faqPublishId,
                                                    })
                                                  }
                                                }}
                                              ></div>
                                            )}



                                          {
                                            faq?.newQuestion
                                              ? adminTranslateLanguage(activeLanguage, faq?.translation, faq?.newQuestion, 'question')
                                              : adminTranslateLanguage(activeLanguage, faq?.faqTranslation, faq?.question, 'question')
                                          }
                                        </b>
                                        <div
                                          className="JoditEditor-border"
                                          // dangerouslySetInnerHTML={{
                                          //   __html: faq?.newAnswer
                                          //     ? faq?.newAnswer
                                          //     : faq?.answer,
                                          // }}
                                          dangerouslySetInnerHTML={{
                                            __html: faq?.newAnswer ? convertLineHeight(adminTranslateLanguage(activeLanguage, faq?.translation, faq.newAnswer, 'answer')) : convertLineHeight(adminTranslateLanguage(activeLanguage, faq?.faqTranslation, faq.answer, 'answer')),
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  )

                                })}
                              </tbody>
                            </Table>
                          }


                          {report.subSections?.map((subSections, index) => {
                            return (
                              <>
                                {
                                  subSections.sectionData?.faqs?.length > 0 && <>
                                    <h4
                                      className="topic_tophead"
                                      id={'sub' + subSections?.subSectionId}
                                    >
                                      {
                                        subSections?.sectionTitle !== "No sub section" && <>{adminTranslateLanguage(activeLanguage, subSections?.translation, subSections?.sectionTitle, 'sectionTitle')}</>
                                      }
                                      {/* {subSections?.sectionTitle} */}
                                    </h4>
                                    <Table border={1}>
                                      {/* <thead>
                                        <tr>
                                          <th style={{ width: '50px' }}>{report?.sectionTitle != "No section" ? __t('frontend.report_details.serial_number')  : __t('frontend.report_details.faq_no')} </th>
                                          <th className='text-center'>{report?.sectionTitle != "No section" ? __t('frontend.report_details.particulars') : __t('frontend.report_details.faq_and_responses')} </th>
                                        </tr>
                                      </thead> */}

                                      <thead>
                                        <tr>
                                          <th style={{ width: '50px' }}>{subSections?.sectionTitle != "No sub section" ? __t('frontend.report_details.serial_number') : __t('frontend.report_details.faq_no')}</th>
                                          <th className='text-center'>{subSections?.sectionTitle != "No sub section" ? __t('frontend.report_details.particulars') : __t('frontend.report_details.faq_and_responses')}</th>
                                        </tr>
                                      </thead>
                                      <tbody key={index}>



                                        {/* <div
                                          className="JoditEditor-border"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              subSections?.sectionData?.description,
                                          }}
                                        /> */}

                                        {subSections.sectionData?.faqs?.map(
                                          (faq, subIndex) => {
                                            return (
                                              <>
                                                {
                                                  incrementCount(faq)
                                                }
                                                <tr>
                                                  <td className='align-top text-center' style={{ width: '50px' }}>{count}</td>
                                                  <td
                                                    className="report_faq"
                                                    key={subIndex}
                                                  >
                                                    <b>
                                                      {!faq?.newQuestion &&
                                                        faq.isReview == 1 &&
                                                        (isAdmin() == true ||
                                                          props.reportDetails?.createdBy ==
                                                          userId) && (
                                                          <div
                                                            className="circle_animation my-tooltip "
                                                            data-tooltip-content="Updated FAQ"
                                                            onClick={async () => {
                                                              const response =
                                                                await commonConfirmBox(
                                                                  'Do you want to show updated FAQ?',
                                                                )
                                                              if (response) {
                                                                isReportRevision({
                                                                  sectionFaqId:
                                                                    faq.sectionFaqId,
                                                                  reportId: viewReportId,
                                                                  faqPublishId:
                                                                    faq?.faqPublishId,
                                                                })
                                                              }
                                                            }}
                                                          ></div>
                                                        )}


                                                      {
                                                        faq?.newQuestion
                                                          ? adminTranslateLanguage(activeLanguage, faq?.translation, faq?.newQuestion, 'question')
                                                          : adminTranslateLanguage(activeLanguage, faq?.faqTranslation, faq?.question, 'question')
                                                      }
                                                    </b>
                                                    <div
                                                      className="JoditEditor-border"
                                                      // dangerouslySetInnerHTML={{
                                                      //   __html: faq?.newAnswer
                                                      //     ? faq?.newAnswer
                                                      //     : faq?.answer,
                                                      // }}

                                                      dangerouslySetInnerHTML={{
                                                        __html: faq?.newAnswer ?
                                                          //faq.newAnswer
                                                          adminTranslateLanguage(activeLanguage, faq?.translation, faq?.newAnswer, 'newAnswer')
                                                          :
                                                          //faq.answer
                                                          adminTranslateLanguage(activeLanguage, faq?.faqTranslation, faq?.answer, 'answer'),
                                                      }}
                                                    />
                                                  </td>
                                                </tr>


                                              </>
                                            )
                                          },
                                        )}
                                      </tbody>
                                    </Table>
                                  </>
                                }

                              </>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        {commentList.length > 0 && (
          <Card className="p-0 mb-0 mt-3">
            <CardBody>
              <div className="">
                <h6 className="separate-head">Comments</h6>
                <ul className="comment-box">
                  {commentList?.map(
                    (item, index) =>
                      item.comment &&
                      item.comment?.length > 0 && (
                        <li key={index}>
                          <div className="user-box">
                            <div className="first_name_td">
                              <span className="short-name">
                                {getFirstLetters(item?.commentBy)}
                              </span>
                            </div>
                          </div>
                          <div className="con-box">
                            <div>
                              <h6>{item?.commentBy}</h6>
                              {item?.date && (
                                <span>{dateFormater(item?.date)} </span>
                              )}
                            </div>

                            <p>{item?.comment}</p>
                          </div>
                        </li>
                      ),
                  )}
                </ul>
              </div>
            </CardBody>
          </Card>
        )}

        {filterApproveAndRejectButton() == true && isComment === true && (
          <Card className="p-0 mb-3 enter_comment">
            <CardBody>
              <h6 className="separate-head d-flex justify-content-between align-items-center">
                <span>
                  Enter Comment
                  {!isApprove && (
                    <span className="text-danger required"> *</span>
                  )}
                </span>

                <div className="enter_comment_btn">
                  <Button
                    color="primary"
                    onClick={async () => {
                      if (isApprove) {
                        if (isComment) {
                          const response = await commonConfirmBox(
                            isPublisher === true
                              ? 'Do you want to publish this report?'
                              : 'Do you want to approve this report?',
                          )
                          if (response) {
                            setComment('')
                            setIsComment(false)
                            approveAndRejectData(true)
                          }
                        }
                      } else if (comment == '') {
                        setIsComment(true)
                        if (isComment === true) {
                          ApiResponseMessage('Please enter comment', DANGER)
                        }
                      } else {
                        const response = await commonConfirmBox(
                          'Do you want to send back this Report for review?',
                        )
                        if (response) {
                          approveAndRejectData(false)
                        }
                      }
                    }}
                  >
                    Submit
                  </Button>

                  <Button
                    className="close_sign"
                    onClick={() => {
                      setIsComment(false)
                      setComment('')
                    }}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.02602 6.49908L12.6799 1.8554C12.8837 1.65158 12.9982 1.37513 12.9982 1.08687C12.9982 0.798614 12.8837 0.522165 12.6799 0.318337C12.4761 0.114509 12.1997 0 11.9114 0C11.6232 0 11.3468 0.114509 11.143 0.318337L6.5 4.97283L1.85699 0.318337C1.65319 0.114509 1.37678 -2.14767e-09 1.08856 0C0.800348 2.14767e-09 0.523938 0.114509 0.320139 0.318337C0.11634 0.522165 0.00184703 0.798614 0.00184703 1.08687C0.00184703 1.37513 0.11634 1.65158 0.320139 1.8554L4.97397 6.49908L0.320139 11.1427C0.218698 11.2434 0.138182 11.3631 0.0832357 11.495C0.0282893 11.6269 0 11.7684 0 11.9113C0 12.0542 0.0282893 12.1957 0.0832357 12.3276C0.138182 12.4595 0.218698 12.5792 0.320139 12.6798C0.420751 12.7813 0.540454 12.8618 0.67234 12.9168C0.804227 12.9717 0.945688 13 1.08856 13C1.23144 13 1.3729 12.9717 1.50479 12.9168C1.63667 12.8618 1.75637 12.7813 1.85699 12.6798L6.5 8.02532L11.143 12.6798C11.2436 12.7813 11.3633 12.8618 11.4952 12.9168C11.6271 12.9717 11.7686 13 11.9114 13C12.0543 13 12.1958 12.9717 12.3277 12.9168C12.4595 12.8618 12.5792 12.7813 12.6799 12.6798C12.7813 12.5792 12.8618 12.4595 12.9168 12.3276C12.9717 12.1957 13 12.0542 13 11.9113C13 11.7684 12.9717 11.6269 12.9168 11.495C12.8618 11.3631 12.7813 11.2434 12.6799 11.1427L8.02602 6.49908Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Button>
                </div>
              </h6>
              <textarea
                name="comment"
                placeholder="Enter comment.."
                className="form-control"
                maxLength={200}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </CardBody>
          </Card>
        )}
      </div >
    </>
  )
}

const mapStateToProps = ({ reportReducer }) => {
  let reportDetails = []
  let reportResponseType = ''
  let isReviewApiType = ''

  if (reportReducer && reportReducer?.reportDetails) {
    reportDetails = reportReducer?.reportDetails?.data?.sectionData
  }

  if (reportReducer && reportReducer?.reportReviewApiresponse) {
    reportResponseType = reportReducer?.reportReviewApiresponse.responseType
  }

  if (reportReducer && reportReducer?.isReviewApiResponse) {
    isReviewApiType = reportReducer?.isReviewApiResponse?.responseType
  }

  return {
    reportDetails,
    reportResponseType,
    isReviewApiType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIsReportRevisionRequest: (data) => {
      dispatch(getIsReportRevisionRequest(data))
    },
    clearIsReportRevision: () => {
      dispatch(clearIsReportRevisionResponse())
    },
    clearReportDetails: () => {
      dispatch(clearReportDetailsResponse())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewReportDetails)
