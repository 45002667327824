import React, { useState, useEffect } from 'react'
import {
  Row,
  Label,
  FormGroup,
  Button,
} from 'reactstrap'
import { commonConfirmBox } from 'helpers/messagehelpers'
import Form from 'react-bootstrap/Form'
import { Formik, Field } from 'formik'
import { connect } from 'react-redux'
import {
  addCategoryRequest,
  getCategoryDeleteRequest,
  getCategoryListRequest,
} from 'store/master/category/action'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { Accordion } from 'react-bootstrap'
import CategoryModal from './CategoryModal'
import CustomPagination from 'components/Table/CustomPagination'
import AuditLogModal from 'pages/AuditLog/AuditLogModal'
import { EN, ENGLISH, JPN, SUCCESS } from 'constant/commonConstants'
import { Tooltip } from 'react-tooltip'
import { adminTranslateLanguage } from 'pages/Faq/CommonFunction'
import { languageList } from 'constant/dropdown'
import Select from 'react-select'

function Category(props) {
  const initialIndustryDataState = () => {
    return {
      serviceId: '',
      selectServices: 'CONTENT',
      catName: '',
      subcate: initialSubIndustriesDataState(),
    }
  }

  const initialSubIndustriesDataState = () => {
    return [
      {
        id: 0,
        subCategoryName: '',
      },
    ]
  }

  const Data = {
    searchText: '',
    pagination: {
      limit: 10,
      page: 0,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: {},
  }

  useEffect(() => {
    props.getCategoryReq(Data)
  }, [])

  useEffect(() => {
    if (props.responseType === SUCCESS) {
      resetFormData(false)
    }
  }, [props.responseType])

  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState([])
  const [isType, setType] = useState()
  const [addCategoryForm, setaddCategoryForm] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState(10)

  const [search, setSearch] = useState('')

  const [industryData, setIndustryData] = useState(initialIndustryDataState())
  const [activeLanguage, setActiveLanguage] = useState(EN);
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [auditId, setAuditId] = useState('')
  const [isSub, setisSub] = useState()

  const [activeTabs, setActiveTabs] = useState([])

  const handleAccordionToggle = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs?.filter((tab) => tab !== index))
    } else {
      setActiveTabs([...activeTabs, index])
    }
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [dataPerPage])
  useEffect(() => {
    props.getCategoryReq({
      searchText: '',
      pagination: {
        limit: dataPerPage,
        page: currentPage,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })
  }, [currentPage, dataPerPage])

  //? condition to avoid by defult open first record in every page

  const closeAuditLogModal = () => {
    setIsAuditModalOpen(false)
    setAuditId('')
  }

  const openAuditLogModal = (id, sub) => {
    setisSub(sub)
    setIsAuditModalOpen(true)
    setAuditId(id)
  }

  const isModalOpen = (value, type) => {
    setShow(true)
    if (value) {
      setModalData(value)
    }
    setType(type)
  }

  const isAddOperation = () => {
    setaddCategoryForm(true)
    setShow(true)
    setType('addOperation')
  }

  const resetFormData = (e) => {
    setShow(e)
    setType('addOperation')
    setaddCategoryForm(false)
    setModalData([])
    setIndustryData(initialIndustryDataState())
  }

  const getListBySearchType = (isEmpty = false) => {
    props?.getCategoryReq({
      searchText: isEmpty ? '' : search?.length > 0 ? search : '',
      pagination: {
        limit: 10,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })
  }

  const paginate = (pageNumber, dataPerPageFromPaginate) => {
    const paginateValues = {
      searchText: '',
      pagination: {
        limit: dataPerPageFromPaginate || 10,
        page: pageNumber,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    }
    setDataPerPage(dataPerPage)
    setCurrentPage(pageNumber)
    props?.getCategoryReq(paginateValues)
    // setSearch('')
  }

  return (
    <>
      <Tooltip anchorSelect=".my-tooltip2" place="right" />
      {/* <Loader /> */}
      <Formik>
        {({ values }) => (
          <>
            <div className='create-report'>
              <div className="container">
                <Row>
                  <FormGroup className="col mb-0 language-selector">
                  <Label className="form-label select-label">Language:</Label>
                    <Field
                      component={Select}
                      menuPlacement="auto"
                      name="language"
                      id="language"
                      className="form-control selectbox"
                      options={languageList}
                      onChange={(opt) => {
                        setActiveLanguage(
                          opt?.value ? opt?.value : ''
                        )
                      }}
                      value={languageList?.filter(
                        (option) =>
                          option?.value == activeLanguage,
                      )}
                    />
                    
                  </FormGroup>
                </Row>            
                
              </div>
            </div>
                  <div className="container category-main">
                    <div className="section-head category-head">
                      <div>
                        <h3>Category</h3>
                      </div>
                      <div className="header-action-item">
                        <Button
                          className="creat_btn"
                          onClick={() => {
                            isAddOperation()
                          }}
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 2L11 20"
                              stroke="currentColor"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                            />
                            <path
                              d="M20 11L2 11"
                              stroke="currentColor"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                            />
                          </svg>
                          Add Category
                        </Button>

                        <div className="search_box">
                          <Button
                            color="link"
                            className=" p-0"
                            // data-tooltip-content={'Search'}
                            onClick={() => {
                              getListBySearchType()
                            }}
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
                            </svg>
                          </Button>

                          {search && (
                            <Button
                              color="link"
                              className="p-0 close-btn"
                              // title="Cancel"
                              onClick={() => {
                                setSearch('')
                                props?.getCategoryReq({
                                  searchText: '',
                                  pagination: {
                                    limit: 10,
                                    page: 0,
                                    orderKey: 'createdDate',
                                    orderBy: 'ASC',
                                  },

                                  filter: {},
                                })
                              }}
                            >
                              <svg
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M31.8807 4.13589C32.2693 3.73354 32.4843 3.19466 32.4794 2.6353C32.4746 2.07595 32.2502 1.54088 31.8547 1.14535C31.4591 0.74981 30.9241 0.525449 30.3647 0.520588C29.8054 0.515728 29.2665 0.730757 28.8641 1.11936L16.5057 13.4778L4.14734 1.11936C3.74499 0.730757 3.2061 0.515728 2.64675 0.520588C2.08739 0.525449 1.55233 0.74981 1.15679 1.14535C0.761254 1.54088 0.536893 2.07595 0.532032 2.6353C0.527172 3.19466 0.742201 3.73354 1.1308 4.13589L13.4892 16.4943L1.1308 28.8527C0.92705 29.0495 0.764527 29.2849 0.652721 29.5452C0.540915 29.8054 0.482065 30.0854 0.479603 30.3686C0.477142 30.6519 0.531118 30.9328 0.638384 31.195C0.74565 31.4572 0.904057 31.6954 1.10436 31.8957C1.30467 32.096 1.54286 32.2544 1.80504 32.3616C2.06721 32.4689 2.34813 32.5229 2.63139 32.5204C2.91466 32.518 3.19459 32.4591 3.45487 32.3473C3.71514 32.2355 3.95054 32.073 4.14734 31.8692L16.5057 19.5108L28.8641 31.8692C29.2665 32.2578 29.8054 32.4729 30.3647 32.468C30.9241 32.4631 31.4591 32.2388 31.8547 31.8432C32.2502 31.4477 32.4746 30.9126 32.4794 30.3533C32.4843 29.7939 32.2693 29.255 31.8807 28.8527L19.5223 16.4943L31.8807 4.13589Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Button>
                          )}

                          <Field
                            name="searchbox"
                            onChange={(e) => {
                              setSearch(e.target.value)
                              if (e.target.value?.length <= 0) {
                                getListBySearchType(true)
                              }
                            }}
                            onKeyDown={(evt) => {
                              if (evt.key === 'Enter' && !evt.shiftKey) {
                                getListBySearchType()
                              }
                            }}
                            placeholder="Search box"
                            value={search}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="category-form-box"></div>
                    <div>
                    
                      <Accordion activeKey={activeTabs}>
                        {props?.getCategoryListData?.map((item, i) => (
                          <AccordionItem eventKey={i} key={i}>
                            <AccordionHeader
                              onClick={() => handleAccordionToggle(i)}
                            >
                              Service Line : {item.catName}
                            </AccordionHeader>

                            <div className="edit-delete-btn">
                              <a
                                // className="my-tooltip"
                                // data-tooltip-content={'Edit'}
                                onClick={() => {
                                  isModalOpen(item, 'parentCategory')
                                }}
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.3956 5.53996L18.9355 2C20.2689 0.666666 20.9355 0 21.764 0C22.5924 0 23.259 0.666667 24.5924 2L25.1776 2.5852C26.5109 3.91853 27.1776 4.5852 27.1776 5.41363C27.1776 6.24205 26.5109 6.90872 25.1776 8.24205L21.6717 11.7479C19.0779 10.2426 16.9197 8.10057 15.3956 5.53996ZM13.9413 6.99423L2.33366 18.6019C1.9086 19.0269 1.69607 19.2395 1.55633 19.5006C1.4166 19.7617 1.35765 20.0564 1.23976 20.6458L0.0638518 26.5254C-0.00267027 26.858 -0.0359313 27.0243 0.0586767 27.1189C0.153281 27.2135 0.319577 27.1802 0.652161 27.1137L0.652183 27.1137L0.6522 27.1137L6.53175 25.9378C7.1212 25.8199 7.41593 25.761 7.67702 25.6212C7.93812 25.4815 8.15065 25.269 8.57571 24.8439L20.2137 13.2059C17.6706 11.6364 15.5268 9.50759 13.9413 6.99423Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                Edit
                              </a>
                              <a
                                className="my-tooltip btn btn-link history_btn"
                                data-tooltip-content={'History'}
                                onClick={(e) => openAuditLogModal(item.id)}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9C0 7.75 0.237333 6.579 0.712 5.487C1.18733 4.39567 1.82933 3.44567 2.638 2.637C3.446 1.829 4.396 1.18733 5.488 0.712C6.57933 0.237333 7.75 0 9 0C10.3667 0 11.6627 0.291667 12.888 0.875C14.1127 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.096 1.479 16.288 1.287C16.4793 1.09567 16.7167 1 17 1C17.2833 1 17.5207 1.09567 17.712 1.287C17.904 1.479 18 1.71667 18 2V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7H13C12.7167 7 12.4793 6.904 12.288 6.712C12.096 6.52067 12 6.28333 12 6C12 5.71667 12.096 5.479 12.288 5.287C12.4793 5.09567 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.396 2.679 4.038 4.037C2.67933 5.39567 2 7.05 2 9C2 10.95 2.67933 12.604 4.038 13.962C5.396 15.3207 7.05 16 9 16C10.5333 16 11.921 15.55 13.163 14.65C14.4043 13.75 15.2417 12.5667 15.675 11.1C15.7583 10.8167 15.9127 10.579 16.138 10.387C16.3627 10.1957 16.6167 10.1333 16.9 10.2C17.2 10.2667 17.4207 10.4333 17.562 10.7C17.704 10.9667 17.7333 11.25 17.65 11.55C17.1 13.4667 16.025 15.0207 14.425 16.212C12.825 17.404 11.0167 18 9 18Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </a>

                              <Form.Check
                                onChange={async () => {
                                  const response = await commonConfirmBox(
                                    !item.isActive
                                      ? 'Do you want to activate this Service Line?'
                                      : 'Do you want to deactivate this Service Line?',
                                  )

                                  if (response) {
                                    props.getStatusCategory({
                                      catId: item.id,
                                      isActive: !item.isActive,
                                      currentPage,
                                      dataPerPage,
                                    })
                                  }
                                }}
                                type="switch"
                                id="custom-switch"
                                className="justify-content-center toggle_switch_btn"
                                checked={item.isActive}
                              />
                            </div>

                            <AccordionBody>
                              <ul className="sub-task-box small-scroll">
                                {item.subCat?.map((sub, index) => (
                                  <li key={index}>
                                    <div className="sub_category_text">
                                      <div
                                        className={
                                          sub?.type == 'REPORT'
                                            ? 'report_icon'
                                            : sub?.type == 'CONSULTATION'
                                              ? 'consultation_icon'
                                              : 'content_icon'
                                        }
                                      >
                                        <svg
                                          className="report_svg"
                                          width="16"
                                          height="21"
                                          viewBox="0 0 16 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.5 3C12.9045 3 13.6067 3 14.1111 3.33706C14.3295 3.48298 14.517 3.67048 14.6629 3.88886C15 4.39331 15 5.09554 15 6.5V16C15 17.8856 15 18.8284 14.4142 19.4142C13.8284 20 12.8856 20 11 20H5C3.11438 20 2.17157 20 1.58579 19.4142C1 18.8284 1 17.8856 1 16V6.5C1 5.09554 1 4.39331 1.33706 3.88886C1.48298 3.67048 1.67048 3.48298 1.88886 3.33706C2.39331 3 3.09554 3 4.5 3"
                                            stroke="currentColor"
                                          />
                                          <path
                                            d="M5 3C5 1.89543 5.89543 1 7 1H9C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5H7C5.89543 5 5 4.10457 5 3Z"
                                            stroke="currentColor"
                                          />
                                          <path
                                            d="M5 10L11 10"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M5 14L9 14"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                        </svg>
                                        <svg
                                          className="content_svg"
                                          width="18"
                                          height="20"
                                          viewBox="0 0 18 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5 5L9 5"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M5 13L8 13"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M5 9L11 9"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M15 9V7C15 4.17157 15 2.75736 14.1213 1.87868C13.2426 1 11.8284 1 9 1H7C4.17157 1 2.75736 1 1.87868 1.87868C1 2.75736 1 4.17157 1 7V13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H8"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <circle
                                            cx="13.5"
                                            cy="15.5"
                                            r="2.5"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M17 19L15.5 17.5"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                        </svg>
                                        <svg
                                          className="consultation_svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10 22C14.4183 22 18 18.4183 18 14C18 9.58172 14.4183 6 10 6C5.58172 6 2 9.58172 2 14C2 15.2355 2.28008 16.4056 2.7802 17.4502C2.95209 17.8093 3.01245 18.2161 2.90955 18.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L5.39939 21.0904C5.78393 20.9876 6.19071 21.0479 6.54976 21.2198C7.5944 21.7199 8.76449 22 10 22Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                          />
                                          <path
                                            d="M18 14.5018C18.0665 14.4741 18.1324 14.4453 18.1977 14.4155C18.5598 14.2501 18.9661 14.1882 19.3506 14.2911L19.8267 14.4185C20.793 14.677 21.677 13.793 21.4185 12.8267L21.2911 12.3506C21.1882 11.9661 21.2501 11.5598 21.4155 11.1977C21.7908 10.376 22 9.46242 22 8.5C22 4.91015 19.0899 2 15.5 2C12.7977 2 10.4806 3.64899 9.5 5.9956"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                          />
                                          <path
                                            d="M6.51828 14H6.52728M10.009 14H10.018M13.5 14H13.509"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>

                                      <span
                                        className={
                                          sub?.type == 'REPORT'
                                            ? 'sub_category_text my-tooltip2 report_icon'
                                            : 'sub_category_text my-tooltip2 content_icon'
                                        }
                                        data-tooltip-content={
                                          sub?.type == 'REPORT'
                                            ? 'REPORT'
                                            : sub?.type == 'CONSULTATION'
                                              ? 'CONSULTATION'
                                              : 'CONTENT'
                                        }
                                      >
                                        {adminTranslateLanguage(activeLanguage, sub?.translation, sub?.catName, 'cat_name')}
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <a
                                        className="my-tooltip btn btn-link history_btn"
                                        data-tooltip-content={'History'}
                                        onClick={(e) =>
                                          openAuditLogModal(sub.id, 'isSub')
                                        }
                                      >
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9C0 7.75 0.237333 6.579 0.712 5.487C1.18733 4.39567 1.82933 3.44567 2.638 2.637C3.446 1.829 4.396 1.18733 5.488 0.712C6.57933 0.237333 7.75 0 9 0C10.3667 0 11.6627 0.291667 12.888 0.875C14.1127 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.096 1.479 16.288 1.287C16.4793 1.09567 16.7167 1 17 1C17.2833 1 17.5207 1.09567 17.712 1.287C17.904 1.479 18 1.71667 18 2V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7H13C12.7167 7 12.4793 6.904 12.288 6.712C12.096 6.52067 12 6.28333 12 6C12 5.71667 12.096 5.479 12.288 5.287C12.4793 5.09567 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.396 2.679 4.038 4.037C2.67933 5.39567 2 7.05 2 9C2 10.95 2.67933 12.604 4.038 13.962C5.396 15.3207 7.05 16 9 16C10.5333 16 11.921 15.55 13.163 14.65C14.4043 13.75 15.2417 12.5667 15.675 11.1C15.7583 10.8167 15.9127 10.579 16.138 10.387C16.3627 10.1957 16.6167 10.1333 16.9 10.2C17.2 10.2667 17.4207 10.4333 17.562 10.7C17.704 10.9667 17.7333 11.25 17.65 11.55C17.1 13.4667 16.025 15.0207 14.425 16.212C12.825 17.404 11.0167 18 9 18Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </a>

                                      <Form.Check
                                        onChange={async () => {
                                          const response = await commonConfirmBox(
                                            !sub.isActive === true
                                              ? 'Do you want to activate this category?'
                                              : 'Do you want to deactivate this category?',
                                          )
                                          if (response) {
                                            props.getStatusCategory({
                                              catId: sub.id,
                                              isActive: !sub.isActive,
                                              currentPage,
                                              dataPerPage,
                                            })
                                          }
                                        }}
                                        type="switch"
                                        id="custom-switch"
                                        className="justify-content-center toggle_switch_btn"
                                        checked={sub.isActive}
                                      />
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </AccordionBody>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </div>

            <CategoryModal
              isOpenModal={show}
              modalData={addCategoryForm == true ? industryData : modalData}
              isType={isType}
              closeModal={(e) => {
                resetFormData(e)
              }}
            />

            <div className="container">
              {props?.totalRecords ? (
                <CustomPagination
                  setDataPerPage={setDataPerPage}
                  setCurrentPage={setCurrentPage}
                  dataPerPage={dataPerPage}
                  currentPage={currentPage}
                  totalData={props?.totalRecords}
                  paginate={paginate}
                />
              ) : (
                <div className="no-data-found">
                  <svg
                    width="96"
                    height="114"
                    viewBox="0 0 96 114"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                      fill="#4F2D7F"
                      fillOpacity="0.12"
                    />
                    <circle
                      cx="57.5592"
                      cy="67.2599"
                      r="27.36"
                      fill="#4F2D7F"
                      fillOpacity="0.3"
                      stroke="#4F2D7F"
                      strokeWidth="5.2"
                    />
                    <path
                      d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                      stroke="#4F2D7F"
                      strokeWidth="5.2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M92.8968 102.6L79.2168 88.9204"
                      stroke="#4F2D7F"
                      strokeWidth="5.2"
                      strokeLinecap="round"
                    />
                  </svg>

                  <h1>No Categories Found</h1>
                </div>
              )}
            </div>
            <AuditLogModal
              isModalOpen={isAuditModalOpen}
              modalName={
                isSub == 'isSub' ? 'Category History' : 'Service Line History'
              }
              auditType={isSub == 'isSub' ? 'category' : 'serviceline'}
              auditId={auditId}
              onModalDismiss={() => closeAuditLogModal()}
            />
          </>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = (categoryReducer) => {
  let CategoryList
  let message
  let responseType
  let totalRecords = 0
  if (
    categoryReducer.categoryReducer.categoryList.list &&
    categoryReducer.categoryReducer.categoryList.list?.length > 0
  ) {
    CategoryList = categoryReducer?.categoryReducer?.categoryList?.list
    totalRecords = categoryReducer?.categoryReducer?.categoryList?.totalRecords
  }

  if (categoryReducer.categoryReducer.addCategoryResponse) {
    message = categoryReducer.categoryReducer.addCategoryResponse.message
    responseType =
      categoryReducer.categoryReducer.addCategoryResponse.responseType
  }

  return {
    getCategoryListData: CategoryList,
    responseType: responseType,
    ApiRespMessage: message,
    totalRecords: totalRecords,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCategory: (creds) => {
      dispatch(addCategoryRequest(creds))
    },
    getStatusCategory: (creds) => {
      dispatch(getCategoryDeleteRequest(creds))
    },
    getCategoryReq: (creds) => {
      dispatch(getCategoryListRequest(creds))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Category)
