export const RightArrow=<svg
width="9"
height="14"
viewBox="0 0 9 14"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M1 1L7 7L1 13"
  stroke="currentColor"
  strokeWidth="2"
  strokeLinecap="round"
/>
</svg>
export const SearchIcon= <svg
width="19"
height="19"
viewBox="0 0 19 19"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<circle
  cx="8.4375"
  cy="8.4375"
  r="7.4375"
  stroke="CurrentColor"
  strokeWidth="2"
/>
<path
  d="M8.4375 5.25C8.01891 5.25 7.60442 5.33245 7.2177 5.49263C6.83097 5.65282 6.47958 5.88761 6.1836 6.1836C5.88761 6.47958 5.65282 6.83097 5.49263 7.2177C5.33245 7.60442 5.25 8.01891 5.25 8.4375"
  stroke="CurrentColor"
  strokeWidth="2"
  strokeLinecap="round"
/>
<path
  d="M18 18L14.8125 14.8125"
  stroke="CurrentColor"
  strokeWidth="2"
  strokeLinecap="round"
/>
</svg>

export const done=<svg
width="20"
height="20"
viewBox="0 0 20 20"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<g clipPath="url(#clip0_1123_13264)">
  <path
    d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
    stroke="CurrentColor"
    strokeWidth="1.66667"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</g>
<defs>
  <clipPath id="clip0_1123_13264">
    <rect width="20" height="20" fill="CurrentColor" />
  </clipPath>
</defs>
</svg>