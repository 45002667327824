import CustomisedModal from 'components/Modal/CustomisedModal'
import React, { useRef, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button } from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import JoditEditor from 'jodit-react'
import { __t } from 'i18n/translator'
import { ENGLISH, JAPANESE } from 'constant/commonConstants'

export default function EditFaqForm(props) {
  const validator = useRef(new SimpleReactValidator())
  const editor = useRef(null)
  const [activeLanguage, setActiveLanguage] = useState(ENGLISH);
  const editorId = 'answer'
  const config = {
    removeButtons: [
      'cut',
      'copy',
      'paste',
      'font',
      'change mode',
      'print',
      'insert',
      'about',
      'subscript',
      'superscript',
      'source',
      'classSpan',
      'copyformat',
      'video',
      'image',
      'file',
    ],
  }
  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      props.updateFaq(values)
      setActiveLanguage(ENGLISH)
    } else {
      validator.current.showMessages()
    }
  }

  return (
    <React.Fragment>
      <CustomisedModal
        isModalOpen={props.isModalOpen}
        modalSize="modal-dialog-scrollable modal-xl report-update"
        onModalDismiss={() => {
          setActiveLanguage(ENGLISH)
          props.onModalDismiss()
        }
        }
        modalName={props.modalName}
      >
        <Formik
          initialValues={props.initialValues}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <Form className="faq-create card mb-0">
              <div className='mb-2 faq-btn-group'>
                <Button color={ENGLISH == activeLanguage ? 'info' : 'secondary' } type='button' onClick={() => { setActiveLanguage(ENGLISH) }}>English</Button>
                <Button color={JAPANESE == activeLanguage ? 'info' : 'secondary' } type='button' onClick={() => { setActiveLanguage(JAPANESE) }}>Japanese 
                  {/* <span className='text-danger'><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.31171 7.76149C6.23007 2.58716 7.68925 0 10 0C12.3107 0 13.7699 2.58716 16.6883 7.76149L17.0519 8.40626C19.4771 12.7061 20.6897 14.856 19.5937 16.428C18.4978 18 15.7864 18 10.3637 18H9.63634C4.21356 18 1.50217 18 0.406258 16.428C-0.689657 14.856 0.522912 12.7061 2.94805 8.40627L3.31171 7.76149Z" fill="currentColor"/><path d="M10 3C10.6213 3 11.125 3.48216 11.125 4.07692V11.2564C11.125 11.8512 10.6213 12.3333 10 12.3333C9.37868 12.3333 8.875 11.8512 8.875 11.2564V4.07692C8.875 3.48216 9.37868 3 10 3Z" fill="white"/><path d="M10 16.1115C10.8284 16.1115 11.5 15.4686 11.5 14.6756C11.5 13.8826 10.8284 13.2397 10 13.2397C9.17157 13.2397 8.5 13.8826 8.5 14.6756C8.5 15.4686 9.17157 16.1115 10 16.1115Z" fill="white"/></svg></span> */}
                </Button>
              </div>
                <div className="col">
                  <Label className="separate-head mb-2">
                    Question/Heading
                    <span className="text-danger required">*</span>
                  </Label>
                  <FormGroup className="mb-3">
                    {
                      activeLanguage == ENGLISH ?
                        <Field
                          name="question"
                          placeholder="Question/Heading here..."
                          className="form-control"
                        />
                        :
                        <>
                          {values?.translation?.map((lang, langIdx) => (
                            <Field
                              placeholder={`Enter your Question/Heading here in ${lang.language}...`}
                              className="form-control"
                              name={`translation[${langIdx}].question`}
                              value={lang.question}
                              onChange={(e) => {
                                const { value } = event.target
                                setFieldValue(`translation[${langIdx}].question`, value);
                              }}
                            />
                          ))}
                        </>
                    }

                    {validator.current.message(
                      __t('validations.faq.faq_question_required'),
                      values.question,
                      'required',
                      {
                        messages: {
                          required: __t(
                            'validations.faq.faq_question_required',
                          ),
                        },
                      },
                    )}
                  </FormGroup>
                </div>
                <div className="answer-editer-box">
                  <Label className="separate-head mb-2">
                    Answer <span className="text-danger required">*</span>
                  </Label>
                  {
                    activeLanguage == ENGLISH ?
                      <JoditEditor
                        ref={editor}
                        config={config}
                        name={editorId}
                        value={values[editorId]}
                        onBlur={(updateValue) => {
                          setFieldValue(editorId, updateValue)
                        }}
                      />
                      : <>
                        {values?.translation?.map((lang, langIdx) => (
                          <div key={langIdx}>
                            <JoditEditor
                              ref={editor}
                              config={{
                                ...config,
                                placeholder: `Enter your Answer here in ${lang.language}...`,
                              }}
                              rows="2"
                              name={`translation[${langIdx}].answer`}
                              value={lang.answer}
                              onBlur={(newContent) => {
                                setFieldValue(`translation[${langIdx}].answer`, newContent);
                              }}
                            />
                          </div>
                        ))}
                      </>
                  }

                  {validator.current.message(
                    __t('validations.faq.faq_answer_required'),
                    values.answer,
                    'required',
                    {
                      messages: {
                        required: __t('validations.faq.faq_answer_required'),
                      },
                    },
                  )}
                </div>
              <Row className="container align-items-center mt-1">
                <Col className="d-flex justify-content-center my-3 p-0">
                  <FormGroup className="d-flex m-0 p-0">
                    <Button
                      type="button"
                      color="outline-primary"
                      block
                      className="w-100 waves-effect waves-light ms-3"
                      onClick={() => {
                        props.onModalDismiss()
                        setActiveLanguage(ENGLISH)
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      block
                      className="w-100 waves-effect waves-light ms-3"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Update
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              </Form>

            </>
          )}
        </Formik>
      </CustomisedModal>
    </React.Fragment>
  )
}
