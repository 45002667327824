import { GET_DASHBOARD_REQUEST, GET_DASHBOARD_RESPONSE } from './actionType'

export const getDashboardRequest = () => {
  return {
    type: GET_DASHBOARD_REQUEST,
  }
}

export const getFaqResponse = (data) => {
  return {
    type: GET_DASHBOARD_RESPONSE,
    payload: { data },
  }
}
