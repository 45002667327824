import {
  APPROVED,
  PENDING,
  PUBLISHED,
  REJECTED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
} from 'constant/databaseConstants'

export const consultationInitialState = () => {
  return {
    faqId: '',
    categoryId: '',
    subCategoryId: '',

    heading: '',
    answer: '',
    reference: '',
    keywords: [],
    firstApprovalId: '',
    secondApprovalId: '',
  }
}

export const createConsultationInitialState = (values) => {
  const value = values === undefined ? '' : values
  const userList = value?.approvalDetails?.filter(
    (x) => x.workFlowId == value.workFlowId,
  )

  return {
    countryId: value?.countryId?.toString(),
    categoryId: value?.categoryDetails?.parentCategoryId?.toString(),
    subCategoryId: value?.categoryDetails?.subcategoryId?.toString(),
    laws: value?.lawList ? value?.lawList?.map((x) => x.id) : '',

    heading: value.heading ? value.heading : '',
    answer: value.answer ? value.answer : '',
    reference: value.reference ? value.reference : '',
    keywords: value.keywords ? value.keywords : '',

    firstApprovalId: userList?.length > 0 ? userList[0]?.approverId : '',
    secondApprovalId: userList?.length > 0 ? userList[1]?.approverId : '',
    documentDetails: value?.documentDetails ? value?.documentDetails : [],
  }
}

export const createFaqFilterSelectionInitialState = (values) => {
  return {
    categoryId: '',
    subCategoryId: '',
    laws: '',

    heading: '',
    answer: '',
    reference: '',
    keywords: '',
    firstApprovalId: '',
    secondApprovalId: '',
    status: '',
    date: '',
  }
}

export const statusBox = (statusName) => {
  let statusBoxColor = ''
  if (statusName) {
    switch (statusName) {
      case APPROVED:
        statusBoxColor += 'bg-success'
        break
      case REJECTED:
        statusBoxColor += 'bg-danger'
        break
      case REJECTED_BY_APPROVER:
        statusBoxColor += 'bg-danger'
        break
      case REJECTED_BY_PUBLISHER:
        statusBoxColor += 'bg-danger'
        break
      case PENDING:
        statusBoxColor += 'bg-warning'
        break
      case PUBLISHED:
        statusBoxColor += 'bg-success'
        break
      default:
        statusBoxColor += 'bg-secondary'
        break
    }
  }

  return statusBoxColor
}

export const getFirstLetters = (str) => {
  if (str === undefined || str === null) {
    return ''
  }
  const words = str.split(' ')
  return words.map((word) => word.charAt(0)).join('')

}
