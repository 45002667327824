import React, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import { CardHeader, FormGroup, Input, Label } from 'reactstrap'
import { Formik, Field, FieldArray } from 'formik'
import {
  addCategoryRequest,
  addCategoryResponse,
  getCategoryDeleteRequest,
  getCategoryListRequest,
} from 'store/master/category/action'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { isValidCharacterFieldX } from 'helpers/SimpleReactValidators'
import { __t } from 'i18n/translator'
import { getServiceLineListRequest } from 'store/master/serviceline/action'
import {
  consultant_icon_svg,
  content_icon_svg,
  report_icon_svg,
} from 'components/CommonSvgs/CommonSvgs'
import { ENGLISH, JAPANESE } from 'constant/commonConstants'

function CategoryModal(props) {
  const { modalData } = props
  let defaultLanguage = [
    { language: "JPN", cat_name: "" },
  ]
  const initialCategoryState = () => {
    return {
      catName: '',
      createdDate: '',
      serviceId: '',
      isActive: '',
      subcate: [
        {
          id: 0,
          subCategoryName: '',
          translation: defaultLanguage,
          type: 'CONTENT',
        },
      ],
      selectServices: 'CONTENT',
      parentCatId: '',
    }
  }

  const [initialCategoryData, setInitialCategoryData] = useState(
    initialCategoryState(),
  )

  const [activeTab, setActiveTab] = useState('English');


  const [removeSubCat] = useState([])
  const [error, setError] = useState(false)

  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidCharacterFieldX()),
    }),
  )

  useEffect(() => {
    if (
      modalData != undefined &&
      modalData &&
      props.isType == 'parentCategory'
    ) {

      let updatedSubCat = modalData?.subCat?.map(item => {
        return {
          ...item,
          translation: item.translation && item.translation.length > 0
            ? item.translation
            : defaultLanguage,
        };
      });
      setInitialCategoryData({
        catName: modalData.catName,
        createdDate: modalData.createdDate,
        serviceId: modalData?.id,
        isActive: modalData.isActive,
        subCat: updatedSubCat,
        selectServices: 'CONTENT',
        parentCatId: modalData.parentCatId,
      })
    } else {
      setInitialCategoryData(initialCategoryState())
    }
  }, [modalData])

  useEffect(() => {
    props.getServiceLineListRequest({
      // searchText: '',
      pagination: {
        limit: 0,
        page: 1,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      // filter: {},
    })
  }, [])

  useEffect(() => {
    if (props.ApiRespMessage != null) {
      // navigate('/category')
      props.clearCategoryResponse()
    }
  }, [props.ApiRespMessage])

  const FormSubmit = async (values) => {
    if (validator.current.allValid()) {
      let customJSON
      console.log("values", values);

      if (props.isType === 'addOperation') {
        let Arr = []
        let translation = []
        values.subcate?.map((X) => {
          Arr?.push(X.subCategoryName)
          translation?.push(X?.translation)
        })


        customJSON = {
          catName: values.catName,
          category: Arr,
          translation: translation ?? null,
          serviceLineId: values?.serviceId,
          type: values.selectServices,
        }
      } else {
        if (props.isType == 'subCat') {
          customJSON = {
            parentCatId: values.parentCatId,
            catName: [values.catName],
            translation: values.translation,
          }
        } else {
          let Arr = []
          if (values?.subCat?.length > 0) {
            values?.subCat?.map((X) => {
              const subCatDetails = {
                catId: `${X.id}`,
                catName: X.catName,
                translation: X?.translation ?? null,
              }
              Arr?.push(subCatDetails)
            })
          }

          customJSON = {
            catName: values.catName,
            category: Arr,
            catId: values?.serviceId,
            // serviceId: values?.serviceId,
            type: values.selectServices,
          }
        }
      }
      console.log("subCatTest ", customJSON);
      if (customJSON) {
        props?.addCategory(customJSON)
        props.closeModal(false)
      }
    } else {
      validator.current.showMessages()
    }
  }

  return (
    <>
      <Modal show={props.isOpenModal} className="category-modal login-cat-modal" centered>
        <Modal.Header
          closeButton
          onClick={() => {
            setError(false)
            props.closeModal(false)
            setInitialCategoryData(initialCategoryState())
            validator.current.purgeFields()
            validator.current.hideMessages()
          }}
        >
          <Modal.Title>Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialCategoryData}
            enableReinitialize={true}
            onSubmit={FormSubmit}
          >
            {({ values, errors, touched, handleSubmit, setFieldValue }) => (
              <Form role={'form'} className="form-horizontal form-login card login-tab-switch">
                {props.isType == 'subCat' ? (
                  <FormGroup>
                    <Input
                      id="catName"
                      name="catName"
                      placeholder="Enter category"
                      className="form-control"
                      type="text"
                    />

                    {validator.current.message(
                      __t('validations.common.cat_required'),
                      values.catName,
                      'required',
                      {
                        messages: {
                          required: __t('validations.common.cat_required'),
                        },
                      },
                    )}
                  </FormGroup>
                ) : (
                  <>
                  <div className='button-wrap'>
                    <Button type='button' className={ENGLISH == activeTab ? 'btn btn-info' : 'btn btn-secondary'} onClick={() => { setActiveTab(ENGLISH) }}>English</Button>
                    <Button type='button' className={JAPANESE == activeTab ? 'btn btn-info' : 'btn btn-secondary'} onClick={() => { setActiveTab(JAPANESE) }}>Japanese
                      {/* <span className='text-danger'><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.31171 7.76149C6.23007 2.58716 7.68925 0 10 0C12.3107 0 13.7699 2.58716 16.6883 7.76149L17.0519 8.40626C19.4771 12.7061 20.6897 14.856 19.5937 16.428C18.4978 18 15.7864 18 10.3637 18H9.63634C4.21356 18 1.50217 18 0.406258 16.428C-0.689657 14.856 0.522912 12.7061 2.94805 8.40627L3.31171 7.76149Z" fill="currentColor"/><path d="M10 3C10.6213 3 11.125 3.48216 11.125 4.07692V11.2564C11.125 11.8512 10.6213 12.3333 10 12.3333C9.37868 12.3333 8.875 11.8512 8.875 11.2564V4.07692C8.875 3.48216 9.37868 3 10 3Z" fill="white"/><path d="M10 16.1115C10.8284 16.1115 11.5 15.4686 11.5 14.6756C11.5 13.8826 10.8284 13.2397 10 13.2397C9.17157 13.2397 8.5 13.8826 8.5 14.6756C8.5 15.4686 9.17157 16.1115 10 16.1115Z" fill="white"/></svg></span> */}
                    </Button>
                  </div>
                    
                    {props.isType === 'addOperation' ? (
                      <FieldArray
                        name="subcate"
                        render={(arrayHelpers) => (
                          <>
                            <div className="category-head mb-2">
                              <div
                                role="group"
                                aria-labelledby="my-radio-group"
                                className="faq_custom_radio"
                              >
                                <FormGroup className="mb-3 mt-3">
                                  <Field
                                    component={Select}
                                    menuPlacement="auto"
                                    name="serviceId"
                                    className="form-control selectbox"
                                    options={props.serviceLineList}
                                    onChange={(opt) => {
                                      setFieldValue(
                                        'serviceId',
                                        opt && opt?.value,
                                      )
                                    }}
                                    value={props.serviceLineList?.filter(
                                      (option) =>
                                        option.value === values?.serviceId,
                                    )}
                                    isClearable={true}
                                    isDisabled={
                                      props.isType === 'addOperation'
                                        ? false
                                        : true
                                    }
                                  />
                                  <Label className="form-label">
                                    Service Line
                                    <span className="text-danger required">
                                      *
                                    </span>
                                  </Label>

                                  {validator.current.message(
                                    __t('validations.common.service'),
                                    values?.serviceId,
                                    'required',
                                    {
                                      messages: {
                                        required: __t(
                                          'validations.common.service',
                                        ),
                                      },
                                    },
                                  )}
                                </FormGroup>
                                <>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <Label className="faq_radio_lable">
                                      <Field
                                        type="radio"
                                        name="selectServices"
                                        value="CONTENT"
                                      />

                                      <div className="faq_radio_text">
                                        <svg
                                          className="content_svg"
                                          width="18"
                                          height="20"
                                          viewBox="0 0 18 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5 5L9 5"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M5 13L8 13"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M5 9L11 9"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M15 9V7C15 4.17157 15 2.75736 14.1213 1.87868C13.2426 1 11.8284 1 9 1H7C4.17157 1 2.75736 1 1.87868 1.87868C1 2.75736 1 4.17157 1 7V13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H8"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <circle
                                            cx="13.5"
                                            cy="15.5"
                                            r="2.5"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M17 19L15.5 17.5"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                        </svg>
                                        <span>Content Type</span>
                                      </div>
                                    </Label>
                                    <Label className="faq_radio_lable">
                                      <Field
                                        type="radio"
                                        name="selectServices"
                                        value="REPORT"
                                      />

                                      <div className="report_icon">
                                        <svg
                                          className="report_svg"
                                          width="16"
                                          height="21"
                                          viewBox="0 0 16 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.5 3C12.9045 3 13.6067 3 14.1111 3.33706C14.3295 3.48298 14.517 3.67048 14.6629 3.88886C15 4.39331 15 5.09554 15 6.5V16C15 17.8856 15 18.8284 14.4142 19.4142C13.8284 20 12.8856 20 11 20H5C3.11438 20 2.17157 20 1.58579 19.4142C1 18.8284 1 17.8856 1 16V6.5C1 5.09554 1 4.39331 1.33706 3.88886C1.48298 3.67048 1.67048 3.48298 1.88886 3.33706C2.39331 3 3.09554 3 4.5 3"
                                            stroke="currentColor"
                                          />
                                          <path
                                            d="M5 3C5 1.89543 5.89543 1 7 1H9C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5H7C5.89543 5 5 4.10457 5 3Z"
                                            stroke="currentColor"
                                          />
                                          <path
                                            d="M5 10L11 10"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                          <path
                                            d="M5 14L9 14"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                          />
                                        </svg>
                                        <span>Report Type</span>
                                      </div>
                                    </Label>

                                    <Label className="faq_radio_lable">
                                      <Field
                                        type="radio"
                                        name="selectServices"
                                        value="CONSULTATION"
                                      />
                                      <div className="consultation_icon">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10 22C14.4183 22 18 18.4183 18 14C18 9.58172 14.4183 6 10 6C5.58172 6 2 9.58172 2 14C2 15.2355 2.28008 16.4056 2.7802 17.4502C2.95209 17.8093 3.01245 18.2161 2.90955 18.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L5.39939 21.0904C5.78393 20.9876 6.19071 21.0479 6.54976 21.2198C7.5944 21.7199 8.76449 22 10 22Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                          />
                                          <path
                                            d="M18 14.5018C18.0665 14.4741 18.1324 14.4453 18.1977 14.4155C18.5598 14.2501 18.9661 14.1882 19.3506 14.2911L19.8267 14.4185C20.793 14.677 21.677 13.793 21.4185 12.8267L21.2911 12.3506C21.1882 11.9661 21.2501 11.5598 21.4155 11.1977C21.7908 10.376 22 9.46242 22 8.5C22 4.91015 19.0899 2 15.5 2C12.7977 2 10.4806 3.64899 9.5 5.9956"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                          />
                                          <path
                                            d="M6.51828 14H6.52728M10.009 14H10.018M13.5 14H13.509"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        <span>Consultant Type</span>
                                      </div>
                                    </Label>
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    {validator.current.message(
                                      __t('validations.common.type'),
                                      values?.selectServices,
                                      'required',
                                      {
                                        messages: {
                                          required: __t(
                                            'validations.common.type',
                                          ),
                                        },
                                      },
                                    )}
                                  </div>
                                </>
                              </div>
                            </div>
                            <ul className="sub-task-box small-scroll p-0 mt-3">
                              {values.subcate?.map((item, idx) => {
                                if (!removeSubCat?.includes(item.id)) {
                                  return (
                                    <li
                                      key={idx}
                                      className={
                                        item.type != 'CONTENT'
                                          ? ''
                                          : item.subCategoryName
                                            ? 'active'
                                            : 'hidden'
                                      }
                                    >
                                      <div className="d-flex flex-column w-100 form-group mb-2">
                                        {
                                          activeTab == "English" ?
                                            <>                                        
                                              <Field
                                                name={`subcate.${idx}.subCategoryName`}
                                                className="form-control"
                                                placeholder="Category"
                                                value={item.subCategoryName}
                                              />
                                              <Label className="form-label" id={`subcate.${idx}.subCategoryName`}>
                                                Category
                                                <span className="text-danger required">
                                                  *
                                                </span>
                                              </Label>
                                              {validator.current.message(
                                                __t(
                                                  'validations.common.subcat_required',
                                                ),
                                                item.subCategoryName,
                                                'required',
                                                {
                                                  messages: {
                                                    required: __t(
                                                      'validations.common.subcat_required',
                                                    ),
                                                  },
                                                },
                                              )}

                                            </> :
                                            <>
                                              {item.translation?.map((lang, langIdx) => {
                                                return <>
                                                  <Field
                                                    name={`subcate.${idx}.translation.[${langIdx}].cat_name`}
                                                    className="form-control"
                                                    placeholder="Translation"
                                                    value={lang.cat_name}
                                                  />
                                                  <Label className="form-label" id={`subcate.${idx}.translation`}>
                                                    {`Translation (${lang.language})`}
                                                  </Label>
                                                </>
                                              })}
                                            </>
                                        }
                                      </div>
                                      <div className="sub-task-btn">
                                        {values.subcate?.length === idx + 1 ? (
                                          <div className='sub-task-btn'>
                                            <Button
                                              type="button"
                                              className="add-icon-btn"
                                              onClick={() => {
                                                if (
                                                  values.subcate[idx]
                                                    .subCategoryName
                                                ) {
                                                  arrayHelpers.insert(idx + 1, {
                                                    id: 0,
                                                    subCat: '',
                                                    translation: defaultLanguage,
                                                    type: values.selectServices,
                                                  })
                                                }
                                              }}
                                            >
                                              <svg
                                                width="25"
                                                height="25"
                                                viewBox="0 0 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M12.5 2L12.5 23"
                                                  stroke="currentColor"
                                                  strokeWidth="3"
                                                  strokeLinecap="square"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M23 12.5L2 12.5"
                                                  stroke="currentColor"
                                                  strokeWidth="3"
                                                  strokeLinecap="square"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </Button>
                                          </div>
                                        ) : null}
                                      </div>
                                      {values.subcate?.length > 1 && (
                                        <div className="sub-task-delete-btn">
                                          <Button
                                            type="button"
                                            className="mr-2 "
                                            onClick={() => {
                                              arrayHelpers.remove(idx)
                                              validator.current.purgeFields()
                                              validator.current.hideMessages()
                                            }}
                                          >
                                            <svg
                                              width="29"
                                              height="31"
                                              viewBox="0 0 29 31"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M11.1177 21.0588L11.1177 16.2941"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                              />
                                              <path
                                                d="M17.4707 21.0588L17.4707 16.2941"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                              />
                                              <path
                                                d="M0 8.35291H28.5882H27.8235C25.9379 8.35291 24.9951 8.35291 24.4093 8.93869C23.8235 9.52448 23.8235 10.4673 23.8235 12.3529V25C23.8235 26.8856 23.8235 27.8284 23.2377 28.4142C22.652 29 21.7091 29 19.8235 29H8.7647C6.87909 29 5.93628 29 5.35049 28.4142C4.76471 27.8284 4.76471 26.8856 4.76471 25V12.3529C4.76471 10.4673 4.76471 9.52448 4.17892 8.93869C3.59313 8.35291 2.65032 8.35291 0.764707 8.35291H0Z"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                              />
                                              <path
                                                d="M11.226 2.58859C11.407 2.41973 11.8058 2.27052 12.3605 2.1641C12.9153 2.05768 13.595 2 14.2942 2C14.9935 2 15.6732 2.05768 16.2279 2.1641C16.7827 2.27052 17.1815 2.41973 17.3625 2.58858"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                              />
                                            </svg>
                                          </Button>
                                        </div>
                                      )}
                                    </li>
                                  )
                                } else {
                                  arrayHelpers.remove(idx)
                                }
                              })}
                            </ul>
                          </>
                        )}
                      />
                    ) : (
                      <>
                        <FieldArray
                          name="subCat"
                          render={(arrayHelpers) => (
                            <>
                              <div className="category-head mb-2">
                                <div
                                  role="group"
                                  aria-labelledby="my-radio-group"
                                  className="faq_custom_radio"
                                >
                                  <FormGroup className="mb-2 mt-2">
                                    <Field
                                      component={Select}
                                      menuPlacement="auto"
                                      name="serviceId"
                                      className="form-control selectbox"
                                      options={props.serviceLineList}
                                      onChange={(opt) => {
                                        setFieldValue(
                                          'serviceId',
                                          opt && opt?.value,
                                        )
                                      }}
                                      value={props.serviceLineList?.filter(
                                        (option) =>
                                          option.value === values?.serviceId,
                                      )}
                                      isClearable={true}
                                      isDisabled={
                                        props.isType === 'addOperation'
                                          ? false
                                          : true
                                      }
                                    />
                                    <Label className="form-label">
                                      Service Line
                                      <span className="text-danger required">
                                        *
                                      </span>
                                    </Label>

                                    {validator.current.message(
                                      __t('validations.common.service'),
                                      values?.serviceId,
                                      'required',
                                      {
                                        messages: {
                                          required: __t(
                                            'validations.common.service',
                                          ),
                                        },
                                      },
                                    )}
                                  </FormGroup>
                                  <>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <Label className="faq_radio_lable">
                                        <Field
                                          type="radio"
                                          name="selectServices"
                                          value="CONTENT"
                                        />

                                        <div className="faq_radio_text">
                                          <svg
                                            className="content_svg"
                                            width="18"
                                            height="20"
                                            viewBox="0 0 18 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M5 5L9 5"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                            <path
                                              d="M5 13L8 13"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                            <path
                                              d="M5 9L11 9"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                            <path
                                              d="M15 9V7C15 4.17157 15 2.75736 14.1213 1.87868C13.2426 1 11.8284 1 9 1H7C4.17157 1 2.75736 1 1.87868 1.87868C1 2.75736 1 4.17157 1 7V13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H8"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                            <circle
                                              cx="13.5"
                                              cy="15.5"
                                              r="2.5"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                            <path
                                              d="M17 19L15.5 17.5"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                          </svg>
                                          <span>Content Type</span>
                                        </div>
                                      </Label>
                                      <Label className="faq_radio_lable">
                                        <Field
                                          type="radio"
                                          name="selectServices"
                                          value="REPORT"
                                        />
                                        <div className="report_icon">
                                          <svg
                                            className="report_svg"
                                            width="16"
                                            height="21"
                                            viewBox="0 0 16 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.5 3C12.9045 3 13.6067 3 14.1111 3.33706C14.3295 3.48298 14.517 3.67048 14.6629 3.88886C15 4.39331 15 5.09554 15 6.5V16C15 17.8856 15 18.8284 14.4142 19.4142C13.8284 20 12.8856 20 11 20H5C3.11438 20 2.17157 20 1.58579 19.4142C1 18.8284 1 17.8856 1 16V6.5C1 5.09554 1 4.39331 1.33706 3.88886C1.48298 3.67048 1.67048 3.48298 1.88886 3.33706C2.39331 3 3.09554 3 4.5 3"
                                              stroke="currentColor"
                                            />
                                            <path
                                              d="M5 3C5 1.89543 5.89543 1 7 1H9C10.1046 1 11 1.89543 11 3C11 4.10457 10.1046 5 9 5H7C5.89543 5 5 4.10457 5 3Z"
                                              stroke="currentColor"
                                            />
                                            <path
                                              d="M5 10L11 10"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                            <path
                                              d="M5 14L9 14"
                                              stroke="currentColor"
                                              strokeLinecap="round"
                                            />
                                          </svg>
                                          <span>Report Type</span>
                                        </div>
                                      </Label>

                                      <Label className="faq_radio_lable">
                                        <Field
                                          type="radio"
                                          name="selectServices"
                                          value="CONSULTATION"
                                        />
                                        <div className="consultation_icon">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10 22C14.4183 22 18 18.4183 18 14C18 9.58172 14.4183 6 10 6C5.58172 6 2 9.58172 2 14C2 15.2355 2.28008 16.4056 2.7802 17.4502C2.95209 17.8093 3.01245 18.2161 2.90955 18.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L5.39939 21.0904C5.78393 20.9876 6.19071 21.0479 6.54976 21.2198C7.5944 21.7199 8.76449 22 10 22Z"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                            />
                                            <path
                                              d="M18 14.5018C18.0665 14.4741 18.1324 14.4453 18.1977 14.4155C18.5598 14.2501 18.9661 14.1882 19.3506 14.2911L19.8267 14.4185C20.793 14.677 21.677 13.793 21.4185 12.8267L21.2911 12.3506C21.1882 11.9661 21.2501 11.5598 21.4155 11.1977C21.7908 10.376 22 9.46242 22 8.5C22 4.91015 19.0899 2 15.5 2C12.7977 2 10.4806 3.64899 9.5 5.9956"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                            />
                                            <path
                                              d="M6.51828 14H6.52728M10.009 14H10.018M13.5 14H13.509"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                          <span>Consultant Type</span>
                                        </div>
                                      </Label>

                                      
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      {validator.current.message(
                                        __t('validations.common.type'),
                                        values?.selectServices,
                                        'required',
                                        {
                                          messages: {
                                            required: __t(
                                              'validations.common.type',
                                            ),
                                          },
                                        },
                                      )}
                                    </div>
                                  </>
                                </div>
                              </div>

                              <ul className="p-0">
                                {props.isType !== 'addOperation' &&
                                  values.subCat?.map((item, idx) => {
                                    if (!removeSubCat?.includes(item.id)) {
                                      return (
                                        <li
                                          key={idx}
                                          className={
                                            item.type == values.selectServices
                                              ? 'show'
                                              : 'd-none'
                                          }
                                        >
                                          <div className="d-flex flex-column w-100">
                                            {
                                              activeTab == "English" ?
                                                <>
                                                  <Field
                                                    id={`subCat.${idx}.catName`}
                                                    name={`subCat.${idx}.catName`}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter sub-category"
                                                  />
                                                  {item.type == 'REPORT'
                                                    ? report_icon_svg()
                                                    : item.type == 'CONSULTATION'
                                                      ? consultant_icon_svg()
                                                      : content_icon_svg()}

                                                  {props.isType != 'addOperation' &&
                                                    validator.current.message(
                                                      __t(
                                                        'validations.common.subcat_required',
                                                      ),
                                                      item.catName,
                                                      'required',
                                                      {
                                                        messages: {
                                                          required: __t(
                                                            'validations.common.subcat_required',
                                                          ),
                                                        },
                                                      },
                                                    )}
                                                </>
                                                :
                                                <>
                                                  {item.translation?.map((lang, langIdx) => {
                                                    return <>
                                                      <Field
                                                        name={`subCat.${idx}.translation.[${langIdx}].cat_name`}
                                                        className="form-control"
                                                        placeholder="Translation"
                                                        value={lang.cat_name}
                                                      />
                                                      {item.type == 'REPORT'
                                                        ? report_icon_svg()
                                                        : item.type == 'CONSULTATION'
                                                          ? consultant_icon_svg()
                                                          : content_icon_svg()}
                                                      {/* <Label className="form-label" id={`subCat.${idx}.translation`}>
                                                        {`Translation (${lang.language})`}
                                                      </Label> */}
                                                    </>

                                                  })}
                                                </>
                                            }
                                          </div>
                                          <div className='sub-task-btn'>
                                              <Button
                                            type="button"
                                            className="add-icon-btn"
                                            onClick={() => {
                                              arrayHelpers.insert(
                                                values?.subCat?.length + 1,
                                                {
                                                  id: 0,
                                                  subCat: '',
                                                  translation: defaultLanguage,
                                                  type: values.selectServices,
                                                },
                                              )
                                            }}
                                          >
                                            <svg
                                              width="25"
                                              height="25"
                                              viewBox="0 0 25 25"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12.5 2L12.5 23"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                strokeLinecap="square"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M23 12.5L2 12.5"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                strokeLinecap="square"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </Button>
                                        </div>

                                          {values.subCat[idx].isTrue !=
                                            true && (
                                              <div className="sub-task-delete-btn-edit-sean">
                                                <Button
                                                  type="button"
                                                  onClick={() => {
                                                    arrayHelpers.remove(idx)
                                                  }}
                                                >
                                                  <svg
                                                    width="29"
                                                    height="31"
                                                    viewBox="0 0 29 31"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M11.1177 21.0588L11.1177 16.2941"
                                                      stroke="currentColor"
                                                      strokeWidth="3"
                                                      strokeLinecap="round"
                                                    />
                                                    <path
                                                      d="M17.4707 21.0588L17.4707 16.2941"
                                                      stroke="currentColor"
                                                      strokeWidth="3"
                                                      strokeLinecap="round"
                                                    />
                                                    <path
                                                      d="M0 8.35291H28.5882H27.8235C25.9379 8.35291 24.9951 8.35291 24.4093 8.93869C23.8235 9.52448 23.8235 10.4673 23.8235 12.3529V25C23.8235 26.8856 23.8235 27.8284 23.2377 28.4142C22.652 29 21.7091 29 19.8235 29H8.7647C6.87909 29 5.93628 29 5.35049 28.4142C4.76471 27.8284 4.76471 26.8856 4.76471 25V12.3529C4.76471 10.4673 4.76471 9.52448 4.17892 8.93869C3.59313 8.35291 2.65032 8.35291 0.764707 8.35291H0Z"
                                                      stroke="currentColor"
                                                      strokeWidth="3"
                                                      strokeLinecap="round"
                                                    />
                                                    <path
                                                      d="M11.226 2.58859C11.407 2.41973 11.8058 2.27052 12.3605 2.1641C12.9153 2.05768 13.595 2 14.2942 2C14.9935 2 15.6732 2.05768 16.2279 2.1641C16.7827 2.27052 17.1815 2.41973 17.3625 2.58858"
                                                      stroke="currentColor"
                                                      strokeWidth="3"
                                                      strokeLinecap="round"
                                                    />
                                                  </svg>
                                                </Button>
                                              </div>
                                            )}
                                        </li>
                                      )
                                    } else {
                                      arrayHelpers.remove(idx)
                                    }
                                  })}
                              </ul>
                            </>
                          )}
                        />
                      </>
                    )}
                  </>
                )}
                <div className="error-msg-box">
                  {error === true && (
                    <label className="text-danger">select sub category</label>
                  )}
                </div>
                <div className="btn-box mt-2">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-100"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ categoryReducer, serviceLineReducer }) => {
  let CategoryList
  let message
  let serviceLineList

  if (
    serviceLineReducer.serviceLineList &&
    serviceLineReducer.serviceLineList.list?.length > 0
  ) {
    serviceLineList = serviceLineReducer.serviceLineList?.list.map((x) => ({
      value: x.id,
      label: x.catName,
    }))
  }

  if (
    categoryReducer.categoryList &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    CategoryList = categoryReducer.categoryList.list
  }

  if (
    categoryReducer.addCategoryResponse != null &&
    categoryReducer.addCategoryResponse
  ) {
    message = categoryReducer.addCategoryResponse.message
  }

  return {
    serviceLineList: serviceLineList,
    getCategoryListData: CategoryList,
    ApiRespMessage: message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshList: (creds) => {
      dispatch(getCategoryListRequest(creds))
    },
    addCategory: (creds) => {
      dispatch(addCategoryRequest(creds))
    },
    getStatusCategory: (creds) => {
      dispatch(getCategoryDeleteRequest(creds))
    },
    clearCategoryResponse: () => {
      dispatch(addCategoryResponse(null))
    },
    getServiceLineListRequest: (creds) => {
      dispatch(getServiceLineListRequest(creds))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal)
