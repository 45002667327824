export const GET_USER_CATEGORIES_REQUEST = 'GET_USER_CATEGORIES_REQUEST'
export const GET_USER_CATEGORIES_RESPONSE = 'GET_USER_CATEGORIES_RESPONSE'

export const GET_VISITOR_REQUEST = 'GET_VISITOR_REQUEST'
export const GET_VISITOR_RESPONSE = 'GET_VISITOR_RESPONSE'

export const ASK_FOR_SUBSCRIBE = 'ASK_FOR_SUBSCRIBE'

export const GET_COUNT_REQUEST = 'GET_COUNT_REQUEST'
export const GET_COUNT_RESPONSE = 'GET_COUNT_RESPONSE'

export const GET_PUBLISHER_LIST_REQUEST = 'GET_PUBLISHER_LIST_REQUEST'
export const GET_PUBLISHER_LIST_RESPONSE = 'GET_PUBLISHER_LIST_RESPONSE'
