//Login
export const LOGIN = '/login';
//otp
export const SEND_CODE = '/send-otp';
//Logout
export const LOGOUT = '/logout';
//change password
export const CHANGE_PASSWORD = '/change-password';
export const FORGOT_PASSWORD = '/forgot-password';
//COnfirm password
export const CONFIRM_RESET_PASSWORD_TOKEN = '/confirm-reset-password-token';
export const RESET_PASSWORD = '/reset-password';
//Country 
export const COUNTRY = '/country';
//Laws
export const LAW = '/laws';
export const LAW_BY_STATUS = '/laws/list';
export const LAW_DETAILS = '/laws/details';
export const SECTION = '/law-sections';
export const SECTION_BY_STATUS = '/law-sections/list';
export const SECTION_LIST_BY_LAW = '/law-sections/list-by-law';


