//get country
export const ADD_COUNTRY_REQUEST = 'ADD_COUNTRY_REQUEST'
export const ADD_COUNTRY_RESPONSE = 'ADD_COUNTRY_RESPONSE'

export const GET_COUNTRY_LIST_REQUEST = ' GET_COUNTRY_LIST_REQUEST'
export const GET_COUNTRY_LIST_RESPONSE = ' GET_COUNTRY_LIST_RESPONSE'

export const ADD_COUNTRY_STATUS_REQUEST = ' ADD_COUNTRY_STATUS_REQUEST'
export const ADD_COUNTRY_STATUS_RESPONSE = ' ADD_COUNTRY_STATUS_RESPONSE'

export const GET_CATEGORY_LIST_REQUEST = 'GET_CATEGORY_LIST_REQUEST'
export const GET_CATEGORY_LIST_RESPONSE = 'GET_CATEGORY_LIST_RESPONSE'

export const ADD_CATEGORY_AND_SUB_CATEGORY_REQUEST =
  'ADD_CATEGORY_AND_SUB_CATEGORY_REQUEST'
export const ADD_CATEGORY_AND_SUB_CATEGORY_RESPONSE =
  'ADD_CATEGORY_AND_SUB_CATEGORY_RESPONSE'

export const GET_CATEGORY_DELETE_REQUEST = 'GET_CATEGORY_DELETE_REQUEST'
export const GET_CATEGORY_DELETE_RESPONSE = 'GET_CATEGORY_DELETE_RESPONSE'

export const CLEAR_CATEGORY_LIST_RESPONSE = 'CLEAR_CATEGORY_LIST_RESPONSE'
export const GET_SUBCAT_LIST_BY_CAT_ID_REQUEST =
  'GET_SUBCAT_LIST_BY_CAT_ID_REQUEST'
export const GET_SUBCAT_LIST_BY_CAT_ID_RESPONSE =
  'GET_SUBCAT_LIST_BY_CAT_ID_RESPONSE'

export const CLEAR_SUB_CATEGORY_LIST_RESPONSE =
  'CLEAR_SUB_CATEGORY_LIST_RESPONSE'

export const GET_REPORT_SUBCAT_LIST_BY_CAT_ID_REQUEST =
  'GET_REPORT_SUBCAT_LIST_BY_CAT_ID_REQUEST'

export const GET_REPORT_SUBCAT_LIST_BY_CAT_ID_RESPONSE =
  'GET_REPORT_SUBCAT_LIST_BY_CAT_ID_RESPONSE'

export const GET_FILTER_CATEGORY_LIST_REQUEST =
  'GET_FILTER_CATEGORY_LIST_REQUEST'
export const GET_FILTER_CATEGORY_LIST_RESPONSE =
  'GET_FILTER_CATEGORY_LIST_RESPONSE'
