import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { createLoadingSelector } from 'store/shared/selector'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import pre_loder from '../images/logo-light--sm.png'
import { Outlet, useLocation } from 'react-router-dom'

function Layout(props) {
  const location = useLocation()
  return (
    <div
      className={
        location.pathname === '/console/home'
          ? 'main-body dashboard_header'
          : 'main-body'
      }
    >
      {props?.loadingSelector && (
        <div className="preloader-main">
          <div className="multi-spinner-container">
            <div className="multi-spinner">
              <img alt="Pre loader" className="pre_logo" src={pre_loder} />
            </div>
          </div>
        </div>
      )}
      <Header />
      <main className="main-inner">
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

const mapStatetoProps = createStructuredSelector({
  loadingSelector: createLoadingSelector(),
})

export default connect(mapStatetoProps)(Layout)
