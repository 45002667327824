import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import HelmetComponent from './Helmet/HelmetComponent'
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { __t } from 'i18n/translator'
import { useTranslation } from 'react-i18next';


const TermsConditions = (props) => {
  useEffect(() => {
    // window.scrollTo(0, 0)
    const topSection = document.querySelector('.inner-main');
    if (topSection) {
      topSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Element with class "top_section" not found.');
    }
  }, [])

  const [IsShow, setIsShow] = useState(true)
  useEffect(() => {
    const isCookies = Cookies.get('isTermsConditions');
    if (isCookies) {
      setIsShow(false)
    } else {
      setIsShow(true)
    }
  }, [location.pathname]);


  return (
    <>
      <HelmetComponent title={null} description={null} keywords={null} />
      <div className="inner-main bg-white inner-main">
        <div className="top_section mb-0">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>{__t('frontend.terms_conditions.page_title')}</h1>
                  <span>{__t('frontend.terms_conditions.page_title')}</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/home"> {__t('frontend.terms_conditions.breadcrumb_home_link')}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>{__t('frontend.terms_conditions.breadcrumb_terms_link')}</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='inner-section'>
          <div className='container'>
            <div className='terms-conditions-data text-center'>
              <h3>{__t('frontend.terms_conditions.section_title')}</h3>
              <p>{__t('frontend.terms_conditions.section_description_one')}<strong>{window.parent.location.hostname}</strong> {__t('frontend.terms_conditions.section_description_two')}</p>
            </div>
          </div>
          <div className='terms-conditions-data-list'>
            <h6>{__t('frontend.terms_conditions.conditions_of_use')}</h6>
            <p>{__t('frontend.terms_conditions.terms_by_using')} <strong>{__t('frontend.terms_conditions.gtmitra_platform')}</strong> {__t('frontend.terms_conditions.terms_description_one')}</p>
            <p>{__t('frontend.terms_conditions.terms_second_paragraph')}</p>
            <ul className='common-list'>
              <li>{__t('frontend.terms_conditions.terms_list_item_one')}</li>
              <li>{__t('frontend.terms_conditions.terms_list_item_two')}</li>
              <li>{__t('frontend.terms_conditions.terms_list_item_three')}</li>
              <li>{__t('frontend.terms_conditions.terms_list_item_four')}</li>
              <li>{__t('frontend.terms_conditions.terms_list_item_five')}</li>
              <li>{__t('frontend.terms_conditions.terms_list_item_six')}</li>
            </ul>
            <p>{__t('frontend.terms_conditions.terms_third_paragraph')}</p>
            <p>{__t('frontend.terms_conditions.terms_fourth_paragraph')}</p>
            <p>{__t('frontend.terms_conditions.terms_fiv_paragraph')}</p>
            <p>This platform, its contents, reports and/or information contained therein is not intended to be a substitute or a replacement for seeking independent professional advice and you are expected and requested to obtain any appropriate professional advice relevant to your particular facts and circumstances. Please reach out to us on <a href="mailto:gtmitra@in.gt.com" className='btn-link' title='gtmitra@in.gt.com'>gtmitra@in.gt.com</a> for a detailed conversation wherein we can explore the possibility to help you further.</p>
            <p>{__t('frontend.terms_conditions.terms_saven_paragraph')}</p>
            <p>{__t('frontend.terms_conditions.terms_eight_paragraph')}</p>
            <p>{__t('frontend.terms_conditions.terms_nine_paragraph')}</p>
            <h6>{__t('frontend.terms_conditions.terms_use_data_title')}</h6>
            <ul className='common-list'>
              <li>{__t('frontend.terms_conditions.terms_use_data_item_one')}</li>
              <li>{__t('frontend.terms_conditions.terms_use_data_item_two')}</li>
              <li>{__t('frontend.terms_conditions.terms_use_data_item_three')}</li>
              <li>{__t('frontend.terms_conditions.terms_use_data_item_four')}</li>
              <li>{__t('frontend.terms_conditions.terms_use_data_item_five')} <a target="_blank" href="https://www.grantthornton.in/en/privacy-policy/" className='btn-link' title='https://www.grantthornton.in/en/privacy-policy/'>https://www.grantthornton.in/en/privacy-policy/</a></li>
            </ul>
            <div className='note-box'>
              <h6>{__t('frontend.terms_conditions.terms_note_title')} </h6>
              <ul className='common-list'>
                <li><strong>{__t('frontend.terms_conditions.terms_note_item_one')}</strong></li>
                <li><strong>{__t('frontend.terms_conditions.terms_note_item_two')}</strong></li>
                <li><strong>{__t('frontend.terms_conditions.terms_note_item_three')}</strong></li>
              </ul>
            </div>
            <p>These terms will be governed by laws of the India and any dispute of any sort that might come between you and us will be subject to exclusive jurisdiction of the Courts at <strong>New Delhi, India.</strong></p>
            {
              IsShow && <div className='btn-box text-center'>
                <Button className="btn btn-outline-light" title='Decline' onClick={() => setIsShow(false)}>
                {__t('frontend.terms_conditions.terms_decline')}
                </Button>
                <Button className="btn btn-primary ms-3" title='Agree' onClick={() => {
                  Cookies.set('isTermsConditions', true);
                  setIsShow(false)
                }}>
                  {__t('frontend.terms_conditions.terms_agree')}
                </Button>
              </div>
            }

          </div>
        </div>
      </div>
    </>
  )
}


export default (TermsConditions)
