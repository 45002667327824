import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  GET_SERVICE_LINE_LIST_REQUEST,
  ADD_SERVICE_LINE_REQUEST,
  IS_ACTIVE_DEACTIVE_SERVICE_LINE_REQUEST,
} from './actionType'
import { handleApiCallException } from '../../shared/action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import {
  getCategoryListRequest,
  getActiveDeactiveServicesResponse,
  getServiceLineListResponse,
  addServiceLineResponse,
  getServiceLineListRequest,
} from './action'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

function* serviceStatus({ payload: { data } }) {
  const { currentPage, dataPerPage, ...modData } = data
  try {
    const response = yield call(invokeApi, 'put', '/serviceLineStatus', modData)
    if (response.status) {
      yield put(
        getServiceLineListRequest({
          // searchText: '',
          pagination: {
            limit: dataPerPage,
            page: currentPage,
            orderKey: 'createdDate',
            orderBy: 'ASC',
          },
          // filter: { roleId: 0 },
        }),
      )
      yield put(
        getActiveDeactiveServicesResponse(
          response.message,
          SUCCESS,
          response.data,
        ),
      )
    } else {
      // yield put(getActiveDeactiveServicesRequest(response.message, DANGER))
      yield put(getActiveDeactiveServicesResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getServiceLineList({ payload: { Data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/serviceLineList', Data)

    if (response.status) {
      yield put(getServiceLineListResponse(response && response.data))
    } else {
      yield put(getServiceLineListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* addService({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/serviceLine', data)
    if (response.status) {
      yield put(addServiceLineResponse(response.message, SUCCESS))

      ApiResponseMessage(
        response.message,
        response.status === true ? SUCCESS : DANGER,
      )

      // ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      ApiResponseMessage(
        response.message,
        response.status === true ? SUCCESS : DANGER,
      )
      yield put(addServiceLineResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchCountry() {
  yield takeEvery(IS_ACTIVE_DEACTIVE_SERVICE_LINE_REQUEST, serviceStatus)
  yield takeEvery(GET_SERVICE_LINE_LIST_REQUEST, getServiceLineList)
  yield takeEvery(ADD_SERVICE_LINE_REQUEST, addService)
}

function* serviceLineSaga() {
  yield all([fork(watchCountry)])
}
export default serviceLineSaga
