import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CardBody, Card, CardHeader, Input } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import { getUserCategoriesRequest } from 'store/frontEnd/common/action'
import { getUserFaqListRequest } from 'store/frontEnd/faq/action'

const Categories = (props) => {
  const [isSearch, setIsSearch] = useState(false)
  const [isSideCollapse, setIsSideCollapse] = useState(true)
  const [isOpen, setIsCollapseIds] = useState([])
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const filteredData = props?.categoriesList?.filter((item) => {
    // search for catName
    if (item.catName.toLowerCase().includes(search.toLowerCase())) {
      return true
    }
    // search for subCat
    if (item.subCat) {
      const filteredSubCat = item.subCat.filter((subItem) =>
        subItem.catName.toLowerCase().includes(search.toLowerCase()),
      )
      return filteredSubCat.length > 0
    }
    return false
  })

  useEffect(() => {
    props.getCategoriesList()
  }, [])

  const handleClick = (id) => {
    let data = isOpen.map((item) =>
      item.id === id ? { ...item, open: !item.open } : item,
    )
    setIsCollapseIds([...data])
  }
  useEffect(() => {
    setIsCollapseIds(
      props?.categoriesList?.map((x, index) => {
        return {
          id: index,
          open: false,
        }
      }),
    )
  }, [props?.categoriesList])

  return (
    <Card
      className={
        isSideCollapse ? 'category-panel' : 'category-panel side-collapse'
      }
    >
      <CardHeader className="panel-head">
        <Link
          to="#"
          onClick={() => {
            setIsSideCollapse(isSideCollapse ? false : true)
          }}
        >
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 8H30"
              stroke="CurrentColor"
              strokeWidth="3.5"
              strokeLinecap="round"
            />
            <path
              d="M7 17H25"
              stroke="CurrentColor"
              strokeWidth="3.5"
              strokeLinecap="round"
            />
            <path
              d="M7 27H30"
              stroke="CurrentColor"
              strokeWidth="3.5"
              strokeLinecap="round"
            />
          </svg>
        </Link>
        <b>List</b>

        <Link
          to="#"
          className="category-search"
          onClick={() => setIsSearch(isSearch ? false : true)}
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="8.4375"
              cy="8.4375"
              r="7.4375"
              stroke="CurrentColor"
              strokeWidth="2"
            />
            <path
              d="M8.4375 5.25C8.01891 5.25 7.60442 5.33245 7.2177 5.49263C6.83097 5.65282 6.47958 5.88761 6.1836 6.1836C5.88761 6.47958 5.65282 6.83097 5.49263 7.2177C5.33245 7.60442 5.25 8.01891 5.25 8.4375"
              stroke="CurrentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M18 18L14.8125 14.8125"
              stroke="CurrentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </Link>
      </CardHeader>
      <CardBody>
        <div className={isSearch ? 'categorysearch show' : 'categorysearch'}>
          <Input
            type="Search"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              if (e.target.value.trim().length == 0) {
                navigate('/report')

                props.onChange()
                props.getAllList()
              }
              setSearch(e.target.value)
            }}
          ></Input>
        </div>
        <ul className="report_highpoint">
          {filteredData?.length > 0 && (
            <li>
              <Link
                to={props.navigateTo ? props.navigateTo : '#'}
                onClick={() => {
                  props.onChange()
                  props.getAllList()
                }}
                className={props.value == -1 ? 'active' : ''}
              >
                All
              </Link>
            </li>
          )}

          {filteredData
            .filter((catItem) => catItem.catName)
            .map((item, idx) => {
              return (
                <li key={idx}>
                  <Link
                    to={
                      props.navigateTo
                        ? props.navigateTo + '\\' + item.slug
                        : '#'
                    }
                    title={item.catName}
                    onClick={() => {
                      props.onChange()
                      handleClick(idx)
                    }}
                    className={props.value === item.id ? 'active' : ''}
                  >
                    {item.catName}
                  </Link>
                </li>
              )
            })}
        </ul>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = ({ userCommonReducer }) => {
  return {
    categoriesList: userCommonReducer?.userCategoriesList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFaqList: (data) => {
      dispatch(getUserFaqListRequest(data))
    },
    getCategoriesList: (data) => {
      dispatch(getUserCategoriesRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Categories)
