/* eslint-disable no-debugger */
import { connect } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import {
  Table,
  FormGroup,
  Label,
  Button,
  Row,
  Card,
  CardBody,
} from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { __t } from 'i18n/translator'
import Select from 'react-select'
import {
  cleareCreateTeamResponse,
  createTeamRequest,
  deleteTeamMemberRequest,
  getTeamLeadRequest,
  getTeamMemberListRequest,
  getTeamUserRequest,
} from 'store/teamCreation/action'
import SimpleReactValidator from 'simple-react-validator'
import { SUCCESS } from 'constant/commonConstants'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { setRoleStatusColor } from 'helpers/generalUtils'

const TeamCreation = (props) => {
  const initialState = {
    userIds: '',
    teamId: '',
  }
  const validator = useRef(new SimpleReactValidator())
  const [teamCreationInitialState, setTeamCreationInitialState] =
    useState(initialState)
  const countryData = JSON.parse(localStorage.getItem('countryData'))
  const [userList, setUserList] = useState([])
  const [teamMeberList, setTeamMeberList] = useState([])

  const formSubmit = async (values, { resetForm }) => {
    if (validator.current.allValid()) {
      let formValue = {
        userList: values.userIds,
        teamLeadId: values.teamId,
      }
      props.createTeam(formValue)
      resetForm()
      validator.current.hideMessages()
    } else {
      validator.current.showMessages()
    }
  }
  useEffect(() => {
    if (props.teamLeadList) {
      let teamData = props.teamLeadList?.filter(
        (x) => x.label == 'CONSULTATION',
      )[0]
      if (teamData?.value) {
        setTeamCreationInitialState({
          ...initialState,
          teamId: teamData?.value,
        })
      }
    }
  }, [props.teamLeadList])

  useEffect(() => {
    props.getTeamLead()

    if (countryData?.id) {
      props.getTeamUser({ countryId: countryData.id })
      props.getTeamMemberList({ countryId: countryData.id })
    }
  }, [])
  useEffect(() => {
    if (props?.isCreateTeam == SUCCESS) {
      props.cleareCreateTeam()
      setTeamCreationInitialState(initialState)
      props.getTeamMemberList({ countryId: countryData.id })
    }
  }, [props.isCreateTeam])

  useEffect(() => {
    let userIds = props.teamMeberList.map((x) => x.TeamMember.userId)
    setUserList(props.userList.filter((x) => !userIds.includes(x.value)))
  }, [props.teamMeberList, props.userList])

  useEffect(() => {
    setTeamMeberList(props.teamMeberList)
  }, [props.teamMeberList])

  return (
    <>
      <div className="container">
        <div className="section-head mt-3">
          <div>
            <h3>Team Creation</h3>
          </div>
        </div>

        <Formik
          initialValues={teamCreationInitialState}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Card>
              <CardBody>
                <Row className="mx-0 pt-0">
                  <Form className="d-flex align-items-center team-creation-form p-0 row mx-0">
                    <FormGroup className="col mb-0 ps-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="teamId"
                        className="form-control selectbox"
                        options={props.teamLeadList}
                        onChange={(opt) => {
                          setFieldValue('teamId', opt && opt?.value)
                        }}
                        value={props.teamLeadList?.filter(
                          (option) => option.value === values.teamId,
                        )}
                        isClearable={true}
                      />
                      <Label className="mb-1 form-label">Team</Label>
                      {validator.current.message(
                        __t('validations.team_creation.team_required'),
                        values.teamId,
                        'required',
                        {
                          messages: {
                            required: __t(
                              'validations.team_creation.team_required',
                            ),
                          },
                        },
                      )}
                    </FormGroup>
                    <FormGroup className="col mb-0 ps-0 ">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="userIds"
                        className="form-control selectbox"
                        options={userList}
                        onChange={(opt) => {
                          let userIds = opt && opt?.map((x) => x.value)

                          setFieldValue('userIds', userIds)
                        }}
                        value={userList.filter((option) =>
                          values.userIds?.includes(option?.value),
                        )}
                        isMulti={true}
                        isClearable={true}
                      />
                      <Label className="mb-1 form-label">User</Label>
                      {validator.current.message(
                        __t('validations.team_creation.user_required'),
                        values.userIds,
                        'required',
                        {
                          messages: {
                            required: __t(
                              'validations.team_creation.user_required',
                            ),
                          },
                        },
                      )}
                    </FormGroup>
                    <Button
                      type="submit"
                      color="primary"
                      className="col-auto user_team_btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M5.78947 0.789474C5.78947 0.580092 5.7063 0.379287 5.55824 0.231232C5.41019 0.0831765 5.20938 0 5 0C4.79062 0 4.58981 0.0831765 4.44176 0.231232C4.2937 0.379287 4.21053 0.580092 4.21053 0.789474V4.21053H0.789474C0.580092 4.21053 0.379287 4.2937 0.231232 4.44176C0.0831765 4.58981 0 4.79062 0 5C0 5.20938 0.0831765 5.41019 0.231232 5.55824C0.379287 5.7063 0.580092 5.78947 0.789474 5.78947H4.21053V9.21053C4.21053 9.41991 4.2937 9.62071 4.44176 9.76877C4.58981 9.91682 4.79062 10 5 10C5.20938 10 5.41019 9.91682 5.55824 9.76877C5.7063 9.62071 5.78947 9.41991 5.78947 9.21053V5.78947H9.21053C9.41991 5.78947 9.62071 5.7063 9.76877 5.55824C9.91682 5.41019 10 5.20938 10 5C10 4.79062 9.91682 4.58981 9.76877 4.44176C9.62071 4.2937 9.41991 4.21053 9.21053 4.21053H5.78947V0.789474Z"
                          fill="currentColor"
                        />
                      </svg>
                      Add user
                    </Button>
                  </Form>
                </Row>
              </CardBody>
            </Card>
          )}
        </Formik>
        <div className="team-table">
          <Card className="mb-3">
            {teamMeberList?.length > 0 ? (
              <Table
                hover
                responsive
                striped
                className="m-0"
                style={{ border: '1px solid #edefff' }}
              >
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Mobile No.</th>
                    <th>Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {teamMeberList?.map((value) => {
                    return (
                      <tr>
                        <td>{value?.fullName}</td>
                        <td>{value?.email}</td>
                        <td>{value?.phoneNumber}</td>
                        <td>
                          <Label
                            className={`badge role-badge ${setRoleStatusColor(
                              value.role?.roleName,
                            )}`}
                          >
                            {value?.role?.roleName}
                          </Label>
                        </td>
                        <td className="text-center">
                          <Button
                            color="link"
                            className="text-danger"
                            onClick={async () => {
                              const response = await commonConfirmBox(
                                'Do you want to delete team user?',
                              )
                              if (response) {
                                props.deleteTeamMember({
                                  teamMemberId: value?.TeamMember?.id,
                                })
                              }
                            }}
                          >
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              {' '}
                              <path
                                d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />{' '}
                              <path
                                d="M1.3335 4H14.6668"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />{' '}
                              <path
                                d="M6.3335 7.33325V12.3333"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />{' '}
                              <path
                                d="M9.6665 7.33325V12.3333"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />{' '}
                              <path
                                d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="no-data-found">
                <svg
                  width="96"
                  height="114"
                  viewBox="0 0 96 114"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                    fill="#4F2D7F"
                    fillOpacity="0.12"
                  />
                  <circle
                    cx="57.5592"
                    cy="67.2599"
                    r="27.36"
                    fill="#4F2D7F"
                    fillOpacity="0.3"
                    stroke="#4F2D7F"
                    strokeWidth="5.2"
                  />
                  <path
                    d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                    stroke="#4F2D7F"
                    strokeWidth="5.2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M92.8968 102.6L79.2168 88.9204"
                    stroke="#4F2D7F"
                    strokeWidth="5.2"
                    strokeLinecap="round"
                  />
                </svg>
                <h1>No Records Found</h1>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ teamCreationReducer }) => {
  let teamLeadList = []
  let userList = []
  let isCreateTeam = false
  let teamMeberList = []

  if (teamCreationReducer?.teamLeadApiResponse) {
    teamLeadList = teamCreationReducer?.teamLeadApiResponse?.map((opt) => ({
      value: opt.id,
      label: opt.teamName,
    }))
  }
  if (teamCreationReducer?.teamUserApiResponse) {
    userList = teamCreationReducer?.teamUserApiResponse?.map((opt) => ({
      value: opt.id,
      label: opt.fullName,
    }))
  }
  if (teamCreationReducer.createTeamApiResponse) {
    isCreateTeam = teamCreationReducer.createTeamApiResponse
  }

  if (teamCreationReducer?.getTeamMeberListApiResponse) {
    teamMeberList = teamCreationReducer?.getTeamMeberListApiResponse
  }

  return {
    teamLeadList: teamLeadList,
    userList: userList,
    isCreateTeam: isCreateTeam,
    teamMeberList: teamMeberList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTeamLead: () => {
      dispatch(getTeamLeadRequest())
    },
    getTeamUser: (data) => {
      dispatch(getTeamUserRequest(data))
    },
    createTeam: (data) => {
      dispatch(createTeamRequest(data))
    },
    cleareCreateTeam: (data) => {
      dispatch(cleareCreateTeamResponse(data))
    },
    getTeamMemberList: (data) => {
      dispatch(getTeamMemberListRequest(data))
    },
    deleteTeamMember: (data) => {
      dispatch(deleteTeamMemberRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamCreation)
