import { useEffect, useRef, useState, React, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Col,
  FormGroup,
  Row,
} from 'reactstrap'
import {
  clearReportDownloadListResponse,
  createReportDownloadApproverRequest,
  getReportDownloadListRequest,
} from 'store/report/action'
import { getInitials, randomColor } from 'helpers/generalUtils'
import {
  formatDateForApi,
  getDateObjectWithValueFromMoment,
  getDateTimeFromDateTime,
} from 'helpers/DataTimeHelpers'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { Field, Form, Formik } from 'formik'
import DatePicker from 'react-datepicker'
import { DATE_FORMAT_DATEPICKER } from 'constant/commonConstants'

import Toast from 'react-bootstrap/Toast'

const ReportRequest = (props) => {
  const [reportDownloadList, setReportDownloadList] = useState([])
  const [filterType, setFilterType] = useState('All')
  const [page, setPage] = useState(0)
  const loader = useRef(null)
  const [searchText, setSearchText] = useState('')

  const [DateFilter, setDateFilter] = useState('')

  const [showDropdown, setShowDropdown] = useState(false)
  const filterToggle = () => setShowDropdown(!showDropdown)

  const handleObserver = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting) {
      setPage((prev) => prev + 1)
    }
  }, [])

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])

  const getSearchRequest = (newSearch, searchvalues, filterValues) => {
    let SearchRequest = {
      offset: newSearch ? 0 : props.reportDownloadList?.length,
      filterType: filterType,
      date: filterValues
        ? DateFilter
          ? formatDateForApi(DateFilter)
          : ''
        : '',
    }
    if (searchText.trim().length > 0 && searchvalues) {
      SearchRequest = { ...SearchRequest, searchText: searchText }
    }
    return SearchRequest
  }

  useEffect(() => {
    if (DateFilter) {
      props.clearReportDownloadList()
      props.getReportDownloadList(getSearchRequest(true, null, DateFilter))
    } else {
      props.getReportDownloadList(getSearchRequest(false, searchText))
    }
  }, [page, DateFilter])

  useEffect(() => {
    props.getReportDownloadList(getSearchRequest(false, searchText, DateFilter))
  }, [filterType])
  useEffect(() => {
    const key = 'id'

    // Create a copy of the reportDownloadList using slice
    const arrayCopy = props.reportDownloadList?.slice()

    const arrayUniqueByKey = [
      ...new Map(arrayCopy?.map((item) => [item[key], item])).values(),
    ]

    // Sort the copied array without modifying the original
    const sortedArray = arrayUniqueByKey
      ?.slice()
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))

    // Set the sorted array to state
    setReportDownloadList(sortedArray)
  }, [props.reportDownloadList])

  const Filter = (values) => {
    if (filterType !== values) {
      props.clearReportDownloadList()
    }
    setFilterType(values)
  }

  useEffect(() => {
    return () => {
      setPage(0)
      props.clearReportDownloadList()
    }
  }, [])

  return (
    <>
      <div className="container">
        <div className="section-head">
          <div>
            <h3>Report Download Requests</h3>
          </div>
          <div className="header-action-item">
            <Formik initialValues={{ date: '' }} enableReinitialize={true}>
              {({ values, setFieldValue }) => (
                <>
                  <div className="search_box">
                    <Button
                      className="p-0"
                      data-tooltip-content={'Search'}
                      color="link"
                      onClick={() => {
                        //setPage(0)
                        props.clearReportDownloadList()
                        props.getReportDownloadList(
                          getSearchRequest(true, searchText),
                        )
                      }}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
                      </svg>
                    </Button>

                    <Field
                      name="cliSearch"
                      type="search"
                      onChange={(e) => {
                        setSearchText(e.target.value)
                        if (e.target.value?.length <= 0) {
                          props.clearReportDownloadList()
                          props.getReportDownloadList(
                            getSearchRequest(true, ''),
                          )
                        }
                      }}
                      onKeyDown={(evt) => {
                        if (evt.key === 'Enter' && !evt.shiftKey) {
                          props.clearReportDownloadList()
                          props.getReportDownloadList(
                            getSearchRequest(true, searchText),
                          )
                        }
                      }}
                      placeholder="Search box"
                      value={searchText}
                    />
                  </div>
                  <div className="filter_btn">
                    <a
                      id="dropdown-basic"
                      className={'filter_icon btn btn-primary'}
                      onClick={() => {
                        filterToggle()
                      }}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2.66669"
                          y="4"
                          width="26.6667"
                          height="12"
                          rx="2"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M21.3334 24V24C21.3334 24.62 21.3334 24.93 21.2652 25.1843C21.0803 25.8745 20.5412 26.4136 19.851 26.5985C19.5967 26.6667 19.2867 26.6667 18.6667 26.6667H13.3334C12.7134 26.6667 12.4034 26.6667 12.1491 26.5985C11.4589 26.4136 10.9198 25.8745 10.7348 25.1843C10.6667 24.93 10.6667 24.62 10.6667 24V24"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M25.3334 18.6666V18.6666C25.3334 19.2866 25.3334 19.5966 25.2652 19.8509C25.0803 20.5411 24.5412 21.0802 23.851 21.2652C23.5967 21.3333 23.2867 21.3333 22.6667 21.3333H9.33335C8.71337 21.3333 8.40338 21.3333 8.14905 21.2652C7.45887 21.0802 6.91977 20.5411 6.73483 19.8509C6.66669 19.5966 6.66669 19.2866 6.66669 18.6666V18.6666"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                      </svg>
                    </a>

                    <Toast
                      className="reportrequest_filter report-request-filter"
                      animation={false}
                      show={showDropdown}
                      onClose={() => {
                        filterToggle()
                        setFieldValue('date', null)
                        setDateFilter(null)

                        //props.getReportDownloadList(getSearchRequest(false))
                      }}
                    >
                      <Toast.Header className="border-0 pb-1">
                        <strong className="me-auto mb-0 separate-head">
                          Filter
                        </strong>
                      </Toast.Header>
                      <Toast.Body className="pt-1">
                        <Form className="form-horizontal form-login ">
                          <Row>
                            <FormGroup className="col mb-0">
                              <DatePicker
                                placeholderText="Select Date"
                                selected={
                                  values?.date
                                    ? getDateObjectWithValueFromMoment(
                                      values?.date,
                                    )
                                    : null
                                }
                                maxDate={new Date()}
                                className="form-control"
                                name="date"
                                dateFormat={DATE_FORMAT_DATEPICKER}
                                onChange={(opt) => {
                                  if (opt == null) {
                                    props.clearReportDownloadList()
                                    setFieldValue('date', null)
                                    setDateFilter(null)
                                  }
                                  setDateFilter(opt)

                                  setFieldValue('date', opt)
                                }}
                                isClearable
                              />
                            </FormGroup>
                          </Row>
                        </Form>
                      </Toast.Body>
                    </Toast>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
        <Row>
          <Col className="d-flex justify-content-between align-items-end">
            <Nav tabs className="reports_tab request_tab">
              <>
                {/* <NavItem>
                  <NavLink
                    className={filterType == 'All' && 'active'}
                    onClick={() => {
                      Filter('All')
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={filterType == 'Pending' && 'active'}
                    onClick={() => {
                      Filter('Pending')
                    }}
                  >
                    Pending
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={filterType == 'Approved' && 'active'}
                    onClick={() => {
                      Filter('Approved')
                    }}
                  >
                    Approved
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={filterType == 'Rejected' && 'active'}
                    onClick={() => {
                      Filter('Rejected')
                    }}
                  >
                    Rejected
                  </NavLink>
                </NavItem> */}
                <NavItem className="report-reset-btn">
                  <Button
                    className="btn btn-link"
                    onClick={() => {
                      setPage(0)
                      props.clearReportDownloadList()
                    }}
                  >
                    <i className="fa fa-refresh"></i>
                  </Button>
                </NavItem>
              </>
            </Nav>
          </Col>
        </Row>

        <TabContent
          activeTab="1"
          className="report_tab-content request_tab-content"
        >
          <TabPane tabId="1">
            <Row>
              {reportDownloadList?.map((item, index) => {
                return (
                  <Col lg={6} key={index}>
                    <FormGroup check inline className="m-0 p-0 w-100 h-100">
                      {/* For Multiple item selection need replace below div to label  */}
                      <div
                        className={
                          item.status != 'Pending'
                            ? item.status == 'Approve'
                              ? 'approve_checkcard approved'
                              : 'approve_checkcard rejected'
                            : 'approve_checkcard'
                        }
                      >
                        {/* Add "approve_checkcard approved" class when request is approved */}
                        {/* Add "approve_checkcard rejected" class when request is approved */}
                        {/* <input type="checkbox" className="card__checkbox" /> */}

                        {/* <Input className="card__checkbox" type="checkbox" /> */}

                        <div className="card__content">
                          <div>
                            <div className="approve_head">
                              <h2>
                                <span style={{ background: randomColor() }}>
                                  {getInitials(
                                    item?.subscriberName
                                      ? item?.subscriberName
                                      : item?.userName,
                                  )}
                                </span>
                                <p>
                                  {item?.subscriberName
                                    ? item?.subscriberName
                                    : item?.userName}
                                  <small>
                                    {item?.subscriberEmail
                                      ? item?.subscriberEmail
                                      : item?.userEmail}
                                  </small>
                                </p>
                              </h2>
                              <p>
                                {' '}
                                {item?.createdDate
                                  ? getDateTimeFromDateTime(item?.createdDate)
                                  : '-'}
                              </p>
                            </div>
                            <p className="report_text">{item?.reportTitle}</p>
                          </div>
                          {/* <div>
                            <div className="approve_btn">
                              <Button
                                color="danger"
                                onClick={async () => {
                                  const response = await commonConfirmBox(
                                    'Do you want to reject this download request?',
                                  )
                                  if (response) {
                                    props.createReportDownloadApprover({
                                      createdBy: item?.subscriberId
                                        ? item?.subscriberId
                                        : item?.userId,
                                      reportId: item?.reportId,
                                      status: 'Reject',
                                      requestId: item?.id,
                                      email: item?.subscriberEmail
                                        ? item?.subscriberEmail
                                        : item?.userEmail,
                                      filterType: filterType
                                    })
                                    setSearchText('')
                                  }
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="9"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                  />
                                  <path
                                    d="M18 18L6 6"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                  />
                                </svg>
                                Reject
                              </Button>
                              <Button
                                color="success"
                                onClick={async () => {
                                  const response = await commonConfirmBox(
                                    'Do you want to approve this download request?',
                                  )
                                  if (response) {
                                    props.createReportDownloadApprover({
                                      createdBy: item?.subscriberId
                                        ? item?.subscriberId
                                        : item?.userId,

                                      reportId: item?.reportId,
                                      status: 'Approve',
                                      requestId: item?.id,
                                      email: item?.subscriberEmail
                                        ? item?.subscriberEmail
                                        : item?.userEmail,
                                      filterType: filterType
                                    })
                                    setSearchText('')
                                  }
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                                Approve
                              </Button>
                            </div>
                            <div className="approved_note">
                              <p className="approved_title">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                                Approved by <span> {item?.fullName}</span>
                              </p>
                              <p className="rejected_title">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="9"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                  />
                                  <path
                                    d="M18 18L6 6"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                  />
                                </svg>
                                Rejected by <span>{item?.fullName}</span>
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                )
              })}
              <div ref={loader} />

              {(props.reportDownloadList == undefined ||
                props.reportDownloadList?.length <= 0) && (
                  <div className="no-data-found">
                    <svg
                      width="96"
                      height="114"
                      viewBox="0 0 96 114"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                        fill="#4F2D7F"
                        fillOpacity="0.12"
                      />
                      <circle
                        cx="57.5592"
                        cy="67.2599"
                        r="27.36"
                        fill="#4F2D7F"
                        fillOpacity="0.3"
                        stroke="#4F2D7F"
                        strokeWidth="5.2"
                      />
                      <path
                        d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                        stroke="#4F2D7F"
                        strokeWidth="5.2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M92.8968 102.6L79.2168 88.9204"
                        stroke="#4F2D7F"
                        strokeWidth="5.2"
                        strokeLinecap="round"
                      />
                    </svg>

                    <h1>No Requests Found</h1>
                  </div>
                )}
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}

const mapStateToProps = ({ reportReducer }) => {
  return {
    reportDownloadList: reportReducer.reportDownloadList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReportDownloadList: (data) => {
      dispatch(getReportDownloadListRequest(data))
    },
    clearReportDownloadList: () => {
      dispatch(clearReportDownloadListResponse())
    },

    createReportDownloadApprover: (data) => {
      dispatch(createReportDownloadApproverRequest(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportRequest)
