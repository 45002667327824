import produce from 'immer'
import {
  CREATE_TEMPLATE_RESPONSE,
  UPDATE_EMAIL_TEMPLATE_RESPONSE,
  GET_CUSTOM_EMAIL_FIELD_LIST_RESPONSE,
  // GET_ROLE_LIST_RESPONSE,
  GET_TEMPLATE_DETAILS_LIST_BY_ID_RESPONSE,
  GET_TEMPLATE_LIST_RESPONSE,
  UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE,
} from '../emailTemplate/actionType'

export const initialState = {
  getTemplateList: [],
  // rolePermission: [],
  createTemplateApiResponse: null,
  customEmailFieldList: [],
  emailDetailsList: [],
  getRoleList: [],
  editEmailTemaplteList: [],
  emailTemaplteStatusList: null,
}
//country function for operation
export const TemplateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TEMPLATE_LIST_RESPONSE: {
        draft.getTemplateList = action.payload.data.list
        break
      }

      case CREATE_TEMPLATE_RESPONSE: {
        draft.createTemplateApiResponse = action.payload.data
        break
      }

      case GET_CUSTOM_EMAIL_FIELD_LIST_RESPONSE: {
        draft.customEmailFieldList = action.payload.data
        break
      }

      case GET_TEMPLATE_DETAILS_LIST_BY_ID_RESPONSE: {
        draft.emailDetailsList = action.payload.data
        break
      }

      case UPDATE_EMAIL_TEMPLATE_RESPONSE: {
        draft.editEmailTemaplteList = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }
      case UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE: {
        draft.emailTemaplteStatusList = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      // case GET_ROLE_LIST_RESPONSE: {
      //   draft.getRoleList = action.payload.data.list
      //   break
      // }

      default:
        state = { ...state }
        break
    }
  })
