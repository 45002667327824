import produce from 'immer'
import { GET_USER_DETAILS_RESPONSE } from './actionType'
export const initialState = {
  userAboutApiResponse: null,
}
export const UserAboutReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_DETAILS_RESPONSE: {
        draft.userAboutApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }

        break
      }

      default:
        state = { ...state }
        break
    }
  })
