import produce from 'immer'
import {
  CLEAR_SUBSCRIBER_QUERY_RESPONSE,
  CLEAR_USER_REPORT_DETAILS_RESPONSE,
  CLEAR_USER_REPORT_LIST_RESPONSE,
  CREATE_SUBSCRIBER_QUERY_RESPONSE,
  GET_CATEGORIES_RESPONSE,
  GET_USER_REPORT_DETAILS_RESPONSE,
  GET_USER_REPORT_LIST_RESPONSE,
} from './actionType'

let reportListOffset = []
export const initialState = {
  categorieList: null,
  reportList: [],

  reportDetails: null,
  createtSubscriberQueryApiResponse: null,
}

export const userReportReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_CATEGORIES_RESPONSE:
        draft.categorieList = action.payload.data
        break

      case GET_USER_REPORT_DETAILS_RESPONSE:
        draft.reportDetails = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case GET_USER_REPORT_LIST_RESPONSE: {
        if (!reportListOffset.includes(action.payload.offset)) {
          reportListOffset.push(action.payload.offset)
          draft.reportList = draft.reportList.concat(action.payload.response)
        }
        break
      }
      case CLEAR_USER_REPORT_LIST_RESPONSE: {
        reportListOffset = []
        draft.reportList = []
        break
      }

      case CREATE_SUBSCRIBER_QUERY_RESPONSE:
        draft.createtSubscriberQueryApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEAR_SUBSCRIBER_QUERY_RESPONSE:
        draft.createtSubscriberQueryApiResponse = null
        break
      case CLEAR_USER_REPORT_DETAILS_RESPONSE:
        draft.reportDetails = {
          data: [],
          message: null,
          responseType: null,
        }
        break
      default:
        state = { ...state }
        break
    }
  })
