export const GET_QUERY_REQUEST = 'GET_QUERY_REQUEST'
export const GET_QUERY_RESPONSE = 'GET_QUERY_RESPONSE'

export const GET_ADMIN_CONVERSATION_DETAILS_QUERY_REQUEST =
  'GET_ADMIN_CONVERSATION_DETAILS_QUERY_REQUEST'
export const GET_ADMIN_CONVERSATION_DETAILS_QUERY_RESPONSE =
  'GET_ADMIN_CONVERSATION_DETAILS_QUERY_RESPONSE'

export const CLEAR_QUERY_LIST_RESPONSE = 'CLEAR_QUERY_LIST_RESPONSE'

export const CREATE_ADMIN_RESEND_QUERY_REQUEST =
  'CREATE_ADMIN_RESEND_QUERY_REQUEST'
export const CREATE_ADMIN_RESEND_QUERY_RESPONSE =
  'CREATE_ADMIN_RESEND_QUERY_RESPONSE'

export const CLEARE_ADMIN_RESEND_QUERY_RESPONSE =
  'CLEARE_ADMIN_RESEND_QUERY_RESPONSE'
