import React from 'react'
import laptop from '../../../src/images/laptop.png'

export default function PageNotFound404() {
  return (
    <>
      <div className="wrapper error_wrapper">
        <main className="container">
          <div className="row error_main">
            <div className="col-md-4 errorcontent">
              <article>
                <h1>OOPS! You Don't want to be here...</h1>
                {/* <a href="../Login/AdminLogin" className="btn" role="button">Home</a> */}
              </article>
            </div>
            <div className="col-md-8 laptop hidden-xs">
              <img src={laptop} alt="laptop" />
              <div className="glitch">404</div>
            </div>
            {/* <div className="laptop visible-xs">
            <img src={laptopxs} alt="laptop xs" />
	  			</div> */}
          </div>
        </main>
      </div>
    </>
  )
}
