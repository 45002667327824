import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { getRoleListRequest } from '../../../store/master/role/action'
import { FormGroup, Label } from 'reactstrap'
import { Field, Formik } from 'formik'
import Select from 'react-select'

const RoleSelector = (props) => {
  const { values, setValue, id, validate } = props
  useEffect(() => {
    props.getRoleListRequest()
  }, [])

  return (
    <Formik>
      <FormGroup className="col-4 mb-0">
        <Field
          component={Select}
          menuPlacement="auto"
          name="roleId"
          id="roleId"
          className="form-control selectbox"
          options={props?.RoleList}
          onChange={(opt) => {
            setValue(id, opt.value)
          }}
          value={props?.RoleList?.filter(
            (option) => option.value === values.roleId,
          )}
        />
        <Label className="form-label">
          Role
          <span className="text-danger required">*</span>
        </Label>

        {/* Passed from parent component */}
        {validate}
      </FormGroup>
    </Formik>
  )
}

//redux for addcountry and countrylist
const mapStateToProps = ({ roleReducer }) => {
  let RoleList
  if (roleReducer.getRoleList && roleReducer.getRoleList?.length > 0) {
    RoleList = roleReducer.getRoleList.map((opt) => ({
      value: opt.id,
      label: opt.roleName,
    }))
  }

  return {
    RoleList: RoleList,
  }
}

//country redux for addCountry
const mapDispatchToProps = (dispatch) => {
  return {
    getRoleListRequest: (getRoleList) => {
      dispatch(getRoleListRequest(getRoleList))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleSelector)
