import { Field } from 'formik'
import CustomPagination from './CustomPagination'
import React, { useState } from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import RoleSelectorFormik from 'components/Selector/RoleSelectorFormik'
import LawSelectorFormik from 'components/Selector/LawSelectorFormik'
import SectionSelectorFormik from 'components/Selector/SectionSelectorFormik'
const ViewTable = (props) => {
  const [customFilter, setCustomFilter] = useState(false)

  const {
    hedingName,
    showHeding = true,
    buttonName = 'Create',
    component,
    href,
    totalRecords,
    updateData,
    columns,
    data,
    dataPerPage,
    isUserModule,
    isLawModule,
    isSectionModule,
    isSubscriberModule,
    tableRefresh,
    currentPage,
    setCurrentPage,
    setDataPerPage,
  } = props
  const [search, setSearch] = useState('')

  const paginate = (pageNumber, dataPerPageFormPaginate) => {
    const Data = {
      searchText: '',
      pagination: {
        limit: dataPerPageFormPaginate || 10,
        page: pageNumber,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },

      filter: {},
    }
    setDataPerPage(dataPerPage)
    setCurrentPage(pageNumber)
    updateData(Data)
    setSearch('')
  }

  const handleCancelFilter = (e) => {
    setSearch('')
    props.getRefreshList(true)
    setCustomFilter(e ? false : true)
  }
  return (
    <div className="container">
      <div className="section-head">
        {showHeding && (
          <div>
            <h3>{hedingName}</h3>
          </div>
        )}

        <div className="header-action-item">
          {!isSubscriberModule && (
            <Link to={href}>
              <Button className="creat_btn" component={component}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 2L11 20"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M20 11L2 11"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
                {buttonName}
              </Button>
            </Link>
          )}

          <div className="search_box">
            <Button
              color="link"
              className="p-0"
              // data-tooltip-content={'Search'}
              onClick={() => {
                if (search?.length > 0) {
                  setCurrentPage(1)
                }
                updateData({
                  searchText: search?.length > 0 ? search : '',
                  pagination: {
                    limit: dataPerPage,
                    page: 0,
                    orderKey: 'createdDate',
                    orderBy: 'ASC',
                  },

                  filter: {},
                })
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
              </svg>
            </Button>

            {search && (
              <Button
                color="link"
                className="p-0 close-btn"
                // title="Cancel"
                onClick={() => {
                  setSearch('')
                  updateData({
                    searchText: '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },

                    filter: {},
                  })
                }}
              >
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M31.8807 4.13589C32.2693 3.73354 32.4843 3.19466 32.4794 2.6353C32.4746 2.07595 32.2502 1.54088 31.8547 1.14535C31.4591 0.74981 30.9241 0.525449 30.3647 0.520588C29.8054 0.515728 29.2665 0.730757 28.8641 1.11936L16.5057 13.4778L4.14734 1.11936C3.74499 0.730757 3.2061 0.515728 2.64675 0.520588C2.08739 0.525449 1.55233 0.74981 1.15679 1.14535C0.761254 1.54088 0.536893 2.07595 0.532032 2.6353C0.527172 3.19466 0.742201 3.73354 1.1308 4.13589L13.4892 16.4943L1.1308 28.8527C0.92705 29.0495 0.764527 29.2849 0.652721 29.5452C0.540915 29.8054 0.482065 30.0854 0.479603 30.3686C0.477142 30.6519 0.531118 30.9328 0.638384 31.195C0.74565 31.4572 0.904057 31.6954 1.10436 31.8957C1.30467 32.096 1.54286 32.2544 1.80504 32.3616C2.06721 32.4689 2.34813 32.5229 2.63139 32.5204C2.91466 32.518 3.19459 32.4591 3.45487 32.3473C3.71514 32.2355 3.95054 32.073 4.14734 31.8692L16.5057 19.5108L28.8641 31.8692C29.2665 32.2578 29.8054 32.4729 30.3647 32.468C30.9241 32.4631 31.4591 32.2388 31.8547 31.8432C32.2502 31.4477 32.4746 30.9126 32.4794 30.3533C32.4843 29.7939 32.2693 29.255 31.8807 28.8527L19.5223 16.4943L31.8807 4.13589Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
            )}

            <Field
              name="searchbox"
              onChange={(e) => {
                setSearch(e.target.value)

                if (e.target.value?.length <= 0) {
                  updateData({
                    searchText: '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },

                    filter: {},
                  })
                }
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter' && !evt.shiftKey) {
                  if (search?.length > 0) {
                    setCurrentPage(1)
                  }
                  updateData({
                    searchText: search?.length > 0 ? search : '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },

                    filter: {},
                  })
                }
              }}
              placeholder="Search box"
              value={search}
            />
          </div>
          {!isSubscriberModule &&
            (isUserModule || isLawModule || isSectionModule) && (
              <Dropdown
                className="my-tooltip filter_btn"
                data-tooltip-content={'Apply Filter'}
                onClick={() => {
                  setCustomFilter(true)
                }}
              >
                <Dropdown.Toggle id="dropdown-basic">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="2.66669"
                      y="4"
                      width="26.6667"
                      height="12"
                      rx="2"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                    <path
                      d="M21.3334 24V24C21.3334 24.62 21.3334 24.93 21.2652 25.1843C21.0803 25.8745 20.5412 26.4136 19.851 26.5985C19.5967 26.6667 19.2867 26.6667 18.6667 26.6667H13.3334C12.7134 26.6667 12.4034 26.6667 12.1491 26.5985C11.4589 26.4136 10.9198 25.8745 10.7348 25.1843C10.6667 24.93 10.6667 24.62 10.6667 24V24"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                    <path
                      d="M25.3334 18.6666V18.6666C25.3334 19.2866 25.3334 19.5966 25.2652 19.8509C25.0803 20.5411 24.5412 21.0802 23.851 21.2652C23.5967 21.3333 23.2867 21.3333 22.6667 21.3333H9.33335C8.71337 21.3333 8.40338 21.3333 8.14905 21.2652C7.45887 21.0802 6.91977 20.5411 6.73483 19.8509C6.66669 19.5966 6.66669 19.2866 6.66669 18.6666V18.6666"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                  </svg>
                </Dropdown.Toggle>

                {/* Uncomment this when advanced filter is required  */}
                {/* <AdvancedFilter /> */}
              </Dropdown>
            )}
        </div>
      </div>

      {/* Header Custom Search box input  */}

      {isUserModule && customFilter && (
        <Card className="filter_section">
          <CardBody>
            <h6 className="separate-head">Filter</h6>
            <RoleSelectorFormik
              getListByRoleIdFilter={(roleid) => {
                props.getFilterDataByRole(roleid)
              }}
              cancelFilterSection={(e) => handleCancelFilter(e)}
            />
          </CardBody>
        </Card>
      )}

      {isLawModule && customFilter && (
        <Card className="filter_section">
          <CardBody>
            <h6 className="separate-head">Filter </h6>
            <LawSelectorFormik
              getListByLawIdFilter={(cat, subcat) => {
                props.getFilterDataByLaw(cat, subcat)
              }}
              type={'CONTENT'}
              cancelFilterSection={(e) => handleCancelFilter(e)}
            />
          </CardBody>
        </Card>
      )}

      {isSectionModule && customFilter && (
        <Card className="filter_section">
          <CardBody>
            <h6 className="separate-head">Filter</h6>
            <SectionSelectorFormik
              getListBySectionIdFilter={(lawid) => {
                props.getFilterDataBySection(lawid)
              }}
              cancelFilterSection={(e) => handleCancelFilter(e)}
            />
          </CardBody>
        </Card>
      )}

      {!tableRefresh && (
        <div className="table-responsive">
          <BootstrapTable
            id="myTable"
            className="small table-sm"
            striped={true}
            bootstrap4
            keyField="id"
            data={data}
            columns={columns}
            bordered={true}
          />
        </div>
      )}

      {totalRecords > 0 ? (
        <CustomPagination
          dataPerPage={dataPerPage}
          totalData={totalRecords}
          setDataPerPage={setDataPerPage}
          setCurrentPage={setCurrentPage}
          paginate={paginate}
          currentPage={currentPage}
        />
      ) : (
        <div className="no-record-found">
          <h4>No Records Found</h4>
        </div>
      )}
    </div>
  )
}

export { ViewTable }
