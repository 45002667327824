import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_RESPONSE,
  GET_USER_REPORT_LIST_REQUEST,
  GET_USER_REPORT_LIST_RESPONSE,
  GET_USER_REPORT_DETAILS_REQUEST,
  GET_USER_REPORT_DETAILS_RESPONSE,
  CLEAR_USER_REPORT_LIST_RESPONSE,
  CREATE_SUBSCRIBER_QUERY_REQUEST,
  CREATE_SUBSCRIBER_QUERY_RESPONSE,
  CLEAR_SUBSCRIBER_QUERY_RESPONSE,
  CREATE_USER_REPORT_DOWNLOAD_REQUEST,
  CREATE_USER_REPORT_DOWNLOAD_RESPONSE,
  CLEAR_USER_REPORT_DETAILS_RESPONSE,
} from './actionType'

export const getCategoriesRequest = () => {
  return {
    type: GET_CATEGORIES_REQUEST,
  }
}

export const getCategoriesResponse = (data) => {
  return {
    type: GET_CATEGORIES_RESPONSE,
    payload: { data },
  }
}

export const getUserReportListRequest = (data) => {
  return {
    type: GET_USER_REPORT_LIST_REQUEST,
    payload: { data },
  }
}

export const getUserReportListResponse = (response, offset) => {
  return {
    type: GET_USER_REPORT_LIST_RESPONSE,
    payload: { response, offset },
  }
}

export const getUserReportDetailsRequest = (id) => {
  return {
    type: GET_USER_REPORT_DETAILS_REQUEST,
    payload: { id },
  }
}

export const getUserReportDetailsResponse = (message, responseType, data) => {
  return {
    type: GET_USER_REPORT_DETAILS_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const clearUserReportListResponse = () => {
  return {
    type: CLEAR_USER_REPORT_LIST_RESPONSE,
  }
}

export const createtSubscriberQueryRequest = (data) => {
  return {
    type: CREATE_SUBSCRIBER_QUERY_REQUEST,
    payload: { data },
  }
}

export const createtSubscriberQueryResponse = (message, responseType) => {
  return {
    type: CREATE_SUBSCRIBER_QUERY_RESPONSE,
    payload: { message, responseType },
  }
}

export const clearSubscriberQueryResponse = () => {
  return {
    type: CLEAR_SUBSCRIBER_QUERY_RESPONSE,
  }
}

export const createUserReportDownloadRequest = (data) => {
  return {
    type: CREATE_USER_REPORT_DOWNLOAD_REQUEST,
    payload: { data },
  }
}

export const getUserReportDownloadResponse = (message, responseType) => {
  return {
    type: CREATE_USER_REPORT_DOWNLOAD_RESPONSE,
    payload: { message, responseType },
  }
}

export const clearUserReportDetailsResponse = () => {
  return {
    type: CLEAR_USER_REPORT_DETAILS_RESPONSE,
  }
}
