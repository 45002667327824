import React, { useState } from 'react'
import { connect } from 'react-redux'
import subscriptionImg from 'images/subscribe_img2.png'
import logosm from 'images/logo.webp'
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import {
  clearSubscribeResponse,
  otpVerificationRequest,
  resendOtpVerificationRequest,
  subscribeFaqRequest,
} from 'store/frontEnd/home/action'
import SimpleReactValidator from 'simple-react-validator'
import { useRef, useEffect } from 'react'
import OtpTimer from 'otp-timer'
import ReCAPTCHA from 'react-google-recaptcha'
import { CodeComponent } from './helper'
import { getsubscriberData } from './Helper/Helper'
import { isValidNumberField } from 'helpers/SimpleReactValidators'
import { __t } from 'i18n/translator'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getIpAddress } from 'pages/Faq/CommonFunction'
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER } from 'constant/commonConstants'
const Subscribe = (props) => {
  const isCookies = Cookies.get('isTermsConditions');

  const initialValues = {
    recaptcha: '',
    email: '',
    name: '',
    number: '',
    code: '',
    isTermsConditions: isCookies ? true : false
  }
  const { subscribe, subscribeToggle } = props
  const [isOtpSection, setisOtpSection] = useState('')
  const [subscribeInitialValues, setInitialValues] = useState(initialValues)
  const [isOtp, setIsOtp] = useState(false)
  const recaptchaRef = useRef();
  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidNumberField()),
    }),
  )
  const [isCode, setCode] = useState(true)
  useEffect(() => {
    if (!isCode) {
      setCode(true)
    }
  }, [isCode])
  useEffect(() => {
    if (isOtpSection == '') {
      setTimeout(() => {
        loadCaptchaEnginge(6);
      }, 0);
    }
  }, [isOtpSection])

  useEffect(() => {
    const isCookies = Cookies.get('isTermsConditions');
    if (isCookies) {
      setInitialValues({ ...subscribeInitialValues, isTermsConditions: true })
    }
    if (subscribe) {
      setTimeout(() => {
        loadCaptchaEnginge(6);
      }, 0);

      setInitialValues(initialValues)
    }
    //loadCaptchaEnginge(6);
  }, [subscribe]);

  // useEffect(() => {
  //   let timer = null;
  //   const timerDuration = 3 * 60 * 1000 + 30 * 1000;
  //   //const timerDuration = 4000;
  //   if (isOtp) {
  //     timer = setTimeout(() => {
  //       setIsOtp(false);
  //       props?.resendOtpVerificationRequest({
  //         email: props?.subscribeData?.userData?.email
  //           ? props?.subscribeData?.userData?.email
  //           : '',
  //         isVerification: false,
  //       })

  //     }, timerDuration);
  //   }
  //   if (!isOtp) {
  //     clearTimeout(timer);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   }
  // }, [isOtp, subscribe]);

  const formSubmit = async (values, { setFieldValue }) => {
    if (validator.current.allValid()) {
      const disallowedDomains = ['yopmail.com', 'mailinator.com',"gmail.com","yahoo.com","hotmail.com","outlook.com",'mail.com', 'gmx.com', 'yandex.com', 'zoho.com', 'protonmail.com', 'mailinator.com',
      'mail.ru', 'live.com', 'msn.com', 'comcast.net', 'att.net', 'verizon.net',
      'btinternet.com', 'sky.com', 'cox.net', 'charter.net', 'sbcglobal.net', 'bellsouth.net',
      'earthlink.net', 'juno.com'];
      const emailDomain = values.email.split('@')[1];
      if (disallowedDomains.includes(emailDomain)) {
        ApiResponseMessage('Please sign in with official email ID', DANGER);
      }else{
       let ip = await getIpAddress()
      if (!isOtpSection) {
        let isValidateCaptcha = validateCaptcha(values?.user_captcha_input)
        if (!isValidateCaptcha) {
          ApiResponseMessage('Invalid captcha', DANGER)
          return false
        }
        let subscribeModVals = {
          email: values.email,
          name: values.name,
          recaptcha: values.recaptcha,
          address: ip ? ip : "0.0.0.0"
        }
        props.subscribeFaqRequest(subscribeModVals)
        Cookies.set('isTermsConditions', true);
        recaptchaRef.current.reset();
        setFieldValue('recaptcha', '')

      } else {
        let otpModVals = {
          name: values.name,
          otp: values.code,
          email: values.email,
          phoneNumber: values.number,
          address: ip ? ip : "0.0.0.0"
        }
        props.otpVerificationRequest(otpModVals)
      }
      }

     
    } else {
      validator.current.showMessages()
    }
  }

  const resendCode = () => {
    props?.resendOtpVerificationRequest({
      email: props?.subscribeData?.userData?.email
        ? props?.subscribeData?.userData?.email
        : '',
    })
    setIsOtp(true)
  }

  useEffect(() => {
    if (
      props.isSubscribedApiResponse &&
      props?.subscribeData?.isOtpScreenVisible
    ) {
      setisOtpSection(props.isSubscribedApiResponse)
    } else {
      setisOtpSection(props?.subscribeData?.isOtpScreenVisible)
    }

    if (props?.subscribeData?.userData?.userId) {
      setIsOtp(true)
      setInitialValues({
        ...initialValues,
        name: props?.subscribeData?.userData?.name,
        number: props?.subscribeData?.userData?.contactNo,
        email: props?.subscribeData?.userData?.email,
      })
    }
  }, [props.isSubscribedApiResponse])

  return (
    <Modal
      isOpen={subscribe}
      toggle={subscribeToggle}
      centered={true}
      size="xl"
      className="subscription_modal"
    >
      <ModalBody className="p-0">
        <Button color="link" onClick={() => {
          setInitialValues(initialValues)
          setisOtpSection('')
          subscribeToggle()
        }}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L13 13"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <Row noGutters>
          <Col lg="7">
            <img alt="Subscription image" src={subscriptionImg} className='subscription_img' />
          </Col>
          <Col lg="5" className="ps-4 pe-5">
            <div
              className={`${isOtpSection
                ? ' subscription_form otp_form'
                : 'subscription_form'
                }`}
            >
              <img alt="Grant Thornton" src={logosm} />

              <div className="w-100">
                {!isOtpSection ? (
                  <h1>
                    Sign in now!
                    {/* <small>
                      For all things FAQs, Reports, Articles and resolve your
                      queries.
                    </small> */}
                  </h1>
                ) : (
                  <h1>
                    Verify Code
                    <small>OTP is sent on your registered Email Address</small>
                  </h1>
                )}

                <Formik
                  initialValues={subscribeInitialValues}
                  enableReinitialize={true}
                  onSubmit={formSubmit}
                >
                  {({ values, setValues, setFieldValue, handleSubmit }) => (
                    <Form className={isOtpSection ? 'row d-flex mt-4' : ''}>
                      {!isOtpSection ? (
                        <>
                          <FormGroup className="col-12">
                            <Field
                              className="form-control"
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Enter Email"
                            />
                            <Label className="form-label" htmlFor="Email">
                              Email Address
                            </Label>

                            {validator.current.message(
                              __t('validations.user.email_required'),
                              values.email,
                              'required|email',
                              {
                                messages: {
                                  required: __t(
                                    'validations.user.email_required',
                                  ),
                                  email:
                                    __t(
                                      'validations.common.email_valid'
                                    ),
                                },
                              },
                            )}
                          </FormGroup>
                          <FormGroup className="col-12">
                            <Field
                              name="name"
                              placeholder="Enter your name"
                              className="form-control"
                              type="text"
                            />
                            <Label className="form-label" htmlFor="name">
                              Name
                            </Label>

                            {validator.current.message(
                              __t('validations.common.allow_only_alpha'),
                              values.name,
                              'required|alpha_space',
                              {
                                messages: {
                                  required: __t(
                                    'validations.common.allow_only_alpha_required',
                                  ),
                                  alpha_space: __t(
                                    'validations.common.allow_only_alpha',
                                  ),
                                },
                              },
                            )}
                          </FormGroup>
                          <FormGroup className="col-12">
                            {/* <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                              onChange={(value) => {
                                setValues({ ...values, recaptcha: value })
                              }}
                              onExpired={() => {
                                setValues({ ...values, recaptcha: '' })
                              }}
                            ></ReCAPTCHA> */}
                            <div className='captcha_box'>
                              <div className='captach_text'>
                                <LoadCanvasTemplateNoReload />
                                <Button className='btn btn-link' color='link' title='Reload Captcha' onClick={() => {
                                  loadCaptchaEnginge(6);
                                }}>
                                  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.93077 9.2003C2.00244 4.23968 6.07619 0.25 11.0789 0.25C14.3873 0.25 17.287 1.99427 18.8934 4.60721C19.1103 4.96007 19.0001 5.42199 18.6473 5.63892C18.2944 5.85585 17.8325 5.74565 17.6156 5.39279C16.2727 3.20845 13.8484 1.75 11.0789 1.75C6.8945 1.75 3.50372 5.0777 3.431 9.19817L3.83138 8.80092C4.12542 8.50918 4.60029 8.51105 4.89203 8.80509C5.18377 9.09913 5.18191 9.574 4.88787 9.86574L3.20805 11.5324C2.91565 11.8225 2.44398 11.8225 2.15157 11.5324L0.471757 9.86574C0.177716 9.574 0.17585 9.09913 0.467591 8.80509C0.759331 8.51105 1.2342 8.50918 1.52824 8.80092L1.93077 9.2003ZM18.7864 8.46658C19.0786 8.17781 19.5487 8.17781 19.8409 8.46658L21.5271 10.1333C21.8217 10.4244 21.8245 10.8993 21.5333 11.1939C21.2421 11.4885 20.7673 11.4913 20.4727 11.2001L20.0628 10.7949C19.9934 15.7604 15.9017 19.75 10.8825 19.75C7.56379 19.75 4.65381 18.007 3.0412 15.3939C2.82366 15.0414 2.93307 14.5793 3.28557 14.3618C3.63806 14.1442 4.10016 14.2536 4.31769 14.6061C5.6656 16.7903 8.09999 18.25 10.8825 18.25C15.0887 18.25 18.4922 14.9171 18.5625 10.7969L18.1546 11.2001C17.86 11.4913 17.3852 11.4885 17.094 11.1939C16.8028 10.8993 16.8056 10.4244 17.1002 10.1333L18.7864 8.46658Z" fill="currentColor" /></svg>
                                </Button>
                              </div>
                              <div className='captcha_input'>
                                <Field
                                  autoComplete="off"
                                  name="user_captcha_input"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter captcha value*"
                                />

                              </div>
                            </div>
                            {validator.current.message(
                              __t('validations.common.recaptcha_required'),
                              values.user_captcha_input,
                              'required',
                              {
                                messages: {
                                  required: __t(
                                    'validations.common.recaptcha_required',
                                  ),
                                },
                              },
                            )}
                          </FormGroup>
                          <FormGroup check inline className='d-flex align-items-baseline flex-column ps-0 text-start mb-3 accept-cookie-checkbox'>
                            <div className='d-flex'>
                              <Input id="accept_tearms" type="checkbox" name="Accept cookies" className='me-1 ms-0 form-sm-check' onClick={() => setFieldValue("isTermsConditions", !values.isTermsConditions)} checked={values.isTermsConditions} />
                              <Label check for='accept_tearms'>
                                I have read and accept the <Link target="_blank" to="/terms-conditions" blank title='Terms and Conditions'>Terms and Conditions</Link>
                              </Label>
                            </div>
                            {validator.current.message(
                              __t('validations.common.terms_required'),
                              values.isTermsConditions ? values.isTermsConditions : "",
                              'required',
                              {
                                messages: {
                                  required: __t(
                                    'validations.common.terms_required',
                                  ),
                                },
                              },
                            )}

                          </FormGroup>


                        </>
                      ) : (
                        <>
                          <FormGroup className="col-12">
                            <Field
                              name="name"
                              placeholder="Enter your name"
                              className="form-control"
                              type="text"
                            />
                            <Label className="form-label" htmlFor="name">
                              Name
                            </Label>
                            {/* {validator.current.message(
                              'name',
                              values.name,
                              'required|alpha_space',
                            )} */}

                            {validator.current.message(
                              __t('validations.common.allow_only_alpha'),
                              values.name,
                              'required|alpha_space',
                              {
                                messages: {
                                  required: __t(
                                    'validations.common.allow_only_alpha_required',
                                  ),
                                  alpha_space: __t(
                                    'validations.common.allow_only_alpha',
                                  ),
                                },
                              },
                            )}
                          </FormGroup>

                          {/* <FormGroup className="col-12">
                            <Field
                              name="number"
                              placeholder="Enter your number"
                              className="form-control"
                              type="Number"
                              onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}

                            />
                            <Label className="form-label" htmlFor="name">
                              Contact No.
                            </Label>
                            {validator.current.message(
                              'number',
                              values.number,
                              'required|isValidNumber:',
                            )}
                          </FormGroup> */}

                          {props.isOtpScreenVisible && isCode && (
                            <FormGroup className="col-12 form-otp">
                              <Field
                                as={CodeComponent}
                                digits={6}
                                name="code"
                                onChange={(opt) => {
                                  setFieldValue('code', opt)
                                }}
                                value={values.code}
                              />
                            </FormGroup>
                          )}
                          {props.isOtpScreenVisible && (
                            <FormGroup className="col-12 mb-2">
                              <div className="resent_time">
                                <OtpTimer
                                  text="Resend in "
                                  ButtonText={'Resend Code'}
                                  className="forgot_password"
                                  // seconds={4}
                                  minutes={3}
                                  // seconds={10}
                                  textColor={'#344767'}
                                  buttonColor={'#000'}
                                  background={'#fff'}
                                  resend={() => {
                                    setCode(false)
                                    setFieldValue('code', "")
                                    resendCode()
                                  }}
                                />
                              </div>
                              {/* <button onClick={() => resendCode()}>Resend</button> */}
                            </FormGroup>
                          )}
                        </>
                      )}
                      <div className='d-flex flex-column align-items-center'>
                        <Button
                          color=""
                          size="lg"
                          className='btn-secound-primary mb-2'
                          onClick={() => {
                            handleSubmit()
                            validator.current.purgeFields()
                          }}
                        // disabled={!values.isTermsConditions}
                        >
                          {!isOtpSection ? 'Sign in' : 'Submit'}
                        </Button>
                        {isOtpSection && (
                          <Button
                            color="link"
                            block
                            className="text-center"
                            onClick={() => {
                              setFieldValue("code", "")
                              setFieldValue("user_captcha_input", "")
                              //setInitialValues(initialValues)
                              setisOtpSection('')
                              props.clearSubscribeResponse()
                            }}
                          >
                            <svg
                              width="18"
                              height="14"
                              viewBox="0 0 18 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
                                fill="currentColor"
                              />
                            </svg>{' '}
                            Back
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (userHomeReducer) => {
  let status
  let subscribeData = []
  let isOtpScreenVisible = []

  if (
    userHomeReducer.userHomeReducer &&
    userHomeReducer.userHomeReducer?.subscribeFaqApiResponse
  ) {
    if (
      userHomeReducer.userHomeReducer?.subscribeFaqApiResponse?.data
        ?.isOtpScreenVisible == false
    ) {
    }

    isOtpScreenVisible =
      userHomeReducer.userHomeReducer?.subscribeFaqApiResponse?.data
        ?.isOtpScreenVisible

    subscribeData =
      userHomeReducer.userHomeReducer?.subscribeFaqApiResponse?.data
    status =
      userHomeReducer.userHomeReducer?.subscribeFaqApiResponse?.responseType ===
        'success'
        ? true
        : false
  }

  return {
    isOtpScreenVisible: isOtpScreenVisible,
    isSubscribedApiResponse: status,
    subscribeData: subscribeData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    subscribeFaqRequest: (data) => {
      dispatch(subscribeFaqRequest(data))
    },
    otpVerificationRequest: (data) => {
      dispatch(otpVerificationRequest(data))
    },
    resendOtpVerificationRequest: (data) => {
      dispatch(resendOtpVerificationRequest(data))
    },
    clearSubscribeResponse: (data) => {
      dispatch(clearSubscribeResponse(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscribe)
