import produce from 'immer'
import {
  CLEARE_ADMIN_RESEND_QUERY_RESPONSE,
  CLEAR_QUERY_LIST_RESPONSE,
  CREATE_ADMIN_RESEND_QUERY_RESPONSE,
  GET_ADMIN_CONVERSATION_DETAILS_QUERY_RESPONSE,
  GET_QUERY_RESPONSE,
} from './actionType'

let queryListOffset = []
export const initialState = {
  subscriberQueryList: [],
  adminConversationDetails: null,
  adminResendQueryApiResponse: null,
}

export const TicketReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_QUERY_RESPONSE: {
        if (!queryListOffset.includes(action.payload.offset)) {
          queryListOffset.push(action.payload.offset)
          draft.subscriberQueryList = draft.subscriberQueryList.concat(
            action.payload.response.list,
          )
        }
        break
      }
      case CLEAR_QUERY_LIST_RESPONSE: {
        queryListOffset = []
        draft.subscriberQueryList = []
        break
      }

      case GET_ADMIN_CONVERSATION_DETAILS_QUERY_RESPONSE:
        draft.adminConversationDetails = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CREATE_ADMIN_RESEND_QUERY_RESPONSE:
        draft.adminResendQueryApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEARE_ADMIN_RESEND_QUERY_RESPONSE:
        draft.adminResendQueryApiResponse = null
        break

      default:
        state = { ...state }
        break
    }
  })
