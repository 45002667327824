/* eslint-disable no-debugger */
import { connect } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import {
  Table,
  FormGroup,
  Label,
  Button,
  Row,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import {
  getRoleListRequest,
  createRoleRequest,
  rolePermissionRequest,
  rolePermissionUpdateRequest,
  updateRoleRequest,
  updateRoleResponse,
} from 'store/master/role/action'
import { getModuleListRequest } from 'store/master/module/action'
import RoleNavItem from './RoleNavItem'
import CheckBoxItem from './CheckBox'
import { __t } from 'i18n/translator'
import SimpleReactValidator from 'simple-react-validator'

const CreateRole = (props) => {
  const [createRole, setCreateRole] = useState()
  const { validate } = props
  useEffect(() => {
    props.getRoleListRequest()
    props.getModuleListRequest()
  }, [props.createRoleResponse])
  const [DisableRole, setDisableRole] = useState(false)
  const validator = useRef(new SimpleReactValidator())
  useEffect(() => {
    if (props.createUpdateRole || props.moduleList.rolePermission) {
      setroleInitialValues({
        roleName: '',
        roleId: 0,
      })
      setDisableRole(false)
    }
  }, [props.updateRes, props.moduleList.rolePermission])
  const [roleInitialValues, setroleInitialValues] = useState({
    roleName: '',
    roleId: 0,
  })
  const handleOnEdit = (roleId, roleName) => {
    setroleInitialValues({ roleName: roleName, roleId: roleId })
  }

  const formSubmit = async (values, { resetForm }) => {
    if (validator.current.allValid()) {
      props.createRoleRequest(values)
      setroleInitialValues({ roleName: '', roleId: '' })
      resetForm()
      validator.current.hideMessages()
    } else {
      validator.current.showMessages()
    }
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={roleInitialValues}
        onSubmit={formSubmit}
      >
        {({ values }) => (
          <>
            <div className="container">
              <div className="section-head mt-3">
                <div>
                  <h3>Role</h3>
                </div>
              </div>

              <Card>
                <CardBody>
                  <Row className="mx-0 pt-0">
                    <Form className="d-flex align-items-baseline role-add-box p-0">
                      {/* <Form className=" d-flex align-items-center"> */}
                      <FormGroup className="col mb-0 ps-0">
                        <Field
                          name="roleName"
                          placeholder="roleName"
                          // className="form-control"
                          className={
                            DisableRole
                              ? 'form-control disable'
                              : 'form-control'
                          }
                        />
                        <Label className="form-label">
                          Role
                          <span className="text-danger required">*</span>
                        </Label>
                        {validator.current.message(
                          __t('validations.role.role_name_required'),
                          values.roleName,
                          'required|alpha_space',
                          {
                            messages: {
                              required: __t(
                                'validations.role.role_name_required',
                              ),
                              alpha_space: __t(
                                'validations.role.role_allow_only_alpha',
                              ),
                            },
                          },
                        )}
                      </FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        className="ms-2 col-auto"
                        // className={
                        //   roleInitialValues.roleName
                        //     ? 'ms-2 col-auto disable'
                        //     : 'ms-2 col-auto'
                        // }
                      >
                        {roleInitialValues.roleName
                          ? 'Update role'
                          : 'Add role'}
                      </Button>
                    </Form>
                  </Row>
                </CardBody>
              </Card>

              <div className="role-tabs">
                <Nav tabs>
                  {props.roleList?.map((role, index) => {
                    return (
                      <RoleNavItem
                        isDefault={index === 0}
                        isActive={role.id == createRole}
                        handleOnEdit={handleOnEdit}
                        setCreateRole={setCreateRole}
                        key={index}
                        id={role.id}
                        handleOnclick={props.getRolePermission}
                        name={role.roleName}
                      />
                    )
                  })}

                  {/* <RoleNavItem name="admin" /> */}
                </Nav>
                <TabContent activeTab="1">
                  <TabPane tabId="1">
                    <Card className="mb-3">
                      <Table
                        hover
                        responsive
                        striped
                        style={{ border: '1px solid #edefff' }}
                      >
                        <thead>
                          <tr>
                            <th>Role and Module access</th>
                            <th className="allocation-check">
                              <div className="role-head">
                                Add
                                <FormGroup check inline>
                                  {/* <Input type="checkbox" /> */}
                                </FormGroup>
                              </div>
                            </th>
                            <th className="allocation-check">
                              <div className="role-head">
                                Edit
                                <FormGroup check inline>
                                  {/* <Input type="checkbox" /> */}
                                </FormGroup>
                              </div>
                            </th>

                            <th className="allocation-check">
                              <div className="role-head">
                                Delete
                                <FormGroup check inline>
                                  {/* <Input type="checkbox" /> */}
                                </FormGroup>
                              </div>
                            </th>

                            <th className="allocation-check">
                              <div className="role-head">
                                Approve
                                <FormGroup check inline>
                                  {/* <Input type="checkbox" /> */}
                                </FormGroup>
                              </div>
                            </th>
                            <th className="allocation-check">
                              <div className="role-head">
                                View
                                <FormGroup check inline>
                                  {/* <Input type="checkbox" /> */}
                                </FormGroup>
                              </div>
                            </th>
                            <th className="allocation-check">
                              <div className="role-head">
                                Publish
                                <FormGroup check inline>
                                  {/* <Input type="checkbox" /> */}
                                </FormGroup>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.moduleList.rolePermission !== undefined &&
                            props.moduleList.rolePermission.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.module.moduleName}</td>

                                    <CheckBoxItem
                                      id="add"
                                      moduleId={item.module.id}
                                      roleId={props.moduleList.id}
                                      permissionId={item.id}
                                      handleOnChange={
                                        props.updateRolePermission
                                      }
                                      value={item.add}
                                    />
                                    <CheckBoxItem
                                      id="update"
                                      moduleId={item.module.id}
                                      roleId={props.moduleList.id}
                                      permissionId={item.id}
                                      handleOnChange={
                                        props.updateRolePermission
                                      }
                                      value={item.update}
                                    />

                                    <CheckBoxItem
                                      id="delete"
                                      moduleId={item.module.id}
                                      roleId={props.moduleList.id}
                                      permissionId={item.id}
                                      handleOnChange={
                                        props.updateRolePermission
                                      }
                                      value={item.delete}
                                    />

                                    <CheckBoxItem
                                      id="approve"
                                      moduleId={item.module.id}
                                      roleId={props.moduleList.id}
                                      permissionId={item.id}
                                      handleOnChange={
                                        props.updateRolePermission
                                      }
                                      value={item.approve}
                                    />
                                    <CheckBoxItem
                                      id="view"
                                      moduleId={item.module.id}
                                      roleId={props.moduleList.id}
                                      permissionId={item.id}
                                      handleOnChange={
                                        props.updateRolePermission
                                      }
                                      value={item.view}
                                    />
                                    <CheckBoxItem
                                      id="publish"
                                      moduleId={item.module.id}
                                      roleId={props.moduleList.id}
                                      permissionId={item.id}
                                      handleOnChange={
                                        props.updateRolePermission
                                      }
                                      value={item.publish}
                                    />
                                  </tr>
                                )
                              },
                            )}
                        </tbody>
                      </Table>
                    </Card>
                  </TabPane>
                  <TabPane tabId="2">Tab 2</TabPane>
                </TabContent>
              </div>
            </div>
            {/* <Field
    component={Select}
menuPlacement="auto"
    name="roleId"
    id="roleId"
    className="form-control selectbox"
    options={props?.RoleList}
    onChange={(opt) => {
      setValue(id, opt.value);
    }}
    value={props?.RoleList?.filter(
      (option) => option.value === values.roleId
    )}
  />
  <Label className="form-label">Role</Label>
Passed from parent component */}
            {validate}
          </>
        )}
      </Formik>
    </>
  )
}

//redux for addcountry and countrylist
const mapStateToProps = ({ roleReducer, moduleReducer }) => {
  let RoleList
  if (roleReducer.getRoleList && roleReducer.getRoleList?.length > 0) {
    RoleList = roleReducer.getRoleList
  }
  return {
    createUpdateRole: roleReducer.createUpdateRoleRespo,
    roleList: RoleList,
    moduleList: roleReducer.rolePermission,
    moduleName: moduleReducer.getModuleList,
    updateRes: roleReducer.updateRoleResponse,
  }
}

//country redux for addCountry
const mapDispatchToProps = (dispatch) => {
  return {
    createRoleRequest: (role) => {
      dispatch(createRoleRequest(role))
    },
    updateRoleRequest: (role) => {
      dispatch(updateRoleRequest(role))
    },
    getRoleListRequest: () => {
      dispatch(getRoleListRequest())
    },
    getModuleListRequest: () => {
      dispatch(getModuleListRequest())
    },
    getRolePermission: (data) => {
      dispatch(rolePermissionRequest(data))
    },
    updateRolePermission: (data) => {
      dispatch(rolePermissionUpdateRequest(data))
    },
    updateRoleResponse: () => {
      dispatch(updateRoleResponse())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateRole)
