import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { GET_DASHBOARD_REQUEST } from './actionType'
import { getFaqResponse } from './action'
import { invokeApi } from '../../helpers/axiosHelper'
import { handleApiCallException } from 'store/shared/action'

function* reportFaqList() {
  try {
    const response = yield call(invokeApi, 'get', '/dashboard')

    if (response.status) {
      yield put(getFaqResponse(response.data))
    } else {
      yield put(getFaqResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchLaw() {
  yield takeEvery(GET_DASHBOARD_REQUEST, reportFaqList)
}

function* dashboardSaga() {
  yield all([fork(watchLaw)])
}
export default dashboardSaga
