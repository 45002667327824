//Add type
export const ADD_SECTION_REQUEST = 'ADD_SECTION_REQUEST'
export const ADD_SECTION_RESPONSE = 'ADD_SECTION_RESPONSE'

//Get  section  type
export const GET_ALL_SECTION_LIST_REQUEST = 'GET_ALL_SECTION_LIST_REQUEST'
export const GET_ALL_SECTION_LIST_RESPONSE = 'GET_ALL_SECTION_LIST_RESPONSE'

export const GET_SECTION_BY_SECTION_ID_REQUEST =
  'GET_SECTION_BY_SECTION_ID_REQUEST'
export const GET_SECTION_BY_SECTION_ID_RESPONSE =
  'GET_SECTION_BY_SECTION_ID_RESPONSE'

//Update section type
export const UPDATE_SECTION_RESPONSE = 'UPDATE_SECTION_RESPONSE'
export const ACTIVE_SECTION_REQUEST = 'ACTIVE_SECTION_REQUEST'
export const ACTIVE_SECTION_RESPONSE = 'ACTIVE_SECTION_RESPONSE'

export const UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST =
  'UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST'
export const UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE =
  'UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE'

export const UPDATE_SECTION_STATUS_REQUEST = 'UPDATE_SECTION_STATUS_REQUEST'

export const GET_SECTION_LIST_BY_STATUS_REQUEST =
  'GET_SECTION_LIST_BY_STATUS_REQUEST'
export const GET_SECTION_LIST_BY_STATUS_RESPONSE =
  'GET_SECTION_LIST_BY_STATUS_RESPONSE'

export const DELETE_SECTION_BY_SECTION_ID_REQUEST =
  'DELETE_SECTION_BY_SECTION_ID_REQUEST'
export const DELETE_SECTION_BY_SECTION_ID_RESPONSE =
  'DELETE_SECTION_BY_SECTION_ID_RESPONSE'

//get law  by request
export const GET_SECTION_LIST_BY_LAW_REQUEST = 'GET_SECTION_LIST_BY_LAW_REQUEST'

export const GET_SECTION_LIST_BY_LAW_RESPONSE =
  'GET_SECTION_LIST_BY_LAW_RESPONSE'

// get section lists by law ids in faq
export const GET_SECTION_LIST_BY_LAW_IDS_REQUEST =
  'GET_SECTION_LIST_BY_LAW_IDS_REQUEST'
export const GET_SECTION_LIST_BY_LAW_IDS_RESPONSE =
  'GET_SECTION_LIST_BY_LAW_IDS_RESPONSE'
