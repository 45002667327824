export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST'
export const CREATE_TEMPLATE_RESPONSE = 'CREATE_TEMPLATE_RESPONSE'

export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST'
export const UPDATE_TEMPLATE_RESPONSE = 'UPDATE_TEMPLATE_RESPONSE'

export const GET_TEMPLATE_LIST_REQUEST = 'GET_TEMPLATE_LIST_REQUEST'
export const GET_TEMPLATE_LIST_RESPONSE = 'GET_TEMPLATE_LIST_RESPONSE'

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST'

export const GET_CUSTOM_EMAIL_FIELD_LIST_REQUEST =
  'GET_CUSTOM_EMAIL_FIELD_LIST_REQUEST'

export const GET_CUSTOM_EMAIL_FIELD_LIST_RESPONSE =
  'GET_CUSTOM_EMAIL_FIELD_LIST_RESPONSE'

export const GET_TEMPLATE_DETAILS_LIST_BY_ID_REQUEST =
  'GET_TEMPLATE_DETAILS_LIST_BY_ID_REQUEST'

export const GET_TEMPLATE_DETAILS_LIST_BY_ID_RESPONSE =
  'GET_TEMPLATE_DETAILS_LIST_BY_ID_RESPONSE'

export const UPDATE_EMAIL_TEMPLATE_REQUEST = 'UPDATE_EMAIL_TEMPLATE_REQUEST'
export const UPDATE_EMAIL_TEMPLATE_RESPONSE = 'UPDATE_EMAIL_TEMPLATE_RESPONSE'

export const UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST =
  'UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST'
export const UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE =
  'UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE'
