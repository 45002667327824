import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {
  DANGER,
  DATE_FORMAT_DATEPICKER,
} from 'constant/commonConstants'

import { Formik, Field, Form } from 'formik'
import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap'
import { connect } from 'react-redux'
import { createSectionInitialState } from './CommonFunction'
import {
  addSectionRequest,
  addSectionResponse,
  getAllSectionListRequest,
} from 'store/master/section/action'
import LawSelector from '../Law/LawSelector'
import SimpleReactValidator from 'simple-react-validator'
import {
  formatDateForApi,
  getDateObjectWithValueFromMoment,
} from 'helpers/DataTimeHelpers'
import { __t } from 'i18n/translator'

const CreateSection = (props) => {
  const [isRevision, setIsRevision] = useState(false)
  const validator = useRef(new SimpleReactValidator())
  const params = useParams()
  const operationType = !params.id ? 'Submit' : 'Update'
  const location = useLocation()
  const navigate = useNavigate()
  const [createSection] = useState(
    createSectionInitialState(location?.state?.section),
  )

  useEffect(() => {
    if (props.sectionCreate?.responseType != DANGER) {
      if (props.sectionCreate != null) {
        navigate('/console/section')
        props.clearSectionRepo()
      }
    }
  }, [props.sectionCreate])

  return (
    <Formik
      initialValues={createSection}
      onSubmit={async (values) => {
        if (validator.current.allValid()) {
          let formatedValues = ''
          formatedValues = {
            ...values,
            sectionName: values.sectionName,
            sectionId: params.id,
            lawId: values.lawId,
            revisionDate: values.revisionDate
              ? formatDateForApi(values.revisionDate)
              : '',
            lawName: props.lawList?.filter((x) => x.value === values.lawId)[0]
              .label,
          }
          props.addSectionRequest(formatedValues)
        } else {
          validator.current.showMessages()
        }
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <>
          <div className="container">
            <div className="section-head mt-3">
              <div>
                <Button
                  data-tooltip-content={'Back'}
                  className="my-tooltip back_btn"
                  // className="back_btn"
                  onClick={() => {
                    navigate('/console/section')
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                  </svg>
                </Button>
                <h3> {!params.id ? 'Add Section' : ' Update Section'} </h3>
              </div>
            </div>
          </div>

          <Form className="form-horizontal form-login">
            <Card className="container p-0">
              <CardBody className="row">
                <LawSelector
                  isDisabled={!params.id ? false : true}
                  touched={touched}
                  values={values}
                  setValue={setFieldValue}
                  id={'lawId'}
                  isReactSimpleValidator={true}
                  errors={validator.current.message(
                    __t('validations.section.law_field_required'),
                    values.lawId,
                    'required',
                    {
                      messages: {
                        required: __t('validations.section.law_field_required'),
                      },
                    },
                  )}
                ></LawSelector>
                <FormGroup className="col mb-0">
                  <Field
                    id={props.sectionName}
                    name="sectionName"
                    placeholder="sectionName"
                    className="form-control"
                    type="sectionName"
                  />
                  <Label className="form-label" htmlFor="sectionName">
                    Section/Rule Number
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t('validations.section.section_name_required'),
                    values.sectionName,
                    'required',
                    {
                      messages: {
                        required: __t(
                          'validations.section.section_name_required',
                        ),
                      },
                    },
                  )}
                </FormGroup>
              </CardBody>
            </Card>
            {isRevision === true && (
              <Card className="container p-0 mt-4 mb-2">
                <CardHeader className="py-0">
                  <h6>Revise Section</h6>
                </CardHeader>
                <CardBody className="row">
                  <FormGroup className="col mb-0 sticky_label">
                    <Label className="form-label">
                      Revision Note
                      <span className="text-danger required">*</span>
                    </Label>
                    <Field
                      component="textarea"
                      name="revisionDescription"
                      className="form-control"
                      placeholder="Revision Note"
                      value={values.revisionDescription}
                      rows="3"
                    />
                    {validator.current.message(
                      __t('validations.section.revise_description'),
                      values.revisionDescription,
                      'required',
                      {
                        messages: {
                          required: __t(
                            'validations.section.revise_description',
                          ),
                        },
                      },
                    )}
                  </FormGroup>
                  <FormGroup className="col-3 mb-0 sticky_label">
                    <Label className="form-label">
                      Revise Date
                      <span className="text-danger required">*</span>
                    </Label>
                    <DatePicker
                      selected={
                        values?.revisionDate
                          ? getDateObjectWithValueFromMoment(
                            values?.revisionDate,
                          )
                          : null
                      }
                      className="form-control"
                      name="revisionDate"
                      dateFormat={DATE_FORMAT_DATEPICKER}
                      onChange={(date) => {
                        setFieldValue('revisionDate', date)
                      }}
                      maxDate={new Date()}
                    />
                    {validator.current.message(
                      __t('validations.section.revise_date'),
                      values.revisionDate,
                      'required',
                      {
                        messages: {
                          required: __t('validations.section.revise_date'),
                        },
                      },
                    )}

                    <Button
                      type="link"
                      size="sm"
                      color="outline-primary"
                      className="waves-effect waves-light float-end mt-3 d-flex align-items-center"
                      // onClick={() => {
                      //   validator.current.purgeFields(), setIsRevision(false)
                      // }}
                      onClick={() => {
                        validator.current.purgeFields()
                        setIsRevision(false)
                      }}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        className="me-1"
                        style={{ width: '0.6rem' }}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L1 13"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 1L13 13"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Close
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            )}
          </Form>

          <Row className="container">
            <Col className="d-flex justify-content-between my-3 p-0">
              <div>
                {operationType === 'Update' && isRevision === false && (
                  <Button
                    type="button"
                    color="outline-info"
                    className="waves-effect waves-light d-flex align-items-center"
                    style={{ gap: '0.3rem' }}
                    onClick={() => {
                      validator.current.purgeFields()
                      setIsRevision(true)
                    }}
                  >
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      style={{ width: '0.8rem' }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 6L1.29289 6.70711L0.585785 6L1.29289 5.29289L2 6ZM7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16L7 18ZM6.29289 11.7071L1.29289 6.70711L2.70711 5.29289L7.70711 10.2929L6.29289 11.7071ZM1.29289 5.29289L6.29289 0.292892L7.70711 1.70711L2.70711 6.70711L1.29289 5.29289ZM2 5L12.5 5L12.5 7L2 7L2 5ZM12.5 18L7 18L7 16L12.5 16L12.5 18ZM19 11.5C19 15.0898 16.0898 18 12.5 18L12.5 16C14.9853 16 17 13.9853 17 11.5L19 11.5ZM12.5 5C16.0899 5 19 7.91015 19 11.5L17 11.5C17 9.01472 14.9853 7 12.5 7L12.5 5Z"
                        fill="currentColor"
                      />
                    </svg>
                    Revise
                  </Button>
                )}
              </div>
              <FormGroup className="d-flex m-0 p-0">
                <Button
                  type="button"
                  color="outline-primary"
                  block
                  className="w-100 waves-effect waves-light ms-4"
                  onClick={() => {
                    navigate('/console/section')
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  block
                  className="w-100 waves-effect waves-light ms-4"
                  onClick={handleSubmit}
                >
                  {operationType}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({ sectionReducer, lawReducer }) => {
  let lawList = []
  if (lawReducer.lawList.list && lawReducer.lawList.list?.length > 0) {
    lawList = lawReducer.lawList.list.map((opt) => ({
      value: opt.id,
      label: opt.lawName,
    }))
  }

  return {
    sectionCreate: sectionReducer.sectionApiResponse,
    lawList: lawList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSectionList: (data) => {
      dispatch(getAllSectionListRequest(data))
    },
    addSectionRequest: (data) => {
      dispatch(addSectionRequest(data))
    },
    clearSectionRepo: () => {
      dispatch(addSectionResponse(null))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateSection)
