import React from 'react'
import { Link } from 'react-router-dom'

const FaqSvgs = () => (
  <span className="bg-primary">
    <svg
      width="63"
      height="57"
      viewBox="0 0 63 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.8272 19.7459H35.5004C35.5704 19.3741 35.6074 18.9947 35.6074 18.6083V6.12132C35.6074 2.74591 32.8613 0 29.486 0H6.11997C2.74529 0 0 2.74591 0 6.12132V18.6083C0 21.9829 2.74529 24.7282 6.11997 24.7282H9.71552L9.52147 29.2487C9.504 29.6566 9.71552 30.0401 10.0699 30.2428C10.2381 30.339 10.425 30.3869 10.6119 30.3869C10.8185 30.3869 11.0249 30.3282 11.2049 30.2116L19.6774 24.7281H20.7508C20.5291 25.4185 20.408 26.1536 20.408 26.9166V42.0114C20.408 45.9654 23.6248 49.1823 27.5789 49.1823H30.677L37.5078 56.1585C37.7166 56.3718 37.9991 56.4863 38.2876 56.4863C38.4088 56.4863 38.5309 56.4661 38.6493 56.4244C39.0492 56.2839 39.3315 55.9244 39.3733 55.5026L40.0005 49.1823H55.8274C59.7825 49.1823 63 45.9654 63 42.0114V26.9167C62.9999 22.9626 59.7822 19.7459 55.8272 19.7459ZM21.8998 22.5456H19.3549C19.1445 22.5456 18.9386 22.6064 18.7619 22.7208L11.7927 27.2314L11.945 23.6838C11.9578 23.3865 11.8485 23.097 11.6428 22.882C11.4369 22.667 11.1523 22.5456 10.8548 22.5456H6.12009C3.94894 22.5456 2.18248 20.7794 2.18248 18.6083V6.12132C2.18248 3.94943 3.94894 2.1826 6.11997 2.1826H29.4863C31.6582 2.1826 33.425 3.94955 33.425 6.12132V18.6083C33.425 18.9993 33.3685 19.3798 33.2577 19.7459H27.5788C25.2691 19.7459 23.2124 20.8446 21.8998 22.5456ZM60.8175 42.0114C60.8175 44.762 58.5789 46.9999 55.8274 46.9999H39.0119C38.451 46.9999 37.9813 47.4251 37.9259 47.9835L37.4321 52.9618L31.9155 47.3278C31.7104 47.1181 31.4292 47 31.1358 47H27.5791C24.8285 47 22.5906 44.7621 22.5906 42.0116V26.9167C22.5906 24.1661 24.8285 21.9284 27.5791 21.9284H55.8275C58.5791 21.9284 60.8177 24.166 60.8177 26.9166V42.0114H60.8175Z"
        fill="currentColor"
      />
      <path
        d="M22.5558 8.27922C22.4074 5.89052 20.4942 3.97726 18.1055 3.82899C16.7757 3.7468 15.5109 4.20158 14.5434 5.11089C13.5893 6.00753 13.0424 7.27233 13.0424 8.58093C13.0422 9.18386 13.5306 9.67236 14.1335 9.67236C14.7365 9.67236 15.2248 9.18374 15.2248 8.58105C15.2248 7.86172 15.5136 7.19432 16.038 6.70127C16.562 6.20884 17.2483 5.96238 17.9701 6.00716C19.2411 6.08604 20.2984 7.14338 20.3774 8.4142C20.437 9.37421 19.9657 10.2817 19.1473 10.7827C17.6226 11.716 16.7123 13.3289 16.7123 15.0974C16.7123 15.7 17.2008 16.1887 17.8036 16.1887C18.4065 16.1887 18.8949 15.7 18.8949 15.0974C18.8949 14.0947 19.4153 13.1777 20.2868 12.6442C21.796 11.7205 22.6656 10.0478 22.5558 8.27922Z"
        fill="currentColor"
      />
      <path
        d="M41.7037 26.3237C41.1009 26.3237 40.6124 26.8123 40.6124 27.415V36.6513C40.6124 37.254 41.101 37.7426 41.7037 37.7426C42.3065 37.7426 42.795 37.254 42.795 36.6513V27.415C42.7949 26.8123 42.3065 26.3237 41.7037 26.3237Z"
        fill="currentColor"
      />
      <path
        d="M41.7029 42.6315C42.3056 42.6315 42.7942 42.1429 42.7942 41.5402C42.7942 40.9375 42.3056 40.4489 41.7029 40.4489C41.1002 40.4489 40.6116 40.9375 40.6116 41.5402C40.6116 42.1429 41.1002 42.6315 41.7029 42.6315Z"
        fill="currentColor"
      />
      <path
        d="M17.8024 20.2348C18.4051 20.2348 18.8937 19.7463 18.8937 19.1435C18.8937 18.5408 18.4051 18.0522 17.8024 18.0522C17.1997 18.0522 16.7111 18.5408 16.7111 19.1435C16.7111 19.7463 17.1997 20.2348 17.8024 20.2348Z"
        fill="currentColor"
      />
    </svg>
  </span>
)

const ReportSvgs = () => (
  <span className="bg-success">
    <svg
      width="48"
      height="65"
      viewBox="0 0 48 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.46947 65H43.4618C45.9277 65 47.9312 62.9964 47.9312 60.5305V10.0178C47.9312 7.55187 45.9277 5.54831 43.4618 5.54831H37.7208V1.44487C37.7208 0.655009 37.0658 0 36.2759 0C35.4861 0 34.8311 0.655009 34.8311 1.44487V5.54831H25.4105V1.44487C25.4105 0.655009 24.7555 0 23.9656 0C23.1758 0 22.5207 0.655009 22.5207 1.44487V5.54831H13.1002V1.44487C13.1002 0.655009 12.4452 0 11.6553 0C10.8654 0 10.2104 0.655009 10.2104 1.44487V5.54831H4.46947C2.00356 5.54831 0 7.55187 0 10.0178V60.5113C0 62.9772 2.00356 65 4.46947 65ZM2.88974 10.0178C2.88974 9.15086 3.60255 8.43806 4.46947 8.43806H10.2104V12.58C10.2104 13.3699 10.8654 14.0249 11.6553 14.0249C12.4452 14.0249 13.1002 13.3699 13.1002 12.58V8.43806H22.5207V12.58C22.5207 13.3699 23.1758 14.0249 23.9656 14.0249C24.7555 14.0249 25.4105 13.3699 25.4105 12.58V8.43806H34.8311V12.58C34.8311 13.3699 35.4861 14.0249 36.2759 14.0249C37.0658 14.0249 37.7208 13.3699 37.7208 12.58V8.43806H43.4618C44.3287 8.43806 45.0415 9.15086 45.0415 10.0178V60.5113C45.0415 61.3782 44.3287 62.091 43.4618 62.091H4.46947C3.60255 62.091 2.88974 61.3782 2.88974 60.5113V10.0178Z"
        fill="currentColor"
      />
      <path
        d="M9.6903 25.449C9.97928 25.738 10.3453 25.8728 10.7113 25.8728C11.0774 25.8728 11.4434 25.738 11.7324 25.449L16.8376 20.3438C17.3963 19.7851 17.3963 18.8604 16.8376 18.3017C16.2789 17.743 15.3542 17.743 14.7955 18.3017L10.7113 22.3859L9.22795 20.9025C8.66926 20.3438 7.74454 20.3438 7.18586 20.9025C6.62717 21.4612 6.62717 22.3859 7.18586 22.9446L9.6903 25.449Z"
        fill="currentColor"
      />
      <path
        d="M20.5171 23.3106H39.7243C40.5142 23.3106 41.1692 22.6556 41.1692 21.8657C41.1692 21.0758 40.5142 20.4208 39.7243 20.4208H20.5171C19.7273 20.4208 19.0723 21.0758 19.0723 21.8657C19.0723 22.6556 19.7273 23.3106 20.5171 23.3106Z"
        fill="currentColor"
      />
      <path
        d="M9.6903 38.8575C9.97928 39.1464 10.3453 39.2813 10.7113 39.2813C11.0774 39.2813 11.4434 39.1464 11.7324 38.8575L16.8376 33.7522C17.3963 33.1936 17.3963 32.2688 16.8376 31.7102C16.2789 31.1515 15.3542 31.1515 14.7955 31.7102L10.7113 35.7751L9.22795 34.2917C8.66926 33.733 7.74454 33.733 7.18586 34.2917C6.62717 34.8503 6.62717 35.7751 7.18586 36.3337L9.6903 38.8575Z"
        fill="currentColor"
      />
      <path
        d="M20.5171 36.719H39.7243C40.5142 36.719 41.1692 36.064 41.1692 35.2742C41.1692 34.4843 40.5142 33.8293 39.7243 33.8293H20.5171C19.7273 33.8293 19.0723 34.4843 19.0723 35.2742C19.0723 36.064 19.7273 36.719 20.5171 36.719Z"
        fill="currentColor"
      />
      <path
        d="M9.6903 52.2466C9.97928 52.5356 10.3453 52.6704 10.7113 52.6704C11.0774 52.6704 11.4434 52.5356 11.7324 52.2466L16.8376 47.1414C17.3963 46.5827 17.3963 45.658 16.8376 45.0993C16.2789 44.5406 15.3542 44.5406 14.7955 45.0993L10.7113 49.1835L9.22795 47.7001C8.66926 47.1414 7.74454 47.1414 7.18586 47.7001C6.62717 48.2588 6.62717 49.1835 7.18586 49.7422L9.6903 52.2466Z"
        fill="currentColor"
      />
      <path
        d="M20.5171 50.1275H39.7243C40.5142 50.1275 41.1692 49.4725 41.1692 48.6826C41.1692 47.8927 40.5142 47.2377 39.7243 47.2377H20.5171C19.7273 47.2377 19.0723 47.8927 19.0723 48.6826C19.0723 49.4725 19.7273 50.1275 20.5171 50.1275Z"
        fill="currentColor"
      />
    </svg>
  </span>
)

const SubscriberSvgs = () => (
  <span className="bg-info">
    <svg
      width="52"
      height="50"
      viewBox="0 0 52 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.0138 47.1177C45.7743 43.9282 43.0431 41.1098 39.2437 39.0997C35.4443 37.0896 30.7891 36 26 36C21.211 36 16.5557 37.0896 12.7563 39.0997C8.95694 41.1098 6.22569 43.9282 4.98619 47.1177"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="26"
        cy="15.5"
        rx="11.1429"
        ry="10.5"
        fill="#7E869E"
        fillOpacity="0.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </span>
)

const SubscriberQueries = () => (
  <span className="bg-orange">
    <svg
      width="42"
      height="49"
      viewBox="0 0 42 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2"
        width="37.0588"
        height="45"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M10 10.5686H25.8824"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 20H25.8824"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 29H20.5882"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 38H15.4902"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29.9536 37.2477C29.5858 37.2477 29.2757 37.1146 29.0232 36.8485C28.77 36.5825 28.6588 36.2694 28.6894 35.9094C28.7354 35.252 28.8886 34.6808 29.1491 34.1955C29.4096 33.7103 29.8233 33.2095 30.3903 32.693C31.0185 32.1295 31.4972 31.6402 31.8263 31.2251C32.1561 30.8107 32.3209 30.3374 32.3209 29.8052C32.3209 29.1635 32.1104 28.6313 31.6893 28.2087C31.2677 27.7861 30.6814 27.5748 29.9306 27.5748C29.4402 27.5748 29.0228 27.6803 28.6784 27.8913C28.3333 28.1029 28.0535 28.3652 27.839 28.6783C27.6245 28.9913 27.3603 29.2026 27.0465 29.3122C26.7321 29.4217 26.4293 29.4139 26.1382 29.2887C25.7857 29.1322 25.5446 28.8739 25.4146 28.5139C25.2841 28.1539 25.3107 27.8174 25.4946 27.5044C25.939 26.8 26.5366 26.2131 27.2874 25.7435C28.0382 25.274 28.9193 25.0392 29.9306 25.0392C31.5395 25.0392 32.777 25.4972 33.643 26.4131C34.5084 27.3284 34.9412 28.4278 34.9412 29.7113C34.9412 30.4939 34.7766 31.1632 34.4475 31.7191C34.1177 32.2744 33.6004 32.9043 32.8956 33.6086C32.3746 34.1095 32.0068 34.5164 31.7923 34.8294C31.5778 35.1425 31.4322 35.5025 31.3556 35.9094C31.2943 36.3007 31.1334 36.6216 30.8729 36.872C30.6124 37.1224 30.306 37.2477 29.9536 37.2477ZM29.9306 42.8823C29.4249 42.8823 28.9922 42.6986 28.6324 42.3311C28.272 41.9629 28.0918 41.5206 28.0918 41.0041C28.0918 40.4876 28.272 40.0453 28.6324 39.6771C28.9922 39.3096 29.4249 39.1259 29.9306 39.1259C30.4362 39.1259 30.8693 39.3096 31.2296 39.6771C31.5894 40.0453 31.7693 40.4876 31.7693 41.0041C31.7693 41.5206 31.5894 41.9629 31.2296 42.3311C30.8693 42.6986 30.4362 42.8823 29.9306 42.8823Z"
        fill="currentColor"
      />
    </svg>
  </span>
)

const Consultation = () => (
  <span className="bg-orange">
   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 22C14.4183 22 18 18.4183 18 14C18 9.58172 14.4183 6 10 6C5.58172 6 2 9.58172 2 14C2 15.2355 2.28008 16.4056 2.7802 17.4502C2.95209 17.8093 3.01245 18.2161 2.90955 18.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L5.39939 21.0904C5.78393 20.9876 6.19071 21.0479 6.54976 21.2198C7.5944 21.7199 8.76449 22 10 22Z" stroke="currentColor" strokeWidth="1.5"/><path d="M18 14.5018C18.0665 14.4741 18.1324 14.4453 18.1977 14.4155C18.5598 14.2501 18.9661 14.1882 19.3506 14.2911L19.8267 14.4185C20.793 14.677 21.677 13.793 21.4185 12.8267L21.2911 12.3506C21.1882 11.9661 21.2501 11.5598 21.4155 11.1977C21.7908 10.376 22 9.46242 22 8.5C22 4.91015 19.0899 2 15.5 2C12.7977 2 10.4806 3.64899 9.5 5.9956" stroke="currentColor"strokeWidth="1.5"/><path d="M6.51828 14H6.52728M10.009 14H10.018M13.5 14H13.509" stroke="currentColor" strokeWidth="2"strokeLinecap="round"strokeLinejoin="round"/></svg>
  </span>
)

const setSvgs = (argument) => {
  switch (argument) {
    case 'FAQs':
      return FaqSvgs()

    case 'Reports':
      return ReportSvgs()

    case 'Subscriber':
      return SubscriberSvgs()

    case 'Subscriber Queries':
      return SubscriberQueries()

    case 'Consultation':
      return Consultation()

    default:
      return null
  }
}

export const TotalCards = (props) => {
  const { Counts, ModuleName, text, href } = props

  return (
    <Link className="dashboard_count" to={href}>
      <h4>
        <small>{`Total ${text}`}</small>
        {Counts}
      </h4>
      {setSvgs(ModuleName)}
    </Link>
  )
}
