import React, { useEffect, useState } from 'react'
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom'
import Auth from './pages/Auth/Auth'
import ResetPassword from './pages/Auth/ResetPassword'
import SectionManager from './pages/masterPages/Section/Section'
import Country from './pages/masterPages/Country/Country'
import User from './pages/masterPages/User/User'
import Home from './pages/Dashboard/Home'
import 'App.scss'
import ForgotPassward from 'pages/Auth/ForgotPassward'
import CreateUser from 'pages/masterPages/User/CreateUser'
import AddCountry from 'pages/masterPages/Country/AddCountry'
import Category from 'pages/masterPages/Category/Category'
import CreateRole from 'pages/masterPages/Role/CreateRole'
import Law from 'pages/masterPages/Law/Law'
import AddLaw from 'pages/masterPages/Law/AddLaw'
import CreateSection from 'pages/masterPages/Section/CreateSection'
import FAQ from 'pages/Faq/FAQ'
import CreateFAQ from 'pages/Faq/CreateFAQ'
import UserHome from 'pages/FrontEnd/Home/home'
import UserReport from 'pages/FrontEnd/Report/report'
import ViewFaqDetails from 'pages/Faq/ViewFaqDetails'
import {
  getModuleId,
  getModulePermissions,
  isHaveModuleAccess,
} from 'helpers/authUtils'
import Report from 'pages/Report/Report'
import Layout from 'components/Layout'
import UserLayout from 'pages/FrontEnd/Layout'
import About from 'pages/FrontEnd/AboutUs/about'
import FaqUser from 'pages/FrontEnd/Faq/faq'
import ReportDetails from 'pages/FrontEnd/Report/reportDetails'
import Tickets from 'pages/FrontEnd/Tickets/Tickets'
import TicketsDetails from 'pages/FrontEnd/Tickets/TicketsDetails'
import ReportRequest from 'pages/Report/ReportRequest'
import AdminTicketsDetails from 'pages/HelpSupport/TicketsDetails'
import LoginHandle from 'pages/Auth/LoginHandle'
import PageNotFound404 from 'pages/Auth/PageNotFound404'
import HelpSupport from 'pages/HelpSupport/HelpSupport'
import Subscriber from 'pages/Subscriber/Subscriber'
import CreateTemplate from 'pages/masterPages/Email/CreateTemplate'

import ViewReportDetails from 'pages/Report/ViewReportDetails'
import { store } from 'store/store'
import { clearStore } from 'store/clearstore/actions'
import ServiceLine from 'pages/masterPages/Services/ServiceLine'
import ConsultationRequest from 'pages/ConsultationRequest/ConsultationRequest'
import CreateConsultation from 'pages/ConsultationRequest/CreateConsultation'
import TeamCreation from 'pages/masterPages/teamCreation/TeamCreation'
import Logout from 'pages/Auth/Logout'
import NotAllowedInIframe from 'components/NotAllowedInIframe/NotAllowedInIframe'
import Unauthorized from 'pages/Auth/Unauthorized'
import TermsConditions from 'pages/FrontEnd/TermsConditions'
import LegalDisclaimer from 'pages/FrontEnd/LegalDisclaimer'
import { useTranslation } from 'react-i18next'
import ContactUs from 'pages/FrontEnd/ContactUS/ContactUs'

function App() {
  const location = useLocation()
  const [showIframeMessage, setShowIframeMessage] = useState(false)

  const { i18n } = useTranslation();

  useEffect(() => {
    if (location.pathname.split('/')[1] != 'report') {
      store.dispatch(clearStore())
    }
  }, [location.pathname])
  useEffect(() => {
    try {
      if (
        !['taxfaq.hcshub.in', 'localhost', 'uatgtmitra.gtbharat.in', 'devgtmitra.gtbharat.in', 'gtmitra.gtbharat.in'].includes(
          window.parent.location.hostname,
        )
      ) {
        throw new Error()
      }
    } catch (e) {
      setShowIframeMessage(true)
    }
  }, [])

  const AccessRoute = () => {
    return isHaveModuleAccess(getModuleId(location.pathname.split('/')[2])) ? (
      <Outlet
        context={getModulePermissions(
          getModuleId(location.pathname.split('/')[2]),
        )}
      />
    ) : (
      <Navigate to="/console/unauthorized" />
    )
  }
  const PrivateRoute = () => {
    return localStorage.getItem('user') ? <Outlet /> : <Navigate to="/home" />
  }
  return showIframeMessage ? (
    <NotAllowedInIframe />
  ) : (
    <Routes>
      <Route element={<UserLayout />}>
        <Route path="/about" element={<About />} />
        <Route path="/home" element={<UserHome />} />
        <Route path="/contact-us" element={<ContactUs />} />


        <Route path="/reportDetails/:id" element={<ReportDetails />} />

        <Route path="/report" element={<UserReport />}>
          <Route path=":id" element={<UserReport />} />
          <Route path=":id/:subCatId" element={<UserReport />} />
        </Route>
        <Route path="/faq" element={<FaqUser />}>
          <Route path=":id" element={<FaqUser />} />
          <Route path=":id/:subCatId" element={<FaqUser />} />
        </Route>

        <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/ticketsDetails/:id" element={<TicketsDetails />} />
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Auth />} />
          <Route path="/console/home" element={<Home />} />
          <Route path="/console/serviceline" element={<ServiceLine />} />

          <Route path="/" element={<AccessRoute />}>
            <Route path="/console/help" element={<HelpSupport />} />
            <Route
              path="/console/help/ticketsDetails/:id"
              element={<AdminTicketsDetails />}
            />
            <Route path="/console/reportRequest" element={<ReportRequest />} />
            {/* <Route path="/section" element={<SectionManager />} /> */}

            <Route path="/console/law" element={<Law />} />
            <Route path="/console/law/create" element={<AddLaw />} />
            <Route path="/console/law/edit/:id" element={<AddLaw />} />

            <Route path="/console/category" element={<Category />} />

            <Route path="/console/country" element={<Country />} />
            <Route path="/console/country/Add" element={<AddCountry />} />
            <Route path="/console/country/edit/:id" element={<AddCountry />} />

            <Route path="/console/user" element={<User />} />
            <Route path="/console/user/create" element={<CreateUser />} />
            <Route path="/console/user/edit/:id" element={<CreateUser />} />

            <Route path="/console/section" element={<SectionManager />} />
            <Route path="/console/section/create" element={<CreateSection />} />
            <Route path="/console/section/edit/:id" element={<CreateSection />} />

            <Route path="/console/role" element={<CreateRole />} />
            <Route path="/console/faq" element={<FAQ />} />
            <Route path="/console/faq/create" element={<CreateFAQ />} />
            <Route path="/console/faq/view" element={<ViewFaqDetails />} />
            <Route path="/console/faq/view/:id" element={<ViewFaqDetails />} />
            <Route path="/console/faq/edit/:id" element={<CreateFAQ />} />

            <Route
              path="/console/consultationRequest"
              element={<ConsultationRequest />}
            />
            <Route
              path="/console/consultationRequest/create"
              element={<CreateConsultation />}
            />
            <Route
              path="/console/consultationRequest/edit/:id"
              element={<CreateConsultation />}
            />

            <Route path="/console/report" element={<Report />} />
            <Route
              path="/console/report/view/:id"
              element={<ViewReportDetails />}
            />

            <Route path="/console/subscriber" element={<Subscriber />} />
            <Route path="/console/template/create" element={<CreateTemplate />} />

            <Route path="console/team-creation" element={<TeamCreation />} />
          </Route>
        </Route>
      </Route>


      <Route path="/console/logout" element={<Logout />} />
      <Route exact path="/console/login" element={<LoginHandle />} />

      <Route exact path="/console/ForgotPassward" element={<ForgotPassward />} />
      <Route path="/console/reset-password/:id" element={<ResetPassword />} />
      <Route path="/console/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<PageNotFound404 />} />

    </Routes>
  )
}

export default App
