import { useEffect, React, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import { Label, FormGroup, Button, Row } from 'reactstrap'
import { connect } from 'react-redux'
import {
  addLawRequest,
  addLawResponse,
  getLawListRequest,
} from 'store/master/law/action'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { getCategoryListRequest } from 'store/master/category/action'
import { DATE_FORMAT_DATEPICKER } from 'constant/commonConstants'
import { getDateObjectWithValueFromMoment } from 'helpers/DataTimeHelpers'
import { reportFilter } from 'constant/dropdown'

const AddLaw = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const [lawData] = useState({
    createdDate: null,
    lawId: '',
    lawName: '',
    categoryId: '',
    lawDescription: '',
    subCategoryId: '',
    lawDate: null,
    publishedReportType: '',
  })

  const [subCatDropDownList, setsubCatDropDownList] = useState([])

  const lawListFilter = {
    searchText: '',
    pagination: {
      limit: 0,
      page: 0,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: { roleId: 0 },
    type: getTypeForFilter(props.type),
  }

  function getTypeForFilter(type) {
    if (type === 'REPORT') {
      return 'REPORT'
    } else if (type === 'CONTENT') {
      return 'CONTENT'
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (params.id) {
      subCategoryFilter(lawData.categoryId)
    }

    props.getCatList(lawListFilter)
    if (props.lawCreate != null) {
      navigate('/console/law')
      props.clearLawResponse()
    }
  }, [props.lawCreate])

  const subCategoryFilter = (value) => {
    let selectedData = props?.getCategoryListData?.filter(
      (x) => x.id == value,
    )[0]
    if (selectedData?.subCat?.length > 0) {
      setsubCatDropDownList(
        selectedData.subCat?.map((opt) => ({
          value: opt.id,
          label: opt.catName,
        })),
      )
    }
  }
  return (
    <Formik initialValues={lawData}>
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <>
          <Form className="form-horizontal form-login ">
            <Row className="mt-3">
              <FormGroup className="col mb-0">
                <Field
                  component={Select}
                  menuPlacement="auto"
                  name="categoryId"
                  id="categoryId"
                  className="form-control selectbox"
                  options={props?.categoryDropdown}
                  onChange={(opt) => {
                    setsubCatDropDownList([])
                    subCategoryFilter(opt?.value)
                    setFieldValue('categoryId', opt?.value)
                    setFieldValue('subCategoryId', '')

                    props.getListByLawIdFilter(
                      opt?.value,
                      '',
                      values?.lawDate,
                      values?.publishedReportType,
                    )
                  }}
                  value={props?.categoryDropdown?.filter(
                    (option) => option?.value === values?.categoryId,
                  )}
                  isClearable={true}
                />
                <Label className="form-label">Service Line</Label>
              </FormGroup>

              <FormGroup className="col mb-0">
                <Field
                  component={Select}
                  menuPlacement="auto"
                  name="subCategoryId"
                  id="subCategoryId"
                  className="form-control selectbox"
                  options={subCatDropDownList}
                  onChange={(opt) => {
                    setFieldValue('subCategoryId', opt?.value)
                    props.getListByLawIdFilter(
                      values?.categoryId,
                      opt?.value,
                      values?.lawDate,
                      values?.publishedReportType,
                    )
                  }}
                  value={subCatDropDownList?.filter(
                    (option) => option?.value === values?.subCategoryId,
                  )}
                  isClearable={true}
                />
                <Label className="form-label">Category</Label>
              </FormGroup>

              {props.isReportModule && (
                <>
                  <FormGroup className="col mb-0">
                    <DatePicker
                      placeholderText="Select Date"
                      selected={
                        values?.lawDate
                          ? getDateObjectWithValueFromMoment(values?.lawDate)
                          : null
                      }
                      maxDate={new Date()}
                      className="form-control"
                      name="lawDate"
                      dateFormat={DATE_FORMAT_DATEPICKER}
                      onChange={(opt) => {
                        if (opt == null) {
                          setFieldValue('lawDate', null)
                        }
                        props?.getListByLawIdFilter(
                          values?.categoryId,
                          values?.subCategoryId,
                          opt,
                          values?.publishedReportType,
                        )
                        setFieldValue('lawDate', opt)
                      }}
                      isClearable
                    />
                  </FormGroup>

                  {props.isActiveTab == 'Published' && (
                    <FormGroup className="col mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="publishedReportType"
                        id="publishedReportType"
                        className="form-control selectbox"
                        options={reportFilter}
                        onChange={(opt) => {
                          setFieldValue(
                            'publishedReportType',
                            opt?.value ? opt?.value : '',
                          )
                          props.getListByLawIdFilter(
                            values?.categoryId,
                            values?.subCategoryId,
                            values?.lawDate,
                            opt?.value,
                          )
                        }}
                        value={reportFilter?.filter(
                          (option) =>
                            option?.value == values?.publishedReportType,
                        )}
                        isClearable={true}
                      />
                      <Label className="form-label">Published Reports</Label>
                    </FormGroup>
                  )}
                </>
              )}
            </Row>
            <Button
              className="my-tooltip cancel_filter"
              data-tooltip-content={'Close filter'}
              color="link"
              onClick={() => {
                props.cancelFilterSection(true)
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13"
                  stroke="CurrentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L13 13"
                  stroke="CurrentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </Form>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({ categoryReducer, lawReducer }) => {
  let categoryDropDownList

  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryDropDownList = categoryReducer.categoryList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  return {
    categoryDropdown: categoryDropDownList,
    getCategoryListData: categoryReducer.categoryList.list,
    lawCreate: lawReducer.lawApiResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLawList: (data) => {
      dispatch(getLawListRequest(data))
    },
    getCatList: (data) => {
      dispatch(getCategoryListRequest(data))
    },

    addLaw: (data) => {
      dispatch(addLawRequest(data))
    },
    clearLawResponse: () => {
      dispatch(addLawResponse())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLaw)
