import produce from 'immer'
import {
  GET_SUBSCRIBER_LIST_RESPONSE,
  // CREATE_USER_API_RESPONSE,
  // USER_STATUS_RESPONSE,
  // GET_USER_LIST_RESPONSE,
} from './actionType'
export const initialState = {
  createUserApiResponse: null,
  getSubsciberListApiResponse: null,
  statusApiResponse: null,
}
export const SubsciberReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_SUBSCRIBER_LIST_RESPONSE:
        draft.getSubsciberListApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      // case CREATE_USER_API_RESPONSE:
      //   {
      //     if (action.payload.message == undefined) {
      //       draft.createUserApiResponse = null
      //     } else {
      //       draft.createUserApiResponse = {
      //         message: action.payload.message,
      //         responseType: action.payload.responseType,
      //       }
      //     }
      //   }
      //   break

      // case USER_STATUS_RESPONSE:
      //   draft.statusApiResponse = {
      //     data: action.payload.data,
      //     message: action.payload.message,
      //     responseType: action.payload.responseType,
      //   }
      //   break

      default:
        state = { ...state }
        break
    }
  })
