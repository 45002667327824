require('yup-phone')
import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Select from 'react-select'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import { createUserRequest, createUserResponse } from 'store/master/user/action'
import { getRoleListRequest } from 'store/master/role/action'
import { createUserInitialState } from './CommonFunction'
import CountrySelector from '../Country/countrySelector'
import RoleSelector from '../Role/RoleSelector'
import SimpleReactValidator from 'simple-react-validator'
import { isValidNumberField } from 'helpers/SimpleReactValidators'
import { __t } from 'i18n/translator'
import { SUCCESS } from 'constant/commonConstants'
import {
  ClearSubCatListByCatIdResponse,
  CleartCategoryListResponse,
  getCategoryListRequest,
  getSubCatListByCatIdRequest,
} from 'store/master/category/action'
import { getServiceLineListRequest } from 'store/master/serviceline/action'
import { AUTH_MODE_LC, AUTH_MODE_SSO } from 'constant/databaseConstants'

const CreateUser = (props) => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [createUser] = useState(
    createUserInitialState(location?.state?.userDetails),
  )
  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidNumberField()),
    }),
  )

  useEffect(() => {
    props.getCategoryList({
      searchText: '',
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })

    return () => {
      props.cleartCategoryListResponse()
      props.clearSubCatCategoryListResponse()
    }
  }, [])

  useEffect(() => {
    if (
      props.userCreate != null &&
      props?.userCreate?.responseType === SUCCESS
    ) {
      navigate('/console/user')
      props.clearUserResponse()
    }
  }, [props.userCreate])

  const setSubCategoryValues = (CatList) => {
    props.getSubCatList({ subCatId: CatList })
  }

  useEffect(() => {
    if (params.id && createUser?.categoryId?.length > 0) {
      props.getSubCatList({ subCatId: createUser.categoryId })
    }
  }, [params.id])

  useEffect(() => {
    props.getServiceLineListRequest({
      // searchText: '',
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
    })
  }, [])

  return (
    <Formik
      initialValues={createUser}
      onSubmit={async (values) => {
        if (validator.current.allValid()) {
          let FormSubmitData = {
            ...values,
            contactNo: parseInt(values.contactNo),
            roleId: parseInt(values.roleId),
            userId: params.id ? params.id : '',
            catId: values.categoryId,
            subCatId: values.subCategoryId,
            authenticationMode: values.authenticationMode,
          }
          delete FormSubmitData.categoryId
          delete FormSubmitData.subCategoryId
          props.CreateLogiUser(FormSubmitData)
        } else {
          validator.current.showMessages()
        }
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <>
          <div className="container">
            <div className="section-head mt-3">
              <div>
                <Button
                  data-tooltip-content={'Back'}
                  className="my-tooltip back_btn"
                  // className="back_btn"
                  onClick={() => {
                    navigate('/console/user')
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                  </svg>
                </Button>
                <h3> {!params.id ? 'Create User' : ' Update User'} </h3>
              </div>
            </div>
          </div>

          <Form className="form-horizontal form-login">
            <Card className="container p-0">
              <CardBody className="row">
                <FormGroup className="col-4 mb-0">
                  <Field
                    id="fullName"
                    name="fullName"
                    placeholder="fullName"
                    className="form-control"
                    type="fullName"
                  />
                  <Label className="form-label" htmlFor="fullName">
                    Full name
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t('validations.user.full_name_required'),
                    values.fullName,
                    'required|alpha_space',
                    {
                      messages: {
                        required: __t('validations.user.full_name_required'),
                        alpha_space: __t('validations.user.allow_only_alpha'),
                      },
                    },
                  )}
                </FormGroup>

                <FormGroup className="col-4 mb-0">
                  <Field
                    id="email"
                    name="email"
                    placeholder="example@example.com"
                    className={
                      params.id ? 'form-control disable' : 'form-control'
                    }
                    type="email"
                  />

                  <Label className="form-label" htmlFor="email">
                    Email Address
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t('validations.user.email_required'),
                    values.email,
                    'required|email',
                    {
                      messages: {
                        required: __t('validations.user.email_required'),
                        email:
                          __t(
                            'validations.common.email_valid'
                          ),
                      },
                    },
                  )}
                </FormGroup>

                <FormGroup className="col-4 mb-0">
                  <Field
                    id="contactNo"
                    name="contactNo"
                    placeholder="contactNo"
                    className="form-control"
                    type="Number"
                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    required={true}
                  />
                  {validator.current.message(
                    __t('validations.user.contact_required'),
                    values.contactNo,

                    'isValidNumber:',
                    {
                      messages: {
                        required: __t('validations.user.contact_required'),
                      },
                    },
                  )}

                  <Label className="form-label" htmlFor="Mobile no">
                    Contact No
                  </Label>
                </FormGroup>
              </CardBody>
            </Card>
            <Card className="container p-0 mt-3">
              <CardBody className="row">
                <h6 className="separate-head">
                  Role / Country / Service Line mapping
                </h6>
                <RoleSelector
                  values={values}
                  setValue={setFieldValue}
                  id={'roleId'}
                  validate={validator.current.message(
                    __t('validations.user.role_required'),
                    values.roleId,
                    'required',
                    {
                      messages: {
                        required: __t('validations.user.role_required'),
                      },
                    },
                  )}
                ></RoleSelector>

                <CountrySelector
                  isUserModule={true}
                  values={values}
                  setValue={setFieldValue}
                  id={'countryId'}
                  validate={validator.current.message(
                    __t('validations.user.country_name_required'),
                    values.countryId,
                    'required',
                    {
                      messages: {
                        required: __t('validations.user.country_name_required'),
                      },
                    },
                  )}
                ></CountrySelector>

                <FormGroup className="col-4 mb-0">
                  <Field
                    component={Select}
                    menuPlacement="auto"
                    name="categoryId"
                    id="categoryId"
                    className="form-control selectbox"
                    options={props?.serviceLineDropdown}
                    onChange={(opt) => {
                      let Category = opt ? opt?.map((x) => x.value) : []
                      setFieldValue('categoryId', Category)
                      setSubCategoryValues(Category)
                      setFieldValue('subCategoryId', [])
                    }}
                    isClearable={true}
                    value={props?.serviceLineDropdown?.filter((option) =>
                      values.categoryId?.includes(option.value),
                    )}
                    isMulti
                  />

                  <Label className="form-label">
                    Service Line
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t('validations.common.servicecategory'),
                    values.categoryId,
                    'required',
                    {
                      messages: {
                        required: __t('validations.common.servicecategory'),
                      },
                    },
                  )}
                </FormGroup>
                <FormGroup className="col-lg-4 sticky_label radio_btn mb-0 mt-2">
                  <div className="form-check form-check-inline">
                    <Field
                      className="form-check-input"
                      type="radio"
                      name="authenticationMode"
                      id="authenticationModeLc"
                      onChange={(opt) => {
                        if (opt) {
                          setFieldValue('authenticationMode', AUTH_MODE_LC)
                        } else {
                          setFieldValue('authenticationMode', AUTH_MODE_SSO)
                        }
                      }}
                      value={AUTH_MODE_LC}
                    />
                    <Label
                      className="form-check-label my-tooltip"
                      for="authenticationModeLc"
                      data-tooltip-content={'Normal User'}
                    >
                      {'Normal User'}
                    </Label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Field
                      className="form-check-input"
                      type="radio"
                      name="authenticationMode"
                      id="authenticationModeSso"
                      onChange={(opt) => {
                        if (opt) {
                          setFieldValue('authenticationMode', AUTH_MODE_SSO)
                        } else {
                          setFieldValue('authenticationMode', AUTH_MODE_LC)
                        }
                      }}
                      value={AUTH_MODE_SSO}
                    />
                    <Label
                      className="form-check-label my-tooltip"
                      for="authenticationModeSso"
                      data-tooltip-content={'Single sign-on'}
                    >
                      {'SSO'}
                    </Label>
                  </div>
                  <Label className="form-label">{'Authentication Mode'}</Label>
                </FormGroup>
              </CardBody>
            </Card>
          </Form>

          <Row className="container">
            <Col className="d-flex justify-content-end my-3 p-0">
              <FormGroup className="d-flex m-0 p-0">
                <Button
                  type="button"
                  color="outline-primary"
                  block
                  className="w-100 waves-effect waves-light ms-4"
                  onClick={() => {
                    navigate('/console/user')
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  block
                  className="w-100 waves-effect waves-light ms-4"
                  onClick={handleSubmit}
                >
                  {!params.id ? 'Submit' : 'Update'}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  roleReducer,
  countryReducer,
  createUserReducer,
  categoryReducer,
  serviceLineReducer,
}) => {
  let categoryDropDownList
  let CountryList
  let RoleList
  let subCategoryList
  let serviceLineDropdown = []

  if (
    categoryReducer.subCatList.list &&
    categoryReducer.subCatList.list.length > 0
  ) {
    subCategoryList = categoryReducer?.subCatList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryDropDownList = categoryReducer.categoryList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  if (countryReducer.countryList && countryReducer.countryList?.length > 0) {
    CountryList = countryReducer.countryList?.map((opt) => ({
      value: opt.id,
      label: opt.countryName,
    }))
  }

  if (roleReducer.getRoleList && roleReducer.getRoleList?.length > 0) {
    RoleList = roleReducer.getRoleList?.map((opt) => ({
      value: opt.id,
      label: opt?.roleName,
    }))
  }

  if (
    serviceLineReducer &&
    serviceLineReducer.serviceLineList?.list?.length > 0
  ) {
    serviceLineDropdown = serviceLineReducer.serviceLineList?.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt?.catName,
      }),
    )
  }

  return {
    getCategoryListData: categoryReducer.categoryList.list,
    categoryDropdown: categoryDropDownList,
    CountryList: CountryList,
    RoleList: RoleList,
    userCreate: createUserReducer.createUserApiResponse,
    subCategoryList: subCategoryList,
    serviceLineDropdown: serviceLineDropdown,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    CreateLogiUser: (creds) => {
      dispatch(createUserRequest(creds))
    },
    getRoleListRequest: (getRoleList) => {
      dispatch(getRoleListRequest(getRoleList))
    },
    clearUserResponse: () => {
      dispatch(createUserResponse(null))
    },
    getCategoryList: (data) => {
      dispatch(getCategoryListRequest(data))
    },
    getSubCatList: (data) => {
      dispatch(getSubCatListByCatIdRequest(data))
    },
    cleartCategoryListResponse: () => {
      dispatch(CleartCategoryListResponse())
    },
    clearSubCatCategoryListResponse: () => {
      dispatch(ClearSubCatListByCatIdResponse())
    },
    getServiceLineListRequest: (creds) => {
      dispatch(getServiceLineListRequest(creds))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
