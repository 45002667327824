import moment from 'moment'
import "moment-timezone"
import { getModuleId } from './authUtils'
import {
  csv_icon_svg,
  doc_icon_svg,
  docx_icon_svg,
  file_icon_svg,
  jpeg_icon_svg,
  jpg_icon_svg,
  pdf_icon_svg,
  png_icon_svg,
  txt_icon_svg,
  xls_icon_svg,
  xlsx_icon_svg,
  zip_icon_svg,
} from 'components/CommonSvgs/CommonSvgs'
export const compareString = (item1, item2) => {
  item1 = item1.toUpperCase() // ignore upper and lowercase
  item2 = item2.toUpperCase() // ignore upper and lowercase
  if (item1 < item2) {
    return -1
  }
  if (item1 > item2) {
    return 1
  }
  // items must be equal
  return 0
}

export const getInitials = function (string) {
  if (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names?.length > 1) {
      initials += names[names?.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  } else {
    return ''
  }
}

export const randomColor = () => {
  const crypto = window.crypto || window.msCrypto
  const array = new Uint8Array(3)
  crypto.getRandomValues(array)
  const [red, green, blue] = array
  return `rgb(${red},${green},${blue})`
}

export const dateFormater = (cell, row) => {
  if (row && row.createdDate) {
    return moment(row.createdDate).format('DD-MM-YYYY')
  } else {
    return moment(cell).format('DD-MM-YYYY')
  }
}

// export const userLogFormater = (cell, row) => {
//   if (row && row.updatedDate) {
//     const ahmedabadDateTime = moment(row.updatedDate)
//       .tz("Asia/Kolkata")
//       .format("DD-MM-YYYY hh:mm:ss A");
//     const hour = moment(row.updatedDate).tz('Asia/Kolkata').format('H');
//     const hourIn12HourFormat = (parseInt(hour, 10) % 12) || 12;
//     return ahmedabadDateTime.replace(hour, hourIn12HourFormat);
//   } else {
//     return ""
//   }
// }


// export const userLogFormater = (cell, row) => {
//   if (row && row.updatedDate) {
//     const utcMoment = moment.utc(row.updatedDate, 'DD-MM-YYYY HH:mm:ss');
//     const newYorkMoment = utcMoment.tz('Asia/Kolkata');
//     const formattedDateTimeString = newYorkMoment.format('DD-MM-YYYY hh:mm A');
//     return formattedDateTimeString;
// } else {
//     return "";
// }
// }

export const userLogFormater = (cell, row) => {
  if (row && row.updatedDate) {
    // Parse the ISO 8601 date string directly
    const utcMoment = moment.utc(row.updatedDate);
    
    // Convert to 'Asia/Kolkata' timezone
    const kolkataMoment = utcMoment.tz('Asia/Kolkata');
    
    // Format the date to 'DD-MM-YYYY hh:mm A' (12-hour format with AM/PM)
    const formattedDateTimeString = kolkataMoment.format('DD-MM-YYYY hh:mm A');
    
    return formattedDateTimeString;
  } else {
    return "";
  }
};





export const faqStatus = [
  {
    status: 'Draft',
    value: 0,
    class: '',
  },
  {
    status: 'Pending',
    value: 50,
    class: 'success',
  },
  {
    status: 'Approved',
    value: 100,
    class: 'success',
  },
  {
    status: 'Rejected',
    value: 100,
    class: 'danger',
  },
  {
    status: 'Rejected by approver',
    value: 100,
    class: 'danger',
  },
  {
    status: 'Rejected by publisher',
    value: 100,
    class: 'danger',
  },
  {
    status: 'Published',
    value: 100,
    class: 'success',
  },
  {
    status: 'Pending with first approver',
    value: 50,
    class: 'success',
  },
  {
    status: 'Pending for publisher',
    value: 75,
    class: 'success',
  },
  {
    status: 'Pending with second approver',
    value: 70,
    class: 'success',
  },
  {
    status: 'Rejected by first approver',
    value: 100,
    class: 'danger',
  },
  {
    status: 'Rejected by second approver',
    value: 100,
    class: 'danger',
  },
  {
    status: 'Personal',
    value: 100,
    class: 'success',
  },
]

export const setRoleStatusColor = (isRole) => {
  let role = isRole
  let roleClass

  switch (role) {
    case 'Content Creator':
      roleClass = 'bg-orange'
      break
    case 'Report Creator':
      roleClass = 'bg-info'
      break
    case 'Publisher':
      roleClass = 'bg-muted'
      break
    case 'Approver':
      roleClass = 'bg-success'
      break
    case 'Admin':
      roleClass = 'bg-primary'
      break
    default:
      roleClass = 'badge role-badge'
      break
  }

  return roleClass
}

export const setActiveStatus = (getCurrentModule, moduleId) => {
  let status
  if (moduleId == 'master') {
    switch (getCurrentModule) {
      case 'serviceline':
      case 'user':
      case 'country':
      case 'category':
      case 'law':
      case 'section':
      case 'role':
        return 'active'
      default:
        return ''
    }
  } else if (moduleId == 'otherModule') {
    switch (getCurrentModule) {
      case 'help':
      case 'reportRequest':
      case 'template':
        return 'active'
      default:
        return ''
    }
  } else {
    // Perticular Module
    switch (getCurrentModule) {
      case 'home':
        status = moduleId == getModuleId('home') ? 'active ' : ''
        break
      case 'faq':
        status = moduleId == getModuleId('faq') ? 'active ' : ''
        break
      case 'subscriber':
        status = moduleId == getModuleId('subscriber') ? 'active ' : ''
        break
      case 'article':
        status = moduleId == getModuleId('article') ? 'active ' : ''
        break
      case 'report':
        status = moduleId == getModuleId('report') ? 'active ' : ''
        break
      case 'consultationRequest':
        status = moduleId == getModuleId('consultationRequest') ? 'active ' : ''
        break

      // case 'template':
      //   status = moduleId == getModuleId('template') ? 'active ' : ''
      //   break
      default:
        status = ''
        break
    }
  }

  return status
}

export const setActiveStatusFrontend = (getCurrentModule, moduleId) => {
  switch (moduleId) {
    case 'article':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'articleDetails':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'report':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'reportDetails':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'faq':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'about':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'contact-us':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'home':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'tickets':
      return getCurrentModule == moduleId ? 'active' : ''

    case 'ticketsDetails':
      return getCurrentModule == moduleId ? 'active' : ''

    default:
      return ''
  }

  // old logic
  // const URL = [location.pathname.split('/')[1]]
  // className={URL.includes('home') && 'active'}
}

export const firstLetterCapital = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const removeTags = (str) => {
  if (str) {
    return str.replace(/(<([^>]+)>)/gi, '')
  } else {
    return ''
  }
}

export const statusLableChanges = (value) => {
  let lable = ''

  switch (value) {
    case 'Pending for approval':
      lable = 'Pending for Approval'
      break
    case 'Pending for publisher':
      lable = 'Pending for Publisher'
      break
    case 'Rejected by approver':
      lable = 'Rejected by Approver'
      break
    case 'Rejected by publisher':
      lable = 'Rejected by Publisher'
      break
    case 'Personal':
      lable = 'Personal'
      break
    case 'Approved':
      lable = 'Approved'
      break
    case 'Published':
      lable = 'Published'
      break

    case 'Pending':
      lable = 'Pending'
      break
    case 'Rejected':
      lable = 'Rejected'
      break

    default:
      lable = 'Saved'
      break
  }

  return lable
}

export const checkFileExtension = (fileName) => {
  let extension = fileName.split('.').pop().toLowerCase()
  let svg = ''
  switch (extension) {
    case 'csv':
      svg = csv_icon_svg()
      break
    case 'doc':
      svg = doc_icon_svg()
      break
    case 'docx':
      svg = docx_icon_svg()
      break
    case 'jpeg':
      svg = jpeg_icon_svg()
      break
    case 'jpg':
      svg = jpg_icon_svg()
      break
    case 'pdf':
      svg = pdf_icon_svg()
      break
    case 'png':
      svg = png_icon_svg()
      break
    case 'txt':
      svg = txt_icon_svg()
      break
    case 'xls':
      svg = xls_icon_svg()
      break
    case 'xlsx':
      svg = xlsx_icon_svg()
      break
    case 'zip':
      svg = zip_icon_svg()
      break
    default:
      svg = file_icon_svg()
      break
  }

  return svg
}
