import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import {
  GET_USER_FAQ_LIST_REQUEST,
  UPDATE_POPULAR_FAQ_REQUEST,
} from './actionType'
import { getUserFaqListResponse, updateFaqListResponse } from './action'

function* getFaqList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/getUserFaqList', data)
    if (response.status) {
      yield put(getUserFaqListResponse(response.data, data.offset))
    } else {
      yield put(getUserFaqListResponse([], data.offset))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* updatePopularFaqList({ payload: { data } }) {
  try {
    yield call(invokeApi, 'post', '/updatePopularFaq', data)

    if (response.status) {
      yield put(updateFaqListResponse(response.data))
    } else {
      yield put(updateFaqListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchLaw() {
  yield takeEvery(GET_USER_FAQ_LIST_REQUEST, getFaqList)
  yield takeEvery(UPDATE_POPULAR_FAQ_REQUEST, updatePopularFaqList)
}
function* userFaqSaga() {
  yield all([fork(watchLaw)])
}
export default userFaqSaga
