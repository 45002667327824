export const GET_REPORT_LIST_FAQ_REQUEST = 'GET_REPORT_LIST_FAQ_REQUEST'
export const GET_REPORT_LIST_FAQ_RESPONSE = 'GET_REPORT_LIST_FAQ_RESPONSE'

export const GET_REPORT_LIST_FAQ_COUNT_RESPONSE =
  'GET_REPORT_LIST_FAQ_COUNT_RESPONSE'

export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST'
export const CREATE_REPORT_RESPONSE = 'CREATE_REPORT_RESPONSE'

export const DELETE_REPORT_FAQ_REQUEST = 'DELETE_REPORT_FAQ_REQUEST'
export const DELETE_REPORT_FAQ_RESPONSE = 'DELETE_REPORT_FAQ_RESPONSE'

export const DELETE_FAQ_SECTION_REQUEST = 'DELETE_FAQ_SECTION_REQUEST'
export const DELETE_FAQ_SECTION_RESPONSE = 'DELETE_FAQ_SECTION_RESPONSE'

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST'
export const GET_REPORT_RESPONSE = 'GET_REPORT_RESPONSE'

export const GET_REPORT_DETAILS_REQUEST = 'GET_REPORT_DETAILS_REQUEST'
export const GET_REPORT_DETAILS_RESPONSE = 'GET_REPORT_DETAILS_RESPONSE'

export const CREATE_REPORT_REVIEW_REQUEST = 'CREATE_REPORT_REVIEW_REQUEST'
export const CREATE_REPORT_REVIEW_RESPONSE = 'CREATE_REPORT_REVIEW_RESPONSE'

export const GET_REPORT_KEYWORDS_REQUEST = 'GET_REPORT_KEYWORDS_REQUEST'
export const GET_REPORT_KEYWORDS_RESPONSE = 'GET_REPORT_KEYWORDS_RESPONSE'

export const GET_REPORT_DOWNLOAD_LIST_REQUEST =
  'GET_REPORT_DOWNLOAD_LIST_REQUEST'
export const GET_REPORT_DOWNLOAD_LIST_RESPONSE =
  'GET_REPORT_DOWNLOAD_LIST_RESPONSE'

export const CLEAR_REPORT_DOWNLOAD_LIST_RESPONSE =
  'CLEAR_REPORT_DOWNLOAD_LIST_RESPONSE'

export const CREATE_REPORT_DOWNLOAD_APPROVE_REQUEST =
  'CREATE_REPORT_DOWNLOAD_APPROVE_REQUEST'
export const CREATE_REPORT_DOWNLOAD_APPROVE_RESPONSE =
  'CREATE_REPORT_DOWNLOAD_APPROVE_RESPONSE'

export const GET_REPORT_DOWNLOAD_REQUEST = 'GET_REPORT_DOWNLOAD_REQUEST'
export const GET_REPORT_DOWNLOAD_RESPONSE = 'GET_REPORT_DOWNLOAD_RESPONSE'

export const GET_REPORT_IS_REVIEW_REQUEST = 'GET_REPORT_IS_REVIEW_REQUEST'
export const GET_REPORT_IS_REVIEW_RESPONSE = 'GET_REPORT_IS_REVIEW_RESPONSE'

export const GET_CHECK_REPORT_TITLE_REQUEST = 'GET_CHECK_REPORT_TITLE_REQUEST'
export const GET_CHECK_REPORT_TITLE_RESPONSE = 'GET_CHECK_REPORT_TITLE_RESPONSE'
export const CLEAR_REPORT_RESPONSE = 'CLEAR_REPORT_RESPONSE'

export const GET_OTHER_CATEGORY_REQUEST = 'GET_OTHER_CATEGORY_REQUEST'
export const GET_OTHER_CATEGORY_RESPONSE = 'GET_OTHER_CATEGORY_RESPONSE'

export const CLEAR_REPORT_FAQ_LIST_RESPONSE = 'CLEAR_REPORT_FAQ_LIST_RESPONSE'

export const CLEAR_REPORT_DETAILS_RESPONSE = 'CLEAR_REPORT_DETAILS_RESPONSE'

export const CLEAR_IS_REPORT_REVISION_RESPONSE =
  'CLEAR_IS_REPORT_REVISION_RESPONSE'
