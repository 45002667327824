//get country
export const ADD_COUNTRY_REQUEST = 'ADD_COUNTRY_REQUEST'
export const ADD_COUNTRY_RESPONSE = 'ADD_COUNTRY_RESPONSE'

//get country list request

export const GET_COUNTRY_LIST_REQUEST = ' GET_COUNTRY_LIST_REQUEST'
export const GET_COUNTRY_LIST_RESPONSE = ' GET_COUNTRY_LIST_RESPONSE'

//get country list request

export const ADD_COUNTRY_STATUS_REQUEST = ' ADD_COUNTRY_STATUS_REQUEST'
export const ADD_COUNTRY_STATUS_RESPONSE = ' ADD_COUNTRY_STATUS_RESPONSE'

//get user country List
export const USER_COUNTRY_LIST_REQUEST = 'USER_COUNTRY_LIST_REQUEST'
export const USER_COUNTRY_LIST_RESPONSE = 'USER_COUNTRY_LIST_RESPONSE'
