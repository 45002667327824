import { useEffect, useRef, useState, React } from 'react'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button, Card, CardBody, CardHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { createFaqInitialState, statusBox } from './CommonFunction'
import { __t } from 'i18n/translator'
import { useParams, useNavigate } from 'react-router-dom'
import {
  getAllSectionListRequest,
  getSectionListByLawIdsRequest,
} from 'store/master/section/action'
import JoditEditor from 'jodit-react'
import {
  createFaqRequest,
  createFaqResponse,
  getListKeywordsRequest,
  getFaqDetailsRequest,
} from 'store/faq/action'
import Select from 'react-select'
import { onlyAlpha } from './../../helpers/validators'
import { WithContext as ReactTags } from 'react-tag-input'

import SimpleReactValidator from 'simple-react-validator'
import {
  getCategoryListRequest,
  getSubCatListByCatIdRequest,
} from 'store/master/category/action'
import { getLawListByCategoryIdRequest } from 'store/master/law/action'
import { DANGER, ENGLISH, JAPANESE } from 'constant/commonConstants'
import { getUserListRequest } from 'store/master/user/action'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

import { removeTags, statusLableChanges } from 'helpers/generalUtils'
import {
  APPROVED,
  DRAFTED,
  REJECTED_BY_APPROVER,
} from 'constant/databaseConstants'
import { getServiceLineListRequest } from 'store/master/serviceline/action'

const selectorFilter = {
  searchText: '',
  pagination: {
    limit: 0,
    page: 0,
    orderKey: 'createdDate',
    orderBy: 'ASC',
  },
  filter: {
    roleId: '',
  },
}
const CreateFAQ = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const editor = useRef(null)
  const [detailFaq, setDetailFaq] = useState([])
  const [initialFaqValues, setFaqValues] = useState(createFaqInitialState())
  const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])
  const [isDraFSave, setisDraFSave] = useState(true)
  const validator = useRef(new SimpleReactValidator())
  const countryData = JSON.parse(localStorage.getItem('countryData'))
  const [activeLanguage, setActiveLanguage] = useState(ENGLISH);

  const [lablesName, setLablesName] = useState([])

  const config = {
    removeButtons: [
      'cut',
      'copy',
      'paste',
      'font',
      'change mode',
      'print',
      'insert',
      'about',
      'subscript',
      'superscript',
      'source',
      'classSpan',
      'copyformat',
      'video',
      'image',
      'file',
    ],
  }

  // add api data for suggestion of keywords
  useEffect(() => {
    props.getlistKeywords()

    props.getCategoryListRequest({
      searchText: '',
      type: 'CONTENT',
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })
  }, [])

  useEffect(() => {
    setDetailFaq(props.faqDetails)

    if (props.faqDetails?.categoryDetails?.parentCategoryId) {
      filterSubCategory(props.faqDetails?.categoryDetails?.parentCategoryId)
    }
  }, [props.faqDetails])

  const suggestions = props.listKeywords?.list?.map((keyword) => {
    return {
      id: keyword,
      text: keyword,
    }
  })
  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]
  const [tags, setTags] = useState([])

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i))
  }

  useEffect(() => {
    if (props.categoryList?.length == 1 && !params.id) {
      let categoryId = props.categoryList[0]?.value
        ? props.categoryList[0]?.value
        : ''
      if (categoryId != '') {
        let lawLable = ''
        if (props.categoryList[0]?.label == 'Tax') {
          lawLable = props.categoryList[0]?.label
        }
        filterSubCategory(categoryId)
        setFaqValues({
          ...createFaqInitialState(),
          categoryId: categoryId,
          lawLable: lawLable,
        })
      }
    }
  }, [props.categoryList])

  // useEffect(() => {
  //   props.getServiceLineList({
  //     // searchText: '',
  //     pagination: {
  //       limit: 0,
  //       page: 0,
  //       orderKey: 'createdDate',
  //       orderBy: 'ASC',
  //     },
  //   })
  // }, [])

  const handleAddition = (tag) => {
    const matches = onlyAlpha.exec(tag.text)

    if (matches) {
      setTags([...tags, tag])
    } else {
      ApiResponseMessage('Only alphabets are allowed for this field', DANGER)
    }
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
  }

  useEffect(() => {
    if (props.faqCreate?.responseType != DANGER) {
      if (props.faqCreate != null) {
        props.clearFaqRepo(null)
        navigate('/console/faq')
      }
    }

    if (params.id) {
      props.getFaqDetailsRequest(params.id)
    }

    // props.getUserListRequest(selectorFilter)
  }, [props.faqCreate])

  useEffect(() => {
    if (detailFaq && params.id) {
      if (detailFaq?.categoryDetails?.parentCategoryId) {
        setLables(detailFaq?.categoryDetails?.parentCategoryId)
        props.getUserListRequest({
          ...selectorFilter,
          countryId: detailFaq?.countryId ? detailFaq?.countryId : '',
          catId: detailFaq.categoryDetails.parentCategoryId
            ? detailFaq.categoryDetails.parentCategoryId
            : '',
          subCatId: detailFaq.categoryDetails?.subcategoryId
            ? detailFaq.categoryDetails?.subcategoryId
            : '',
        })
      }

      setFaqValues(createFaqInitialState(detailFaq))
      setTags(detailFaq?.keywords?.map((x) => ({ id: x, text: x })))

      getLawListByCatogeryId(
        detailFaq.categoryDetails?.subcategoryId?.toString(),
      )
      getSectionListByLawIds(detailFaq?.lawList?.map((x) => x.id))
    }
  }, [detailFaq])

  useEffect(() => {
    if (initialFaqValues?.firstApprovalId) {
      if (props?.userDropdownList.length > 0) {
        let checkApprovalId = props?.userDropdownList?.filter(
          (x) => x.value == initialFaqValues.firstApprovalId,
        )
        if (checkApprovalId.length == 0) {
          setFaqValues({ ...initialFaqValues, firstApprovalId: "" })
        }
      }
    }
  }, [props.userDropdownList])


  const setLables = (id) => {
    let categoryData = props.categoryAndSubList.filter((x) => x.id == id)[0]

    if (categoryData) {
      setLablesName({
        labelForCategory: categoryData.labelForCategory,
        labelForLaws: categoryData.labelForLaws,
        labelForSections: categoryData.labelForSections,
      })
    }
  }
  const getLawListByCatogeryId = (id) => {
    if (id) {
      props.getLawListByCategoryIdRequest(id)
    }
  }

  const getSectionListByLawIds = (id) => {
    if (id) {
      props.getSectionListByLawIdsRequest({
        lawIdList: id,
      })
    }
  }

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let formattedValues = {
        laws: props.lawDropdownList
          ?.filter((x) => values.laws.includes(x.value))
          ?.map((opt) => ({
            id: opt.value,
            name: opt.label,
          })),
        sections: props.sectionDropdownList
          ?.filter((x) => values.sections.includes(x.value))
          ?.map((opt) => ({
            id: opt.value,
            name: opt.label,
          })),

        countryId: countryData != undefined && countryData.id,
        // values.countryId,
        categoryId: values.categoryId,
        subCategoryId: values.subCategoryId,
        question: values.question,
        [editorId]: values[editorId],
        reference: values.reference,
        keywords: tags.map((opt) => opt.id),
        firstApprovalId: values.firstApprovalId,
        secondApprovalId: values.secondApprovalId,
        isDraft: isDraFSave,
        translation:values.translation
        //version: version,
      }
      if (params.id) {
        formattedValues = {
          ...formattedValues,
          faqId: params.id,
        }
      }
console.log("formattedValues", formattedValues);

      props.createFaq(formattedValues)
    } else {
      validator.current.showMessages()
    }
  }

  const filterSubCategory = (id) => {
    if (id) {
      let filterSubCategoryValue = props.categoryAndSubList
        ?.filter((item) => item.id == id)[0]
        ?.subCat.map((opt) => ({
          value: opt.id,
          label: opt.catName,
        }))

      setSubCategoryDropdownList(filterSubCategoryValue)
    } else {
      setSubCategoryDropdownList([])
    }
  }

  const editorId = 'answer'
  return (
    <Formik
      initialValues={initialFaqValues}
      // validationSchema={createFaqSchema}
      enableReinitialize={true}
      onSubmit={formSubmit}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <>
          <div className="container">
            <div className="section-head mt-3">
              <div>
                <Button
                  data-tooltip-content={'Back'}
                  className="my-tooltip back_btn"
                  // className="back_btn"
                  onClick={() => {
                    navigate('/console/faq')
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                  </svg>
                </Button>
                <h3>
                  {' '}
                  {!params.id
                    ? 'Create Content / FAQ'
                    : ' Update Content / FAQ'}
                </h3>
              </div>
              <div className="status-drop-box gap-2">
                {params.id && (
                  <div className="version-box">
                    <p>
                      Published Version : {props.faqDetails?.publishedVersion}{' '}
                    </p>
                  </div>
                )}

                {params.id && props.faqDetails?.status && (
                  <div className="notification-list">
                    <span
                      className={
                        'faq-list-status-box ' +
                        statusBox(
                          props.faqDetails?.status
                            ? props.faqDetails?.status
                            : 'Draft',
                        )
                      }
                    ></span>
                    {props.faqDetails?.status == 'Drafted'
                      ? 'Saved'
                      : statusLableChanges(props.faqDetails?.status)}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Form className="faq-create">
            <Card className="container p-0 mb-3">
              <CardBody className="py-3">
                <Row>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="categoryId"
                        className="form-control selectbox"
                        options={props.categoryList}
                        onChange={(opt) => {
                          setFieldValue('categoryId', opt && opt?.value)
                          setLables(opt && opt?.value)
                          filterSubCategory(opt ? opt.value : '')
                          setFieldValue('subCategoryId', '')
                          setFieldValue('laws', '')
                          setFieldValue('sections', '')
                          getLawListByCatogeryId(null)
                          getSectionListByLawIds(null)
                          if (opt?.label == 'Tax') {
                            setFieldValue('lawLable', 'Law')
                            validator.current.purgeFields()
                          } else {
                            setFieldValue('lawLable', '')
                            validator.current.purgeFields()
                          }

                          // setSubCategoryValues(opt?.value)

                          props.getUserListRequest({
                            ...selectorFilter,
                            // type: 'CONTENT',
                            countryId:
                              countryData != undefined && countryData.id,
                            subCatId: values.subCategoryId
                              ? values.subCategoryId
                              : '',
                            catId: opt?.value,
                          })
                        }}
                        value={props?.categoryList.filter(
                          (option) => option?.value === values?.categoryId,
                        )}
                        isClearable={true}
                      // closeMenuOnSelect={false}
                      />
                      <Label className="form-label">
                        Service Line
                        <span className="text-danger required">*</span>
                      </Label>

                      {validator.current.message(
                        __t('validations.common.service'),
                        values.categoryId,
                        'required',
                        {
                          messages: {
                            required: __t('validations.common.service'),
                          },
                        },
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="subCategoryId"
                        className="form-control selectbox"
                        options={subCategoryDropdownList}
                        onChange={(opt) => {
                          setFieldValue('subCategoryId', opt && opt?.value)
                          getLawListByCatogeryId(opt && opt?.value)
                          getSectionListByLawIds(null)
                          setFieldValue('laws', '')
                          setFieldValue('sections', '')
                          props.getUserListRequest({
                            ...selectorFilter,
                            countryId:
                              countryData != undefined && countryData.id,
                            catId: values.categoryId ? values.categoryId : '',
                            subCatId: opt?.value,
                          })
                        }}
                        value={subCategoryDropdownList?.filter(
                          (option) => option?.value === values.subCategoryId,
                        )}
                        isClearable={true}
                      />
                      <Label className="form-label">
                        {lablesName && lablesName?.labelForCategory
                          ? lablesName?.labelForCategory
                          : 'Category'}
                        <span className="text-danger required">*</span>
                      </Label>
                      {validator.current.message(
                        (lablesName && lablesName?.labelForCategory
                          ? lablesName?.labelForCategory
                          : 'category') + __t('validations.common.required'),
                        values.subCategoryId,
                        'required',
                        {
                          messages: {
                            required:
                              (lablesName && lablesName?.labelForCategory
                                ? lablesName?.labelForCategory
                                : 'category') +
                              __t('validations.common.required'),
                          },
                        },
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="laws"
                        className="form-control selectbox"
                        options={props.lawDropdownList}
                        onChange={(opt) => {
                          let lawIds = opt && opt?.map((x) => x.value)
                          setFieldValue(`laws`, lawIds)
                          getSectionListByLawIds(lawIds)
                          setFieldValue('sections', '')
                        }}
                        value={props.lawDropdownList.filter((option) =>
                          values.laws?.includes(option?.value),
                        )}
                        isClearable={true}
                        isMulti={true}
                      />
                      <Label className="form-label">
                        {lablesName && lablesName?.labelForLaws
                          ? lablesName?.labelForLaws
                          : 'Laws'}
                        {values.lawLable && (
                          <span className="text-danger required">*</span>
                        )}
                      </Label>
                      {values.lawLable &&
                        validator.current.message(
                          (lablesName && lablesName?.labelForLaws
                            ? lablesName?.labelForLaws
                            : 'Laws') + __t('validations.common.required'),
                          values.laws,
                          'required',
                          {
                            messages: {
                              required:
                                (lablesName && lablesName?.labelForLaws
                                  ? lablesName?.labelForLaws
                                  : 'Laws') +
                                __t('validations.common.required'),
                            },
                          },
                        )}
                    </FormGroup>
                  </Col>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="sections"
                        className="form-control selectbox"
                        options={props.sectionDropdownList}
                        onChange={(opt) => {
                          setFieldValue(
                            `sections`,
                            opt && opt?.map((x) => x.value),
                          )
                        }}
                        value={props.sectionDropdownList?.filter((option) =>
                          values.sections?.includes(option?.value),
                        )}
                        isClearable={true}
                        isMulti={true}
                      />
                      <Label className="form-label">
                        {lablesName && lablesName?.labelForSections
                          ? lablesName?.labelForSections
                          : 'Sections'}
                        {/* <span className="text-danger required">*</span> */}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="container p-0 mb-3 mt-5 lang-switch-main">
              <CardHeader className='nav-tabs lang-switch'>
                <Button color={ENGLISH == activeLanguage ? 'info' : 'secondary' } type='button' onClick={() => { setActiveLanguage(ENGLISH) }}>English</Button>
                <Button color={JAPANESE == activeLanguage ? 'info' : 'secondary' } type='button' onClick={() => { setActiveLanguage(JAPANESE) }}>Japanese
                {/* <span className='text-danger'><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.31171 7.76149C6.23007 2.58716 7.68925 0 10 0C12.3107 0 13.7699 2.58716 16.6883 7.76149L17.0519 8.40626C19.4771 12.7061 20.6897 14.856 19.5937 16.428C18.4978 18 15.7864 18 10.3637 18H9.63634C4.21356 18 1.50217 18 0.406258 16.428C-0.689657 14.856 0.522912 12.7061 2.94805 8.40627L3.31171 7.76149Z" fill="currentColor"/><path d="M10 3C10.6213 3 11.125 3.48216 11.125 4.07692V11.2564C11.125 11.8512 10.6213 12.3333 10 12.3333C9.37868 12.3333 8.875 11.8512 8.875 11.2564V4.07692C8.875 3.48216 9.37868 3 10 3Z" fill="white"/><path d="M10 16.1115C10.8284 16.1115 11.5 15.4686 11.5 14.6756C11.5 13.8826 10.8284 13.2397 10 13.2397C9.17157 13.2397 8.5 13.8826 8.5 14.6756C8.5 15.4686 9.17157 16.1115 10 16.1115Z" fill="white"/></svg></span> */}
                </Button>
               
              </CardHeader>
              <CardBody className="row">
                <div className="col">
                  {
                    activeLanguage == ENGLISH ? <>
                      <Label className="separate-head mb-2">
                        Heading
                        <span className="text-danger required">*</span>
                      </Label>
                      <FormGroup className="mb-3">
                        <Label className="form-label">

                        </Label>
                        <Field
                          name="question"
                          placeholder="Heading here..."
                          className="form-control"
                        />
                      </FormGroup>

                    </> : <>
                      {values?.translation?.map((lang, langIdx) => {
                        return <>
                          <Label className="separate-head mb-2">
                            {`Heading (${lang.language})`}
                          </Label>
                          <FormGroup className="mb-3">
                            <Field
                              name={`translation.[${langIdx}].question`}
                              className="form-control"
                              placeholder="Question"
                              value={lang.question}
                            />
                          </FormGroup>
                        </>
                      })}
                    </>
                  }
                  {validator.current.message(
                    __t('validations.faq.faq_question_required'),
                    values.question,
                    'required',
                    {
                      messages: {
                        required: __t(
                          'validations.faq.faq_question_required',
                        ),
                      },
                    },
                  )}
                </div>
                <div className="answer-editer-box">
                  {
                    activeLanguage == ENGLISH ? <>
                      <Label className="separate-head mb-2">
                        Details <span className="text-danger required">*</span>
                      </Label>
                      <JoditEditor
                        ref={editor}
                        config={config}
                        name={editorId}
                        value={values[editorId]}
                        onBlur={(updateValue) => {
                          setFieldValue(editorId, updateValue)
                        }}
                      />
                    </> : <>
                      <>
                        {values?.translation?.map((lang, langIdx) => {
                          const editorId = `translation_${langIdx}`; // Unique ID for each editor
                          return (
                            <div key={langIdx} className="translation-editor">
                              <Label className="separate-head mb-2">
                                {`Details (${lang.language})`}
                              </Label>
                              <JoditEditor
                                ref={(el) => (editor.current[langIdx] = el)} // Maintain refs for each editor
                                config={config}
                                name={editorId}
                                value={values.translation[langIdx]?.answer || ''} // Use language-specific value
                                onBlur={(updateValue) => {
                                  const updatedTranslations = [...values.translation];
                                  updatedTranslations[langIdx] = {
                                    ...updatedTranslations[langIdx],
                                    answer: updateValue, // Update specific language details
                                  };
                                  setFieldValue('translation', updatedTranslations);
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    </>
                  }

                  {validator.current.message(
                    __t('validations.faq.faq_details_required'),
                    removeTags(values.answer),
                    'required',
                    {
                      messages: {
                        required: __t('validations.faq.faq_details_required'),
                      },
                    },
                  )}
                </div>
              </CardBody>
            </Card>
            <Card className="container p-0 mb-3">
              <CardBody className="row">
                <Col className="no-floating-label">
                  <FormGroup className="mb-0">
                    <Label className="form-label" htmlFor="reference">
                      Reference Name
                    </Label>
                    <Field
                      name="reference"
                      placeholder="Reference link"
                      className="form-control"
                      type="reference"
                    />
                  </FormGroup>
                </Col>
                <Col className="no-floating-label">
                  <FormGroup className="mb-0 add_tags_field">
                    <Label className="form-label">SEO Keywords</Label>
                    <ReactTags
                      tags={tags}
                      suggestions={suggestions}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      inputFieldPosition="bottom"
                      autocomplete
                    />
                  </FormGroup>
                </Col>

                {(!isDraFSave || params.id) && (
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Label className="form-label">
                        Approver
                        <span className="text-danger required">*</span>
                      </Label>
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="firstApprovalId"
                        className="form-control selectbox"
                        options={props.userDropdownList?.filter(
                          (x) => x.value != values.secondApprovalId,
                        )}
                        onChange={(opt) => {
                          setFieldValue('firstApprovalId', opt && opt.value)
                        }}
                        value={props.userDropdownList
                          ?.filter((x) => x.value != values.secondApprovalId)
                          .filter(
                            (option) => option.value === values.firstApprovalId,
                          )}
                        isClearable={true}
                      />
                      {!isDraFSave &&
                        validator.current.message(
                          __t('validations.common.first_apvl_required'),
                          values.firstApprovalId,
                          'required',
                          {
                            messages: {
                              required: __t(
                                'validations.common.first_apvl_required',
                              ),
                            },
                          },
                        )}
                    </FormGroup>
                  </Col>
                )}
              </CardBody>
            </Card>
          </Form>

          <Row className="container align-items-center mt-2">
            <Col lg={12} className="d-flex justify-content-end my-3 p-0">
              <FormGroup className="d-flex m-0 p-0">
                <Button
                  type="button"
                  color="outline-primary"
                  block
                  className="w-100 waves-effect waves-light ms-3"
                  onClick={() => {
                    navigate('/console/faq')
                  }}
                >
                  Cancel
                </Button>

                {(detailFaq?.status === DRAFTED ||
                  detailFaq?.status === REJECTED_BY_APPROVER ||
                  detailFaq?.status === APPROVED ||
                  !params.id) && (
                    <Button
                      type="button"
                      color="outline-primary"
                      id="drtf"
                      className="w-100 waves-effect waves-light ms-3 overflow-visible text-nowrap"
                      onClick={async () => {
                        setisDraFSave(true)
                        handleSubmit()
                        validator.current.purgeFields()
                      }}
                    >
                      Save
                    </Button>
                  )}

                <Button
                  type="submit"
                  color="primary"
                  block
                  className="w-100 waves-effect waves-light ms-3"
                  onClick={() => {
                    setisDraFSave(false)
                    handleSubmit()
                    validator.current.purgeFields()
                  }}
                >
                  {!params.id ? 'Submit' : 'Update'}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  sectionReducer,
  faqReducer,
  categoryReducer,
  lawReducer,
  createUserReducer,
  userCommonReducer,
  serviceLineReducer,
}) => {
  let faqDetails = []
  let categoryAndSubList = []
  let categoryList = []
  let lawDropdownList = []
  let sectionDropdownList = []
  let faqCreate = null
  let userDropdownList = []
  let publisherList = []
  let serviceLineDropdown = []
  let subCategoryList = []

  if (faqReducer.detailFaq && faqReducer.detailFaq.details) {
    faqDetails = faqReducer.detailFaq.details
  }
  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryList.list
    categoryList = categoryReducer.categoryList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  if (lawReducer.lawList && lawReducer.lawList.list?.length > 0) {
    lawDropdownList = lawReducer.lawList.list?.map((opt) => ({
      value: opt.id,
      label: opt.lawName,
    }))
  }

  if (
    sectionReducer.sectionListByLawIds &&
    sectionReducer.sectionListByLawIds?.length > 0
  ) {
    sectionDropdownList = sectionReducer?.sectionListByLawIds.map((opt) => ({
      value: opt.id,
      label: opt.sectionName,
    }))
  }

  if (
    createUserReducer.getUserListApiResponse &&
    createUserReducer.getUserListApiResponse?.data?.list?.length > 0
  ) {
    userDropdownList = createUserReducer.getUserListApiResponse?.data.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt.fullName,
      }),
    )
  }

  if (
    userCommonReducer?.publisherList &&
    userCommonReducer?.publisherList?.length > 0
  ) {
    publisherList = userCommonReducer?.publisherList.map((opt) => ({
      value: opt.id,
      label: opt.fullName,
    }))
  }

  if (faqReducer?.addFaq) {
    faqCreate = faqReducer?.addFaq
  }

  if (
    serviceLineReducer &&
    serviceLineReducer.serviceLineList?.list?.length > 0
  ) {
    serviceLineDropdown = serviceLineReducer.serviceLineList?.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt?.catName,
      }),
    )
  }

  if (
    categoryReducer.subCatList &&
    categoryReducer.subCatList?.list?.length > 0
  ) {
    subCategoryList = categoryReducer?.subCatList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  return {
    faqDetails,
    categoryAndSubList,
    categoryList,
    lawDropdownList,
    sectionDropdownList,
    faqCreate,
    listKeywords: faqReducer.listKeywords,
    userDropdownList: userDropdownList,
    publisherList: publisherList,
    serviceLineDropdown: serviceLineDropdown,
    subCategoryList: subCategoryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserListRequest: (data) => {
      dispatch(getUserListRequest(data))
    },
    getAllSectionList: (data) => {
      dispatch(getAllSectionListRequest(data))
    },
    clearFaqRepo: () => {
      dispatch(createFaqResponse(null))
    },
    createFaq: (data) => {
      dispatch(createFaqRequest(data))
    },
    getlistKeywords: () => {
      dispatch(getListKeywordsRequest())
    },
    getSubCatList: (data) => {
      dispatch(getSubCatListByCatIdRequest(data))
    },
    getServiceLineList: (data) => {
      dispatch(getServiceLineListRequest(data))
    },
    getSectionListByLawIdsRequest: (data) => {
      dispatch(getSectionListByLawIdsRequest(data))
    },
    getLawListByCategoryIdRequest: (data) => {
      dispatch(getLawListByCategoryIdRequest(data))
    },
    getCategoryListRequest: (data) => {
      dispatch(getCategoryListRequest(data))
    },
    getFaqDetailsRequest: (data) => {
      dispatch(getFaqDetailsRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateFAQ)
