import React, { useState, useEffect, useRef } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { Field, Formik } from 'formik'
import { connect } from 'react-redux'
import {
  consultationInitialState,
  getFirstLetters,
  statusBox,
} from './CommonFunction'
import { Button, Card, CardBody, Form, FormGroup, Label, Row } from 'reactstrap'
import { createReviewLawRequest } from 'store/faq/action'
import moment from 'moment'
import {
  checkFileExtension,
  dateFormater,
  statusLableChanges,
} from 'helpers/generalUtils'
import {
  APPROVED,
  DRAFTED,
  PENDING,
  PENDING_FOR_APPROVER,
  PUBLISHED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
} from 'constant/databaseConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { getValueByKey } from 'helpers/authUtils'
import { getCategoryListRequest } from 'store/master/category/action'
import {
  clearCreateDocumentResponse,
  clearTeamDelegateQueryResponse,
  createConsultationResponse,
  createConsultationReviewRequest,
  createDocumentRequest,
  createTeamDelegateQueryRequest,
  getConsultationDetailsRequest,
  getTeamListRequest,
  hideNotificationRequest,
  queryRespondRequest,
} from 'store/consultation/action'
import { getBaseUrl } from 'helpers/axiosHelper'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'

import { __t } from 'i18n/translator'
import Dropzone from 'react-dropzone'
import { customFileValidator } from 'helpers/SimpleReactValidators'
import CustomisedModal from 'components/Modal/CustomisedModal'

const ViewConsultationDetails = (props) => {
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const [consultationDetailData] = useState(consultationInitialState())

  const [commentList, setCommentList] = useState([])
  const [comment, setComment] = useState('')
  const [queryComment, setQueryComment] = useState('')
  const [isParentApiCall, setIsParentApiCall] = useState(false)
  const [userId] = useState(JSON.parse(getValueByKey('userId')))

  const [isComment, setIsComment] = useState(false)
  const [isPublisher, setIsPublisher] = useState(false)
  const viewDetailConsultationId = props.viewDetailPageData?.id
  const validator = useRef(new SimpleReactValidator())
  const [selectedFiles, setSelectedFiles] = useState([])
  const [isDocument, setIsDocument] = useState(false)
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [isModalType, setIsModalType] = useState('')
  const [queryDetails, setQueryDetails] = useState([])

  const initialTeamState = () => {
    return {
      delegateUserId: '',
      query: '',
    }
  }

  const [initialTeamValues, setTeamValues] = useState(initialTeamState())
  const [isDelegateTeam, setIsDelegateTeam] = useState(false)
  const [isApprove, setIsApprove] = useState(false)

  useEffect(() => {
    if (props.consultationCreate != null) {
      props.clearConsultationReponse()
      setComment("")
      setIsComment(false)
      props.resetViewDetailsData(isParentApiCall, location.state?.isActiveTab)
    }

    if (props.viewDetailPageData?.id) {
      props.getConsultationDetails(props.viewDetailPageData?.id)
    }
  }, [props.consultationCreate, props.viewDetailPageData?.id])

  const onModalDismiss = () => {
    setIsAuditModalOpen(false)
    // setSearchText('')
  }

  const isAdmin = () => {
    let isUserAdmin =
      permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
        ? true
        : false

    return isUserAdmin
  }

  useEffect(() => {
    if (props.viewDetailPageData?.id) {
      setIsDocument(false)
      setSelectedFiles([])
      setComment('')
      props.getConsultationDetails(props.viewDetailPageData?.id)
      props.clearCreateDocument()
    }
  }, [props.isCreateDocumentType])

  useEffect(() => {
    if (queryComment == '\n') {
      setQueryComment('')
    }
  }, [queryComment])

  useEffect(() => {
    props.getCategoryListRequest({
      searchText: '',
      type: 'CONSULTATION',
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })
  }, [])

  useEffect(() => {
    if (isDelegateTeam || props.consultationDetails?.delegateUserId) {
      props.getTeamList({
        createdBy: props.consultationDetails?.createdBy
          ? props.consultationDetails?.createdBy
          : '',
        catId: props.consultationDetails?.categoryDetails?.parentCategoryId
          ? props.consultationDetails?.categoryDetails?.parentCategoryId
          : '',
      })
    } else {
      setTeamValues(initialTeamState())
    }
  }, [isDelegateTeam, props.consultationDetails])

  useEffect(() => {
    if (props.reviewLawApiResponse == SUCCESS) {
      setIsParentApiCall(true)
      setIsComment(false)
    }
  }, [props.reviewLawApiResponse])

  useEffect(() => {
    if (props.consultationDetails?.approvalDetails) {
      setCommentList(
        props.consultationDetails?.approvalDetails.filter(
          (x) => x?.comment !== null && x?.comment !== '',
        ),
      )
    }
    let approvalUsers = props.consultationDetails?.approvalDetails?.filter(
      (x) =>
        x?.orderNo == 1 &&
        x?.workFlowId == props.consultationDetails?.workFlowId &&
        props.consultationDetails.status === APPROVED,
    )[0]
    if (approvalUsers != undefined) {
      setIsPublisher(true)
    }

    if (props.consultationDetails.delegateUserId) {
      setTeamValues({
        ...initialTeamValues,
        delegateUserId: props.consultationDetails.delegateUserId,
        query: '',
      })
    }
  }, [props.consultationDetails?.approvalDetails])

  const approveAndRejectData = (value) => {
    let approvalUsers = []

    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      approvalUsers = props.consultationDetails?.approvalDetails?.filter(
        (x) =>
          x?.approverId == userId &&
          x?.workFlowId == props.consultationDetails?.workFlowId,
      )[0]
    } else {
      approvalUsers = props.consultationDetails?.approvalDetails?.filter(
        (x) =>
          x?.workFlowId == props.consultationDetails?.workFlowId &&
          x?.status == PENDING,
      )[0]
    }

    let formatValue = {
      consultationId: viewDetailConsultationId,
      approverDetailsId: approvalUsers?.id ? approvalUsers.id : '',
      workFlowId: approvalUsers?.workFlowId ? approvalUsers?.workFlowId : '',
      status: value
        ? consultationDetails.status === PENDING_FOR_APPROVER
          ? APPROVED
          : PUBLISHED
        : consultationDetails.status === PENDING_FOR_APPROVER
          ? REJECTED_BY_APPROVER
          : REJECTED_BY_PUBLISHER,
      comment: comment,
    }
    setIsParentApiCall(true)

    props.createConsultationReview(formatValue)
  }

  const filterApproveAndRejectButton = () => {
    let isStatus = false
    const approvalUsers = props.consultationDetails?.approvalDetails?.filter(
      (x) =>
        x?.viewToUser == 1 &&
        x?.status != APPROVED &&
        x?.status != PUBLISHED &&
        // x?.status != PENDING &&
        x?.workFlowId == props.consultationDetails?.workFlowId,
    )?.[0]
    if (
      (permissions?.approve == 1 ||
        permissions?.publish == 1 ||
        permissions?.add == 1) &&
      (approvalUsers?.status != APPROVED ||
        approvalUsers?.status != PUBLISHED) &&
      consultationDetails?.status != APPROVED &&
      consultationDetails?.status != DRAFTED &&
      consultationDetails?.status != PUBLISHED &&
      consultationDetails?.status != REJECTED_BY_APPROVER
    ) {
      if (
        permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
      ) {
        isStatus = false
      } else {
        isStatus = true
      }
    }
    return isStatus
  }

  let consultationDetails = props.consultationDetails
  let lawList = []

  let categoryName
  let subCategoryName

  let status = ''
  let userList = []
  let documentDetails = []

  if (props.consultationDetails != '') {
    categoryName =
      props.consultationDetails?.categoryDetails?.parentCategoryName
    subCategoryName =
      props.consultationDetails?.categoryDetails?.subcategoryName

    lawList = props.consultationDetails?.lawList
      ? props.consultationDetails?.lawList?.filter((x) => x?.name)
      : []

    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      const approvalUsersList =
        props.consultationDetails?.approvalDetails?.filter(
          (x) =>
            x?.approverId == userId &&
            x?.workFlowId == props.consultationDetails?.workFlowId,
        )
      userList = approvalUsersList
    } else {
      const approvalUsersList =
        props.consultationDetails?.approvalDetails?.filter(
          (x) => x?.workFlowId == props.consultationDetails?.workFlowId,
        )
      userList = approvalUsersList
    }

    const approvalUsers = props.consultationDetails?.approvalDetails?.filter(
      (x) =>
        x?.approverId == userId &&
        x?.workFlowId == props.consultationDetails?.workFlowId,
    )?.[0]

    documentDetails = props.consultationDetails?.documentDetails

    if (
      (permissions?.approve == 1 ||
        permissions?.publish == 1 ||
        permissions?.add == 1) &&
      permissions?.add != 1 &&
      approvalUsers
    ) {
      status = approvalUsers?.status
    } else {
      status = props.consultationDetails?.status
    }
  }

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let formattedValues = {
        ...values,
        consultationRequestsId: viewDetailConsultationId,
        isAlreadyDelegate: props.consultationDetails?.delegateUserId
          ? true
          : false,
      }
      setIsParentApiCall(true)
      props.createTeamDelegateQuery(formattedValues)
    } else {
      validator.current.showMessages()
    }
  }

  useEffect(() => {
    if (props.createTeamDelegate == 'success') {
      setIsDelegateTeam(false)
      setTeamValues(initialTeamState())
      props.resetViewDetailsData(isParentApiCall, location.state?.isActiveTab)
      props.clearTeamDelegateQuery()
    }
  }, [props.createTeamDelegate])

  const handleFileUpload = (files) => {
    const chosenFiles = Array.prototype.slice.call(files)

    let errorsArray = []
    chosenFiles.map((file) => {
      if (!customFileValidator(file.name)) {
        errorsArray.push(
          'Must be a file of type: zip, pdf, jpg, jpeg, png, txt, doc, docx, csv, xlsx, xls.',
        )
        return false
      }
      if (file.size >= 5 * 1024 * 1024) {
        // Max size is 5 MB
        errorsArray.push('File size exceeds the limit of 5 MB.')
        return false
      }
    })
    if (errorsArray.length > 0) {
      ApiResponseMessage(__t(errorsArray[0]), DANGER)

      return false
    }
    if (chosenFiles) {
      let formData = new FormData()
      chosenFiles.map((x) => formData.append('file', x))

      chosenFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      setSelectedFiles([...selectedFiles, ...chosenFiles])
    }
  }

  const removeFile = async (file) => {
    const response = await commonConfirmBox(
      __t('alerts.are_you_sure'),
      __t('alerts.document_delete'),
    )
    if (response) {
      var array = [...selectedFiles] // make a separate copy of the array
      var index = array.indexOf(file)
      if (index !== -1) {
        array.splice(index, 1)
        setSelectedFiles(array)
      }
    }
  }

  const documentSubmit = () => {
    if (selectedFiles.length == 0) {
      ApiResponseMessage('At least one document is required', DANGER)
      return false
    }
    let formData = new FormData()
    formData.append('consultationId', props.viewDetailPageData?.id)
    selectedFiles.forEach((file) => {
      formData.append('files', file)
    })
    props.createDocument(formData)
  }

  const queryRespond = () => {
    if (queryComment.trim().length == 0) {
      ApiResponseMessage('Please enter comment', DANGER)
    } else {
      let formData = {
        assignedUserId: consultationDetails?.assignedToDelegateUserId,
        consultationRequestsId: viewDetailConsultationId,
        answer: queryComment,
        isDelegateUser:
          consultationDetails?.delegateUserId == userId ? true : false,
        delegateUserId: consultationDetails?.delegateUserId
          ? consultationDetails?.delegateUserId
          : '',
        isContentEditor: permissions.add && permissions.update ? true : false,
      }
      setQueryComment('')
      props.queryRespond(formData)
    }
  }

  useEffect(() => {
    if (isAuditModalOpen) {
      setQueryDetails(props.consultationDetails?.queryDetails)
    } else {
      setQueryDetails([])
    }
  }, [isAuditModalOpen, props.consultationDetails?.queryDetails])

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(scrollToBottom, [queryDetails])

  return (
    <>
      <Formik initialValues={consultationDetailData}>
        <div className="container faq-view-detail">
          <div className="section-head mt-4">
            <div>
              <Button
                className="back_btn"
                onClick={() => {
                  props.resetViewDetailsData(
                    isParentApiCall,
                    location.state?.isActiveTab,
                  )
                  setCommentList([])
                  setComment('')
                  setIsDelegateTeam(false)
                  setIsComment(false)
                }}
              >
                <svg
                  width="23"
                  height="18"
                  viewBox="0 0 23 18"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                </svg>
              </Button>
            </div>
            <div className="status-drop-box header-action-item">
              {consultationDetails.publishedVersion && (
                <div className="version-box">
                  <p>
                    Published Version : {consultationDetails?.publishedVersion}
                  </p>
                </div>
              )}

              {consultationDetails?.currentVersion && (
                <div className="version-box draft-version">
                  <p>
                    current Version : {consultationDetails?.currentVersion}{' '}
                  </p>
                </div>
              )}

              {consultationDetails?.delegateUserId && isAdmin() == false && (
                <div className="comments_btn">
                  <Button
                    onClick={() => {
                      setIsParentApiCall(true)
                      props.hideNotification({
                        isDelegateUser:
                          consultationDetails?.delegateUserId == userId
                            ? true
                            : false,
                        consultationRequestsId: viewDetailConsultationId,
                        isContentEditor:
                          permissions.add && permissions.update ? true : false,
                      })

                      setIsAuditModalOpen(!isAuditModalOpen)
                      setIsModalType('chat')
                    }}
                  >
                    <svg
                      width="117"
                      height="110"
                      viewBox="0 0 117 110"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_810_4)">
                        <path
                          d="M105.45 92.6024C103.436 90.0857 102.513 86.1008 102.304 84.423C105.45 80.6478 111.238 68.9497 111.742 58.3793C112.371 45.1663 102.933 36.3576 97.8994 32.5824C92.8659 28.8073 79.6529 25.0322 68.9566 26.2905C58.2604 27.5489 50.0809 31.5709 43.789 37.2336C38.7554 41.7638 36.8679 47.4733 36.2387 49.5706C35.6095 51.6679 33.6481 57.259 34.3511 64.2888C35.6095 76.8727 43.1598 83.5469 45.6765 86.0637C48.1933 88.5805 54.4852 92.6024 61.4063 94.49C66.9432 96.0001 78.3945 95.1192 83.428 94.49L85.9448 96.3776L90.3491 98.8943C92.6562 99.7333 98.2769 101.411 102.304 101.411C106.331 101.411 111.112 99.3138 113 98.2652C111.322 97.4262 107.463 95.1192 105.45 92.6024Z"
                          fill="#9BD732"
                        />
                      </g>
                      <path
                        d="M52.5976 51.0758V46.0422H94.1243V51.0758H52.5976Z"
                        fill="white"
                      />
                      <path
                        d="M52.5976 64.918V58.626H94.1243V64.918H52.5976Z"
                        fill="white"
                      />
                      <path
                        d="M62.6647 77.5018V72.4683H94.1243V77.5018H62.6647Z"
                        fill="white"
                      />
                      <g filter="url(#filter1_d_810_4)">
                        <path
                          d="M11.5503 66.5587C13.5637 64.042 14.4865 60.0571 14.6963 58.3792C11.5503 54.6041 5.76174 42.906 5.25838 32.3356C4.62919 19.1226 14.0671 10.3139 19.1006 6.53875C24.1341 2.7636 37.3471 -1.01155 48.0434 0.246835C58.7396 1.50522 66.9191 5.52719 73.211 11.1899C78.2446 15.7201 80.1321 21.4296 80.7613 23.5269C81.3905 25.6242 83.3519 31.2153 82.6489 38.2451C81.3905 50.829 73.8402 57.5032 71.3235 60.02C68.8067 62.5368 62.5148 66.5587 55.5937 68.4463C50.0568 69.9564 38.6055 69.0755 33.572 68.4463L31.0552 70.3339L26.6509 72.8506C24.3438 73.6896 18.7231 75.3674 14.6963 75.3674C10.6694 75.3674 5.88757 73.2701 4 72.2215C5.67784 71.3825 9.53688 69.0755 11.5503 66.5587Z"
                          fill="#4F2D7F"
                        />
                      </g>
                      <path
                        d="M64.4024 25.0321V19.9985H22.8757V25.0321H64.4024Z"
                        fill="white"
                      />
                      <path
                        d="M64.4024 38.8743V32.5824H22.8757V38.8743H64.4024Z"
                        fill="white"
                      />
                      <path
                        d="M54.3353 51.4581V46.4246H22.8757V51.4581H54.3353Z"
                        fill="white"
                      />
                      <defs>
                        <filter
                          id="filter0_d_810_4"
                          x="30.201"
                          y="26.0437"
                          width="86.799"
                          height="83.3674"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_810_4"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_810_4"
                            result="shape"
                          />
                        </filter>
                        <filter
                          id="filter1_d_810_4"
                          x="0"
                          y="0"
                          width="86.799"
                          height="83.3674"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_810_4"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_810_4"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>

                    {permissions.add && permissions.update ? (
                      consultationDetails?.isReviewedByAdminUser == 1 && (
                        <span className="notification_badge"></span>
                      )
                    ) : (
                      <>
                        {consultationDetails?.delegateUserId != userId &&
                          consultationDetails?.isReviewedByQueryUser == 0 ? (
                          <span className="notification_badge"></span>
                        ) : (
                          ''
                        )}
                        {consultationDetails?.delegateUserId == userId &&
                          consultationDetails?.isReviewedByTeam == 0 ? (
                          <span className="notification_badge"></span>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </Button>
                </div>
              )}

              <div className="attech_btn">
                <Button
                  onClick={() => {
                    setIsAuditModalOpen(!isAuditModalOpen)
                    setIsModalType('document')
                  }}
                >
                  <svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.375 11.4893L8.68203 19.1823C7.83811 20.0262 6.69351 20.5003 5.50003 20.5003C4.30655 20.5003 3.16195 20.0262 2.31803 19.1823C1.47411 18.3383 1 17.1937 1 16.0003C1 14.8068 1.47411 13.6622 2.31803 12.8183L13.258 1.87825C13.5367 1.59972 13.8675 1.3788 14.2315 1.22811C14.5956 1.07742 14.9857 0.999907 15.3797 1C15.7737 1.00009 16.1639 1.07779 16.5278 1.22865C16.8918 1.37951 17.2225 1.60059 17.501 1.87925C17.7796 2.15792 18.0005 2.48872 18.1512 2.85276C18.3019 3.2168 18.3794 3.60696 18.3793 4.00096C18.3792 4.39496 18.3015 4.78508 18.1506 5.14906C17.9998 5.51303 17.7787 5.84372 17.5 6.12225L6.55203 17.0703C6.26801 17.3425 5.88839 17.4913 5.49497 17.4863C5.10156 17.4813 4.72585 17.322 4.44883 17.0426C4.1718 16.7632 4.01564 16.3862 4.01399 15.9927C4.01234 15.5993 4.16535 15.2209 4.44003 14.9393L12.25 7.12925M6.56103 17.0603L6.55103 17.0703"
                      stroke="currentColor"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Documents
                  <span className="notification_badge">
                    {consultationDetails?.documentDetails?.length > 0
                      ? consultationDetails?.documentDetails?.length
                      : 0}
                  </span>
                </Button>
              </div>

              <div className="user-box">
                <div className="first_name_td">
                  {userList?.map((user) => (
                    <span
                      className="my-tooltip short-name"
                      data-tooltip-content={user?.name}
                    >
                      {getFirstLetters(user?.name)}
                    </span>
                  ))}
                </div>
              </div>
              <div className="notification-list">
                <span
                  className={
                    'faq-list-status-box ' +
                    statusBox(status ? status : 'Draft')
                  }
                ></span>
                {status === 'Drafted' ? 'Saved' : statusLableChanges(status)}
              </div>

              {((permissions?.add == 1 &&
                (consultationDetails.status == REJECTED_BY_APPROVER ||
                  consultationDetails.status == REJECTED_BY_PUBLISHER ||
                  consultationDetails.status == PUBLISHED ||
                  consultationDetails.status == APPROVED)) ||
                consultationDetails.status == DRAFTED) &&
                isAdmin() == false && (
                  <Link
                    to={
                      '/console/consultationRequest/edit/' +
                      viewDetailConsultationId
                    }
                    className="creat_btn edit_faq_btn"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.1208 7.42339L15.9168 5.62738C16.462 5.08213 16.7346 4.80951 16.8804 4.51541C17.1577 3.95586 17.1577 3.29891 16.8804 2.73935C16.7346 2.44526 16.462 2.17263 15.9168 1.62738C15.3715 1.08213 15.0989 0.809509 14.8048 0.663773C14.2452 0.38649 13.5883 0.38649 13.0287 0.663773C12.7346 0.809509 12.462 1.08213 11.9168 1.62738L10.0981 3.44604C11.062 5.09664 12.4481 6.4722 14.1208 7.42339ZM8.64365 4.9005L1.77314 11.771C1.34808 12.1961 1.13555 12.4086 0.995818 12.6697C0.856084 12.9308 0.797138 13.2255 0.679248 13.815L0.0638519 16.8919C-0.00267025 17.2246 -0.0359313 17.3909 0.0586767 17.4855C0.153285 17.5801 0.31959 17.5468 0.6522 17.4803L3.72918 16.8649C4.31863 16.747 4.61336 16.6881 4.87446 16.5483C5.13555 16.4086 5.34808 16.1961 5.77315 15.771L12.6625 8.8816C11.0409 7.86595 9.66919 6.50366 8.64365 4.9005Z"
                        fill="CurrentColor"
                      />
                    </svg>
                    Edit Consultation
                  </Link>
                )}
              <div className="btn-box">
                {consultationDetails?.createdBy != userId &&
                  consultationDetails?.delegateUserId != userId &&
                  !permissions.publish &&
                  filterApproveAndRejectButton() &&
                  !isDelegateTeam && (
                    <div className="approve_btn">
                      <Button
                        // className="my-tooltip"
                        // data-tooltip-content={'Reject'}
                        color="danger"
                        onClick={async () => {
                          setIsApprove(false)
                          if (comment == '') {
                            setIsComment(true)
                            setIsApprove(false)
                            if (isComment) {
                              ApiResponseMessage('Please enter comment', DANGER)
                            }
                          } else {
                            const response = await commonConfirmBox(
                              'Do you want to send back this Consultation for review?',
                            )
                            if (response) {
                              setComment('')
                              approveAndRejectData(false)
                            }
                          }
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="9"
                            stroke="CurrentColor"
                            strokeWidth="2"
                          />
                          <path
                            d="M18 18L6 6"
                            stroke="CurrentColor"
                            strokeWidth="2"
                          />
                        </svg>
                        Send Back for Review
                      </Button>
                      <Button
                        // className="my-tooltip"
                        // data-tooltip-content={'Approve'}
                        color="success"
                        onClick={async () => {
                          setIsComment(true)
                          setIsApprove(true)
                          if (isComment) {
                            const response = await commonConfirmBox(
                              isPublisher
                                ? 'Do you want to publish this Consultation Request?'
                                : 'Do you want to approve this Consultation Request?',
                            )
                            if (response) {
                              setComment('')
                              setIsComment(false)
                              approveAndRejectData(true)
                            }
                          }
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5"
                            stroke="CurrentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667"
                            stroke="CurrentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                        {isPublisher ? 'Publish' : 'Approve'}
                      </Button>
                    </div>
                  )}

                {consultationDetails?.isTeamDelegate == true &&
                  consultationDetails?.createdBy != userId &&
                  consultationDetails?.delegateUserId != userId &&
                  consultationDetails.approvalDetails?.length > 0 &&
                  filterApproveAndRejectButton() &&
                  !isDelegateTeam && (
                    <Button
                      className="btn btn-info"
                      onClick={() => {
                        setIsDelegateTeam(true)
                      }}
                    >
                      {consultationDetails?.delegateUserId
                        ? 'Change Delegate to Team Member'
                        : 'Delegate to Team Member'}
                    </Button>
                  )}
              </div>
            </div>
          </div>
          <div className="faq-view-detail-inner-box consultation-view-detail-inner-box">
            <Card className="p-0 mb-3">
              <CardBody className="row">
                <div className="col-3">
                  <div className="faq-form-box">
                    <label>Service Line </label>
                    <span>{categoryName}</span>
                  </div>
                </div>

                <div className="col-3">
                  <div className="faq-form-box">
                    <label>Category</label>
                    <span>{subCategoryName}</span>
                  </div>
                </div>

                <div className="col-3">
                  <div className="faq-form-box mark_btn">
                    <div className="d-flex justify-content-between">
                      <label>Laws</label>
                      {consultationDetails.isRevision == 1 && (
                        <a
                          onClick={async () => {
                            const response = await commonConfirmBox(
                              'Do you want to mark as read update Law?',
                            )
                            if (response) {
                              props.createReviewLawRequest({
                                faqId: viewDetailConsultationId,
                                isReviewed: false,
                                type: 'LAW',
                              })
                            }
                          }}
                          className="btn btn-outline-info"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="8"
                              cy="8"
                              r="8"
                              fill="currentColor"
                              fillOpacity="1"
                            />
                            <path
                              d="M3 8L6.23299 10.9097C6.29003 10.961 6.37663 10.961 6.43368 10.9097L13 5"
                              stroke="#fff"
                              strokeWidth="1.2"
                            />
                          </svg>
                          mark as read
                        </a>
                      )}
                    </div>

                    <span className="law_badge d-inline-flex flex-wrap">
                      {lawList?.map((x, index) => (
                        <div key={index}>
                          {x?.revisionDescription ? (
                            <small> {x.name} </small>
                          ) : (
                            x.name
                          )}
                          {index === lawList.length - 1 ? '' : ','}
                        </div>
                      ))}
                      {lawList.length == 0 && 'Not Available'}
                    </span>
                  </div>
                </div>

                <div className="col-3 mt-2">
                  <div className="faq-form-box">
                    <label>Last updated</label>
                    <span>
                      {consultationDetails.updatedDate == null
                        ? 'Not available'
                        : moment(consultationDetails.updatedDate).format(
                          'DD-MM-YYYY',
                        )}
                    </span>
                  </div>
                </div>

                <div className="col-3 mt-2">
                  <div className="faq-form-box">
                    <label>Created By</label>
                    <span>
                      {props.viewDetailPageData?.createdByUser?.fullName}
                    </span>
                  </div>
                </div>
                {props.viewDetailPageData?.approvalWorkFlow?.approvalDetails[0]
                  ?.user?.fullName && (
                    <div className="col-3 mt-2">
                      <div className="faq-form-box">
                        <label>Approver</label>
                        <span>
                          {
                            props.viewDetailPageData?.approvalWorkFlow
                              ?.approvalDetails[0]?.user?.fullName
                          }
                        </span>
                      </div>
                    </div>
                  )}

                {(consultationDetails?.delegateUserName ||
                  consultationDetails?.assignedToDelegateUserName) &&
                  consultationDetails?.createdBy != userId && (
                    <div className="col-3 mt-2">
                      <div className="faq-form-box">
                        <label>
                          {' '}
                          {props.consultationDetails?.approvalDetails.length > 0
                            ? 'Delegated User'
                            : 'Assigned User'}
                        </label>
                        <span>
                          {props.consultationDetails?.approvalDetails.length > 0
                            ? consultationDetails?.delegateUserName
                            : consultationDetails?.assignedToDelegateUserName}
                        </span>
                      </div>
                    </div>
                  )}
              </CardBody>
            </Card>
            <Card className="p-0 mb-3">
              <CardBody>
                <h5>{consultationDetails.heading}</h5>
                <div
                  className="JoditEditor-border"
                  dangerouslySetInnerHTML={{
                    __html: consultationDetails.answer,
                  }}
                />
              </CardBody>
            </Card>

            {commentList?.length > 0 && (
              <Card className="p-0 mb-3">
                <CardBody>
                  <div className="">
                    <h6 className="separate-head">Comments</h6>
                    <ul className="comment-box">
                      {commentList?.map(
                        (item, index) =>
                          item.comment &&
                          item.comment.length > 0 && (
                            <li key={index}>
                              <div className="user-box">
                                <div className="first_name_td">
                                  <span className="short-name">
                                    {getFirstLetters(item?.commentBy)}
                                  </span>
                                </div>
                              </div>
                              <div className="con-box">
                                <div>
                                  <h6>{item?.commentBy}</h6>
                                  {item?.date && (
                                    <span>{dateFormater(item?.date)} </span>
                                  )}
                                </div>
                                <p>{item?.comment}</p>
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            )}

            {consultationDetails?.createdBy != userId &&
              consultationDetails?.delegateUserId != userId &&
              filterApproveAndRejectButton() &&
              !isDelegateTeam &&
              isComment && (
                <Card className="p-0 mb-3 enter_comment">
                  <CardBody>
                    <h6 className="separate-head d-flex justify-content-between align-items-center">
                      <span>
                        Enter Comment
                        {!isApprove && (
                          <span className="text-danger required"> *</span>
                        )}
                      </span>

                      <div className="enter_comment_btn">
                        <Button
                          color="primary"
                          onClick={async () => {
                            if (isApprove) {
                              if (isComment) {
                                const response = await commonConfirmBox(
                                  isPublisher
                                    ? 'Do you want to publish this Consultation Request?'
                                    : 'Do you want to approve this Consultation Request?',
                                )
                                if (response) {
                                  setComment('')
                                  setIsComment(false)
                                  approveAndRejectData(true)
                                }
                              }
                            } else if (comment == '') {
                              setIsComment(true)
                              if (isComment) {
                                ApiResponseMessage(
                                  'Please enter comment',
                                  DANGER,
                                )
                              }
                            } else {
                              const response = await commonConfirmBox(
                                'Do you want to send back this Consultation for review?',
                              )
                              if (response) {
                                approveAndRejectData(false)
                              }
                            }
                          }}
                        >
                          Submit
                        </Button>

                        <Button
                          className="close_sign"
                          onClick={() => {
                            setComment('')
                            setIsComment(false)
                          }}
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.02602 6.49908L12.6799 1.8554C12.8837 1.65158 12.9982 1.37513 12.9982 1.08687C12.9982 0.798614 12.8837 0.522165 12.6799 0.318337C12.4761 0.114509 12.1997 0 11.9114 0C11.6232 0 11.3468 0.114509 11.143 0.318337L6.5 4.97283L1.85699 0.318337C1.65319 0.114509 1.37678 -2.14767e-09 1.08856 0C0.800348 2.14767e-09 0.523938 0.114509 0.320139 0.318337C0.11634 0.522165 0.00184703 0.798614 0.00184703 1.08687C0.00184703 1.37513 0.11634 1.65158 0.320139 1.8554L4.97397 6.49908L0.320139 11.1427C0.218698 11.2434 0.138182 11.3631 0.0832357 11.495C0.0282893 11.6269 0 11.7684 0 11.9113C0 12.0542 0.0282893 12.1957 0.0832357 12.3276C0.138182 12.4595 0.218698 12.5792 0.320139 12.6798C0.420751 12.7813 0.540454 12.8618 0.67234 12.9168C0.804227 12.9717 0.945688 13 1.08856 13C1.23144 13 1.3729 12.9717 1.50479 12.9168C1.63667 12.8618 1.75637 12.7813 1.85699 12.6798L6.5 8.02532L11.143 12.6798C11.2436 12.7813 11.3633 12.8618 11.4952 12.9168C11.6271 12.9717 11.7686 13 11.9114 13C12.0543 13 12.1958 12.9717 12.3277 12.9168C12.4595 12.8618 12.5792 12.7813 12.6799 12.6798C12.7813 12.5792 12.8618 12.4595 12.9168 12.3276C12.9717 12.1957 13 12.0542 13 11.9113C13 11.7684 12.9717 11.6269 12.9168 11.495C12.8618 11.3631 12.7813 11.2434 12.6799 11.1427L8.02602 6.49908Z"
                              fill="currentColor"
                            />
                          </svg>
                        </Button>
                      </div>
                    </h6>
                    <textarea
                      name="comment"
                      placeholder="Enter comment.."
                      maxLength={200}
                      className="form-control"
                      onChange={(e) => setComment(e.target.value)}
                      value={comment}
                    ></textarea>
                  </CardBody>
                </Card>
              )}

            {isDelegateTeam &&
              consultationDetails?.isTeamDelegate == true &&
              filterApproveAndRejectButton() && (
                <Formik
                  initialValues={initialTeamValues}
                  onSubmit={formSubmit}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue, handleSubmit }) => (
                    <Card className="p-0 mb-3 enter_comment delegate_user">
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <h6 className="separate-head">Team Member</h6>
                          <div className="d-flex justify-content-end">
                            <Button
                              type="button"
                              onClick={() => {
                                handleSubmit()
                              }}
                              color="primary"
                              className="waves-effect waves-light"
                            >
                              Submit
                            </Button>
                            <Button
                              type="button"
                              className="waves-effect waves-light ms-3"
                              onClick={() => {
                                setIsDelegateTeam(false)
                              }}
                              color="outline-primary"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <Form className="form-horizontal form-login ">
                          <Row className="mt-3">
                            <FormGroup className="col-4 mb-0">
                              <Field
                                menuPlacement="top"
                                component={Select}
                                name="delegateUserId"
                                id="delegateUserId"
                                className="form-control selectbox"
                                options={props?.teamList.filter(
                                  (x) =>
                                    x.value !=
                                    props.viewDetailPageData?.approvalWorkFlow
                                      ?.approvalDetails[0]?.user?.approverId,
                                )}
                                // props.consultationDetails
                                //       ?.approvalDetails[0]?.approverId,
                                onChange={(opt) => {
                                  setFieldValue('delegateUserId', opt?.value)
                                }}
                                value={props?.teamList?.filter(
                                  (option) =>
                                    option?.value === values?.delegateUserId,
                                )}
                                isClearable={true}
                              />
                              <Label className="form-label">
                                Delegate User{' '}
                                <span className="text-danger required">*</span>
                              </Label>
                              {validator.current.message(
                                __t(
                                  'validations.consultation.delegate_user_required',
                                ),
                                values.delegateUserId,
                                'required',
                                {
                                  messages: {
                                    required: __t(
                                      'validations.consultation.delegate_user_required',
                                    ),
                                  },
                                },
                              )}
                            </FormGroup>
                            <FormGroup className="col-8 mb-0">
                              <Field
                                as="textarea"
                                type="textarea"
                                name="query"
                                id="query"
                                className="form-control"
                              />
                              <Label className="form-label">
                                Query / Comment{' '}
                                <span className="text-danger required">*</span>
                              </Label>
                              {validator.current.message(
                                __t('validations.common.query_required'),
                                values.query,
                                'required',
                                {
                                  messages: {
                                    required: __t(
                                      'validations.common.query_required',
                                    ),
                                  },
                                },
                              )}
                            </FormGroup>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  )}
                </Formik>
              )}

            {/* History Section and appove/reject */}
          </div>
        </div>
      </Formik>
      <CustomisedModal
        isModalOpen={isAuditModalOpen}
        modalSize="modal-dialog-scrollable side_slider auditlog_modal attach_file_modal"
        onModalDismiss={() => {
          onModalDismiss()
          setSelectedFiles([])
          setIsDocument(false)
        }}
        modalName={isModalType == 'document' ? 'Documents' : 'Query / Comment'}
      >
        {isModalType == 'document' ? (
          <Card className="">
            <CardBody>
              <div className="faq-form-box">
                {!isDocument && isAdmin() == false && (
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn btn-info"
                      onClick={() => {
                        setIsDocument(true)
                      }}
                    >
                      Add Documents
                    </Button>
                  </div>
                )}
                {isDocument && (
                  <Dropzone
                    multiple={true}
                    onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div className="dropzone py-3">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="drop_msg">
                              <div className="file-icon">
                                <svg
                                  width="22"
                                  height="25"
                                  viewBox="0 0 22 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {' '}
                                  <rect
                                    x="1"
                                    y="1"
                                    width="17.5"
                                    height="22.5"
                                    rx="2"
                                    fill="currentColor"
                                    fillOpacity="0.25"
                                  />{' '}
                                  <path
                                    d="M4.75 14.75L12.25 14.75"
                                    stroke="currentColor"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                  />{' '}
                                  <path
                                    d="M4.75 11L9.75 11"
                                    stroke="currentColor"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                  />{' '}
                                  <path
                                    d="M4.75 18.5L9.75 18.5"
                                    stroke="currentColor"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                  />{' '}
                                  <path
                                    d="M18.5 13.5V17.5C18.5 20.3284 18.5 21.7426 17.6213 22.6213C16.7426 23.5 15.3284 23.5 12.5 23.5H7C4.17157 23.5 2.75736 23.5 1.87868 22.6213C1 21.7426 1 20.3284 1 17.5V7C1 4.17157 1 2.75736 1.87868 1.87868C2.75736 1 4.17157 1 7 1H9.75"
                                    stroke="currentColor"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                  />{' '}
                                  <path
                                    d="M17.25 1L17.25 8.5"
                                    stroke="currentColor"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                  />{' '}
                                  <path
                                    d="M21 4.75L13.5 4.75"
                                    stroke="currentColor"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                  />{' '}
                                </svg>
                              </div>
                              <div className="dropzone-title">
                                Drag and drop your files or
                                <span className="browse"> browse</span> your
                                files. <br></br>( zip, pdf, jpg, jpeg, png, txt,
                                doc, docx, csv, xlsx, xls.)
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dropzone_bottom_btn">
                          <Button
                            className="btn btn-info"
                            onClick={() => {
                              documentSubmit()
                            }}
                          >
                            Add
                          </Button>
                          <Button
                            className="btn"
                            onClick={() => {
                              setSelectedFiles([])
                              setIsDocument(false)
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    )}
                  </Dropzone>
                )}
                <ul className="p-0 m-0 mt-1 attachment_preview">
                  {selectedFiles &&
                    selectedFiles?.map((f, i) => {
                      return (
                        <li className="attachment-data-box">
                          <Link to={f.preview} title={f.name} target="_blank">
                            {checkFileExtension(f.name)}
                            <a
                              href="javascript:void(0);"
                              className="remove-box"
                              title="remove"
                              onClick={async () => {
                                removeFile(f)
                              }}
                            >
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M1.3335 4H14.6668"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M6.3335 7.33325V12.3333"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M9.6665 7.33325V12.3333"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </a>
                            <span className="attchment-file_name">
                              {f.name}
                            </span>
                          </Link>
                        </li>
                      )
                    })}
                  {documentDetails?.map((file, index) => (
                    <li className="attachment-data-box">
                      <a
                        href={
                          getBaseUrl() +
                          '/consultationRequests/' +
                          props.viewDetailPageData?.id +
                          '/' +
                          file.documentPath
                        }
                        title={file.documentPath}
                        target="_blank"
                      >
                        {checkFileExtension(file.documentPath)}

                        <div className="attchment-file">
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="attchment-file_name">
                              {file.documentPath}
                            </span>
                            <span className="attchment-date">
                              {dateFormater(file?.createdDate)}
                            </span>
                          </div>
                          <p className="attchment-user_name">
                            <svg
                              className="attchment-user_icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="24"
                              viewBox="0 0 23 24"
                              fill="none"
                            >
                              <path
                                d="M11.2147 12.8906C14.6372 12.8906 17.4121 10.0047 17.4121 6.44531C17.4121 2.88594 14.6372 0 11.2147 0C7.79222 0 5.01728 2.88594 5.01728 6.44531C5.02103 10.0031 7.79447 12.8867 11.2147 12.8906ZM11.2147 1.95312C13.6005 1.95312 15.5341 3.96406 15.5341 6.44531C15.5341 8.92656 13.6005 10.9375 11.2147 10.9375C8.82888 10.9375 6.89528 8.92656 6.89528 6.44531C6.89829 3.96563 8.83038 1.95625 11.2147 1.95312ZM22.4061 22.6437C21.2387 17.25 16.6722 13.2812 11.2139 13.2812C5.75571 13.2812 1.18915 17.25 0.0360577 22.5641L0.0217849 22.6437C0.00826325 22.7062 0 22.7781 0 22.8516C0 23.3906 0.420673 23.8281 0.939003 23.8281C1.38672 23.8281 1.76157 23.5016 1.85547 23.0656L1.85697 23.0594C2.83429 18.5516 6.65189 15.2344 11.2147 15.2344C15.7775 15.2344 19.5944 18.5508 20.5596 22.9922L20.5717 23.0586C20.6663 23.5008 21.0397 23.8273 21.4874 23.8273C21.5587 23.8273 21.6279 23.8188 21.6947 23.8031L21.6887 23.8047C22.1146 23.707 22.4279 23.318 22.4279 22.8523C22.4279 22.7781 22.4196 22.7062 22.4046 22.6367L22.4061 22.6437Z"
                                fill="currentColor"
                                fillOpacity="0.88"
                              />
                            </svg>
                            {file?.userName}
                          </p>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardBody>
              <ul className="comments_section">
                {queryDetails?.map((value) => {
                  return (
                    <li
                      className={
                        value?.createdBy != userId ? 'query' : 'answer'
                      }
                      key={value?.id}
                    >
                      <div className="comments_part">
                        <div className="comments_bottom">
                          <span className="comments_user">
                            {value.userName}
                          </span>
                        </div>
                        <div className="comments_box">
                          <span className="comment">
                            {value.query != null ? value.query : value.answer}
                            <span className="comments_date">
                              {dateFormater(value.date)}
                            </span>
                          </span>
                        </div>
                        <div className="comments_bottom"></div>
                      </div>
                    </li>
                  )
                })}
                <div ref={messagesEndRef}></div>
              </ul>
              <div className="comment_text_box">
                <textarea
                  name="comment"
                  placeholder="Enter comment.."
                  maxLength={200}
                  className="form-control"
                  onChange={(e) => setQueryComment(e.target.value)}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter' && !evt.shiftKey) {
                      queryRespond()
                    }
                  }}
                  value={queryComment}
                ></textarea>
                {consultationDetails?.delegateUserId && (
                  <Button onClick={() => queryRespond()}>
                    <svg
                      width="38"
                      height="32"
                      viewBox="0 0 38 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.6897 7.63793L2 13.6724L12.2586 19.7069C18.4943 14.6782 31.3276 4.5 32.7759 4.01724C34.2241 3.53448 33.7816 4.62069 33.3793 5.22414L14.069 20.9138L26.1379 30.569L30.9655 19.1034L37 1L17.6897 7.63793Z"
                        fill="currentColor"
                        stroke="currentColor"
                      />
                      <path
                        d="M13.1748 22.4003L12.3424 21.732L12.3619 22.7993L12.4722 28.8328L12.4917 29.9001L13.2992 29.2018L16.8641 26.1189L17.318 25.7264L16.8501 25.3508L13.1748 22.4003Z"
                        stroke="currentColor"
                      />
                    </svg>
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </CustomisedModal>
    </>
  )
}

const mapStateToProps = ({ consultationReducer, categoryReducer }) => {
  let consultationDetails = []

  let consultationCreate = null
  let reviewLawApiResponse = null
  let categoryAndSubList = []
  let teamList = []
  let createTeamDelegate = false
  let isCreateDocumentType = false

  if (
    consultationReducer.consultationDetails &&
    consultationReducer.consultationDetails.details
  ) {
    consultationDetails = consultationReducer?.consultationDetails?.details
  }

  if (consultationReducer.reviewLawApiResponse) {
    reviewLawApiResponse = consultationReducer.reviewLawApiResponse.responseType
  }

  if (consultationReducer?.addConsultation) {
    consultationCreate = consultationReducer?.addConsultation
  }
  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryList.list
  }

  if (consultationReducer.createTeamDelegateApiResponse) {
    createTeamDelegate =
      consultationReducer.createTeamDelegateApiResponse.responseType
  }

  if (consultationReducer.createDocumentApiResponse) {
    isCreateDocumentType =
      consultationReducer.createDocumentApiResponse.responseType
  }

  if (consultationReducer.teamList) {
    teamList = consultationReducer?.teamList?.list?.map((opt) => ({
      value: opt.User.id,
      label: opt.User.fullName,
    }))
  }

  return {
    consultationDetails,
    teamList,
    consultationCreate,
    reviewLawApiResponse,
    categoryAndSubList,
    createTeamDelegate,
    isCreateDocumentType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConsultationDetails: (data) => {
      dispatch(getConsultationDetailsRequest(data))
    },
    createConsultationReview: (data) => {
      dispatch(createConsultationReviewRequest(data))
    },
    clearConsultationReponse: () => {
      dispatch(createConsultationResponse(null))
    },
    createReviewLawRequest: (data) => {
      dispatch(createReviewLawRequest(data))
    },
    getCategoryListRequest: (data) => {
      dispatch(getCategoryListRequest(data))
    },
    getTeamList: (data) => {
      dispatch(getTeamListRequest(data))
    },
    createTeamDelegateQuery: (data) => {
      dispatch(createTeamDelegateQueryRequest(data))
    },
    createDocument: (data) => {
      dispatch(createDocumentRequest(data))
    },
    clearCreateDocument: (data) => {
      dispatch(clearCreateDocumentResponse(data))
    },
    queryRespond: (data) => {
      dispatch(queryRespondRequest(data))
    },

    hideNotification: (data) => {
      dispatch(hideNotificationRequest(data))
    },
    clearTeamDelegateQuery: () => {
      dispatch(clearTeamDelegateQueryResponse())
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewConsultationDetails)
