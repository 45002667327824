import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Formik, Field } from 'formik'
import Select from 'react-select'
import { connect } from 'react-redux'
import { adminTranslateLanguage, faqInitialState, statusBox } from './CommonFunction'
import { Button, Card, CardBody, FormGroup, Row, Label, CardHeader } from 'reactstrap'
import {
  createFaqResponse,
  createFaqReviewRequest,
  createReviewLawRequest,
  getFaqDetailsRequest,
} from 'store/faq/action'
import moment from 'moment'
import { dateFormater, statusLableChanges } from 'helpers/generalUtils'
import {
  APPROVED,
  DRAFT,
  DRAFTED,
  PENDING,
  PENDING_FOR_APPROVER,
  PUBLISHED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
} from 'constant/databaseConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER, EN, JPN, SUCCESS } from 'constant/commonConstants'
import RevisionModal from './RevisionModal'
import { commonAleartBox, commonConfirmBox } from 'helpers/messagehelpers'
import { getValueByKey } from 'helpers/authUtils'
import { getFirstLetters } from 'pages/ConsultationRequest/CommonFunction'
import { isUrl } from 'helpers/SimpleReactValidators'


const ViewFaqDetails = (props) => {
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const [faqDetailData] = useState(faqInitialState())
  const navigate = useNavigate()
  const [isRevisionModal, setIsRevisionModal] = useState(false)
  const [commentList, setCommentList] = useState([])
  const [comment, setComment] = useState('')
  const [isParentApiCall, setIsParentApiCall] = useState(false)
  const [userId] = useState(JSON.parse(getValueByKey('userId')))
  const [isComment, setIsComment] = useState(false)
  const [isPublisher, setIsPublisher] = useState(false)
  const [IsRevisionType, setIsRevisionType] = useState('SECTION')
  const [lablesName, setLablesName] = useState([])
  const viewDetailFaqId = props.viewDetailPageId
  const [isApprove, setIsApprove] = useState(false)
  const [activeLanguage, setActiveLanguage] = useState(EN);
  useEffect(() => {
    if (props.faqCreate != null) {
      setComment("")
      setIsComment(false)
      props.clearFaqRepo()
      props.resetViewDetailsData(isParentApiCall, location.state?.isActiveTab)
    }

    if (props.viewDetailPageId) {
      props.getFaqDetails(props.viewDetailPageId)
    }
  }, [props.faqCreate, props.viewDetailPageId])

  useEffect(() => {
    if (props.reviewLawApiResponse == SUCCESS) {
      setIsRevisionModal(false)
      setIsParentApiCall(true)
    }
  }, [props.reviewLawApiResponse])

  useEffect(() => {
    if (
      props.faqDetails?.categoryDetails?.parentCategoryId &&
      props.categoryAndSubList
    ) {
      setLables(props.faqDetails?.categoryDetails?.parentCategoryId)
    }
  }, [props?.faqDetails, props.categoryAndSubList])

  useEffect(() => {
    if (props.faqDetails?.approvalDetails) {
      setCommentList(
        props.faqDetails?.approvalDetails.filter(
          (x) => x?.comment !== null && x?.comment !== '',
        ),
      )
    }
    let approvalUsers = props.faqDetails?.approvalDetails?.filter(
      (x) =>
        x?.orderNo == 1 &&
        x?.workFlowId == props.faqDetails?.workFlowId &&
        props.faqDetails.status === APPROVED,
    )[0]
    if (approvalUsers != undefined) {
      setIsPublisher(true)
    }
  }, [props.faqDetails?.approvalDetails])

  const approveAndRejectData = (value) => {
    let approvalUsers = []

    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      approvalUsers = props.faqDetails?.approvalDetails?.filter(
        (x) =>
          x?.approverId == userId &&
          x?.workFlowId == props.faqDetails?.workFlowId,
      )[0]
    } else {
      approvalUsers = props.faqDetails?.approvalDetails?.filter(
        (x) =>
          x?.workFlowId == props.faqDetails?.workFlowId && x?.status == PENDING,
      )[0]
    }

    let formatValue = {
      faqId: viewDetailFaqId,
      approverDetailsId: approvalUsers?.id ? approvalUsers.id : '',
      workFlowId: approvalUsers?.workFlowId ? approvalUsers?.workFlowId : '',
      status: value
        ? faqDetails.status === PENDING_FOR_APPROVER
          ? APPROVED
          : PUBLISHED
        : faqDetails.status === PENDING_FOR_APPROVER
          ? REJECTED_BY_APPROVER
          : REJECTED_BY_PUBLISHER,
      comment: comment,
    }
    setIsParentApiCall(true)
    props.createFaqReviewRequest(formatValue)
  }
  const setLables = (id) => {
    let categoryData = props.categoryAndSubList?.filter((x) => x.id == id)[0]

    if (categoryData) {
      setLablesName({
        labelForCategory: categoryData.labelForCategory,
        labelForLaws: categoryData.labelForLaws,
        labelForSections: categoryData.labelForSections,
      })
    }
  }

  const filterApproveAndRejectButton = () => {
    let isStatus = false
    const approvalUsers = props.faqDetails?.approvalDetails?.filter(
      (x) =>
        x?.viewToUser == 1 &&
        x?.status == PENDING &&
        x?.workFlowId == props.faqDetails?.workFlowId,
    )?.[0]

    if (
      faqDetails?.approvalDetails?.length > 0 &&
      approvalUsers?.status == PENDING &&
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      faqDetails?.status != DRAFT &&
      faqDetails?.status != PUBLISHED
    ) {
      isStatus = true
    }
    return isStatus
  }

  let faqDetails = props.faqDetails
  let lawList = []
  let sectionList = []
  let categoryName
  let subCategoryName
  let keyWords = []
  let refrence

  let status = ''
  let userList = []
  let isRevision = false

  if (props.faqDetails != '') {
    categoryName = props.faqDetails?.categoryDetails?.parentCategoryName
    subCategoryName = props.faqDetails?.categoryDetails?.subcategoryName

    lawList = props.faqDetails?.lawList
      ? props.faqDetails?.lawList?.filter((x) => x?.name)
      : ''
    let isLawListDescription = props.faqDetails?.lawList?.filter(
      (x) => x?.revisionDescription,
    )
    isRevision = isLawListDescription?.length > 0 ? true : false

    //sectionList = props.faqDetails?.sectionsList?.map((x) => x.name)
    sectionList = props.faqDetails?.sectionsList
      ? props.faqDetails?.sectionsList?.filter((x) => x?.name)
      : ''
    keyWords = props.faqDetails?.keywords
    refrence = props.faqDetails?.reference

    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      const approvalUsersList = props.faqDetails?.approvalDetails?.filter(
        (x) =>
          x?.approverId == userId &&
          x?.workFlowId == props.faqDetails?.workFlowId,
      )
      userList = approvalUsersList
    } else {
      const approvalUsersList = props.faqDetails?.approvalDetails?.filter(
        (x) => x?.workFlowId == props.faqDetails?.workFlowId,
      )
      userList = approvalUsersList
    }

    const approvalUsers = props.faqDetails?.approvalDetails?.filter(
      (x) =>
        x?.approverId == userId &&
        x?.workFlowId == props.faqDetails?.workFlowId,
    )?.[0]

    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      status = approvalUsers?.status
    } else {
      status = props.faqDetails?.status
    }
  }
  const pRef = useRef(null);
  useEffect(() => {
    // Find the <p> tag with negative margin
    if (pRef.current) {
      // Get computed styles of the <p> element
      const styles = window.getComputedStyle(pRef.current);
      // Extract margin value
      const margin = styles.getPropertyValue('margin');

      // Check if margin is negative
      if (margin.includes('-')) {
        // If negative, update margin to '0000'
        pRef.current.style.margin = '0000';
      }
    }
  }, []);


  function convertLineHeight(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const elementsWithLineHeight = doc.querySelectorAll('[style*="line-height"]');
    const elementsWithTextIndent = doc.querySelectorAll('[style*="text-indent"]');

    elementsWithLineHeight.forEach(element => {
      const style = element.getAttribute('style');
      const modifiedStyle = style.replace(/line-height:\s*(\d+)%/gi, (_, lineHeightPercentage) => {
        if (lineHeightPercentage) {
          return `line-height: 1.5`;
        }
      });
      element.setAttribute('style', modifiedStyle);
    });

    elementsWithTextIndent.forEach(element => {
      const style = element.getAttribute('style');
      const modifiedStyle = style.replace(/text-indent:\s*(-?\d+)px/gi, (_, textIndent) => {
        if (parseInt(textIndent) < 0) {
          return `text-indent: 0`;
        }
      });
      element.setAttribute('style', modifiedStyle);
    });

    return doc.body.innerHTML;
  }

  return (
    <Formik initialValues={faqDetailData}>
      <div className="container faq-view-detail">
       
        <div className="section-head mt-3">
          <div>
            <Button
              className="back_btn"
              onClick={() => {
                setIsApprove(false)
                setIsComment(false)
                setIsRevisionModal(false)
                setComment("")
                setCommentList([])
                props.resetViewDetailsData(
                  isParentApiCall,
                  location.state?.isActiveTab,
                )
              }}
            >
              <svg
                width="23"
                height="18"
                viewBox="0 0 23 18"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
              </svg>
            </Button>
          </div>
          <div className="status-drop-box header-action-item">
            {faqDetails.publishedVersion && (
              <div className="version-box">
                <p>Published Version : {faqDetails?.publishedVersion}</p>
              </div>
            )}

            {faqDetails?.currentVersion && (
              <div className="version-box draft-version">
                <p>current Version : {faqDetails?.currentVersion} </p>
              </div>
            )}

            <div className="user-box">
              <div className="first_name_td">
                {userList?.map((user) => (
                  <span
                    className="my-tooltip short-name"
                    data-tooltip-content={user?.name}
                  >
                    {getFirstLetters(user?.name)}
                  </span>
                ))}
              </div>
            </div>
            <div className="notification-list">
              <span
                className={
                  'faq-list-status-box ' + statusBox(status ? status : 'Draft')
                }
              ></span>
              {status === 'Drafted' ? 'Saved' : statusLableChanges(status)}
            </div>
            <div className="btn-box">
              {filterApproveAndRejectButton() && (
                <div className="approve_btn">
                  <Button
                    // className="my-tooltip"
                    // data-tooltip-content={'Reject'}
                    color="danger"
                    onClick={async () => {
                      setIsApprove(false)
                      if (comment.trim().length == 0) {
                        setIsComment(true)
                        setIsApprove(false)
                        if (isComment) {
                          ApiResponseMessage('Please enter comment', DANGER)
                        }
                      } else {
                        const response = await commonConfirmBox(
                          'Do you want to send back this FAQ for review?',
                        )
                        if (response) {
                          approveAndRejectData(false)
                        }
                      }
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="9"
                        stroke="CurrentColor"
                        strokeWidth="2"
                      />
                      <path
                        d="M18 18L6 6"
                        stroke="CurrentColor"
                        strokeWidth="2"
                      />
                    </svg>
                    Send Back for Review
                  </Button>
                  <Button
                    // className="my-tooltip"
                    // data-tooltip-content={'Approve'}
                    color="success"
                    onClick={async () => {
                      setComment('')
                      setIsComment(true)
                      setIsApprove(true)
                      if (isComment) {
                        const response = await commonConfirmBox(
                          isPublisher
                            ? 'Do you want to publish this FAQ?'
                            : 'Do you want to approve this FAQ?',
                        )
                        if (response) {
                          setComment('')
                          setIsComment(false)
                          approveAndRejectData(true)
                        }
                      }
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 10L12.2581 12.4436C12.6766 12.7574 13.2662 12.6957 13.6107 12.3021L20 5"
                        stroke="CurrentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M21 12C21 13.8805 20.411 15.7137 19.3156 17.2423C18.2203 18.7709 16.6736 19.9179 14.893 20.5224C13.1123 21.1268 11.187 21.1583 9.38744 20.6125C7.58792 20.0666 6.00459 18.9707 4.85982 17.4789C3.71505 15.987 3.06635 14.174 3.00482 12.2945C2.94329 10.415 3.47203 8.56344 4.51677 6.99987C5.56152 5.4363 7.06979 4.23925 8.82975 3.57685C10.5897 2.91444 12.513 2.81996 14.3294 3.30667"
                        stroke="CurrentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                    {isPublisher ? 'Publish' : 'Approve'}
                  </Button>
                </div>
              )}
            </div>

            {((permissions?.add == 1 &&
              (faqDetails.status == REJECTED_BY_APPROVER ||
                faqDetails.status == REJECTED_BY_PUBLISHER ||
                faqDetails.status == PUBLISHED ||
                faqDetails.status == APPROVED)) ||
              faqDetails.status == DRAFTED) && (
                <Button
                  className="creat_btn edit_faq_btn"
                  onClick={async () => {
                    if (isRevision || faqDetails.isSectionRevision == 1) {
                      await commonAleartBox(
                        isRevision && faqDetails.isSectionRevision
                          ? 'Please Review the Law and Section'
                          : isRevision
                            ? 'Please Review the Law'
                            : 'Please Review the Section',
                      )
                    } else {
                      navigate('/console/faq/edit/' + viewDetailFaqId)
                    }
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.1208 7.42339L15.9168 5.62738C16.462 5.08213 16.7346 4.80951 16.8804 4.51541C17.1577 3.95586 17.1577 3.29891 16.8804 2.73935C16.7346 2.44526 16.462 2.17263 15.9168 1.62738C15.3715 1.08213 15.0989 0.809509 14.8048 0.663773C14.2452 0.38649 13.5883 0.38649 13.0287 0.663773C12.7346 0.809509 12.462 1.08213 11.9168 1.62738L10.0981 3.44604C11.062 5.09664 12.4481 6.4722 14.1208 7.42339ZM8.64365 4.9005L1.77314 11.771C1.34808 12.1961 1.13555 12.4086 0.995818 12.6697C0.856084 12.9308 0.797138 13.2255 0.679248 13.815L0.0638519 16.8919C-0.00267025 17.2246 -0.0359313 17.3909 0.0586767 17.4855C0.153285 17.5801 0.31959 17.5468 0.6522 17.4803L3.72918 16.8649C4.31863 16.747 4.61336 16.6881 4.87446 16.5483C5.13555 16.4086 5.34808 16.1961 5.77315 15.771L12.6625 8.8816C11.0409 7.86595 9.66919 6.50366 8.64365 4.9005Z"
                      fill="CurrentColor"
                    />
                  </svg>
                  Edit Content / FAQ
                </Button>
              )}
          </div>
        </div>
        <div className="faq-view-detail-inner-box">
          {/* <p style={{margin: '0 0 0 -5px'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A maiores laboriosam aliquid doloribus! Earum maiores illum, harum dolorem nobis asperiores quas accusantium officia eum architecto officiis adipisci voluptatum eligendi est?
          </p> */}
          <Card className="p-0 mb-3">
            <CardBody className="row">
              <div className="col-3">
                <div className="faq-form-box">
                  <label>Service Line</label>
                  <span>{categoryName}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="faq-form-box">
                  <label>
                    {' '}
                    {lablesName && lablesName?.labelForCategory
                      ? lablesName?.labelForCategory
                      : 'Category'}
                  </label>
                  <span>{subCategoryName}</span>
                </div>
              </div>

              <div className="col-3">
                <div className="faq-form-box mark_btn">
                  <div className="d-flex justify-content-between">
                    <label>
                      {' '}
                      {lablesName && lablesName?.labelForLaws
                        ? lablesName?.labelForLaws
                        : 'Laws'}
                    </label>
                    {isRevision && (
                      <a
                        onClick={async () => {
                          const response = await commonConfirmBox(
                            'Do you want to mark as read update Law?',
                          )
                          if (response) {
                            props.createReviewLawRequest({
                              faqId: viewDetailFaqId,
                              isReviewed: false,
                              type: 'LAW',
                            })
                          }
                        }}
                        className="btn btn-outline-info"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="8"
                            cy="8"
                            r="8"
                            fill="currentColor"
                            fillOpacity="1"
                          />
                          <path
                            d="M3 8L6.23299 10.9097C6.29003 10.961 6.37663 10.961 6.43368 10.9097L13 5"
                            stroke="#fff"
                            strokeWidth="1.2"
                          />
                        </svg>
                        mark as read
                      </a>
                    )}
                  </div>
                  {/* <span
                    className="law_badge d-inline-flex flex-wrap"
                    style={{ gap: '0.5rem' }}
                  >
                    {lawList?.map((x, index) => (
                      <div key={index}>
                        {x.name}
                        {index === lawList.length - 1 ? '' : ','}
                      </div>
                    ))}
                  </span> */}
                  <span className="law_badge d-inline-flex flex-wrap">
                    {lawList?.length > 0
                      ? lawList?.map((x, index) => (
                        <div key={index}>
                          {x?.revisionDescription ? (
                            <small
                              onClick={() => {
                                setIsRevisionType('LAW')
                                setIsRevisionModal(true)
                              }}
                            >
                              {' '}
                              {x.name}{' '}
                            </small>
                          ) : (
                            x.name
                          )}
                          {index === lawList.length - 1 ? '' : ','}
                        </div>
                      ))
                      : 'Not Available'}
                  </span>
                </div>
              </div>
              <div className="col-3">
                <div className="faq-form-box mark_btn">
                  {/* <span>{sectionList}</span> */}
                  <div className="d-flex justify-content-between">
                    <label>
                      {' '}
                      {lablesName && lablesName?.labelForSections
                        ? lablesName?.labelForSections
                        : 'Sections'}
                    </label>

                    {faqDetails.isSectionRevision == 1 && (
                      <a
                        onClick={async () => {
                          const response = await commonConfirmBox(
                            'Do you want to mark as read this Section?',
                          )
                          if (response) {
                            props.createReviewLawRequest({
                              faqId: viewDetailFaqId,
                              isReviewed: false,
                              type: 'SECTION',
                            })
                          }
                        }}
                        className="btn btn-outline-info"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="8"
                            cy="8"
                            r="8"
                            fill="currentColor"
                            fillOpacity="1"
                          />
                          <path
                            d="M3 8L6.23299 10.9097C6.29003 10.961 6.37663 10.961 6.43368 10.9097L13 5"
                            stroke="#fff"
                            strokeWidth="1.2"
                          />
                        </svg>
                        mark as read
                      </a>
                    )}
                  </div>
                  <span className="law_badge d-inline-flex flex-wrap">
                    {sectionList?.length == 0 && 'Not Available'}
                    {sectionList?.map((x, index) => (
                      <div key={index}>
                        {x?.revisionDescription ? (
                          <small
                            onClick={() => {
                              setIsRevisionType('SECTION')
                              setIsRevisionModal(true)
                            }}
                          >
                            {' '}
                            {x.name}{' '}
                          </small>
                        ) : (
                          x.name
                        )}
                        {index === sectionList.length - 1 ? '' : ','}
                      </div>
                    ))}
                  </span>
                </div>
              </div>
              <div className="col-6">
                <div className="faq-form-box link-box">
                  <label>Reference</label>
                  <span className="">
                    {isUrl(refrence) ? (
                      <a href={refrence} target="_blank">
                        {refrence}
                      </a>
                    ) : (
                      refrence
                    )}
                  </span>
                </div>
              </div>
              <div className="col-2">
                <div className="faq-form-box">
                  <label>Last updated</label>
                  <span>
                    {faqDetails.updatedDate == null
                      ? 'Not available'
                      : moment(faqDetails.updatedDate).format('DD-MM-YYYY')}
                  </span>
                  {/* <h5>12-02-2023</h5> */}
                </div>
              </div>
              <div className="col-12">
                <div className="faq-form-box mb-0">
                  <label>SEO Keywords</label>
                  <ul className="p-0 m-0 mt-1">
                    {keyWords?.map((keywords, index, x) => (
                      <li key={index}> {keywords} </li>
                    ))}
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-0 mb-3 common-tab mt-5 lang-switch-main">
            <CardHeader className='nav-tabs lang-switch'>
              <Button color={EN == activeLanguage ? 'info' : 'secondary' } type='button' onClick={() => { setActiveLanguage(EN) }}>English</Button>
              <Button color={JPN == activeLanguage ? 'info' : 'secondary' } type='button' onClick={() => { setActiveLanguage(JPN) }}>Japanese
              {/* <span className='text-danger'><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.31171 7.76149C6.23007 2.58716 7.68925 0 10 0C12.3107 0 13.7699 2.58716 16.6883 7.76149L17.0519 8.40626C19.4771 12.7061 20.6897 14.856 19.5937 16.428C18.4978 18 15.7864 18 10.3637 18H9.63634C4.21356 18 1.50217 18 0.406258 16.428C-0.689657 14.856 0.522912 12.7061 2.94805 8.40627L3.31171 7.76149Z" fill="currentColor"/><path d="M10 3C10.6213 3 11.125 3.48216 11.125 4.07692V11.2564C11.125 11.8512 10.6213 12.3333 10 12.3333C9.37868 12.3333 8.875 11.8512 8.875 11.2564V4.07692C8.875 3.48216 9.37868 3 10 3Z" fill="white"/><path d="M10 16.1115C10.8284 16.1115 11.5 15.4686 11.5 14.6756C11.5 13.8826 10.8284 13.2397 10 13.2397C9.17157 13.2397 8.5 13.8826 8.5 14.6756C8.5 15.4686 9.17157 16.1115 10 16.1115Z" fill="white"/></svg></span> */}
              </Button>               
            </CardHeader>
            <CardBody>
              
              <h5>
                {adminTranslateLanguage(activeLanguage, faqDetails?.translation, faqDetails?.question, 'question')}
              </h5>
              <div
                ref={pRef}
                className="JoditEditor-border"
                dangerouslySetInnerHTML={{
                  __html: convertLineHeight(
                    adminTranslateLanguage(activeLanguage, faqDetails?.translation, faqDetails?.answer, 'answer'))
                }}
              />
            </CardBody>
          </Card>



          {commentList?.length > 0 && (
            <Card className="p-0 mb-3">
              <CardBody>
                <div className="">
                  <h6 className="separate-head">Comments</h6>
                  <ul className="comment-box">
                    {commentList?.map(
                      (item, index) =>
                        item.comment &&
                        item.comment.length > 0 && (
                          <li key={index}>
                            <div className="user-box">
                              <div className="first_name_td">
                                <span className="short-name">
                                  {getFirstLetters(item?.commentBy)}
                                </span>
                              </div>
                            </div>
                            <div className="con-box">
                              <div>
                                <h6>{item?.commentBy}</h6>
                                {item?.date && (
                                  <span>{dateFormater(item?.date)} </span>
                                )}
                              </div>
                              <p>{item?.comment}</p>
                            </div>
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              </CardBody>
            </Card>
          )}

          {filterApproveAndRejectButton() && isComment && (
            <Card className="p-0 mb-3 enter_comment">
              <CardBody>
                <h6 className="separate-head d-flex justify-content-between align-items-center">
                  <span>
                    Enter comment
                    {!isApprove && (
                      <span className="text-danger required"> *</span>
                    )}
                  </span>

                  <div className="enter_comment_btn">
                    <Button
                      color="primary"
                      onClick={async () => {
                        if (isApprove) {
                          if (isComment) {
                            const response = await commonConfirmBox(
                              isPublisher
                                ? 'Do you want to publish this FAQ?'
                                : 'Do you want to approve this FAQ?',
                            )
                            if (response) {
                              setComment('')
                              setIsComment(false)
                              approveAndRejectData(true)
                            }
                          }
                        } else if (comment.trim().length == 0) {
                          setIsComment(true)
                          if (isComment) {
                            ApiResponseMessage('Please enter comment', DANGER)
                          }
                        } else {
                          const response = await commonConfirmBox(
                            'Do you want to send back this FAQ for review?',
                          )
                          if (response) {
                            approveAndRejectData(false)
                          }
                        }
                      }}
                    >
                      Submit
                    </Button>

                    <Button
                      className="close_sign"
                      onClick={() => {
                        setComment('')
                        setIsComment(false)
                      }}
                    >
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.02602 6.49908L12.6799 1.8554C12.8837 1.65158 12.9982 1.37513 12.9982 1.08687C12.9982 0.798614 12.8837 0.522165 12.6799 0.318337C12.4761 0.114509 12.1997 0 11.9114 0C11.6232 0 11.3468 0.114509 11.143 0.318337L6.5 4.97283L1.85699 0.318337C1.65319 0.114509 1.37678 -2.14767e-09 1.08856 0C0.800348 2.14767e-09 0.523938 0.114509 0.320139 0.318337C0.11634 0.522165 0.00184703 0.798614 0.00184703 1.08687C0.00184703 1.37513 0.11634 1.65158 0.320139 1.8554L4.97397 6.49908L0.320139 11.1427C0.218698 11.2434 0.138182 11.3631 0.0832357 11.495C0.0282893 11.6269 0 11.7684 0 11.9113C0 12.0542 0.0282893 12.1957 0.0832357 12.3276C0.138182 12.4595 0.218698 12.5792 0.320139 12.6798C0.420751 12.7813 0.540454 12.8618 0.67234 12.9168C0.804227 12.9717 0.945688 13 1.08856 13C1.23144 13 1.3729 12.9717 1.50479 12.9168C1.63667 12.8618 1.75637 12.7813 1.85699 12.6798L6.5 8.02532L11.143 12.6798C11.2436 12.7813 11.3633 12.8618 11.4952 12.9168C11.6271 12.9717 11.7686 13 11.9114 13C12.0543 13 12.1958 12.9717 12.3277 12.9168C12.4595 12.8618 12.5792 12.7813 12.6799 12.6798C12.7813 12.5792 12.8618 12.4595 12.9168 12.3276C12.9717 12.1957 13 12.0542 13 11.9113C13 11.7684 12.9717 11.6269 12.9168 11.495C12.8618 11.3631 12.7813 11.2434 12.6799 11.1427L8.02602 6.49908Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Button>
                  </div>
                </h6>
                <textarea
                  name="comment"
                  placeholder="Enter comment.."
                  maxLength={200}
                  className="form-control"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                ></textarea>
              </CardBody>
            </Card>
          )}
        </div>

        {(faqDetails.isRevision == 1 || faqDetails.isSectionRevision == 1) && (
          <RevisionModal
            lawDescription={lawList}
            isOpenModal={isRevisionModal}
            lawList={
              IsRevisionType == 'LAW'
                ? faqDetails?.lawList
                : faqDetails?.sectionsList
            }
            revisionType={IsRevisionType}
            //lawList={faqDetails?.sectionsList}
            faqId={viewDetailFaqId}
            closeModal={(e) => {
              setIsRevisionModal(false)
            }}
          />
        )}
      </div>
    </Formik>
  )
}

const mapStateToProps = ({ faqReducer, categoryReducer }) => {
  let faqDetails = []
  let faqReviewApiResponse = []
  let faqCreate = null
  let reviewLawApiResponse = null
  let categoryAndSubList = []

  if (faqReducer.detailFaq && faqReducer.detailFaq.details) {
    faqDetails = faqReducer.detailFaq.details
  }

  if (faqReducer.faqReviewApiResponse) {
    faqReviewApiResponse = faqReducer.faqReviewApiResponse
  }
  if (faqReducer.reviewLawApiResponse) {
    reviewLawApiResponse = faqReducer.reviewLawApiResponse.responseType
  }

  if (faqReducer?.addFaq) {
    faqCreate = faqReducer?.addFaq
  }
  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryList.list
  }
  return {
    faqDetails,
    faqReviewApiResponse,
    faqCreate,
    reviewLawApiResponse,
    categoryAndSubList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFaqDetails: (data) => {
      dispatch(getFaqDetailsRequest(data))
    },
    createFaqReviewRequest: (data) => {
      dispatch(createFaqReviewRequest(data))
    },
    clearFaqRepo: () => {
      dispatch(createFaqResponse(null))
    },

    createReviewLawRequest: (data) => {
      dispatch(createReviewLawRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewFaqDetails)
