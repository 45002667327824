import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_RESPONSE,
} from './actionType'

export const getUserDetailsRequest = (data) => {
  return {
    type: GET_USER_DETAILS_REQUEST,
    payload: { data },
  }
}

export const getUserDetailsResponse = (message, responseType, data) => {
  return {
    type: GET_USER_DETAILS_RESPONSE,
    payload: { message, responseType, data },
  }
}
