import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import CreateUser from './CreateUser'
import {
  getUserListRequest,
  getUserDeleteRequest,
} from 'store/master/user/action'
import { getRoleListRequest } from 'store/master/role/action'
import { connect } from 'react-redux'
import {
  compareString,
  dateFormater,
  setRoleStatusColor,
  userLogFormater,
} from '../../../helpers/generalUtils'
import { UserInitialState } from './CommonFunction'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { userListFilter } from './helpers'
import { ViewTable } from 'components/Table/viewTable'
import { resetPasswordByEmailApiRequest } from 'store/auth/passwordforget/action'
import AuditLogModal from 'pages/AuditLog/AuditLogModal'
import { Button } from 'reactstrap'
import ResendEmailModal from './ResendEmailModal'

const User = (props) => {
  const [userData] = useState(UserInitialState())
  const [roleState] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [userList, setUserList] = useState([])
  const [dataPerPage, setDataPerPage] = useState(10)
  const [tableRefresh, setTableRefresh] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    setUserList(props.userList)
    if (!tableRefresh) {
      setTableRefresh(true)
    }
  }, [props.userList])

  useEffect(() => {
    if (tableRefresh) {
      setTableRefresh(false)
    }
  }, [tableRefresh])

  const [paginationData, setPaginationData] = useState({
    searchText: searchText,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: { roleId: '' },
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [dataPerPage])
  useEffect(() => {
    props.getUserList({
      searchText: searchText,
      pagination: {
        limit: dataPerPage,
        page: currentPage,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: paginationData.filter,
    })
  }, [currentPage, dataPerPage, searchText])

  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [auditId, setAuditId] = useState('')

  const closeAuditLogModal = () => {
    setIsAuditModalOpen(false)
    setAuditId('')
  }

  const openAuditLogModal = (id) => {
    setIsAuditModalOpen(true)
    setAuditId(id)
  }

  useEffect(() => {
    props.getUserList(paginationData)
    props.getRoleList()
  }, [])

  useEffect(() => {
    if (roleState) {
      props.getUserList(userListFilter)
    }
  }, [roleState])

  useEffect(() => {
    props?.getUserList(paginationData)
  }, [paginationData])

  const columns = [
    {
      dataField: 'fullName',
      text: 'Full Name',
      formatter: (cell, row) => (
        <div className="first_name_td justify-content-start">
          <span className="short-name" style={{ background: row.color }}>
            {row.shortName}
          </span>
          <p>{row.fullName}</p>
        </div>
      ),
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email Address',
      sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Mobile No.',
      sort: true,
    },
    {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      formatter: dateFormater,
    },
    {
      dataField: 'updatedDate',
      text: 'Last Login',
      sort: true,
      formatter: userLogFormater,
    },
    {
      sort: true,
      dataField: 'role.roleName',
      text: 'Role',
      headerClasses: 'text-center',
      formatter: (cell, row) => (
        <div className="text-center">
          <span
            className={`badge role-badge ${setRoleStatusColor(
              row.role?.roleName,
            )}`}
          >
            {row.role?.roleName}
          </span>
        </div>
      ),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerClasses: 'text-center',

      formatter: (cell, row) => (
        <Form className="text-center">
          <Form.Check
            onChange={async () => {
              const response = await commonConfirmBox(
                !row.isActive
                  ? 'Do you want to activate this user?'
                  : 'Do you want to deactivate this user?',
              )
              if (response) {
                setTableRefresh(true)
                props.userStatusUpdateRequest({
                  userId: row.id,
                  isActive: !row.isActive,
                  currentPage,
                  dataPerPage,
                })
              }
            }}
            type="switch"
            id="custom-switch"
            className="justify-content-center"
            checked={row.isActive}
          />
        </Form>
      ),
    },
    {
      dataField: 'Action',
      text: '',
      headerClasses: 'action_col',
      events: {},

      formatter: (cell, row) => (
        <div className="action_col text-center">
          <Button
            color="link"
            className="my-tooltip btn btn-link resend_btn"
            data-tooltip-content={'Resend Email'}
            onClick={() => {
              setEmail(row.email)
              setIsResendEmailModalOpen(true)
            }}
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="256.000000pt"
              height="256.000000pt"
              viewBox="0 0 256.000000 256.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                fill="CurrentColor"
                stroke="none"
              >
                <path d="M45 2289 c-46 -26 -45 -4 -45 -746 l0 -704 25 -24 24 -25 695 0 c498 0 702 3 720 11 35 16 47 57 30 97 -7 18 -14 33 -16 34 -2 2 -300 5 -664 8 l-661 5 285 235 c156 129 288 236 293 238 4 2 81 -53 170 -122 120 -94 168 -126 189 -126 28 0 86 40 278 192 40 32 76 58 80 58 4 0 59 -43 122 -96 63 -52 125 -99 138 -104 49 -20 109 33 96 85 -4 17 -43 57 -116 117 -60 50 -108 94 -106 99 2 5 102 86 223 180 l220 171 5 -257 c5 -255 5 -257 29 -276 30 -25 76 -24 101 1 19 19 20 33 20 468 0 444 0 449 -21 468 -20 18 -54 19 -1058 21 -739 2 -1042 -1 -1056 -8z m1985 -181 l-1 -43 -406 -315 c-223 -173 -434 -337 -469 -363 l-63 -49 -470 366 -471 367 0 39 0 40 940 0 940 0 0 -42z m-1423 -589 c-1 -4 -96 -84 -212 -179 -116 -95 -218 -180 -227 -188 -17 -14 -18 3 -18 356 l0 372 229 -178 c127 -97 229 -180 228 -183z" />
                <path d="M2014 1156 c-161 -37 -287 -152 -335 -307 -13 -42 -19 -91 -19 -157 l0 -96 -32 31 c-37 35 -69 53 -93 53 -28 0 -65 -46 -65 -81 0 -28 16 -48 113 -146 151 -151 143 -151 294 0 102 102 113 116 113 149 0 28 -7 42 -26 57 -38 30 -59 26 -110 -21 l-46 -43 4 105 c3 93 7 111 33 158 94 173 328 209 468 74 66 -63 91 -124 91 -217 0 -92 -20 -143 -83 -208 -48 -51 -99 -76 -184 -91 -57 -9 -74 -16 -90 -39 -25 -33 -16 -69 24 -99 24 -18 32 -19 100 -9 342 54 501 439 295 718 -100 136 -287 206 -452 169z" />
              </g>
            </svg>
          </Button>

          <Link
            className="my-tooltip btn btn-link edit_btn"
            data-tooltip-content={'Edit'}
            // className="btn btn-link edit_btn"
            to={'edit/' + row.id}
            state={{ userDetails: row }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1123_13264)">
                <path
                  d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1123_13264">
                  <rect width="20" height="20" fill="CurrentColor" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <a
            className="my-tooltip btn btn-link history_btn"
            data-tooltip-content={'History'}
            onClick={(e) => openAuditLogModal(row.id)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9C0 7.75 0.237333 6.579 0.712 5.487C1.18733 4.39567 1.82933 3.44567 2.638 2.637C3.446 1.829 4.396 1.18733 5.488 0.712C6.57933 0.237333 7.75 0 9 0C10.3667 0 11.6627 0.291667 12.888 0.875C14.1127 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.096 1.479 16.288 1.287C16.4793 1.09567 16.7167 1 17 1C17.2833 1 17.5207 1.09567 17.712 1.287C17.904 1.479 18 1.71667 18 2V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7H13C12.7167 7 12.4793 6.904 12.288 6.712C12.096 6.52067 12 6.28333 12 6C12 5.71667 12.096 5.479 12.288 5.287C12.4793 5.09567 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.396 2.679 4.038 4.037C2.67933 5.39567 2 7.05 2 9C2 10.95 2.67933 12.604 4.038 13.962C5.396 15.3207 7.05 16 9 16C10.5333 16 11.921 15.55 13.163 14.65C14.4043 13.75 15.2417 12.5667 15.675 11.1C15.7583 10.8167 15.9127 10.579 16.138 10.387C16.3627 10.1957 16.6167 10.1333 16.9 10.2C17.2 10.2667 17.4207 10.4333 17.562 10.7C17.704 10.9667 17.7333 11.25 17.65 11.55C17.1 13.4667 16.025 15.0207 14.425 16.212C12.825 17.404 11.0167 18 9 18Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      ),
    },
  ]

  return (
    <>
      <Formik initialValues={userData}>
        <ViewTable
          tableRefresh={tableRefresh}
          isUserModule={true}
          getFilterDataByRole={(e) => {
            setTableRefresh(true)
            setCurrentPage(1)
            setPaginationData({
              ...paginationData,
              filter: { roleId: e.roleId.value },
            })
          }}
          getRefreshList={() => {
            setCurrentPage(1)
            setPaginationData({
              ...paginationData,
              filter: { roleId: '' },
            })
          }}
          hedingName="User"
          buttonName="Create User"
          component={CreateUser}
          href="/console/user/create"
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          totalRecords={props?.totalRecords}
          updateData={(e) => {
            if (e.searchText) {
              setSearchText(e.searchText)
            } else {
              setSearchText('')
            }
            setCurrentPage(1)
            // props.getUserList(e)
          }}
          data={userList ? userList : []}
          columns={columns}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        ></ViewTable>
      </Formik>
      <AuditLogModal
        isModalOpen={isAuditModalOpen}
        modalName="User History"
        auditType="user"
        auditId={auditId}
        onModalDismiss={() => closeAuditLogModal()}
      />

      <ResendEmailModal
        isModalOpen={isResendEmailModalOpen}
        modalName="Resend Email"
        onModalDismiss={() => {
          setIsResendEmailModalOpen(false)
          setEmail('')
        }}
        formSubmit={(value) => {
          props.getPasswordResetByEmail({
            ...value,
            email: email
          })
          setIsResendEmailModalOpen(false)
          setEmail('')
        }}
      />


    </>
  )
}

const mapStateToProps = (createUserReducer) => {
  let getUserListRecords = []
  let Message
  let getRoleListData = []
  let roleDropdownList = []
  let totalRecords = 0
  if (
    createUserReducer.createUserReducer.getUserListApiResponse?.data?.list &&
    createUserReducer.createUserReducer.getUserListApiResponse.data.list
      ?.length > 0
  ) {
    getUserListRecords =
      createUserReducer.createUserReducer.getUserListApiResponse.data.list

    totalRecords =
      createUserReducer.createUserReducer.getUserListApiResponse.data
        .totalRecords
  }

  if (createUserReducer.roleReducer.getRoleList?.length > 0) {
    getRoleListData = createUserReducer.roleReducer.getRoleList
    roleDropdownList = createUserReducer.roleReducer.getRoleList
      ?.map((opt) => ({
        value: opt.id,
        label: opt?.roleName,
      }))
      .sort((item1, item2) => compareString(item1.label, item2.label))
  }

  return {
    getRoleListData: getRoleListData,
    message: Message,
    userList: getUserListRecords,
    roleDropdownList: roleDropdownList,
    totalRecords: totalRecords,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (creds) => {
      dispatch(getUserListRequest(creds))
    },
    userStatusUpdateRequest: (creds) => {
      dispatch(getUserDeleteRequest(creds))
    },
    getRoleList: (creds) => {
      dispatch(getRoleListRequest(creds))
    },

    getPasswordResetByEmail: (creds) => {
      dispatch(resetPasswordByEmailApiRequest(creds))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(User)
