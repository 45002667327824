import React from 'react'
import { Field, Formik, Form } from 'formik'
import SimpleReactValidator from 'simple-react-validator'
import { Button, FormGroup, Label } from 'reactstrap'
import Modal from 'react-bootstrap/Modal'
import { useRef } from 'react'
import { __t } from 'i18n/translator'
const initialValues = {
  query: '',
}

export default function UserQueryModal(props) {
  const validator = useRef(new SimpleReactValidator())

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let formatValue = {
        ...values,
        ...props.modalData,
      }
      props.submitForm(formatValue)
    } else {
      validator.current.showMessages()
    }
  }

  return (
    <Modal show={props.isOpenModal} className="user-query-modal" centered>
      <Modal.Header
        closeButton
        onClick={() => {
          props.closeModal(false)
          validator.current.purgeFields()
          validator.current.hideMessages()
        }}
      >
        <Modal.Title>{__t('frontend.report_details.user_query')}</Modal.Title>
      </Modal.Header>

      <Formik initialValues={initialValues} onSubmit={formSubmit}>
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <div className="contact_form">
            {/* <Col className="contact_text" lg={4}>
                  <div>
                    <h3>Let us know your query.</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore
                      magna.
                    </p>
                  </div>
                </Col> */}

            <div className="contact_field">
              <Form className="row">
              <FormGroup className="col-12">
                  <Label for="topics">{__t('frontend.report_details.report_title')}</Label>
                  <Field
                    className="form-control"
                    type="text"
                    name="sectionTitle"
                    value={props.modalData?.reportTitle}
                    disabled={true}
                  />
                </FormGroup>


                {props.modalData.categoryId && (
                  <FormGroup className="col-12">
                    <Label className="form-label">{__t('frontend.report_details.category')}</Label>
                    <Field
                      className="form-control"
                      type="text"
                      name="categoryName"
                      disabled={true}
                      value={props.modalData?.categoryName}
                    />
                  </FormGroup>
                )}

                {props.modalData.sectionId && (
                  <FormGroup className="col-12">
                    <Label for="topics"> {__t('frontend.report_details.topics')} </Label>
                    <Field
                      className="form-control"
                      type="text"
                      name="sectionTitle"
                      value={props.modalData?.sectionTitle}
                      disabled={true}
                    />
                  </FormGroup>
                )}
                {props.modalData.subSectionId && (
                  <FormGroup className="col-12">
                    <Label for="topics">Sub-topic</Label>
                    <Field
                      className="form-control"
                      type="text"
                      name="sectionTitle"
                      value={props.modalData?.sectionTitle}
                      disabled={true}
                    />
                  </FormGroup>
                )}
                {props.modalData.faqId && (
                  <FormGroup className="col-12">
                    <Label for="topics">Question</Label>
                    <Field
                      className="form-control"
                      type="text"
                      name="question"
                      value={props.modalData?.question}
                      disabled={true}
                    />
                  </FormGroup>
                )}
             
                <FormGroup className="col-12">
                  <Label for="message">{__t('frontend.report_details.your_queries')}</Label>
                  <Field
                    as="textarea"
                    type="textarea"
                    name="query"
                    id="query"
                    className="form-control"
                    rows="5"
                  />

                  {validator.current.message(
                    __t('validations.common.query_field_required'),
                    values.query,
                    'required',
                    {
                      messages: {
                        required: __t(
                          'validations.common.query_field_required',
                        ),
                      },
                    },
                  )}
                </FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  block
                  className="w-100 waves-effect waves-light ms-4 btn-secound-primary"
                  onClick={handleSubmit}
                >
                  {__t('frontend.report_details.submit')}
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  )
}
