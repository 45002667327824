export const GET_USER_ARTICLE_LIST_REQUEST = 'GET_USER_ARTICLE_LIST_REQUEST'
export const GET_USER_ARTICLE_LIST_RESPONSE = 'GET_USER_ARTICLE_LIST_RESPONSE'
export const CLEAR_USER_ARTICLE_LIST_RESPONSE =
  'CLEAR_USER_ARTICLE_LIST_RESPONSE'

export const UPDATE_POPULAR_FAQ_REQUEST = 'UPDATE_POPULAR_FAQ_REQUEST'

export const GET_USER_ARTICLE_DETAILS_REQUEST =
  'GET_USER_ARTICLE_DETAILS_REQUEST'
export const GET_USER_ARTICLE_DETAILS_RESPONSE =
  'GET_USER_ARTICLE_DETAILS_RESPONSE'

export const CLEAR_USER_ARTICLE_DETAILS_RESPONSE =
  'CLEAR_USER_ARTICLE_DETAILS_RESPONSE'
