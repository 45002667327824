import {
  CLEARE_CREATE_TEAM_RESPONSE,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_RESPONSE,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_RESPONSE,
  GET_TEAM_LEAD_REQUEST,
  GET_TEAM_LEAD_RESPONSE,
  GET_TEAM_MEMBER_LIST_REQUEST,
  GET_TEAM_MEMBER_LIST_RESPONSE,
  GET_TEAM_USER_REQUEST,
  GET_TEAM_USER_RESPONSE,
} from './actionType'

export const createTeamRequest = (data) => {
  return {
    type: CREATE_TEAM_REQUEST,
    payload: { data },
  }
}

export const cleareCreateTeamResponse = () => {
  return {
    type: CLEARE_CREATE_TEAM_RESPONSE,
  }
}

export const createTeamResponse = (message, responseType) => {
  return {
    type: CREATE_TEAM_RESPONSE,
    payload: { message, responseType },
  }
}
export const getTeamLeadRequest = () => {
  return {
    type: GET_TEAM_LEAD_REQUEST,
  }
}

export const getTeamLeadResponse = (data, responseType) => {
  return {
    type: GET_TEAM_LEAD_RESPONSE,
    payload: { data, responseType },
  }
}

export const getTeamUserRequest = (data) => {
  return {
    type: GET_TEAM_USER_REQUEST,
    payload: { data },
  }
}

export const getTeamUserResponse = (data, responseType) => {
  return {
    type: GET_TEAM_USER_RESPONSE,
    payload: { data, responseType },
  }
}

export const getTeamMemberListRequest = (data) => {
  return {
    type: GET_TEAM_MEMBER_LIST_REQUEST,
    payload: { data },
  }
}

export const getTeamMemberListResponse = (data) => {
  return {
    type: GET_TEAM_MEMBER_LIST_RESPONSE,
    payload: { data },
  }
}

export const deleteTeamMemberRequest = (data) => {
  return {
    type: DELETE_TEAM_MEMBER_REQUEST,
    payload: { data },
  }
}

export const deleteTeamMemberResponse = (data, responseType) => {
  return {
    type: DELETE_TEAM_MEMBER_RESPONSE,
    payload: { data, responseType },
  }
}
