export const DEFAULT_PAGE_LENGTH = 10
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 200]
export const SUCCESS = 'success'
export const DANGER = 'error'
export const UPDATE = 'Update'
export const DATE_FORMAT_DATEPICKER = 'dd-MM-yyyy'

export const ERROR = 'ERROR_ALERT'
export const ENGLISH = 'English'
export const JAPANESE = 'Japanese'
export const JPN = 'JPN'
export const EN = 'en'
export const INFO = 'INFO_ALERT'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const INTERNAL = 'Internal'
export const PERSONAL = 'Personal'
