import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Button, Label, FormGroup } from 'reactstrap'
import { useParams, useNavigate } from 'react-router-dom'
import resetbg from '../../images/reset_02.jpg'
import logosm from '../../images/logo.webp'
import { resetPasswordApiRequest } from '../../store/auth/passwordforget/action.js'
import { Field, Formik } from 'formik'
import { ResetPasswordState } from './CommonFunctions'
import { __t } from 'i18n/translator'
import SimpleReactValidator from 'simple-react-validator'

function ResetPassword(props) {
  const token = useParams()

  const navigate = useNavigate()
  const [lawData] = useState(ResetPasswordState())
  const validator = useRef(new SimpleReactValidator())

  useEffect(() => {
    if (props.isApiSuccess) {
      navigate('/console/login')
    }
  }, [props.isApiSuccess])

  return (
    <>
      <div className="login-page">
        <Row>
          <Col className="login-img">
            <img alt="Reset password" src={resetbg} />
          </Col>

          <Formik
            initialValues={lawData}
            onSubmit={async (values) => {
              if (validator.current.allValid()) {
                props.resetPassword({
                  token: token,
                  newPassword: values.password,
                  confirmPassword: values.confirmPassword,
                })
              } else {
                validator.current.showMessages()
              }
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Col className="login-form">
                <div className="login-main">
                  <div className="loging-head">
                    <img alt="Logo" src={logosm} />
                  </div>
                  <div className="loging-content">
                    <h2>Reset Password</h2>
                    <p>Password must be at least 8 characters</p>

                    <Form>
                      <FormGroup className="col-12">
                        <Field
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="New Password"
                          type="password"
                        />
                        <Label className="form-label" htmlFor="password">
                          New Password
                        </Label>
                        {validator.current.message(
                          __t('validations.auth.password_required'),
                          values.password,
                          'required',
                          {
                            messages: {
                              required: __t(
                                'validations.auth.password_required',
                              ),
                            },
                          },
                        )}
                      </FormGroup>

                      <FormGroup className="col-12">
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="confirmPassword"
                          type="password"
                        />
                        <Label className="form-label" htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        {validator.current.message(
                          __t('validations.auth.confirm_password'),
                          values.confirmPassword,
                          'required',
                          {
                            messages: {
                              required: __t(
                                'validations.auth.confirm_password',
                              ),
                            },
                          },
                        )}
                      </FormGroup>

                      <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        onClick={handleSubmit}
                        block
                      >
                        Reset Password
                      </Button>
                    </Form>
                  </div>
                  <div className="text-center copy-right w-100">
                    <p>
                      {' '}
                      &#169; {new Date().getFullYear()} - All Rights Reserved.
                    </p>
                  </div>
                </div>
              </Col>
            )}
          </Formik>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = (forgetPasswordReducer) => {
  const isApiSuccess =
    forgetPasswordReducer?.forgetPasswordReducer?.resetPasswordApiRsponse
      ?.status

  return {
    isApiSuccess: isApiSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data) => {
      dispatch(resetPasswordApiRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
