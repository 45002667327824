import React, { useEffect } from 'react'
import packageJson from '../../../package.json'

export default function CachingClear({ children }) {
  const caching = () => {
    let version = localStorage.getItem('version')
    if (version != packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name)
          })
        })

        // Makes sure the page reloads. Changes are only visible after you refresh.
      }

      localStorage.setItem('version', packageJson.version)
    }
  }

  useEffect(() => {
    caching()
  }, [])

  return children
}
