import { all } from 'redux-saga/effects'
import LoginSaga from './auth/login/saga'
import lawSaga from './master/law/saga'
import sectionSaga from './master/section/saga'
import countrySaga from './master/country/saga'
import userSaga from './master/user/saga'
import roleSaga from './master/role/saga'

import ForgetPasswordSaga from './auth/passwordforget/saga'
import CategorySaga from './master/category/saga'
import modelSaga from './master/module/saga'
import faqSaga from './faq/saga'
import reportSaga from './report/saga'
//---------------------------------------------------
import userHomeSaga from './frontEnd/home/saga'
import userAboutSaga from './frontEnd/about/saga'
import userFaqSaga from './frontEnd/faq/saga'
import userCommonSaga from './frontEnd/common/saga'
import userReportSaga from './frontEnd/report/saga'
import userContactUsSaga from './frontEnd/contactus/saga'
import articleSaga from './article/saga'
import userTicketSaga from './frontEnd/tickets/saga'
import ticketSaga from './tickets/saga'
import userArticleSaga from './frontEnd/article/saga'
import auditLogSaga from './auditLog/saga'
import subsciberSaga from './subscriber/saga'
import dashboardSaga from './dashboard/saga'
import emailtemplateSaga from './master/emailTemplate/saga'
import serviceLineSaga from './master/serviceline/saga'
import consultationSaga from './consultation/saga'
import teamCreation from './teamCreation/saga'

/**
 * Here we add all saga if we not add then that saga not work
 * add one saga only one time do not add saga multiple times
 * root saga we add in store file store.js
 */
function* rootSaga() {
  yield all([
    userSaga(),
    ForgetPasswordSaga(),
    LoginSaga(),
    lawSaga(),
    sectionSaga(),
    countrySaga(),
    roleSaga(),
    CategorySaga(),
    modelSaga(),
    faqSaga(),
    articleSaga(),
    reportSaga(),
    userHomeSaga(),
    userAboutSaga(),
    userFaqSaga(),
    userCommonSaga(),
    userReportSaga(),
    userContactUsSaga(),
    userTicketSaga(),
    ticketSaga(),
    userArticleSaga(),
    auditLogSaga(),
    subsciberSaga(),
    dashboardSaga(),
    emailtemplateSaga(),
    serviceLineSaga(),
    consultationSaga(),
    teamCreation(),
  ])
}
export default rootSaga
