import produce from 'immer'
import {
  CLEAR_CONSULTATION_LIST_RESPONSE,
  CLEAR_DOCUMENT_RESPONSE,
  CLEAR_TEAM_DELEGATE_QUERY_RESPONSE,
  CREATE_CONSULTATION_RESPONSE,
  CREATE_CONSULTATION_REVIEW_RESPONSE,
  CREATE_DOCUMENT_RESPONSE,
  CREATE_REVIEW_LAW_RESPONSE,
  CREATE_TEAM_DELEGATE_QUERY_RESPONSE,
  DELETE_CONSULTATION_DOCUMENT_RESPONSE,
  GET_CONSULTATION_DETAILS_RESPONSE,
  GET_CONSULTATION_HISTORY_RESPONSE,
  GET_LIST_CONSULTATION_RESPONSE,
  GET_LIST_KEYWORDS_RESPONSE,
  GET_TEAM_LIST_RESPONSE,
} from './actionType'

export const initialState = {
  addConsultation: null,
  listconsultation: null,
  listKeywords: null,
  consultationDetails: null,
  faqHistoryApiResponse: null,
  faqReviewApiResponse: null,
  reviewLawApiResponse: null,
  deleteDocumentApiResponse: null,
  teamList: null,
  createTeamDelegateApiResponse: null,
  createDocumentApiResponse: null,
}

export const consultationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_CONSULTATION_RESPONSE:
        if (action.payload.message == undefined) {
          draft.addConsultation = null
        } else {
          draft.addConsultation = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          }
        }
        break
      case GET_LIST_KEYWORDS_RESPONSE:
        draft.listKeywords = action.payload.data
        break
      case GET_LIST_CONSULTATION_RESPONSE:
        draft.listconsultation = action.payload.data
        break
      case GET_CONSULTATION_DETAILS_RESPONSE:
        draft.consultationDetails = action.payload.data
        break
      case GET_CONSULTATION_HISTORY_RESPONSE:
        draft.faqHistoryApiResponse = action.payload.data
        break

      case CREATE_CONSULTATION_REVIEW_RESPONSE:
        draft.addConsultation = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CREATE_REVIEW_LAW_RESPONSE:
        draft.reviewLawApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case DELETE_CONSULTATION_DOCUMENT_RESPONSE:
        draft.deleteDocumentApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case GET_TEAM_LIST_RESPONSE:
        draft.teamList = action.payload.data
        break

      case CREATE_TEAM_DELEGATE_QUERY_RESPONSE:
        draft.createTeamDelegateApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEAR_TEAM_DELEGATE_QUERY_RESPONSE:
        draft.createTeamDelegateApiResponse = null
        break

      case CLEAR_CONSULTATION_LIST_RESPONSE:
        draft.listconsultation = null
        break

      case CREATE_DOCUMENT_RESPONSE:
        draft.createDocumentApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEAR_DOCUMENT_RESPONSE:
        draft.createDocumentApiResponse = null
        break

      default:
        state = { ...state }
        break
    }
  })
