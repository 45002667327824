import Swal from 'sweetalert2'

export const SignOutTostrMessage = async () => {
  const Toast = Swal.mixin({
    showCloseButton: true,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  Toast.fire({
    icon: 'success',
    title: 'Signed out successfully',
  })

  return Toast
}

export const SignInTostrMessage = async () => {
  const Toast = Swal.mixin({
    showCloseButton: true,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  Toast.fire({
    icon: 'success',
    title: 'Sign in successfully',
  })

  return Toast
}

export const ApiResponseMessage = async (message, status) => {
  const Toast = Swal.mixin({
    showCloseButton: true,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,

    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  Toast.fire({
    icon: status,
    title: message,
  })

  return Toast
}
