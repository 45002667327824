import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { GET_MODULE_LIST_REQUEST } from './actionType'

import { handleApiCallException } from '../../shared/action'
import { getModuleListResponse } from './action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER } from '../../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

function* getModuleList() {
  try {
    const response = yield call(invokeApi, 'get', '/moduleList')
    if (response.status) {
      yield put(getModuleListResponse(response.data.list))
    } else {
      yield put(getModuleListResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(GET_MODULE_LIST_REQUEST, getModuleList)
}
function* modelSaga() {
  yield all([fork(watchLaw)])
}
export default modelSaga
