import produce from 'immer'
import { GET_DASHBOARD_RESPONSE } from './actionType'

export const initialState = {
  dashboardApiResponse: null,
}

export const DashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_DASHBOARD_RESPONSE:
        draft.dashboardApiResponse = action.payload.data
        break

      default:
        state = { ...state }
        break
    }
  })
