import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  CREATE_USER_API_REQUEST,
  GET_USER_LIST_REQUEST,
  USER_STATUS_REQUEST,
} from './actionType'
import { handleApiCallException } from '../../shared/action'
import {
  createUserResponse,
  getUserDeleteResponse,
  getUserListRequest,
  getUserListResponse,
} from './action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

//send request to server
function* user({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/user', data)
    if (response.status) {
      yield put(createUserResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      yield put(createUserResponse(response.message, DANGER))
      ApiResponseMessage(response.message, response.status, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//send request to server
function* userList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/userList', data)
    if (response.status) {
      yield put(getUserListResponse(response.message, SUCCESS, response.data))
    } else {
      yield put(getUserListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* userStatus({ payload: { data } }) {
  const { currentPage, dataPerPage, ...modData } = data
  try {
    const response = yield call(invokeApi, 'post', '/userDelete', modData)
    if (response.status) {
      yield put(getUserDeleteResponse(response.message, SUCCESS, response.data))
      yield put(
        getUserListRequest({
          searchText: '',
          pagination: {
            limit: dataPerPage,
            page: currentPage ? currentPage : 1,
            orderKey: 'createdDate',
            orderBy: 'ASC',
          },
          filter: { roleId: 0 },
        }),
      )
    } else {
      yield put(getUserDeleteResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(CREATE_USER_API_REQUEST, user)
  yield takeEvery(GET_USER_LIST_REQUEST, userList)
  yield takeEvery(USER_STATUS_REQUEST, userStatus)
}
function* userSaga() {
  yield all([fork(watchLaw)])
}
export default userSaga
