import React, { useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { FormGroup, Label } from 'reactstrap'
import { Formik, Field } from 'formik'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { isValidCharacterFieldX } from 'helpers/SimpleReactValidators'
import { __t } from 'i18n/translator'
import {
  addServiceLineRequest,
} from 'store/master/serviceline/action'

function CategoryModal(props) {
  const { modalData } = props

  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidCharacterFieldX()),
    }),
  )

  // useEffect(() => {
  //   if (props.ApiRespMessage != null) {
  //     //props.ClearServiceListResponse()
  //   }
  // }, [props.ApiRespMessage])

  const FormSubmit = async (values) => {
    if (validator.current.allValid()) {
      let customJSON
      customJSON = {
        serviceName: values.catName,
        labelForCategory:
          values.labelForCategory?.trim().length > 0
            ? values.labelForCategory
            : 'Category',
        labelForLaws:
          values.labelForLaws?.trim().length > 0 ? values.labelForLaws : 'Laws',
        labelForSections:
          values.labelForSections?.trim().length > 0
            ? values.labelForSections
            : 'Sections',
      }
      if (props.isType != 'addOperation') {
        customJSON = { ...customJSON, serviceLineId: values.id }
      }
      if (customJSON) {
        props?.addServiceLineRequest(customJSON)
      }
    } else {
      validator.current.showMessages()
    }
  }
  return (
    <>
      <Modal show={props.isOpenModal} className="category-modal" centered>
        <Modal.Header
          closeButton
          onClick={() => {
            props.closeModal(false)
            validator.current.purgeFields()
            validator.current.hideMessages()
          }}
        >
          <Modal.Title>Enter Service Line</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik initialValues={modalData} onSubmit={FormSubmit}>
            {({ values, handleSubmit, setFieldValue }) => (
              <Form role={'form'} className="form-horizontal form-login">
                <div className="category-head mb-2">
                  <FormGroup className=" mb-2">
                    <Field
                      id="catName"
                      name="catName"
                      placeholder="Enter Service Line"
                      className="form-control"
                      type="text"
                    />

                    <Label className="form-label">
                      Enter Service Line
                      <span className="text-danger required">*</span>
                    </Label>
                    {validator.current.message(
                      __t('validations.common.servicecategory'),
                      values.catName,
                      'required',
                      {
                        messages: {
                          required: __t('validations.common.servicecategory'),
                        },
                      },
                    )}
                  </FormGroup>
                  <FormGroup className=" mb-2">
                    <Field
                      id="labelForCategory"
                      name="labelForCategory"
                      placeholder="Enter Service Line"
                      className="form-control"
                      type="text"
                    />
                    <Label className="form-label">Heading For Category</Label>
                  </FormGroup>
                  <FormGroup className=" mb-2">
                    <Field
                      id="labelForLaws"
                      name="labelForLaws"
                      placeholder="Enter Service Line"
                      className="form-control"
                      type="text"
                    />
                    <Label className="form-label">Heading For Laws</Label>
                  </FormGroup>
                  <FormGroup className=" mb-2">
                    <Field
                      id="labelForSections"
                      name="labelForSections"
                      placeholder="Enter Service Line"
                      className="form-control"
                      type="text"
                    />
                    <Label className="form-label">Heading For Sections</Label>
                  </FormGroup>
                </div>

                <div className="btn-box mt-3">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-100"
                    onClick={handleSubmit}
                  >
                    {props.isType === 'addOperation' ? 'Submit' : 'Update'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ serviceLineReducer }) => {
  let message

  if (serviceLineReducer && serviceLineReducer.addServiceResponse) {
    message = serviceLineReducer.addServiceResponse.message
  }

  return {
    ApiRespMessage: message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addServiceLineRequest: (data) => {
      dispatch(addServiceLineRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal)
