export const GET_SUBSCRIBER_QUERY_REQUEST = 'GET_SUBSCRIBER_QUERY_REQUEST'
export const GET_SUBSCRIBER_QUERY_RESPONSE = 'GET_SUBSCRIBER_QUERY_RESPONSE'
export const CLEAR_SUBSCRIBER_QUERY_LIST_RESPONSE =
  'CLEAR_SUBSCRIBER_QUERY_LIST_RESPONSE'

export const GET_CONVERSATION_DETAILS_QUERY_REQUEST =
  'GET_CONVERSATION_DETAILS_QUERY_REQUEST'
export const GET_CONVERSATION_DETAILS_QUERY_RESPONSE =
  'GET_CONVERSATION_DETAILS_QUERY_RESPONSE'

export const CREATE_RESEND_QUERY_REQUEST = 'CREATE_RESEND_QUERY_REQUEST'
export const CREATE_RESEND_QUERY_RESPONSE = 'CREATE_RESEND_QUERY_RESPONSE'

export const CLEARE_RESEND_QUERY_RESPONSE = 'CLEARE_RESEND_QUERY_RESPONSE'

export const SUBSCRIBER_TICKET_INDICATOR_REQUEST =
  'SUBSCRIBER_TICKET_INDICATOR_REQUEST'
export const SUBSCRIBER_TICKET_INDICATOR_RESPONSE =
  'SUBSCRIBER_TICKET_INDICATOR_RESPONSE'
