import produce from 'immer'
import {
  GET_CATEGORY_LIST_RESPONSE,
  ADD_CATEGORY_AND_SUB_CATEGORY_RESPONSE,
  GET_CATEGORY_DELETE_RESPONSE,
  CLEAR_CATEGORY_LIST_RESPONSE,
  GET_SUBCAT_LIST_BY_CAT_ID_RESPONSE,
  CLEAR_SUB_CATEGORY_LIST_RESPONSE,
  GET_REPORT_SUBCAT_LIST_BY_CAT_ID_RESPONSE,
  GET_FILTER_CATEGORY_LIST_RESPONSE,
} from './actionType'

//Initial state for Country
export const initialState = {
  addCategoryResponse: null,
  editCountryApiResponse: null,
  countryList: [],
  countyStatus: null,
  statusApiResponse: null,
  categoryList: [],
  subCatList: [],
  reportSubCatList: [],
  categoryFilterList: [],
}

export const categoryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_CATEGORY_AND_SUB_CATEGORY_RESPONSE:
        if (action.payload.message == undefined) {
          draft.addCategoryResponse = null
        } else {
          draft.addCategoryResponse = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          }
        }
        break

      case GET_SUBCAT_LIST_BY_CAT_ID_RESPONSE:
        draft.subCatList = action.payload.subCatList
        break

      case GET_REPORT_SUBCAT_LIST_BY_CAT_ID_RESPONSE:
        draft.reportSubCatList = action.payload.reportSubCatList
        break

      case CLEAR_SUB_CATEGORY_LIST_RESPONSE:
        return {
          ...state,
          subCatList: [],
        }

      case CLEAR_CATEGORY_LIST_RESPONSE:
        return {
          ...state,
          categoryList: [],
        }

      case GET_CATEGORY_LIST_RESPONSE:
        draft.categoryList = action.payload.categoryList
        break

      case GET_CATEGORY_DELETE_RESPONSE:
        draft.statusApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case GET_FILTER_CATEGORY_LIST_RESPONSE:
        draft.categoryFilterList = action.payload.categoryList
        break

      default:
        state = { ...state }
        break
    }
  })
