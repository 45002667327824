export const SUBSCRIBE_FAQ_REQUEST = 'SUBSCRIBE_FAQ_REQUEST'
export const SUBSCRIBE_FAQ_RESPONSE = 'SUBSCRIBE_FAQ_RESPONSE'

export const GET_RECENT_FAQ_REQUEST = 'GET_RECENT_FAQ_REQUEST'
export const GET_RECENT_FAQ_RESPONSE = 'GET_RECENT_FAQ_RESPONSE'

export const GET_POPULAR_FAQ_REQUEST = 'GET_POPULAR_FAQ_REQUEST'
export const GET_POPULAR_FAQ_RESPONSE = 'GET_POPULAR_FAQ_RESPONSE'

export const OTP_VERIFICATION_REQUEST = 'OTP_VERIFICATION_REQUEST'
export const OTP_VERIFICATION_RESPONSE = 'OTP_VERIFICATION_RESPONSE'

export const RESEND_OTP_VERIFICATION_REQUEST = 'RESEND_OTP_VERIFICATION_REQUEST'
export const RESEND_OTP_VERIFICATION_RESPONSE =
  'RESEND_OTP_VERIFICATION_RESPONSE'

export const CLEAR_SUBSCRIBE_RESPONSE = 'CLEAR_SUBSCRIBE_RESPONSE'

export const GET_KEYWORDS_REQUEST = 'GET_KEYWORDS_REQUEST'
export const GET_KEYWORDS_RESPONSE = 'GET_KEYWORDS_RESPONSE'
export const CLEAR_KEYWORDS_RESPONSE = 'CLEAR_KEYWORDS_RESPONSE'

export const GET_QUERY_NOTIFICATION_REQUEST = 'GET_QUERY_NOTIFICATION_REQUEST'
export const GET_QUERY_NOTIFICATION_RESPONSE = 'GET_QUERY_NOTIFICATION_RESPONSE'

export const GET_SEARCH_REQUEST = 'GET_SEARCH_REQUEST'
export const GET_SEARCH_RESPONSE = 'GET_SEARCH_RESPONSE'
