import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  CREATE_REPORT_DOWNLOAD_APPROVE_REQUEST,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_REVIEW_REQUEST,
  DELETE_FAQ_SECTION_REQUEST,
  DELETE_REPORT_FAQ_REQUEST,
  GET_CHECK_REPORT_TITLE_REQUEST,
  GET_OTHER_CATEGORY_REQUEST,
  GET_REPORT_DETAILS_REQUEST,
  GET_REPORT_DOWNLOAD_LIST_REQUEST,
  GET_REPORT_DOWNLOAD_REQUEST,
  GET_REPORT_IS_REVIEW_REQUEST,
  GET_REPORT_KEYWORDS_REQUEST,
  GET_REPORT_LIST_FAQ_REQUEST,
  GET_REPORT_REQUEST,
} from './actionType'
import {
  clearReportDownloadListResponse,
  createReportDownloadApproveResponse,
  createReportResponse,
  createReportReviewResponse,
  createReportsKeywordsResponse,
  deleteFaqSectionResponse,
  deleteReportFaqResponse,
  getCheckReportTitleResponse,
  getFaqResponse,
  getFaqTotalCountResponse,
  getIsReportRevisionResponse,
  getOtherCategoryResponse,
  getReportDetailsRequest,
  getReportDetailsResponse,
  getReportDownloadListRequest,
  getReportDownloadListResponse,
  getReportDownloadResponse,
  getReportFaqListRequest,
  getReportResponse,
} from './action'

import { invokeApi } from '../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

function* reportFaqList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reportFaqList', data)

    if (response.status) {
      yield put(getFaqResponse(response.data.list, data.offset))
      yield put(getFaqTotalCountResponse(response.data.totalRecords))
    } else {
      yield put(getFaqResponse([], data.offset))
      yield put(getFaqTotalCountResponse(0, data.offset))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* createReport({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/createReport', data)
    if (response.status) {
      yield put(createReportResponse(response.message, SUCCESS, response.data))
    } else {
      yield put(createReportResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* deleteReportFaq({ payload: { data, reportId } }) {
  try {
    const response = yield call(invokeApi, 'post', '/deleteFaq', data)

    if (response.status) {
      if (reportId) {
        yield put(
          getReportFaqListRequest({
            reportId: reportId,
            searchText: '',
            filter: { categoryId: '', subCategoryId: '' },
          }),
        )
      }
      yield put(deleteReportFaqResponse(response.message))
    } else {
      yield put(deleteReportFaqResponse([]))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* deleteFaqSection({ payload: { data, reportId } }) {
  try {
    const response = yield call(invokeApi, 'post', '/deleteFaqSection', data)

    if (response.status) {
      if (reportId) {
        yield put(
          getReportFaqListRequest({
            reportId: reportId,
            searchText: '',
            filter: { categoryId: '', subCategoryId: '' },
          }),
        )
      }
      yield put(deleteFaqSectionResponse(response.message))
    } else {
      yield put(deleteFaqSectionResponse([]))
    }

    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* reportList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reportList', data)

    if (response.status) {
      yield put(getReportDetailsResponse([]))
      yield put(createReportReviewResponse([]))
      yield put(getReportResponse(response.data))
    } else {
      yield put(getReportResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* reportDetails({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/reportDetails/${id}`)
    if (response.status) {
      response.data?.sectionData?.reportSection?.sort((x, y) => {
        return x.orderNo - y.orderNo
      })
      response.data.sectionData?.reportSection?.forEach((section) => {
        if (section?.subSections?.length > 0) {
          section?.subSections?.sort((x, y) => {
            return x.orderNo - y.orderNo
          })

          section?.subSections?.forEach((subSection) => {
            subSection?.sectionData?.faqs.sort((faq1, faq2) => {
              return faq1.orderNo - faq2.orderNo
            })
          })
        }
        if (section.sectionData?.faqs.length > 0) {
          section.sectionData?.faqs?.sort((faq1, faq2) => {
            return faq1.orderNo - faq2.orderNo
          })
        }
      })
      yield put(
        getReportDetailsResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getReportDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* reportReview({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reportReview', data)

    if (response.status) {
      yield put(createReportReviewResponse(response.message, SUCCESS))
      if (data.reportId) {
        yield put(getReportDetailsRequest(data.reportId))
      }
    } else {
      yield put(createReportReviewResponse([]))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getReportKeywordList() {
  try {
    const response = yield call(invokeApi, 'post', '/reportsKeywords')
    if (response.status) {
      yield put(createReportsKeywordsResponse(response.data))
    } else {
      yield put(createReportsKeywordsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getReportDownloadList({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'post',
      '/reportRequestDownloadList',
      data,
    )

    if (response.status) {
      yield put(getReportDownloadListResponse(response.data.list, data.offset))
    } else {
      yield put(getReportDownloadListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createReportDownloadApprover({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'post',
      '/reportDownloadApprove',
      data,
    )
    if (response.status) {
      yield put(clearReportDownloadListResponse())
      yield put(getReportDownloadListRequest({ offset: 0, filterType: data.filterType }))
      yield put(createReportDownloadApproveResponse(response.message, SUCCESS))
    } else {
      yield put(createReportDownloadApproveResponse([]))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getReportDownload({ payload: { id ,language} }) {
  try {
    const response = yield call(
      invokeApi,
      'get',
      `/reportDownload/${id}/${language}`,
      '',
      'arraybuffer',
      true,
    )
    if (response.status) {
      var cacheBuster = Math.random();
      var urlWithCacheBuster = response.data.link + '?report=' + cacheBuster;
      // Open the window with the modified URL
      window.open(urlWithCacheBuster);
      //window.open(response.data.link)
      yield put(getReportDownloadResponse(response.message, SUCCESS))
    } else {
      yield put(getReportDownloadResponse([]))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* isReviewReport({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reviewReportSection', data)
    if (response.status) {
      yield put(
        getIsReportRevisionResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getIsReportRevisionResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* checkReport({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/checkReportTitle', data)
    if (response.status) {
      yield put(
        getCheckReportTitleResponse(response.message, SUCCESS, response.data),
      )
    } else {
      ApiResponseMessage(response.message, response.message && DANGER)

      yield put(getCheckReportTitleResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* otherCategoryList() {
  try {
    const response = yield call(invokeApi, 'post', '/otherCategoryList')

    if (response.status) {
      yield put(
        getOtherCategoryResponse(
          response.message,
          SUCCESS,
          response?.data?.list,
        ),
      )
    } else {
      ApiResponseMessage(response.message, response.message && DANGER)
      getOtherCategoryResponse(response.message, DANGER, [])
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchLaw() {
  yield takeEvery(GET_REPORT_LIST_FAQ_REQUEST, reportFaqList)
  yield takeEvery(CREATE_REPORT_REQUEST, createReport)
  yield takeEvery(DELETE_REPORT_FAQ_REQUEST, deleteReportFaq)
  yield takeEvery(DELETE_FAQ_SECTION_REQUEST, deleteFaqSection)
  yield takeEvery(GET_REPORT_REQUEST, reportList)
  yield takeEvery(GET_REPORT_DETAILS_REQUEST, reportDetails)
  yield takeEvery(CREATE_REPORT_REVIEW_REQUEST, reportReview)
  yield takeEvery(GET_REPORT_KEYWORDS_REQUEST, getReportKeywordList)
  yield takeEvery(GET_REPORT_DOWNLOAD_LIST_REQUEST, getReportDownloadList)
  yield takeEvery(GET_REPORT_IS_REVIEW_REQUEST, isReviewReport)
  yield takeEvery(GET_CHECK_REPORT_TITLE_REQUEST, checkReport)

  yield takeEvery(
    CREATE_REPORT_DOWNLOAD_APPROVE_REQUEST,
    createReportDownloadApprover,
  )
  yield takeEvery(GET_REPORT_DOWNLOAD_REQUEST, getReportDownload)
  yield takeEvery(GET_OTHER_CATEGORY_REQUEST, otherCategoryList)
}

function* reportSaga() {
  yield all([fork(watchLaw)])
}
export default reportSaga
