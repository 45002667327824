import { uniqueId } from 'lodash'
import {
  ASK_FOR_SUBSCRIBE,
  GET_USER_CATEGORIES_REQUEST,
  GET_USER_CATEGORIES_RESPONSE,
  GET_VISITOR_RESPONSE,
  GET_VISITOR_REQUEST,
  GET_COUNT_REQUEST,
  GET_COUNT_RESPONSE,
  GET_PUBLISHER_LIST_REQUEST,
  GET_PUBLISHER_LIST_RESPONSE,
} from './actionType'

export const getUserCategoriesRequest = (data) => {
  return {
    type: GET_USER_CATEGORIES_REQUEST,
    payload: { data },
  }
}

export const getUserCategoriesResponse = (data) => {
  return {
    type: GET_USER_CATEGORIES_RESPONSE,
    payload: { data },
  }
}

export const askForSubscribe = () => {
  return {
    type: ASK_FOR_SUBSCRIBE,
    payload: { id: uniqueId() },
  }
}

// visitor Api

export const getVisitorRequest = (data) => {
  return {
    type: GET_VISITOR_REQUEST,
    payload: { data },
  }
}

export const getVisitorResponse = (response) => {
  return {
    type: GET_VISITOR_RESPONSE,
    payload: { response },
  }
}

export const getCountRequest = (data) => {
  return {
    type: GET_COUNT_REQUEST,
    payload: { data },
  }
}

export const getCountResponse = (response) => {
  return {
    type: GET_COUNT_RESPONSE,
    payload: { response },
  }
}

export const getPublisherListRequest = (Data) => {
  return {
    type: GET_PUBLISHER_LIST_REQUEST,
    payload: { Data },
  }
}

export const getPublisherListResponse = (publisherList) => {
  return {
    type: GET_PUBLISHER_LIST_RESPONSE,
    payload: { publisherList },
  }
}
